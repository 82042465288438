import React from "react";

export default function Modal(props) {
  const {
    isOpen,
    width,
    onClose,
    style,
    children,
    transitionTime = 0.4,
  } = props;
  return (
    <div
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        top: "0",
        left: "0",
        transition: transitionTime + "s",
        opacity: isOpen ? "1" : "0",
        pointerEvents: isOpen ? "all" : "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "arial",
        zIndex: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "rgba(0,0,0,0.5)",
        }}
        onClick={onClose || (() => {})}
      ></div>
      <div
        style={{
          position: "absolute",
          top: isOpen ? "50%" : "45%",
          left: "50%",
          transform: `translateX(-50%) translateY(-50%)`,
          background: "#fff",
          minWidth: width ? width : "76%",
          padding: "10px",
          transition: transitionTime + "s",
          borderRadius: "1rem",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
        >
          <span onClick={onClose || (() => {})}>
            <box-icon name="x" />
          </span>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
