import React from "react";
import { _es } from "utils/func";

export const getSubstepsNum = (steps, stepIndex, substepIndex) => {
  let totalSubsteps = steps
    .slice(0, stepIndex)
    .reduce((acc, cur) => acc + cur.substeps, 0);
  return totalSubsteps + substepIndex;
};

export default function BreadCrumbs(props) {
  const {
    steps,
    step: currentStep,
    substep: currentSubstep,
    substepWidth,
    className,
    onClick,
  } = props;
  const totalSubsteps = getSubstepsNum(
    steps,
    steps.length - 1,
    steps[steps.length - 1].substeps - 1
  );
  const traversedSubsteps = getSubstepsNum(steps, currentStep, currentSubstep);

  return (
    <div
      className={`flex flex-col rounded ${className} overflow-x-auto pt-4 pl-2`}
    >
      <div style={{ width: "100%", minWidth: `${totalSubsteps * 60}px` }}>
        <div
          style={{
            height: "0.6rem",
            width: `calc(${totalSubsteps} * ${substepWidth})`,
            borderRadius: "1rem",
            background: "#ddd",
          }}
        />
        <div
          style={{
            height: "0.6rem",
            width: `calc(${traversedSubsteps} * ${substepWidth})`,
            borderRadius: "1rem",
            background: "var(--success-green)",
            transition: "0.5s",
            transform: "translateY(-100%)",
          }}
        />
        <div
          className="flex justify-between items-center w-full"
          style={{ width: `calc(${totalSubsteps} * ${substepWidth})` }}
        >
          {steps.map((step, stepIndex) => (
            <>
              <div
                className={`breadcrumbs-step w-max cursor-pointer ${_es(
                  stepIndex <= currentStep && "covered"
                )}`}
                style={{
                  transform: "translateY(-1.8rem)",
                }}
                onClick={() => onClick(stepIndex, 0)}
              >
                <div className="circle" />
                <div
                  className={`light-b mt-2 flex ${_es(
                    step === steps.length - 1 && "justify-end"
                  )}`}
                  style={{ width: "1rem" }}
                >
                  {step.label}
                </div>
              </div>

              {new Array(step.substeps - 1).fill("").map((_, substepIndex) => (
                <div
                  className={`breadcrumbs-substep w-max cursor-pointer ${_es(
                    (stepIndex < currentStep ||
                      (stepIndex === currentStep &&
                        substepIndex + 1 <= currentSubstep)) &&
                      "covered"
                  )}`}
                  style={{ transform: "translateY(-3.4rem)" }}
                  onClick={() => onClick(stepIndex, substepIndex + 1)}
                >
                  <div className="circle" />
                </div>
              ))}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
