import ApplyCoupon from "./ApplyCoupon";
import ApplyForScholarship from "./ApplyForScholarship";
import BookDemoCourse from "./BookDemoCourse";
import CertificateVerification from "./CertificateVerification";
import JoinBatch from "./JoinBatch";
import JoinDemoSession from "./JoinDemoSession";
import PurchaseCourse from "./PurchaseCourse";
import RedirectToMoodleCourse from "./RedirectToMoodleCourse";
import ShowDemoDetails from "./ShowDemoDetails";
import ReferralPartner from "./ReferalPartner";
import CreateReferralCode from "./CreateReferralCode";

export const ACTION_TYPES = {
  apply_coupon: "apply-coupon",
  book_demo_course: "book-course-demo",
  join_batch: "join-batch",
  join_demo_session: "join-demo-session",
  show_demo_details: "show-demo-details",
  purchase_course: "purchase-course",
  certificate_verification: "certificate-verification",
  redirect_to_moodle_course: "redirect-to-moodle-course",
  apply_for_scholarship: "apply-for-scholarship",
  referral_partner: "join_for_referral_partner",
  create_referral_code: "create_referral_code",
};

export const routeAction = (
  type,
  component,
  promptMessage,
  isProtected = false
) => ({
  type,
  isProtected,
  component,
  promptMessage,
});

export const routeActions = [
  routeAction(
    ACTION_TYPES.apply_coupon,
    ApplyCoupon,
    "Please login / signup to apply the coupon",
    true
  ),
  routeAction(
    ACTION_TYPES.book_demo_course,
    BookDemoCourse,
    "Please login / signup to book a demo",
    true
  ),
  routeAction(
    ACTION_TYPES.join_demo_session,
    JoinDemoSession,
    "Please login / signup to join the session",
    true
  ),
  routeAction(
    ACTION_TYPES.show_demo_details,
    ShowDemoDetails,
    "Please login to view demo details",
    true
  ),
  routeAction(
    ACTION_TYPES.purchase_course,
    PurchaseCourse,
    "Please login / signup to continue purchasing the course",
    true
  ),
  routeAction(
    ACTION_TYPES.join_batch,
    JoinBatch,
    "Please login / signup to join the batch",
    true
  ),
  routeAction(ACTION_TYPES.certificate_verification, CertificateVerification),
  routeAction(
    ACTION_TYPES.redirect_to_moodle_course,
    RedirectToMoodleCourse,
    "Please login / signup to go to the moodle course",
    true
  ),
  routeAction(
    ACTION_TYPES.apply_for_scholarship,
    ApplyForScholarship,
    "Please login / signup to apply for the scholarship",
    true
  ),
  routeAction(
    ACTION_TYPES.referral_partner,
    ReferralPartner,
    "Please login / signup to Join as A Affiliate Partner",
    true
  ),
  routeAction(
    ACTION_TYPES.create_referral_code,
    CreateReferralCode,
    "Please login / signup to Create Referral Code",
    true
  ),
];
