import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "skillstrainer-resource-library";
import { FETCH_REFERRALS } from "../graphql/queries/AccountDetails";
import { useQuery, gql } from "@apollo/client";
import axios from "../api/Api";
import { useSelector } from "react-redux";
import {englishStar,englishStartSchemeId} from "../api/Consts"

export default function ReferralPartner(props) {
  const { user, complete } = props;
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;

  console.log(props);
  const { data, loading, error } = useQuery(FETCH_REFERRALS, {
    variables: { userId: user.id },
  });

  console.log("data==", props);
  console.log("data==", data);

  const filteredData = data?.courses_referral_codes?.filter(
    (item) => item.referral_scheme?.constraints?.course_ids?.[0] === englishStar.course_id
  );

  const history = useHistory();

  useEffect(async () => {
    history.push("/referral");
  }, []);
  const handleCreateNewCode = async (data) => {
    complete();
    if (data === "referral_code_not_exists") {
      return history.push(
        `/?action=create_referral_code&&scheme_id=${englishStartSchemeId.scheme_id}&&user_id=${userId}`
      );
    } else {
      return history.push(
        `/?action=create_referral_code&&scheme_id=${filteredData[0].referral_scheme_id}&&user_id=${filteredData[0].user_id}`
      );
    }
  };

  return (
    <>
      {filteredData?.length >= 1 ? (
        <Modal isOpen={true} onClose={complete}>
          <div className="flex justify-center p-5 w-full ml-4">
            You already have an English star referral code active, do you wish
            to create a new one?
          </div>
          <div className="flex flex-row justify-center">
            <button
              onClick={() => handleCreateNewCode("referral_code")}
              className="mx-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
            >
              Yes
            </button>
            <button
              onClick={complete}
              className="mx-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
            >
              No
            </button>
          </div>
        </Modal>
      ) : (
        <Modal isOpen={true} onClose={complete}>
          <div className="p-5 w-full ml-4">
            <h1 className="text-2xl font-semibold text-japanese_indigo mb-6">
              Generate Referral Code
            </h1>
            <div className="mb-2">
              <span className="font-semibold">Course: </span> English Star - Certificate Program in English for Employability
            </div>
            <div className="mb-2">
              <span className="font-semibold">Discount: </span> 15%
            </div>
            <div className="mb-2">
              <span className="font-semibold">Commission: </span>15%
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <button
              onClick={() => handleCreateNewCode("referral_code_not_exists")}
              className="mx-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
            >
              Click to Continue
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
