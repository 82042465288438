import { gql } from "@apollo/client";

export const INSERT_JOB_APPLICATION = gql`
  mutation insertJobApplication(
    $application: [courses_job_applications_insert_input!] = {}
  ) {
    insert_courses_job_applications(objects: $application) {
      affected_rows
    }
  }
`;

export const STAR_JOB = gql`
  mutation starJob($star: [courses_starred_jobs_insert_input!] = {}) {
    insert_courses_starred_jobs(objects: $star) {
      affected_rows
    }
  }
`;

export const REMOVE_FROM_STARRED = gql`
  mutation ($user_id: bigint, $job_id: uuid) {
    delete_courses_starred_jobs(
      where: { _and: { job_id: { _eq: $job_id }, user_id: { _eq: $user_id } } }
    ) {
      affected_rows
    }
  }
`;
