import React from "react";

export default function CourseCard(props) {
  const {
    id,
    full_name: displayName,
    course_category: { name: categoryName, id: categoryId },
    image_url: courseImg,
    cost,
    students_enrolled,
    duration: courseDuration,
    nsqf_level,
    discount,
    moodle_course_id,
  } = props.data;

  const { courseProgress } = props;

  const progress =
    courseProgress &&
    courseProgress.filter((e) => {
      return e.course_id == id;
    });

  return (
    <div
      className={`box border border-gray-300 pb-4 rounded-lg width-3 hover:shadow-xl relative flex flex-col justify-between ${categoryName} ${props.className}`}
      onClick={props.onClick}
    >
      <div>
        <div className="border-bottom bg-orange-light1 absolute top-full left-6 right-6 h-1.5 rounded-b-xl"></div>
        <div className="cover-image" style={{ height: "210px" }}>
          <img
            src={courseImg && courseImg.url ? courseImg.url : courseImg}
            className="h-full w-full"
          />
        </div>
        <div className="p-3">
          <span className="button bg-pink-light w-max">{categoryName}</span>
          <p
            className="text-xl font-semibold mt-3 box-title text-gray-600 line-text"
            title={displayName}
          >
            {displayName}
          </p>
          <div className="flex items-start justify-between text my-3 font-medium">
            <p className="whitespace-nowrap">
              {students_enrolled ? students_enrolled : "34,455 Students"}
            </p>
          </div>
          <div className="flex items-center justify-between text">
            <p style={{ maxWidth: "50%" }} className="lc-2">
              {courseDuration
                ? courseDuration
                : "3 months, 36 one Hour Live Sessions"}
            </p>

            <p>
              <span className="text-red-500 font-medium">NSQF</span>{" "}
              {nsqf_level ? nsqf_level : "NA"}
            </p>
          </div>
        </div>
      </div>
      <div className="px-4">
        {/* {progress.length>0 &&(<ProgressBar bgcolor="#E76F51" progress={progress[0].currentProgress}  height={10} />)} */}

        {/* <div className="flex items-center w-full justify-between mt-5 pt-6 pb-2 border-t aabsolute bottom-0">
          <div className="flex items-center">
            <button
              className="text ml-2 font-medium"
              attr={id}
              onClick={() =>
                courseSvc.openCourse({ moodle_course_id, courseId: id })
              }
            >
              View course
            </button>
          </div>
          <div className="flex items-center">
            {discount ? (
              <div className="flex items-center mr-2 relative max-w-max">
                <div className="bg-green-600 h-1 w-full absolute top-1/2 left-2 transform -rotate-12"></div>
                <img src={RuppeeGray} className="h-4 w-4" />
                <p className="text-xl text-gray-400 font-semibold">
                  {discount}
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="flex items-center">
              {cost ? (
                <>
                  <img src={Ruppee} className="h-6 w-6" />
                  <p className="text-2xl green-dark font-semibold">{cost}</p>
                </>
              ) : (
                <p className="text-2xl green-dark font-semibold">Free</p>
              )}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
