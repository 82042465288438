import Toast from "utils/Toast";
import userSvc from "services/User";
import { useEffect } from "react";
import { sleep } from "utils/func";

export default function CertificateVerification(props) {
  const { params, complete } = props;

  const { id } = params;

  const getCertificate = async (id) => {
    const certificate_link = await userSvc.getCertificateLinkById(id);
    if (certificate_link) {
      Toast.success("Certificate Verified Successfully");
      await sleep(3000);
      window.location.href = certificate_link;
    } else Toast.error("Certificate Verification Failed");
  };

  useEffect(() => {
    getCertificate(id);
  }, []);

  complete();

  return null;
}
