import { BiTimeFive } from "react-icons/bi";
import { MdPeopleAlt } from "react-icons/md";

export default function CourseCard() {
  return (
    <>
      <div className="w-full lg:w-4/12 p-4">
        <div className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl relative">
          <div className="relative">
            {/* <img
              className="h-56 w-full object-cover object-center shadow-xl"
              src={courseImg && courseImg.url ? courseImg.url : courseImg}
              alt="course-img"
            /> */}
            <img
              className="h-56 w-full object-cover object-center border-b-2"
              src="https://lms.skillstrainer.in/moodle/pluginfile.php/1550098/course/overviewfiles/3-SWAR%20logo%20options_25.09.2021.jpg"
              alt="course-img"
            />
            <div className="absolute top-4 left-2 font-semibold">
              {/* <span className="bg-white text-xs rounded-lg p-2 shadow-lg border">
                {categoryName}
              </span> */}
              <span className="bg-white text-xs rounded-lg p-2 shadow-lg border">
                Career
              </span>
            </div>
          </div>
          <div className="p-4 h-auto md:h-40 lg:h-56">
            {/* <h1
              className="text-base font-semibold text-gray-600"
              title={displayName}
            >
              {displayName}
            </h1> */}
            <h1
              className="text-left text-base font-semibold text-gray-600"
              title="SWAR English Course"
            >
              SWAR English Course
            </h1>

            <div className="w-full lg:w-11/12 relative lg:absolute bottom-0 mx-auto my-2 md:bottom-16">
              <div className="flex flex-row justify-between">
                {/* <p className="text-xs">
                  {is_live_course
                    ? "Live Classes"
                    : "Self paced digital content"}
                </p> */}
                <p className="text-xs text-left">Self Paced Digital Content</p>
                <a
                  href="https://www.youtube.com/watch?v=riE-VMMXMHI"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-xs font-semibold"
                >
                  NSQF Level{" "}
                  {/* <span className="text-orange">
                    {nsqf_level ? nsqf_level : "N.A."}
                  </span> */}
                  3
                </a>
              </div>

              <div className="flex flex-row my-2 justify-between">
                <p className="flex flex-row items-center justify-center">
                  <BiTimeFive className="text-orange mx-1" />{" "}
                  {/* {courseDuration ? courseDuration : "36 Hours"} */}
                  36 Hours
                </p>
                <p className="flex flex-row items-center justify-center">
                  <MdPeopleAlt className="text-orange mx-1" />
                  {/* {students_enrolled ? students_enrolled : "N.A."} */}5
                </p>
              </div>

              <div className="flex flex-row my-4 justify-between">
                  <p className="text-sm font-semibold text-japanese_indigo flex flex-row items-center justify-center">
                        Certification:
                  </p>
                  <img width={100} src="./black.png" alt="certification" />
                  <img width={100} src="./black.png" alt="certification" />
              </div>
            </div>

            <div
              onClick={(e) => e.stopPropagation()}
              className="flex item-center justify-center w-full"
            >
              <div className="relative mt-2 lg:absolute bottom-0 mb-4">
                <button
                  className="w-full bg-orange hover:opacity-90 px-6 py-2 text-white rounded-lg"
                  // onClick={openCourse}
                >
                  <p className="w-full text-sm font-medium">
                    {/* {isActive ? (
                      "View Course"
                    ) : cost ? (
                      <>
                        <span>Get Enrolled for </span>
                        <span className="font-bold">₹{cost}</span>
                      </>
                    ) : (
                      "Get Enrolled for Free!"
                    )} */}
                    Gift this course for ₹1000
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
