import _ from "lodash";
import React, { useEffect, useState } from "react";
import { wireEventValue } from "utils/func";
import { getCountries, getDataFromPincode } from "utils/resources";

export default function AddressField(props) {
  const { value, onChange: _onChange, className } = props;
  const onChange = (val) =>
    _onChange(
      _.pick(val, [
        "id",
        "pincode",
        "country",
        "country_iso_code",
        "house_number",
        "location",
        "district",
        "city_town",
        "state",
      ])
    );
  // Remove extra fields
  useEffect(() => onChange(value), []);

  const updateDetail = (detail) => (val) =>
    onChange({ ...(value || {}), [detail]: val });

  const [countries, setCountries] = useState();
  useEffect(
    () =>
      getCountries().then((countries) => {
        setCountries(countries);

        // Setting India as the default country
        const india = countries.find((c) => c.name.toLowerCase() === "india");
        if (india && !value?.country_iso_code)
          handleCountryChange(india.iso, countries);
      }),
    []
  );
  const handleCountryChange = (countryIso, countryList = countries) => {
    const country = countryList.find((c) => c.iso === countryIso);
    updateDetail("country")(country.name);
    updateDetail("country_iso_code")(countryIso);
  };

  const [pincodeRes, setPincodeRes] = useState();
  const [isPincodeOpen, setIsPincodeOpen] = useState();
  const handlePincodeChange = (pincode) => {
    getDataFromPincode({ country: value.country_iso_code, pincode })
      .then((res) => {
        console.log(res);
        setPincodeRes(res);
      })
      .catch(() => setPincodeRes(null));
    updateDetail("pincode")(pincode);
  };
  const applyPincodeRes = () => onChange({ ...value, ...pincodeRes });

  return (
    <div className={className}>
      <select
        className="input my-1"
        onChange={wireEventValue(handleCountryChange)}
        value={value?.country_iso_code}
      >
        {countries &&
          countries.map((country) => (
            <option value={country.iso}>{country.name}</option>
          ))}
      </select>
      <div className="relative my-1">
        <input
          type="text"
          className="input"
          placeholder="Pincode"
          value={value?.pincode}
          onChange={wireEventValue(handlePincodeChange)}
          onFocus={() => setIsPincodeOpen(true)}
          onBlur={() => setIsPincodeOpen(false)}
          autoComplete="off"
        />
        {isPincodeOpen && pincodeRes && (
          <div
            className="absolute top-full w-full left-0 bg-white shadow-md rounded-xl hover:bg-gray-200 p-3 cursor-pointer"
            onPointerDown={applyPincodeRes}
          >
            {pincodeRes.city_town}, {pincodeRes.district}, {pincodeRes.state}
          </div>
        )}
      </div>
      <input
        type="text"
        className="input my-1"
        placeholder="House no."
        onChange={wireEventValue(updateDetail("house_number"))}
        value={value?.house_number}
      />
      <input
        type="text"
        className="input my-1"
        placeholder="Location"
        onChange={wireEventValue(updateDetail("location"))}
        value={value?.location}
      />
      <input
        type="text"
        className="input my-1"
        placeholder="City/Town"
        onChange={wireEventValue(updateDetail("city_town"))}
        value={value?.city_town}
      />
      <input
        type="text"
        className="input my-1"
        placeholder="District"
        onChange={wireEventValue(updateDetail("district"))}
        value={value?.district}
      />
      <input
        type="text"
        className="input my-1"
        placeholder="State"
        onChange={wireEventValue(updateDetail("state"))}
        value={value?.state}
      />
    </div>
  );
}
