import { gql } from "@apollo/client";

export const GET_COURSE_CERTIFICATE_TEMPLATES_BY_ID = gql`
  query GetCertificateTemplatesByID($id: Int) {
    courses_course_certificate_templates(where: { id: { _eq: $id } }) {
      certificate_name
      certificate_url
      director_name
      id
      partnerlogo1
      partnerlogo2
      partnerlogo3
    }
  }
`;

export const GET_COURSE_CERTIFICATE_DATA = gql`
  query getPreviewCertData($mapping_id: Int) {
    courses_course_template_certificates_data(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      bg_image_url
      course_name
      course_name_size
      course_x_axis
      course_y_axis
      date_size
      date_x_axis
      date_y_axis
      enroll_size
      enroll_x_axis
      enroll_y_axis
      enrollment_id
      id
      issued_date
      mapping_id
      nsqf
      nsqf_size
      nsqf_x_axis
      nsqf_y_axis
      partner_name
      partner_size
      partner_x_axis
      partner_y_axis
      per_size
      per_x_axis
      per_y_axis
      percentage_grade
      project_name
      project_size
      project_x_axis
      project_y_axis
      qr_size
      qr_x_axis
      qr_y_axis
      user_name
      user_text_size
      user_x_axis
      user_y_axis
      grade
      grade_x_axis
      grade_y_axis
      grade_size
      enrollment_id_element
      course_name_element
      grade_element
      issued_date_element
      nsqf_element
      partner_name_element
      percentage_element
      project_element
      qr_element
      user_name_element
    }
  }
`;

export const USER_COURSE_CERTIFICATE_ISSUE = gql`
  query UserCourseCertificateIssue($course_id: Int, $user_id: Int) {
    courses_course_user_certificate_issue(
      where: { course_id: { _eq: $course_id }, user_id: { _eq: $user_id } }
    ) {
      issue_date
      enrollment_id
      user_id
      mapping_id
    }
  }
`;

export const CERTIFICATE_VERIFY = gql`
  query MyQuery($enrollment_id: Int, $mapping_id: Int) {
    courses_course_user_certificate_issue(
      where: {
        enrollment_id: { _eq: $enrollment_id }
        mapping_id: { _eq: $mapping_id }
      }
    ) {
      certificate_mapping {
        bg_image_url
        course_name
        course_name_size
        course_x_axis
        course_y_axis
        date_size
        date_x_axis
        date_y_axis
        enroll_size
        enroll_x_axis
        enroll_y_axis
        enrollment_id
        issued_date
        mapping_id
        nsqf
        nsqf_size
        nsqf_x_axis
        nsqf_y_axis
        partner_name
        partner_size
        partner_x_axis
        partner_y_axis
        per_size
        per_x_axis
        per_y_axis
        percentage_grade
        project_name
        project_size
        project_x_axis
        project_y_axis
        qr_size
        qr_x_axis
        qr_y_axis
        user_name
        user_text_size
        user_x_axis
        user_y_axis
      }
      issue_date
      enrollment_id
      certificate_user_mapping {
        name
      }
    }
  }
`;

export const GET_USER_CERTIFICATE_DATA = gql`
  query UserCertificateData($user_id: bigint, $course_id: bigint) {
    courses_certificates(
      where: { user_id: { _eq: $user_id }, course_id: { _eq: $course_id } }
    ) {
      certificate_pdf_url
    }
  }
`;
