import { api } from "./User";

export function create_razorpay_order(data) {
  return api("create_order", data);
}

export function create_razorpay_subscription(data) {
  return api("subscription_payment", data);
}

export function verify_order(data) {
  return api("verify_order", data);
}
