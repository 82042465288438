import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { GET_REFERRAL_SCHEMES } from "graphql/queries/Referrals";
import Modal from "components/Modal";
import Toast from "wrappers/Toast";
import userSvc from "services/User";
import axios from "../../api/Api";
import { useHistory } from "react-router-dom";

export default function referralschemes() {
  const [openedReferralScheme, setOpenedReferralScheme] = useState({});
  const [referralCode, setreferralCode] = useState("");
  const [referralSchemeOpen, setReferralSchemeOpen] = useState();
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  // const [referralCodesDetail, setReferralCodesDetail] = useState([]);
  const { data } = useQuery(GET_REFERRAL_SCHEMES);
  const history = useHistory();

  const checkAndAddPaymentDetails = async () => {
    // more specified checks can be applied using regex
    if (referralCode.length == 0) {
      Toast.error("Please enter referral code");
      return;
    }

    // is referral code unique
    let checkReferral = await userSvc
      .checkReferralCodeAvailability(referralCode)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Toast.error("Error occured while checking uniqueness!");
        return;
      });

    if (!checkReferral) {
      return;
    }
    if (checkReferral.length) {
      Toast.error("Referral code is already in use!");
      return;
    }

    let insertObj = {
      user_id: userId,
      referral_code: referralCode,
      referral_scheme_id: openedReferralScheme.id,
    };

    userSvc
      .insertReferralCode(insertObj)
      .then((res) => {
        Toast.success("Added Successfully!");
      })
      .catch((err) => {
        Toast.error("Error occured while adding referral code!");
        return;
      });
  };

  const columns = [
    {
      name: "Make Referral Code",
      width: "150px",
      cell: (row) => (
        <button
          className="mx-2 rounded-md py-1 px-5 bg-orange text-white font-semibold"
          onClick={async () => {
            console.log("row==", row);
            // const data = {
            //   id: row.id,
            //   user_id: userId,
            // };
            history.push(
              `/?action=create_referral_code&&scheme_id=${row.id}&&user_id=${userId}`
            );
            // const response = await axios.post("/create_referral_code", data);
            // const clonedRow = { ...row };
            // clonedRow["referral_code_created"] = response.data?.referral_code;

            // setReferralSchemeOpen(true);
            // setOpenedReferralScheme(clonedRow);
          }}
          id={row.ID}
        >
          Add
        </button>
      ),
      button: true,
    },
    {
      name: "Scheme No.",
      cell: (row, index) => `${row.id}`,
    },
    {
      name: "Scheme Name",
      width: "200px",
      cell: (row, index) => `${row.name}`,
    },
    {
      name: "Applicable On",
      width: "200px",
      cell: (row, index) => `${row.action}`,
    },
    {
      name: "Referrer Commission Type",
      width: "200px",
      cell: (row, index) => row.referrer_reward.type,
    },
    {
      name: "Referrer Commission Value",
      width: "200px",
      cell: (row, index) => row.referrer_reward.value,
    },
    {
      name: "Referee Commission Type",
      width: "200px",
      cell: (row, index) => row.referee_reward.type,
    },
    {
      name: "Referree Commission Value",
      width: "200px",
      cell: (row, index) => row.referee_reward.value,
    },
    {
      name: "Applicable Courses Ids",
      width: "200px",
      cell: (row, index) =>
        row.constraints.course_ids
          ? row.constraints.course_ids.toString()
          : "All",
    },
    {
      name: "Created At",
      width: "200px",
      cell: (row, index) => `${row.created_at.split("T")[0]}`,
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        data-key={columns}
        data={data?.courses_referral_scheme || []}
        // onRowClicked={(row) => {
        //   setReferralSchemeOpen(true);
        //   setOpenedReferralScheme(row);
        // }}
        selectableRowsHighlight={false}
        highlightOnHover
      />
      <Modal
        isOpen={referralSchemeOpen}
        onClose={() => setReferralSchemeOpen(false)}
      >
        <div style={{ maxHeight: "40rem" }} className="flex font-poppins">
          <div className="flex-1 flex flex-col">
            <div className="flex h-full w-full">
              <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-scroll mb-8">
                <div className="flex w-full mx-auto px-6">
                  <div className="flex flex-col w-full h-full">
                    <h1 className="text-2xl font-semibold text-japanese_indigo mx-2">
                      Referral Scheme
                    </h1>
                    <div className="flex flex-col justify-evenly my-4">
                      <div className="flex p-4">
                        <div className="my-auto  font-semibold rounded-md mr-4 w-52">
                          <span className="text-orange">Scheme Number: </span>
                          {openedReferralScheme?.id}
                        </div>
                        <div className="my-auto  font-semibold rounded-md mr-4 w-52">
                          <span className="text-orange">Name: </span>
                          {openedReferralScheme?.name}
                        </div>
                      </div>
                      <div className="flex p-4">
                        <div className="my-auto text-orange font-semibold rounded-md mr-4 w-52">
                          Referral Code
                        </div>
                        <input
                          type="text"
                          className="input"
                          disabled
                          placeholder="Enter the referral code (unique)"
                          value={openedReferralScheme.referral_code_created}
                          onChange={({ target: { value } }) =>
                            setreferralCode(value)
                          }
                        />
                      </div>
                    </div>
                    {/* <span className="justify-end my-4">
                      <button
                        className="px-4 bg-orange text-white font-semibold p-2 rounded-md text-lg"
                        onClick={checkAndAddPaymentDetails}
                      >
                        Submit
                      </button>
                    </span> */}
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
