import moment from "moment";

export default function OnboardingDate({ data, onChange, value }) {
  const { id, type, displayName, placeHolder } = data;

  return (
    <>
      <label htmlFor={id} className="block text-lg font-semibold text-gray-800">
        {displayName}
      </label>
      <input
        type={type}
        id={id}
        placeholder={placeHolder}
        className={`block w-full sm:text-sm rounded-md border-none bg-transparent outline-none text-gray-800 datetime-m uppercase datetime-field-bg-japanese_indigo-light datetime-p datetime-text-none datetime-calendar-orange datetime-width-min mt-1`}
        onChange={(e) => onChange({ target: { value: e.target.value } })}
        value={value}
        min="1900-01-01"
        max={moment(new Date("2010-12-31T00:00:00")).format("Y-MM-DD")}
      />
    </>
  );
}
