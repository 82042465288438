import { useParams } from "react-router";
import razorpayService from "services/razorpay";
import React, { useState, useEffect } from "react";
import Container from "./Container";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function CourseOrder() {
  const history = useHistory();

  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState({});

  useEffect(async () => {
    const order = await razorpayService.GetOrderDetails(id);
    setOrderDetails(order);
  }, []);

  return (
    <Container>
      {orderDetails && (
        <div className="row">
          <div className="jumbotron">
            <div class="p-4">
              <h1 class="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
                Your Order Has Been Received
              </h1>
            </div>

            <h1 class="font-semibold  text-center my-2 px-4 text-lg">
              Thank You For Your Payment, it’s {orderDetails.order_status}
            </h1>

            <h1 class="font-semibold  text-center my-2 px-4 text-lg">
              Your Order No. Is: #{orderDetails.id}{" "}
            </h1>
            <h1 class="font-semibold  text-center my-2 px-4 text-lg">
              You Will Receive An Order Confirmation Email With Details Of Your
              Order
            </h1>
            {/* <center>
              <div class="flex flex-row border-t-2 py-4 mt-4">
                <button
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-center p-2 text-white font-semibold bg-orange rounded-md"
                  onClick={() =>
                    history.push("/course/view/" + orderDetails.course_id)
                  }
                >
                  View Course
                </button>
              </div>
            </center> */}
          </div>
        </div>
      )}
    </Container>
  );
}
