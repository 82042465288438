import InputRange from "react-input-range";

export default function ScholarshipFilter() {
  return (
    <>
      <nav class="flex w-1/4 h-full rounded-lg border-2 mx-2 bg-white">
        <div class="w-full flex mx-auto px-6 py-8 ">
          <div class="w-full h-full overflow-y-auto">
            <div className="flex flex-col">
              <p className="text-sm font-semibold my-3">Fee</p>
              {/* <input type="range" min="1000" max="100000" value="5000" id="" /> */}
              <div className="font-slate-100 pl-4 pr-6 pb-2">
                <InputRange maxValue={1000} minValue={0} draggableTrack />
              </div>

              <p className="text-sm font-semibold my-3">Scholarship Amount</p>
              {/* <input type="range" min="0" max="100000" value="5000" id="" /> */}
              <div className="font-slate-100 pl-4 pr-6 pb-3">
                <InputRange maxValue={100000} minValue={0} draggableTrack />
              </div>
              <p className="text-sm font-semibold my-3">City</p>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">All Cities</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">Bangalore</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">New Delhi</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">Chennai</p>
              </label>

              <p className="text-sm font-semibold my-3">College</p>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">All Colleges</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">Christ, Banglore</p>
              </label>

              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">NMIMS, Mumbai</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">SRM, Chennai</p>
              </label>

              <p className="text-sm font-semibold my-3">Course</p>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">All Courses</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">B.Com / B.A / B.Sc</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">B.Tech</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">Diploma</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">ITI</p>
              </label>

              <p className="text-sm font-semibold my-3">By Gender</p>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">Male</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">Female</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">Other</p>
              </label>

              <p className="text-sm font-semibold my-3">By categories</p>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">All Categories</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">General</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">OBC</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">SC</p>
              </label>
              <label className="flex flex-row">
                <input type="checkbox" />
                <p className="mx-2">ST</p>
              </label>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
