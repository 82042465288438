import React from "react";
import { useSelector } from "react-redux";
import OngoingCourseListItem from "./userongoingcourse/OngoingCourseListItem";

export default function UserOngoingCourse(props) {
  const { courses } = props;

  const auth = useSelector(({ auth }) => auth);
  const user = auth.user?.db_user;

  return (
    <div>
      {courses &&
        courses.map((course) => (
          <OngoingCourseListItem user={user} course={course} />
        ))}
    </div>
  );
}
