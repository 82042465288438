import React, { useEffect } from "react";
import { STRLContextProvider } from "skillstrainer-resource-library";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "boxicons";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { env, graphqlUri, graphqlWsUri } from "./api/Consts";
import { getJwtToken } from "./utils/Auth";
import AuthWrapper from "./wrappers/Auth";
import Index from "pages/Index";
import LiveCourse from "components/LiveCourse";
import Batches from "components/Batches";
import SelectedBatches from "components/SelectedBatches";
import CourseWrapper from "wrappers/Course";
import { ToastProvider } from "wrappers/Toast";
import { condObj } from "utils/func";
import Dashboard from "pages/Dashboard";
import ReactGA from "react-ga";
import { RecommendedCourses } from "components/recommendedcourses";
import CourseDetail from "components/recommendedcourses/course-detail";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import RouteActionsSwitch from "components/RouteActionsSwitch";
import { routeActions } from "route-actions";
import queryClient from "hooks/queryClient";

import {
  QueryClientProvider
} from '@tanstack/react-query';

const httpLink = createHttpLink({
  uri: graphqlUri,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: graphqlWsUri,
    connectionParams: {
      reconnect: true,
    },
  })
);

const requestLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getJwtToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...condObj(token, { authorization: `Bearer ${token}` }),
    },
  };
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          courses_skillstrainer_jobs: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  link: authLink.concat(requestLink),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
});

// initilize analytics
if (env.isProduction) {
  const TRACKING_ID = "UA-224886564-3";
  ReactGA.initialize(TRACKING_ID);
}

const App = () => {
  //tracking routes
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const env = process.env.REACT_APP_BUILD_ENV;

  return (
    <QueryClientProvider client={queryClient}>
     <ApolloProvider client={apolloClient}>
      <ToastProvider>
        <Router>
          <AuthWrapper>
            <STRLContextProvider
              multiLang={{
                isInEditableMode: false,
              }}
              request={{
                env,
              }}
            >
              <CourseWrapper>
                <Switch>
                  <Route path="/" component={Index} />
                  <Route path="/Dashboard" exact component={Dashboard}></Route>
                  <Route path="/LiveCourse" component={LiveCourse}></Route>
                  <Route path="/Batches" exact component={Batches}></Route>
                  <Route
                    path="/SelectedBatches"
                    exact
                    component={SelectedBatches}
                  ></Route>

                  {/* Courses */}
                  <Route
                    path="/courses/:courseId"
                    exact
                    component={CourseDetail}
                  />
                  <Route
                    path="/courses"
                    exact
                    component={RecommendedCourses}
                  ></Route>
                </Switch>

                <RouteActionsSwitch actions={routeActions} />
              </CourseWrapper>
            </STRLContextProvider>
          </AuthWrapper>
        </Router>
      </ToastProvider>
      <ToastContainer />
    </ApolloProvider>
    </QueryClientProvider>
   
  );
};

export default App;
