import React from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { GET_ALL_JOB_APPLICATIONS } from "graphql/queries/Jobs";
import JobCard from "./jobcard";

function AppliedJobs({ starredJobsQuery, isJobStarred }) {
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const { data } = useQuery(GET_ALL_JOB_APPLICATIONS, {
    variables: {
      userId,
    },
  });

  return (
    <div className="p-4">
      <div class="flex h-screen">
        <div class="flex-1 flex flex-col overflow-visible">
          <div class="h-full w-full">
            <main class="max-h-screen flex flex-col bg-white overflow-x-hidden overflow-y-auto mb-14">
              <div class="flex w-full mx-auto px-6 py-8">
                <div class="flex flex-col w-full h-full">
                  {data?.courses_job_applications?.map((item) => {
                    let isStarred = isJobStarred(item.job);
                    return (
                      <JobCard
                        userId={userId}
                        isStarred={isStarred}
                        starredJobsQuery={starredJobsQuery}
                        jobApplication={item}
                        key={item.job.id}
                        title={item.job.title}
                        description={item.job.description}
                        social_categories={item.job.social_categories}
                        gender={item.job.gender}
                        organization_name={item.job.partner.organization_name}
                        salary_ctc={item.job.salary_ctc}
                        min_age={item.job.min_age}
                        min_experience={item.job.min_experience}
                        max_age={item.job.max_age}
                        logo={item.job.partner.logo}
                        created_at={item.job.created_at}
                        details={item.job}
                      />
                    );
                  })}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppliedJobs;
