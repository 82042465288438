import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SetSearchProps } from "redux/actions/Course";
import { wireEventValue } from "utils/func";

export default function CourseSearchBox(props) {
  const dispatch = useDispatch();
  const course = useSelector(({ course }) => course);

  return (
    <div className="hidden md:block bg-white relative p-1 rounded-md border-2 border-orange">
      <div className="absolute top-3 left-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 mr-1 mb-2 text-orange"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
      <input
        className="ml-10 border-white rounded-md focus:outline-none mx-2"
        type="search"
        placeholder="Search course"
        onChange={wireEventValue((value) =>
          dispatch(SetSearchProps({ keywords: value }))
        )}
        value={course.searchProps.keywords}
      />
      <Link to="/courses">
        <button className="text-white rounded-md text-lg px-7 py-1 bg-orange font-semibold">
          Search
        </button>
      </Link>
    </div>
  );
}
