/* eslint-disable no-unused-vars */
import moment from "moment";
import Modal from "components/Modal";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { MdCategory } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
import { BsGenderAmbiguous } from "react-icons/bs";
import { IoPeopleSharp } from "react-icons/io5";
import { GiBackwardTime } from "react-icons/gi";
import Toast from "../../utils/Toast";
import { INSERT_SCHOLARSHIP_APPLICATION } from "graphql/mutations/Scholarships";

export default function ScholarshipDetailsModal(props) {
  const auth = useSelector(({ auth }) => auth);

  const [addScholarshipApplication, { data, loading }] = useMutation(
    INSERT_SCHOLARSHIP_APPLICATION
  );

  const submitScholarshipApplication = async () => {
    const submitValues = {
      variables: {
        application: {
          partner_id: props.details.partner_id,
          scholarship_id: props.details.id,
          user_id: auth.user.db_user.id,
        },
      },
    };
    console.log(submitValues);

    const res = await addScholarshipApplication(submitValues);
    console.log(res);
    if (res.data.insert_courses_scholarship_partner_user.affected_rows) {
      console.log(res);
      Toast.success("Applied to scholarship successfully!");
      props.setScholarshipDetailsOpen(false);
    } else {
      Toast.error("An error occured, please try again later!");
    }
  };

  return (
    <>
      <Modal
        isOpen={props.scholarshipDetailsOpen}
        onClose={() => props.setScholarshipDetailsOpen(false)}
      >
        <div style={{ height: "40rem" }} className="flex font-poppins">
          <div className="flex-1 flex flex-col">
            <div className="flex h-full w-full">
              <main className="flex flex-col w-4/6 bg-white overflow-x-hidden overflow-y-scroll mb-8">
                <div className="flex w-full mx-auto px-6">
                  <div className="flex flex-col w-full h-full">
                    <div className="flex flex-row items-center">
                      <img
                        src={
                          props.details.partner.logo
                            ? props.details.partner.logo
                            : "http://placehold.jp/150x150.png"
                        }
                        alt="work-partner-img"
                        className="w-16 h-16 rounded-md border-2"
                      />
                      <div className="flex flex-row w-full justify-between">
                        <div className="flex flex-col">
                          <h1 className="text-2xl font-semibold text-japanese_indigo mx-2">
                            {props.details.title}
                          </h1>
                          <h1 className="text-sm font-semibold text-gray-500 mx-2">
                            by {props.details.partner.organization_name}
                          </h1>
                        </div>

                        <div className="flex flex-row item-center justify-center">
                          <GiBackwardTime
                            size={20}
                            className="text-orange my-auto"
                          />
                          <p className="text-xs text-orange my-auto">
                            Posted{" "}
                            {moment(
                              props.details.created_at,
                              "YYYYMMDD"
                            ).fromNow()}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col justify-evenly my-4">
                      <div className="flex flex-row my-2">
                        <MdCategory
                          className="text-japanese_indigo"
                          size={20}
                        />
                        <p className="font-semibold text-sm text-japanese_indigo mx-2">
                          {props.details.social_category === 0
                            ? "General Only"
                            : props.details.social_category === 1
                            ? "SC Only"
                            : props.details.social_category === 2
                            ? "ST Only"
                            : props.details.social_category === 3
                            ? "OBC Only"
                            : props.details.social_category === 4
                            ? "Physically Challenged Only"
                            : props.details.social_category === 5
                            ? "Orphans Only"
                            : "All Social Categories"}
                        </p>
                      </div>
                      <div className="flex flex-row my-2">
                        <FaMoneyBillAlt
                          className="text-japanese_indigo"
                          size={20}
                        />
                        <p className="font-semibold text-sm text-japanese_indigo mx-2">
                          ₹{props.details.amount}
                        </p>
                      </div>
                      <div className="flex flex-row my-2">
                        <BsGenderAmbiguous
                          className="text-japanese_indigo"
                          size={20}
                        />
                        <p className="font-semibold text-sm text-japanese_indigo mx-2">
                          {props.details.gender === 0
                            ? "Male Only"
                            : props.details.gender === 1
                            ? "Female Only"
                            : props.details.gender === 2
                            ? "Others"
                            : "All Genders"}
                        </p>
                      </div>
                      <div className="flex flex-row my-2">
                        <IoPeopleSharp
                          className="text-japanese_indigo"
                          size={20}
                        />
                        <p className="font-semibold text-sm text-japanese_indigo mx-2">
                          Age{" "}
                          {props.details.min_age
                            ? props.details.min_age
                            : "NIL"}{" "}
                          -{" "}
                          {props.details.max_age
                            ? props.details.max_age
                            : "NIL"}{" "}
                          Years
                        </p>
                      </div>
                    </div>

                    <h2 className="text-black text-lg font-semibold my-2">
                      Scholarship Description
                    </h2>
                    <p>{props.details.description}</p>

                    <h2 className="text-black text-lg font-semibold my-2">
                      Scholarship Additional Details
                    </h2>
                    <p>{props.details.description}</p>
                  </div>
                </div>
              </main>
              <nav class="flex w-2/6 h-full rounded-lg border-2 mx-2 bg-white">
                <div class="w-full flex mx-auto px-6 py-8">
                  <div class="w-full h-full">
                    <div className="flex justify-evenly flex-col">
                      <h2 className="text-japanese_indigo text-lg font-semibold my-2">
                        Application Status
                      </h2>
                      <p className="text-xs italic">
                        Ensure to have all ticks before you click Apply!
                      </p>

                      <div className="my-2 flex flex-row">
                        <img src="status-tick.svg" alt="tick" />
                        <p className="mx-2">First Name</p>
                      </div>

                      <div className="my-2 flex flex-row">
                        <img src="status-tick.svg" alt="tick" />
                        <p className="mx-2">Last Name</p>
                      </div>

                      <div className="my-2 flex flex-row">
                        <img src="status-tick.svg" alt="tick" />
                        <p className="mx-2">Phone No.</p>
                      </div>

                      <div className="my-2 flex flex-row">
                        <img src="status-tick.svg" alt="tick" />
                        <p className="mx-2">Email ID</p>
                      </div>
                      <div className="my-2 flex flex-row">
                        <img src="status-tick.svg" alt="tick" />
                        <p className="mx-2">Education Qualification</p>
                      </div>

                      <button
                        onClick={() => submitScholarshipApplication()}
                        className="w-full bg-orange text-white font-semibold text-lg my-2 p-2 rounded-lg"
                      >
                        {loading ? "Please wait" : "Apply"}
                      </button>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
