import Container from "components/Container";
import ExpandedLoader from "components/ExpandedLoader";
import Modal from "components/Modal";
import _ from "lodash";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ApplyCoupon, FetchCoupons } from "redux/actions/Course";
import { wireEventValue } from "utils/func";

export default function Coupons(props) {
  const { auth, course: courseStore } = useSelector(({ auth, course }) => ({
    auth,
    course,
  }));
  const user = auth?.user?.db_user;
  const dispatch = useDispatch();

  const coupons = courseStore.coupons;

  useEffect(() => {
    if (user.id) dispatch(FetchCoupons(user.id));
  }, [user.id]);

  const [couponCode, setCouponCode] = useState();

  console.log("couponCode==", couponCode);

  return (
    <Container>
      <div className="p-4">
        <div className="text-3xl font-semibold">Coupons</div>
        <div className="flex p-4">
          <input
            type="text"
            className="input"
            id="coupon_code"
            placeholder="Enter the code here"
            value={couponCode}
            onChange={wireEventValue(setCouponCode)}
          />
          <button
            className="text-white font-semibold bg-orange rounded-md ml-2 px-4"
            style={{ width: "200px" }}
            onClick={() => {
              dispatch(ApplyCoupon(couponCode.toUpperCase(), user && user.id));
              setCouponCode();
            }}
          >
            Apply Code
          </button>
        </div>
        {coupons && coupons.length ? (
          <div className="mt-10 bg-white rounded-lg shadow-xl">
            <div
              className="grid grid-cols-3 border-l-2 border-r-2 border-t-2 rounded-t-md p-2"
              style={{ gridTemplateColumns: "0.2fr 0.3fr 1.5fr" }}
            >
              <div className="font-semibold">S. No.</div>
              <div className="font-semibold">Coupon Code</div>
              <div className="font-semibold">Courses</div>
            </div>
            {coupons.map((coupon, index) => (
              <div
                className="grid grid-cols-3 border-2 border-t-0 p-2"
                style={{ gridTemplateColumns: "0.2fr 0.3fr 1.5fr" }}
              >
                <div className>{index + 1 + ""}</div>
                <div className="text-orange">{coupon.code}</div>
                <div>
                  <ul>
                    {(coupon.courses || []).map((course) => (
                      <li> • {course.full_name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        ) : coupons === null ? (
          <div className="flex-center w-full h-full">
            <ExpandedLoader />
          </div>
        ) : (
          <div className="flex-center w-full text-2xl font-semibold text-gray-300">
            You haven't applied any coupons
          </div>
        )}
      </div>
    </Container>
  );
}
