import React from "react";
import { CenturyView } from "react-calendar";
import { BsJustify } from "react-icons/bs";

export default function demo1() {
  return (
    <div>
      <h2 style={{ textAlign: "Center", fontSize: "30px" }}>Quiz Test 1</h2>
      <div className="flex justify-evenly ">
        <div className="font-semibold">Number of question: 5</div>
        <div className="font-semibold">To pass 50% or Higher</div>
      </div>
      <div
        className="text-blue outer-circle-border
       hover:bg-blue flex h-60 w-60
       cursor-pointer flex-col items-center
        justify-center rounded-full 
        cricle-width bg-white uppercase tracking-wide shadow-lg  m-auto mt-20"
      >
        <div
          className="text-blue inner-circle-border
       hover:bg-blue flex h-40 w-40
       cursor-pointer flex-col items-center
        justify-center rounded-full 
        cricle-width bg-white uppercase tracking-wide shadow-lg  m-auto"
        >
          Your Best Score 10%
        </div>
      </div>
      <div className="flex gap-10 mt-10">
        <div className="ml-5 w-1/3 h-12 border-2 border-orange rounded-lg">
          <div className="flex justify-between">
            <span className="w-1/2 px-2 py-2"> Attempts Allowed</span>
            <span className="w-1/2 px-2 py-2">4</span>
          </div>
        </div>

        <div className="ml-5 w-1/3 h-12 border-2 border-orange rounded-lg">
          <div className="flex justify-between">
            <span className="w-1/2 px-2 py-2"> Attempts Allowed</span>
            <span className="w-1/2 px-2 py-2">4</span>
          </div>
        </div>

        <div className="ml-5 w-1/3 h-12 border-2 border-orange rounded-lg">
          <div className="flex justify-between">
            <span className="w-1/2 px-2 py-2"> Attempts Allowed</span>
            <span className="w-1/2 px-2 py-2">4</span>
          </div>
        </div>
      </div>
    </div>
  );
}
