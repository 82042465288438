import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import userSvc from "services/User";

export default function Privacy(props) {
  const [state, setState] = useState(null);
  const {
    user: { db_user: user },
  } = useSelector(({ auth }) => auth);

  const settings = {
    whatsapp: {
      label: "Whatsapp Notifications",
      items: {
        0: {
          title: "Continue Watching (Ex-Retail Sales)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
        1: {
          title: "New Course (Ex-TELECOM)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
        2: {
          title: "Watch Later (Ex-Retail)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
      },
    },

    facebook: {
      label: "Facebook notifications",
      items: {
        0: {
          title: "Continue Watching (Ex-Retail Sales)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
        1: {
          title: "New Course (Ex-TELECOM)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
        2: {
          title: "Watch Later (Ex-Retail)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
      },
    },

    general: {
      label: "General notifications",
      items: {
        0: {
          title: "Continue Watching (Ex-Retail Sales)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
        1: {
          title: "New Course (Ex-TELECOM)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
        2: {
          title: "Watch Later (Ex-Retail)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
      },
    },

    promotional: {
      label: "Promotional Notifications",
      items: {
        0: {
          title: "Continue Watching (Ex-Retail Sales)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
        1: {
          title: "New Course (Ex-TELECOM)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
        2: {
          title: "Watch Later (Ex-Retail)",
          subtitle:
            "Announcements event, and Lorem Ipsum dolor sit amet sed bit teu lolar pie diy",
        },
      },
    },
  };

  useEffect(() => {
    userSvc
      .fetchPrivacyTags(user.id)
      .then((response) => {
        const state = [];
        Object.keys(settings).map((key) => {
          Object.keys(settings[key].items).map((itemId) => {
            const itemName = `privacy.${key}.${itemId}`;
            const itemFromResponse = response.find(
              (ri) => ri.name === itemName
            );
            state.push({
              value: "0",
              loading: false,
              name: itemName,
              ...itemFromResponse,
            });
          });
        });
        setState(state);
      })
      .catch((err) => console.log(err) || setState(false));
  }, []);

  const updateTag = (name, value) => {
    const itemFromState = state.find((i) => i.name === name);

    // Set loading to true
    itemFromState.loading = true;
    setState([...state]);

    // updating tag
    userSvc
      .createOrUpdateUserTag({
        id: itemFromState.id,
        name: itemFromState.name,
        value: value ? "1" : "0",
        user_id: user.id,
      })
      .then((res) => {
        setState(state.map((i) => (i.name === itemFromState.name ? res : i)));
      })
      .catch((err) => {
        console.error(err);
        setState(
          state.map((i) =>
            i.name === itemFromState.name ? { ...i, loading: false } : i
          )
        );
      });
  };

  return (
    <div className="p-2 pt-4 mb-4">
      {state ? (
        <div className="card p-4">
          {Object.keys(settings).map((key) => {
            const { label, items } = settings[key];
            return (
              <div className="mb-5">
                <span className="small-title">{label}</span>
                <div className="mt-3">
                  {Object.keys(items).map((itemId) => {
                    const item = items[itemId];
                    const itemName = `privacy.${key}.${itemId}`;
                    const itemFromState = state.find(
                      (i) => i.name === itemName
                    );
                    return (
                      <div
                        className="flex w-full mb-3"
                        onClick={() =>
                          updateTag(
                            itemName,
                            itemFromState && itemFromState.value !== "1"
                          )
                        }
                      >
                        <div className="mr-2">
                          {!itemFromState?.loading ? (
                            <input
                              type="checkbox"
                              checked={
                                itemFromState && itemFromState.value === "1"
                              }
                              name={itemId}
                            />
                          ) : (
                            <span>L...</span>
                          )}
                        </div>
                        <div>
                          <div>{item.title}</div>
                          <div className="small-text mt-1">{item.subtitle}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : state === false ? (
        <div className="error">An error occured</div>
      ) : (
        <div className="loader">Loading</div>
      )}
    </div>
  );
}
