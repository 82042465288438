import { DATE_TIME_FORMATS } from "api/Consts";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import courseSvc from "services/Course";
import { Modal } from "skillstrainer-resource-library";
import Toast from "wrappers/Toast";

export default function BookDemoList(props) {
  const { courseDemoBatchSlots, onComplete, onSlotConfirm } = props;

  const userId = useSelector(({ auth }) => auth.user?.db_user?.id);

  const bookDemo = async (slotId) => {
    if (!slotId) {
      Toast.error("Please select a slot");
      return;
    }

    const selectedSlot = courseDemoBatchSlots.availableSlots.find(
      (s) => s.id === slotId
    );
    const isOk = await Toast.confirm(
      `Are you sure you want to confirm the session on ${moment(
        selectedSlot.slot_date
      ).format(DATE_TIME_FORMATS.STANDARD_DATE_TIME)}?`
    );

    if (isOk) {
      onSlotConfirm(slotId);
    }
  };

  return (
    <Modal isOpen={courseDemoBatchSlots} onClose={() => onComplete()}>
      <div className="p-3">
        <div className="text-2xl font-semibold">
          Select a time slot for a demo of
        </div>
        <div className="text-2xl font-semibold text-orange">
          {courseDemoBatchSlots && courseDemoBatchSlots.course.full_name}
        </div>
        <ul className="mt-10">
          {(courseDemoBatchSlots?.availableSlots || []).map((slot) => (
            <li className="grid grid-cols-4 items-center my-2">
              <div className="flex items-center col-span-2">
                <div className="font-semibold text-gray-400 p-3 bg-gray-100 rounded-lg mr-4">
                  {moment(slot.slot_date).format("ddd")}{" "}
                </div>
                <div className="font-semibold">
                  {moment(slot.slot_date).format(
                    DATE_TIME_FORMATS.DEMO_BATCH_SLOTS_DATE
                  )}
                  ,
                </div>
                <div className="font-semibold text-gray-500 ml-2">
                  {moment(slot.slot_date).format("HH:mm")}
                </div>
              </div>
              <div className="mx-5">
                <span className="font-bold text-orange">
                  {slot.batch.max_learners - slot.slot_enrolled_users.length}
                </span>{" "}
                slots left
              </div>
              <button className="btn-primary" onClick={() => bookDemo(slot.id)}>
                Book
              </button>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
}
