import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import courseSvc from "services/Course";
import { error } from "utils/error";
import BookDemoList from "wrappers/Course/BookDemoList";
import Toast from "wrappers/Toast";

export default function BookDemoCourse(props) {
  const { params, user, complete } = props;
  const course_to_demo = params.courseId;
  const userId = useSelector(({ auth }) => auth.user?.db_user?.id);

  const [active_courses] = useSelector(({ course }) => [course.active_courses]);

  const [courseDemoBatchSlots, setCourseDemoBatchSlots] = useState();
  const checkBookDemoCourses = async () => {
    if (course_to_demo) {
      const course = await courseSvc.getCourseById(course_to_demo);
      if (!course) error("The course you are looking for doesn't exist");
      else if (active_courses[course_to_demo])
        error("You are already enrolled in this course");
      else if (!course.isDemoAvailable) {
        error("Demo for this course isn't available");
      } else {
        const userDemoSlots = await courseSvc
          .getDemoBatchSlotsByUserId(user.id)
          .catch(() => error("An unknown error occurred"));

        if (userDemoSlots.find((uds) => uds.batch.course.id == course_to_demo))
          error("You have already enrolled for a demo of this course");
        else
          setCourseDemoBatchSlots({
            availableSlots: course.availableSlots,
            course,
          });
      }
    }
  };

  useEffect(() => {
    checkBookDemoCourses().catch((err) => {
      Toast.error(err.message);
      complete();
    });
  }, []);

  const bookUserDemoSession = (selectedSlot) => {
    courseSvc.bookDemo(user.id, selectedSlot).then((res) => {
      Toast.success("Demo is booked!");
      complete();
    });
  };

  return (
    <>
      <BookDemoList
        courseDemoBatchSlots={courseDemoBatchSlots}
        onSlotConfirm={bookUserDemoSession}
        onComplete={complete}
      />
    </>
  );
}
