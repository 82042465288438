/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { BiErrorAlt } from "react-icons/bi";
import { LOGIN_ERROR, Login } from "../../../redux/actions/Auth";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GoogleButton from "components/social-auth/GoogleButton";
import FbButton from "components/social-auth/FbButton";
import userSvc from "services/User";
import Toast from "wrappers/Toast";
import Modal from "components/Modal";
import SocialAuthOptions from "./Login/SocialAuthOptions";
import { useEffect } from "react";
import Loader from "assets/animated-images/Loader";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Axios from "api/Api";
import { emailRegex, phoneNumberRegex } from "api/Consts";

const LoginComponent = (props) => {
  const [input, setInput] = useState({
    id: "",
    password: "",
  });

  const onInputChange = (e) =>
    setInput({ ...input, [e.target.name]: e.target.value });

  const auth = useSelector(({ auth }) => auth);
  const error = auth.login_error;

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [attemptedLogin, setAttemptedLogin] = useState();
  const [loggingIn, setLoggingIn] = useState();
  const [duplicateLoginUsers, setDuplicateLoginUsers] = useState({
    status: "inactive",
    users: [],
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoggingIn(true);

    /*
     *
     *
     * Checking duplicate users
     *
     *
     */

    const email =
      emailRegex.test(input.id.toLowerCase()) && input.id.toLowerCase();
    const mobile_number = phoneNumberRegex.test(input.id) && input.id;
    const { data: duplicateTrainees } = await Axios.post("/search_trainees", {
      email,
      mobile_number,
    }).then(({ data }) => data);

    if (!duplicateTrainees.length) {
      dispatch({
        type: LOGIN_ERROR,
        payload: { message: "User doesn't exist" },
      });
      setLoggingIn(false);
      setAttemptedLogin(true);
      return;
    } else if (duplicateTrainees.length > 1) {
      console.log("More than one users exist");
      duplicateLoginUsers.users = duplicateTrainees;
      duplicateLoginUsers.status = "active";
      setDuplicateLoginUsers({ ...duplicateLoginUsers });
      return;
    } else {
      console.log("Only 1 user exists");
      login({
        username: duplicateTrainees[0].username,
        password: input.password,
      });
    }
  };

  const login = (input) => {
    dispatch(
      Login(input, {
        onSuccess: () => {
          setLoggingIn(false);
        },
        onError: () => {
          setAttemptedLogin(true);
          checkIsUserOld();
          setLoggingIn(false);
        },
      })
    );
  };

  // If the user is old and unable to login
  const [showOldUserNotice, setShowOldUserNotice] = useState();
  const checkIsUserOld = async () => {
    const isUserOld = await userSvc.checkIsUserOld(input.id);
    if (isUserOld) setShowOldUserNotice(true);
  };

  const [forgotOTPDialogOpen, setForgotOTPDialogOpen] = useState();
  const [sendingRecoveryNotification, setSendingRecoveryNotification] =
    useState();

  return (
    <div className="col-span-3 md:col-span-1 bg-white shadow-xl rounded-lg p-10 z-1 w-full flex flex-col items-center md:items-start">
      <h1 className="text-2xl font-semibold text-japanese_indigo">Log In</h1>
      {props.notice && (
        <div className="w-full text-center px-4 py-2 rounded-md bg-yellow-100 text-orange-500 mt-3">
          {props.notice}
        </div>
      )}
      <p className="text-xs my-5 text-gray-600">
        Do you have an account?&nbsp;
        <Link to="/signup" className="text-orange">
          Sign Up
        </Link>
      </p>
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block tracking-wide text-japanese_indigo text-xs mb-2"
              htmlFor="grid-email"
            >
              Email id* or Phone number*
            </label>
            <input
              className="input"
              id="grid-email"
              name="id"
              type="text"
              placeholder="Your Email or Phone Number"
              required={true}
              onChange={onInputChange}
            />
            {/* <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3 relative">
            <label
              className="block tracking-wide text-japanese_indigo text-xs mb-2"
              htmlFor="grid-password"
            >
              Password
            </label>
            {showPassword ? (
              <BsEye
                onClick={() => setShowPassword(false)}
                className="absolute mt-4 right-6 opacity-60"
              />
            ) : (
              <BsEyeSlash
                onClick={() => setShowPassword(true)}
                className="absolute mt-4 right-6 opacity-60"
              />
            )}
            <input
              className="input"
              id="grid-password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="********"
              required={true}
              onChange={onInputChange}
            />
            {/* <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
          </div>
        </div>
        {attemptedLogin && error && (
          <p className="flex flex-row items-center justify-center text-red font-semibold p-2 bg-red-200 rounded-md mt-1 mb-6 text-center text-red-600">
            <BiErrorAlt className="mx-1" />
            {error}
          </p>
        )}
        <div>
          <button
            className="text-orange"
            type="button"
            onClick={() => setForgotOTPDialogOpen(true)}
          >
            Forgot password?
          </button>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <button
              className="w-full mt-4 font-semibold bg-orange text-white rounded-md p-2 text-lg flex items-center justify-center"
              type="submit"
              disabled={loggingIn}
            >
              {!loggingIn ? (
                <div className="h-8">Log In</div>
              ) : (
                <Loader color="#fff" className="h-8" />
              )}
            </button>
          </div>
        </div>
      </form>

      <SocialAuthOptions />

      {/* Forgot password modal */}
      <Modal
        isOpen={forgotOTPDialogOpen}
        onClose={() => setForgotOTPDialogOpen(false)}
      >
        <div className="flex flex-col p-4">
          <div className="flex-center font-semibold mb-4">
            Please enter your email address / phone number
          </div>
          <div className="flex-center flex-col">
            <input
              type="text"
              className="input"
              name="id"
              onChange={onInputChange}
              value={input.id}
            />
            <button
              className="button w-full mt-2"
              onClick={async () => {
                setSendingRecoveryNotification(true);
                const response = await userSvc.sendRecoveryNotification(
                  input.id
                );
                setSendingRecoveryNotification(false);
                if (response) setForgotOTPDialogOpen(false);
              }}
              disabled={sendingRecoveryNotification}
            >
              {sendingRecoveryNotification
                ? "Sending..."
                : "Send password recovery instructions"}
            </button>
          </div>
        </div>
      </Modal>

      {/* old user notice */}
      <Modal
        isOpen={showOldUserNotice}
        onClose={() => setShowOldUserNotice(false)}
      >
        <div className="flex flex-col p-4">
          <div className="text-xl font-semibold flex-center">Notice</div>
          <div className="text-center mt-3">
            Due to the update in SkillsTrainer.in, passwords of existing users
            need to be re generated. On clicking proceed, a new password will be
            generated and sent to your email / phone number that you can then
            use to log in to your account
          </div>
          <button
            className="button w-full"
            onClick={() => {
              userSvc.sendRecoveryNotification(input.id);
              setShowOldUserNotice(false);
            }}
          >
            Proceed
          </button>
        </div>
      </Modal>

      {/* duplicate user login */}
      <Modal
        isOpen={duplicateLoginUsers.status !== "inactive"}
        onClose={() => {
          duplicateLoginUsers.status = "inactive";
          setDuplicateLoginUsers({ ...duplicateLoginUsers });
        }}
      >
        <DuplicateLoginUsersPrompt
          onSelect={(loginUser) => {
            login({ username: loginUser.username, password: input.password });
            duplicateLoginUsers.status = "inactive";
            setDuplicateLoginUsers({ ...duplicateLoginUsers });
          }}
          list={duplicateLoginUsers.users}
          disabled={duplicateLoginUsers.status === "logging"}
        />
      </Modal>
    </div>
  );
};

export default LoginComponent;

const DuplicateLoginUsersPrompt = (props) => {
  const { onSelect, list, disabled } = props;

  return (
    <div className="flex flex-col p-4 relative overflow-hidden">
      <div className="font-semibold text-md mb-2">
        We found multiple accounts with the provided credentials. Please pick
        your account to proceed
      </div>
      <ul className="m-0 p-0 text-md">
        {list.map((loginUser) => (
          <li
            key={loginUser.username}
            className="w-full flex justify-between my-2 py-2 px-4 bg-gray-200 items-center rounded-xl"
          >
            <div>
              <span className="font-semibold">{loginUser.name.familyName}</span>{" "}
              <span>({loginUser.username})</span>
            </div>
            <button
              disabled={disabled}
              onClick={() => onSelect(loginUser)}
              className="btn-primary"
            >
              This is me
            </button>
          </li>
        ))}
      </ul>
      {disabled && (
        <div
          className="top-0 left-0 absolute h-full w-full flex items-center justify-center"
          style={{ background: "rgba(255,255,255,0.7)" }}
        >
          <span className="font-semibold text-xl">Please wait...</span>
        </div>
      )}
    </div>
  );
};
