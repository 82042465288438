import React, {useState, useContext, useMemo} from 'react';
import { useQuery } from "@apollo/client";
import { BiChevronDown } from "react-icons/bi";
import { FiltersContext } from '../alljobs';
import { FILTERS } from 'graphql/queries/Jobs';
import {MULTI_SELECT_JOBS_FILTERS} from 'api/Consts';
import MultiSelectFilter from './multiselectfilter';


export default function SelectLocation() {
  const TABLE_NAME= 'courses_job_location';
  const [countryDropdown, setCountryDropdown] = useState(false);
  const countries = useQuery(FILTERS.GET_COUNTRIES);
  const {filters, setFilters, setPageNo} = useContext(FiltersContext);

  const cityWhereClause = useMemo(() => ({_and: [
    {country: {_eq: filters[MULTI_SELECT_JOBS_FILTERS.COUNTRY]? filters[MULTI_SELECT_JOBS_FILTERS.COUNTRY]:''}},
    {city: {_is_null: false}}
  ]}
  ), [filters]);
  const cityFilterDetails = {
    options: useQuery(FILTERS.GET_CITIES, { variables: { cityWhereClause }})?.data?.[TABLE_NAME],
    table: TABLE_NAME,
    filtername: MULTI_SELECT_JOBS_FILTERS.CITIES
  }

  return (
    <>
      <div
        onClick={() => setCountryDropdown(!countryDropdown)}
        // onMouseEnter={() => setCountryDropdown(true)}
        >
        <div className="flex flex-row items-center p-2 rounded-md cursor-pointer justify-center shadow-md">
          <p className="text-black-500 text-sm font-medium mx-2 m-0 font-poppins hover:text-blue-500 transition duration-300">
            {filters[MULTI_SELECT_JOBS_FILTERS.COUNTRY]?filters[MULTI_SELECT_JOBS_FILTERS.COUNTRY]:'Select Country'}
          </p>
          <div className="flex flex-row text-gray-500 font-poppins transition duration-300">
            <BiChevronDown
              className="text-orange my-auto"
              size={20}
            />
          </div>
        </div>
        {countryDropdown && (
          <div
            onMouseLeave={() => setCountryDropdown(false)}
            className="max-h-80 overflow-y-auto absolute bg-white text-base w-auto	z-50 flex flex-col rounded-md my-4 shadow-lg px-1.5 py-1.5"
          >
            {countries?.data?.[TABLE_NAME]?.map((option) => (
              <div key={option.country}
                onClick={()=>{
                  setFilters({...filters, [MULTI_SELECT_JOBS_FILTERS.COUNTRY]:option.country});
                  setPageNo(0);
                }} 
                className="cursor-pointer py-2 px-2 w-28 text-sm font-medium hover:text-orange">{option.country}</div>
            ))}
          </div>
        )}
      </div>
      {filters[MULTI_SELECT_JOBS_FILTERS.COUNTRY] && <MultiSelectFilter key={cityFilterDetails.filtername} filterDetails={cityFilterDetails}/>}
    </>
  )
}

