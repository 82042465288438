import { useQuery } from "@tanstack/react-query";
import { GET_COURSE_SECTION } from "graphql/queries/CourseBuilder/CourseSection";
import queryClient from "./queryClient";
import { apolloClient } from "../App";
// export const useGetUserDetails = (userId) => {
//   return useQuery(
//     ["userDetails", userId],
//     async () => {
//       return getUserDetails(userId)
//     },
//     {
//       enabled: !!userId,
//     }
//   );
// };

// export const getUserDetails = async (userId) => {
//   return queryClient.fetchQuery(["userDetails", userId], async () => {
//     return apolloClient
//       .query({
//         query: GET_USER_BATCH_COURSE_BY_UID,
//         variables: { user_id: userId },
//       })
//       .then(({ data }) => {
//         return data;
//       });
//   });
// };

// get course section
export const useCourseSection = (courseId) => {
  return useQuery(
    ["getCourseSection", courseId],
    async () => {
      return await getCourseSection(courseId);
    },
    {
      enabled: !!courseId,
    }
  );
};

export const getCourseSection = async (courseId) => {
  const data = await queryClient.fetchQuery({
    queryKey: ["getCourseSection", courseId],
    queryFn: async () => {
      const result = await apolloClient
        .query({
          query: GET_COURSE_SECTION,
          variables: { course_id: courseId },
        })
        .then((res) => res.data.courses_course_section);

      return result;
    },
    enabled: !!courseId,
  });

  console.log("🚀 ~ data ~ data:", data);

  return data;
};
