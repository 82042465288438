import { gql } from "@apollo/client";
//sector
export const JOB_COLUMNS = `
  created_at
  description
  gender
  id
  is_active
  no_of_positions
  partner_id
  project_id
  title
  updated_at
  min_experience
  marital_status
  social_categories
  min_age
  max_age
  is_remote
  shift_start_timing
  shift_end_timing
  salary_ctc
  partner {
    logo
    organization_name
    name
  }
  job_languages_required {
    id
    language_id
    can_speak
    can_read
    can_write
    language {
      name
    }
  }
  job_required_skills_criteria {
    id
    st_skill_id
    skillstrainer_skill_id {
      label
      name
    }
  }
  job_location {
    id
    num_of_positions
    pin_code
    country
    city
  }
  job_required_qualifications {
    qualification_type
    required
    specialization
    qualification {
      type
      id
      name
      qp_code
    }
  }
  job_applications(where:{user_id:{_eq:$userId}}){
    status
    stage
  }
`;

export const GET_JOBS = gql`
  query getJobs ($whereClause: courses_skillstrainer_jobs_bool_exp!, $limit: Int!, $offset: Int!, $userId: bigint) {
    courses_skillstrainer_jobs_aggregate(
      where: {
        is_active: {
          _eq: true
        }
      }
    ){
      aggregate {
        totalCount: count
      }
    }
    courses_skillstrainer_jobs(where: $whereClause, limit: $limit, offset: $offset, order_by: { created_at: desc }) {
      ${JOB_COLUMNS}
    }
  }
`;

export const FILTERS = {
  GET_SKILLS: gql`
    query getSkills {
      courses_skillstrainer_skills(order_by: { name: asc }) {
        id
        name
      }
    }
  `,
  GET_PARTNERS: gql`
    query getPartners {
      courses_partner(order_by: { name: asc }) {
        id
        name
      }
    }
  `,
  GET_LANGUAGES: gql`
    query getLanguages {
      courses_skillstrainer_languages(order_by: { name: asc }) {
        id
        name
      }
    }
  `,
  GET_QUALIFICATIONS: gql`
    query getQualifications {
      courses_qualifications_lookup(order_by: { name: asc }) {
        id: code
        name: name
      }
    }
  `,
  GET_CITIES: gql`
    query getCities($cityWhereClause: courses_job_location_bool_exp!) {
      courses_job_location(
        distinct_on: city
        where: $cityWhereClause
        order_by: { city: asc }
      ) {
        id: city
        name: city
      }
    }
  `,
  GET_COUNTRIES: gql`
    query getCountries {
      courses_job_location(
        distinct_on: country
        where: { country: { _is_null: false } }
        order_by: { country: asc }
      ) {
        country
      }
    }
  `,
  GET_SECTOR: gql`
    query getSector {
      courses_course_categories(order_by: { updated_at: desc }) {
        id
        name
      }
    }
  `,
};

export const GET_JOB_APPLICATION = gql`
  query getJobApplication($whereClause: courses_job_applications_bool_exp!) {
    courses_job_applications(where: $whereClause, limit: 1) {
      id
      status
      stage
    }
  }
`;

export const GET_ALL_JOB_APPLICATIONS = gql`
query getJobApplications($userId: bigint){
  courses_job_applications_aggregate(where: {user_id: {_eq: $userId}}){
    aggregate {
      totalCount: count
    }
  }
  courses_job_applications(where: {user_id: {_eq: $userId}}){
    status
    stage
    job_id
    job{
      ${JOB_COLUMNS}
    }
  }
}
`;

export const GET_STARRED_JOBS = gql`
  query getStarredJobs($userId: bigint) {
    courses_starred_jobs(where: { user_id: { _eq: $userId } }) {
      job_id
      job{
        ${JOB_COLUMNS}
      }
    }
  }
`;
