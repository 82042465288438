import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExpandedLoader from "../ExpandedLoader";
import UserCompletedCourses from "../userdashboard/usercompletedcourses";
import courseSvc from "../../services/Course";
import { s3BaseUrl, COURSE_STATUS } from "../../api/Consts";
import CourseSearchBox from "../CourseSearchBox";

export default function CompletedCourses() {
  const [courses, setCourses] = useState([]);
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;

  const [loadData, setLoadData] = useState(true);
  const [coursesMap] = useSelector(({ course }) => [course.active_courses]);

  console.log("coursesMap=======", coursesMap);
  useEffect(async () => {
    if (coursesMap) {
      const courses = await Promise.all(
        Object.values(coursesMap)?.map(async (course) => {
          //get user course progress percentage
          const progress = course.is_moodle_course
            ? course.progress
            : await courseSvc.getUserCourseProgress(course.id, userId);

          //Define course img if not mdl course
          const img = {
            image_url: course.is_moodle_course
              ? course.image_url
              : s3BaseUrl + "/" + course.image_url,
            progress: Math.round(progress),
          };

          return Object.assign({}, course, img);
        })
      );
      setCourses([...courses]);

      setLoadData();
    }
  }, [coursesMap]);

  return (
    <>
      <div className="p-4">
        <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          My Certificates
        </h1>
      </div>

      {/* <div className="flex items-center justify-between mb-5">
        <CourseSearchBox />
      </div> */}

      {loadData ? (
        <p className="text-center p-20">
          <ExpandedLoader />
        </p>
      ) : courses.length > 0 ? (
        <UserCompletedCourses
          data={courses.filter((c) => c.status === COURSE_STATUS.COMPLETED)}
        />
      ) : (
        <p className="text-center p-20">No Completed Courses!</p>
      )}
    </>
  );
}
