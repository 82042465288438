import { gql } from "@apollo/client";

export const INSERT_ENTERPRISE_CONTACT = gql`
  mutation insertEnterpriseContact(
    $enterprises: [courses_signup_enterprises_insert_input!] = {}
  ) {
    insert_courses_signup_enterprises(objects: $enterprises) {
      affected_rows
    }
  }
`;
