import { create_razorpay_order } from "api/RazorpayBackendApi";
import { RAZORPAY_KEY_ID } from "api/Consts";

class razorpayClass {
  async RazorpayApi({
    courseId,
    user,
    couponId,
    partnerId,
    gst_no,
    business_name,
    billing_address_id,
  }) {
    const params = {
      currency: "INR",
      coupon_id: couponId,
      user: user,
      course_id: courseId,
      partner_id: partnerId,
      gst_no: gst_no,
      business_name: business_name,
      billing_address_id: billing_address_id,
    };

    const result = await create_razorpay_order(params).catch(() => error());

    const options = {
      key: RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: result.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: result.data.currency,
      name: "SkillsTrainer",
      description: "Test Transaction",
      image: "/skill2.png",
      order_id: result.data.id,

      handler: async function (response) {},
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.mobile_number,
      },
      modal: {
        ondismiss: function () {
          console.log("Checkout form closed");
        },
      },
      theme: {
        color: "#3399cc",
      },
    };

    console.log(options);

    const rzp1 = new window.Razorpay(options);

    rzp1.open();
  }
}
const razorpayClassAPi = new razorpayClass();

export default razorpayClassAPi;
