/* eslint-disable no-unused-vars */

export default function OnboardingSelectBig({ data, onChange, value = [] }) {
  const { id, type, displayName, optional, options } = data;

  const handleSelection = (val) => {
    const idx = value.indexOf(val);
    const finalValue = [...value];
    if (idx > -1) finalValue.splice(idx, 1);
    else finalValue.push(val);
    onChange({ target: { value: finalValue } });
  };

  return (
    <>
      <label htmlFor={id} className="block text-lg font-semibold text-gray-800">
        {displayName}
      </label>
      <div className="flex flex-row justify-start md:h-44">
        {options.map(({ text, Image, value: optionValue }, i) => (
          <div
            key={i}
            className={`w-full md:w-48 h-48 md:h-32 border-solid rounded-md flex flex-col justify-around border cursor-pointer bg-japanese_indigo-light bg-opacity-60 m-2 ${
              value.includes(optionValue) && "shadow-2xl border-orange"
            }`}
            onClick={() => handleSelection(optionValue)}
          >
            <Image />
            <p className="text-japanese_indigo text-center text-sm font-semibold">{text}</p>
          </div>
        ))}
      </div>
    </>
  );
};
