import { LOGOUT } from "redux/actions/Auth";
import {
  APPEND_COURSES,
  SET_APPLYING_COUPON,
  SET_COUPON_IN_FOCUS,
  UNSET_COUPON_IN_FOCUS,
  SET_COUPONS,
  SET_COURSE_TO_PURCHASE,
  SET_SEARCH_PROPS,
  SET_COURSE_PROGRESS,
  SET_USER_ENROLLED_COURSES,
  SET_COURSE_TO_DEMO,
  SET_DEMO_BATCHES,
  SET_BATCH_SLOT_TO_JOIN,
  SET_BATCH_TO_JOIN,
  SET_COURSE_LESSON_AND_TOPICS,
  OPEN_COURSE_RESOURCES,
  OPEN_COURSE_COMPLETION_SETTINGS,
} from "redux/actions/Course";

const initialState = () => ({
  active_courses: {},
  coupons: [],
  courseProgress: [],
  courseLessonsTopics: [],
  user_demo_batches: null,
  userEnrolledCourse: [],
  searchProps: {
    keywords: "",
    selectedCategory: null,
    selectedStatus: "",
  },
  viewResource: {
    active: false,
    activeMappingID: null,
    lessonResourecs: null,
  },

  viewCourseCompletionSetting: false,
});

export const CourseReducer = (state = initialState(), action) => {
  switch (action.type) {
    case SET_COUPON_IN_FOCUS:
      return { ...state, coupon_in_focus: action.payload.code };
    case SET_APPLYING_COUPON:
      return { ...state, applying_coupon: action.payload };
    case UNSET_COUPON_IN_FOCUS:
      return { ...state, coupon_in_focus: undefined };
    case APPEND_COURSES:
      action.payload.forEach(
        (course) => (state.active_courses[course.id] = course)
      );
      return { ...state, active_courses: { ...state.active_courses } };
    case SET_COUPONS:
      return { ...state, coupons: action.payload };
    case SET_COURSE_TO_PURCHASE:
      return { ...state, course_to_purchase: action.payload.courseId };

    case SET_SEARCH_PROPS:
      return {
        ...state,
        searchProps: { ...state.searchProps, ...action.payload },
      };
    case SET_COURSE_TO_DEMO:
      return {
        ...state,
        course_to_demo: action.payload.courseId,
      };
    case SET_DEMO_BATCHES:
      return {
        ...state,
        user_demo_batches: action.payload,
      };
    case SET_COURSE_PROGRESS:
      return {
        ...state,
        courseProgress: [...state.courseProgress, action.payload],
      };
    case SET_USER_ENROLLED_COURSES:
      return { ...state, userEnrolledCourse: action.payload };
    case SET_BATCH_SLOT_TO_JOIN:
      return { ...state, batch_slot_to_join: action.payload };
    case SET_BATCH_TO_JOIN:
      return { ...state, batch_to_join: action.payload };
    case SET_COURSE_LESSON_AND_TOPICS:
      return { ...state, courseLessonsTopics: action.payload };
    case OPEN_COURSE_RESOURCES:
      return { ...state, viewResource: action.payload };
    case OPEN_COURSE_COMPLETION_SETTINGS:
      return { ...state, viewCourseCompletionSetting: action.payload };

    case LOGOUT:
      return initialState();
    default:
      return state;
  }
};
