import DemoDetailsModal from "components/userdashboard/DemoDetailsModal";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import courseSvc from "services/Course";
import Toast from "wrappers/Toast";

export default function ShowDemoDetails(props) {
  const { params, complete } = props;

  // Demo batch detail modal
  const [demoBatchSlot, setDemoBatchSlot] = useState();
  const { slotId } = params;

  useEffect(() => {
    const loader = Toast.load("Please wait...");
    courseSvc.getBatchSlotById(slotId).then((res) => {
      setDemoBatchSlot(res);
      Toast.endLoader(loader);
    });
  }, []);

  return <DemoDetailsModal demoBatchSlot={demoBatchSlot} onClose={complete} />;
}
