import { getFileUrl, uploadFile } from "api/Api";
import { useQuery } from "@apollo/client";
import { FETCH_POSSIBLE_QUALIFICATIONS } from "graphql/queries/SkillsTrainer";
import {
  CONTACT_PERSON_NAMES,
  CONTACT_PERSON_TYPE,
  MARKING_TYPE,
  MARKING_TYPE_NAMES,
  QUALIFICATION_NAMES,
  STUDENT_QUAL_TYPE,
  TECHNICAL_QUALIFICATION_TYPE,
  ACEDEMIC_QUALIFICATION_DETAILS,
} from "api/Consts";

import { AccordionItem } from "components/AccordionItem";
import FileUploader, { resolveFileFields } from "components/Form/FileUploader";
import ToggleList, { ToggleListItem } from "components/Form/ToggleList";
import AddressField from "components/FormResources/Address";
import _ from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import userSvc from "services/User";
import { wireEventValue } from "utils/func";
import Toast from "wrappers/Toast";

/*
  - (user id + qualification) type is primary key
  
  - For each qual
    - Show document name
    - Set
      - Document Proof
      - Address
      - Contact person

  - Upon form submission,
    - For each new entry
      - Create a new qual record
      - Create a new document_proof
      - Create a new address
      - Update local entry
    - For each old entry
      - Update address
      - Update qual record
    - For each deleted entry
      - Delete document_proof
      - Delete qual record
      - Delete address
      - Delete local entry
*/

export default function Qualifications(props) {
  const CORRESPONDING_POSSIBLE_QUALIFICATIONS = useQuery(
    FETCH_POSSIBLE_QUALIFICATIONS
  );

  const [allQuals, setAllQuals] = useState([]);
  const quals = allQuals.filter((q) => !q.disabled);
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;

  const fetchQualifications = () =>
    userSvc.fetchUserQualifications(userId).then((list) => {
      list = _.cloneDeep(
        list.filter(
          (qual) =>
            Object.values(TECHNICAL_QUALIFICATION_TYPE).indexOf(
              qual.qualification_type
            ) === -1
        )
      );
      list.sort((a, b) => b.qualification_type - a.qualification_type);
      // Settings the doc object in all qualifications
      setAllQuals(
        list.map((qual) => ({
          ...qual,
          doc: !_.isEmpty(qual.document_proof) &&
            qual.document_proof.url && [
              _.pick(qual.document_proof, ["name", "url"]),
            ],
        }))
      );
    });

  useEffect(() => {
    fetchQualifications();
  }, []);

  const newId = useRef(1);

  const docs = useRef();

  useEffect(() => {
    setHighestQualification(
      (allQuals[0] && allQuals[0].qualification_type) || -1
    );
  }, [!!allQuals]);

  // Actions

  const setHighestQualification = (target_qual_type) => {
    let hqt = allQuals[0] && allQuals[0].qualification_type; // User's highest qualification's type
    if (!hqt) hqt = -1;

    let newAllQuals;
    if (target_qual_type > hqt) {
      const newQuals = new Array(target_qual_type - hqt)
        .fill("")
        .map((_, idx) => ({
          qualification_id: ACEDEMIC_QUALIFICATION_DETAILS[
            target_qual_type - idx + ""
          ].key
            ? null
            : ACEDEMIC_QUALIFICATION_DETAILS[target_qual_type - idx + ""]
                .qualification_id,
          qualification_type: target_qual_type - idx + "",
          qualification_name: QUALIFICATION_NAMES[target_qual_type - idx + ""],
          fresh: true,
          id: "f" + newId.current++,
          document_proof: {},
        }));
      newAllQuals = _.cloneDeep(allQuals);
      newAllQuals.splice(0, 0, ...newQuals);
      newAllQuals.forEach((q) => (q.disabled = undefined));
    } else if (target_qual_type <= hqt) {
      newAllQuals = _.cloneDeep(allQuals).map((q) => {
        if (q.qualification_type <= target_qual_type) {
          q.disabled = undefined;
        } else {
          q.disabled = true;
        }
        return q;
      });
    }

    if (JSON.stringify(newAllQuals) !== JSON.stringify(allQuals))
      setAllQuals(newAllQuals);
  };

  // const getQualification = (id) => allQuals.find((q) => q.id === id);

  const updateQualification = (id, path, value) => {
    const newAllQuals = [...allQuals];
    const qual = newAllQuals.find((q) => q.id === id);
    _.set(qual, path, value);
    setAllQuals(newAllQuals);
  };

  const submit = async () => {
    console.log(allQuals);
    for (let qual of allQuals) {
      if (!qual.qualification_id && !qual.disabled) {
        Toast.error("Please fill program name for " + qual.qualification_name);
        return;
      }
    }
    let newAllQuals = [...allQuals];

    // Resolving file fields for non-deleted qualifications
    for (const qual of newAllQuals)
      if (!qual.disabled && qual.doc && qual.doc[0]) {
        await resolveFileFields(qual, (...args) =>
          uploadFile(...args).then((res) => res.data_url)
        );
        qual.document_proof.name = qual.doc[0].name;
        qual.document_proof.url = qual.doc[0].url;
      } else {
        qual.document_proof.name = null;
        qual.document_proof.url = null;
      }

    newAllQuals = await userSvc.setUserQualifications(newAllQuals, userId);
    if (newAllQuals) {
      setAllQuals(newAllQuals);
      Toast.success("Successfully updated!");
      if (props.isLastPage) props.finishFn();
      else props.next();
    }
  };

  // Actions End

  return (
    <div className="card p-4 mb-20">
      <b className="mb-5 text-lg font-bold">Academic Qualifications</b>
      <div>
        <select
          onChange={wireEventValue(setHighestQualification)}
          value={quals[0] && quals[0].qualification_type}
          className="my-2 mt-4"
        >
          <option value="-1">No qualification</option>
          {Object.keys(STUDENT_QUAL_TYPE).map((qual_name) => {
            const qual_type = STUDENT_QUAL_TYPE[qual_name];
            return (
              <option value={qual_type}>
                {QUALIFICATION_NAMES[qual_type]}
              </option>
            );
          })}
        </select>
        <ToggleList>
          {quals.map((qual) => {
            const { id, qualification_name, qualification_type, doc } = qual;

            const updateQualProp = (key) => (v) =>
              updateQualification(id, key, v);
            return (
              <ToggleListItem key={id}>
                {(opts) => (
                  <AccordionItem {...opts} title={qualification_name}>
                    <div className="mb-3">
                      {ACEDEMIC_QUALIFICATION_DETAILS[qualification_type]
                        .key && (
                        <div className="m-2 mb-5">
                          <div className="small-title mt-2">Program Name</div>
                          <select
                            className="input"
                            onChange={wireEventValue(
                              updateQualProp("qualification_id")
                            )}
                            value={qual.qualification_id}
                          >
                            <option>Choose an option</option>
                            {CORRESPONDING_POSSIBLE_QUALIFICATIONS?.data?.[
                              ACEDEMIC_QUALIFICATION_DETAILS[qualification_type]
                                .key
                            ].map(({ name, id }) => (
                              <option value={id}>{name}</option>
                            ))}
                          </select>
                        </div>
                      )}
                      {qual.qualification_type !== STUDENT_QUAL_TYPE.FIFTH && (
                        <div className="m-2 mb-5">
                          <div className="small-title mt-2">Marking system</div>
                          <select
                            className="input"
                            onChange={wireEventValue(
                              updateQualProp("marking_type")
                            )}
                            value={qual.marking_type}
                          >
                            <option>Choose an option</option>
                            {Object.values(MARKING_TYPE).map((mType) => (
                              <option value={mType}>
                                {MARKING_TYPE_NAMES[mType]}
                              </option>
                            ))}
                          </select>

                          <div className="small-title mt-2">
                            Percentage / Grade
                          </div>
                          <input
                            className="input mb-2"
                            onChange={wireEventValue(updateQualProp("marks"))}
                            value={qual.marks}
                          />

                          <span className="small-title">Upload marksheet</span>
                          <FileUploader
                            value={qual.doc}
                            onChange={updateQualProp("doc")}
                            fileFieldProps={{ accept: ".pdf" }}
                            services={{
                              getUrl: getFileUrl,
                            }}
                          />
                        </div>
                      )}

                      <div className="m-2">
                        <span className="small-title">Institute Name</span>
                        <input
                          className="input mb-2"
                          onChange={wireEventValue(
                            updateQualProp("institution_name")
                          )}
                          value={qual.institution_name}
                        />

                        <span className="small-title">Institute Address</span>
                        <AddressField
                          value={qual.address}
                          onChange={(val) =>
                            updateQualProp("address")({
                              ...val,
                              id: qual?.address?.id,
                            })
                          }
                        />
                      </div>

                      <div className="m-2">
                        <span className="small-title">
                          Institute Contact person
                        </span>
                        <select
                          onChange={wireEventValue(
                            updateQualProp("contact_person_type")
                          )}
                          className="input mb-2"
                          value={qual.contact_person_type}
                        >
                          <option value="">Choose a person</option>
                          {Object.values(CONTACT_PERSON_TYPE).map((type) => (
                            <option value={type}>
                              {CONTACT_PERSON_NAMES[type]}
                            </option>
                          ))}
                        </select>

                        <span className="small-title">Name</span>
                        <input
                          type="text"
                          onChange={wireEventValue(
                            updateQualProp("contact_person_name")
                          )}
                          className="input mb-2"
                          placeholder="Name"
                          value={qual.contact_person_name}
                        />

                        <span className="small-title">Phone number</span>
                        <input
                          type="text"
                          onChange={wireEventValue(
                            updateQualProp("contact_person_mobile_number")
                          )}
                          className="input mb-2"
                          placeholder="Phone number"
                          value={qual.contact_person_mobile_number}
                        />
                      </div>
                    </div>
                  </AccordionItem>
                )}
              </ToggleListItem>
            );
          })}
        </ToggleList>

        <div className="w-full flex justify-end my-4">
          <button
            className="w-1/3 bg-orange text-white font-semibold p-2 rounded-md text-lg"
            onClick={submit}
          >
            Save and Proceed
          </button>
        </div>
      </div>
    </div>
  );
}
