import { PROVIDERS, providers } from "api/Consts";
import Loader from "assets/animated-images/Loader";
import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { LoginWithProvider } from "redux/actions/Auth";
import Toast from "wrappers/Toast";

export default function GoogleButton(props) {
  const dispatch = useDispatch();
  const [loggingIn, setLoggingIn] = useState();

  return (
    <GoogleLogin
      clientId={providers.google.client_id}
      render={(renderProps) => (
        <button
          className={`google-button ${props.className}`}
          onClick={renderProps.onClick}
        >
          <img
            src={require("assets/icons/google-icon.svg").default}
            className="button-icon"
          />
          {loggingIn ? <Loader style={{ height: "20px" }} /> : "Google"}
        </button>
      )}
      scope={"profile"}
      onSuccess={(response) => {
        setLoggingIn(true);
        dispatch(
          LoginWithProvider(PROVIDERS.GOOGLE, response, () => setLoggingIn())
        );
      }}
      onFailure={(err) => Toast.error(err)}
      cookiePolicy="single_host_origin"
    />
  );
}
