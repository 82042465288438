import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DashboardCarousel, UserCompletedCourses, UserOngoingCourse } from ".";

import ExpandedLoader from "components/ExpandedLoader";
import { COURSE_STATUS, s3BaseUrl } from "api/Consts";
import { FetchUserMoodleCourses, SetSearchProps } from "redux/actions/Course";
import { useHistory } from "react-router";
import courseSvc from "services/Course";
import { CourseCard } from "components/recommendedcourses";
import { GET_COUPONS_CODE } from "../../graphql/MycourseQuery/MyCourse";
import { useQuery } from "@apollo/client";

let loadCount = 0;

export default function UserDashboard(props) {
  const [courses, setCourses] = useState([]);
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;

  const couponData = useQuery(GET_COUPONS_CODE, {
    variables: { user_id: userId },
  });

  // const coupon_id = couponData?.data?.courses_st_user_coupons[0]?.coupon_id;

  const [coursesMap, allDemoBatchSlots] = useSelector(({ course }) => [
    course.active_courses,
    course.user_demo_batches || [],
  ]);

  const demoBatchSlots = useMemo(() => {
    return allDemoBatchSlots.filter(
      (bs) => new Date().getTime() < new Date(bs.endto_date).getTime()
    );
  }, [allDemoBatchSlots]);

  const [completedCourseRange, setCompletedCourseRange] = useState(3);

  const [loadData, setLoadData] = useState(true);

  useEffect(async () => {
    if (coursesMap) {
      const courses = await Promise.all(
        Object.values(coursesMap)?.map(async (course) => {
          //get user course progress percentage
          const progress = course.is_moodle_course
            ? course.progress
            : await courseSvc.getUserCourseProgress(course.id, userId);

          //Define course img if not mdl course
          const img = {
            image_url: course.is_moodle_course
              ? course.image_url
              : s3BaseUrl + "/" + course.image_url,
            progress: Math.round(progress),
          };

          return Object.assign({}, course, img);
        })
      );
      setCourses([...courses]);
      setLoadData();
    }
  }, [coursesMap]);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loadCount++) {
      dispatch(FetchUserMoodleCourses({ onEnd: setLoadData() }));
    }
  }, []);

  const seeAllOngoingCourses = () => {
    dispatch(SetSearchProps({ selectedStatus: COURSE_STATUS.ONGOING }));
    history.push("/courses");
  };
  const completedCoursesLength = (courses || []).filter(
    (course) => course.status === COURSE_STATUS.COMPLETED
  ).length;

  const seeAllCompletedCourses = () => {
    // dispatch(SetSearchProps({ selectedStatus: COURSE_STATUS.COMPLETED }));
    // history.push("/courses");
    if (completedCourseRange === 3)
      setCompletedCourseRange(completedCoursesLength);
    else setCompletedCourseRange(3);
  };

  return (
    <>
      <div className="m-2 rounded-sm text-grey-500">
        <nav className="rounded-md">
          <div className="max-w-6xl mx-auto px-4">
            <div className="flex justify-between">
              <div className="hidden mobile-menu"></div>
            </div>
          </div>
        </nav>
      </div>
      <DashboardCarousel coupon_data={couponData} />

      {demoBatchSlots.length > 0 && (
        <>
          <div className="font-poppins font-bold text-japanese_indigo mt-8 px-6 py-4 flex w-full justify-between">
            <span className="text-2xl">Demo Batches</span>
          </div>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {demoBatchSlots.map((demoBatchSlot) => {
              const { course } = demoBatchSlot.batch;
              return (
                <CourseCard
                  data={{
                    ...course,
                    userHasRegisteredDemo: true,
                    registeredSlotId: demoBatchSlot.id,
                  }}
                  openCourse={() =>
                    courseSvc.openCourse({
                      moodle_course_id: course.moodle_course_id,
                      courseId: course.id,
                    })
                  }
                />
              );
            })}
          </div>
        </>
      )}

      <div className="font-poppins font-bold text-japanese_indigo mt-16 border-t-2 px-6 py-4 flex w-full justify-between">
        <span className="text-2xl">Ongoing Courses</span>
        <button
          className="font-semibold bg-orange hover:opacity-90 px-6 py-2 text-white rounded-lg"
          onClick={seeAllOngoingCourses}
        >
          See All
        </button>
      </div>

      {loadData ? (
        <div className="text-center p-20">
          <ExpandedLoader />
        </div>
      ) : courses.length > 0 ? (
        <UserOngoingCourse
          courses={courses.filter((c) => c.status === COURSE_STATUS.ONGOING)}
        />
      ) : (
        <div className="text-center p-20">No Ongoing Courses!</div>
      )}

      <div className="font-poppins font-bold text-japanese_indigo mt-16 border-t-2 px-6 py-4 flex w-full justify-between">
        <span className="text-2xl">Completed Courses</span>
        <button
          className="font-semibold bg-orange hover:opacity-90 px-6 py-2 text-white rounded-lg"
          onClick={seeAllCompletedCourses}
        >
          {completedCourseRange === completedCoursesLength
            ? "Collapse All"
            : "See All"}
        </button>
      </div>

      {loadData ? (
        <p className="text-center p-20">
          <ExpandedLoader />
        </p>
      ) : courses.length > 0 ? (
        <UserCompletedCourses
          data={courses.filter((c) => c.status === COURSE_STATUS.COMPLETED)}
          maxRange={completedCourseRange}
          key={completedCourseRange}
        />
      ) : (
        <p className="text-center p-20">No Completed Courses!</p>
      )}
    </>
  );
}
