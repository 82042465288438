import FbButton from "components/social-auth/FbButton";
import GoogleButton from "components/social-auth/GoogleButton";
import React from "react";

export default function SocialAuthOptions(props) {
  return (
    <div className="w-full">
      <div className="font-semibold mb-2">Continue with:</div>
      <div className="flex w-full">
        <GoogleButton className="w-1/2 mr-2" />
        <FbButton className="w-1/2" />
      </div>
    </div>
  );
}
