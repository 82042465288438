/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { formatBySchema, getSchema } from "./utils";
import FormSection from "./Section";
import _ from "lodash";
import { resolveFileFields } from "./FileUploader";
import ExpandedLoader from "components/ExpandedLoader";

const FormComponent = ({
  name,
  formBuilder,
  initValues,
  onSubmit,
  onSubmitError,
  submitButton,
  onUpdate,
  onCancel,
  hideForm,
  hideSubmit,
  $submitSignal,
  className,
  onFileUpload,
  uploadFileFn,
  plugins = {},
}) => {
  const formName = name || "unnamed";
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState();
  const items = formBuilder(formValues);

  const setFieldValueFn = useRef();
  const [attemptedSubmit, setAttemptedSubmit] = useState();

  // create validation schema
  const [validationSchema, setValidationSchema] = useState(null);
  useEffect(() => {
    if (items && !hideForm)
      setValidationSchema(getSchema({ type: "object", fields: items }));
  }, [items]);

  // Handle submission
  const formikSubmitFn = useRef();
  const submit = () => {
    setAttemptedSubmit(true);
    // Upload files
    resolveFileFields(formValues, uploadFileFn)
      .then(async (resolvedFormValues) => {
        // Files are uploaded
        setFormValues(resolvedFormValues);
        // Validate form
        const errors = await validationSchema
          .validate(resolvedFormValues)
          .then(() => false)
          .catch((err) => err);

        // Trigger submit function
        if (!errors) return formikSubmitFn.current();
        // Or throw errors
        else {
          throw { msg: "Please check the form" };
        }
      })
      .catch((err) => {
        console.error(err);
        onSubmitError({ msg: (err && err.msg) || "Couldn't upload files" });
      });
  };

  // Handle submit trigger
  const formRef = useRef();
  const submitSignalSubscription = useRef();
  useEffect(() => {
    const currentSub = submitSignalSubscription.current;
    if (currentSub) currentSub.unsubscribe();

    if ($submitSignal) {
      submitSignalSubscription.current = $submitSignal.subscribe((signal) => {
        if (signal) submit();
      });
      return () => submitSignalSubscription.current.unsubscribe();
    }
  }, [$submitSignal, formRef, submit]);

  // load initial values
  const initValuesLoaded = useRef();
  const [initValuesError, setInitValuesError] = useState();
  useEffect(() => {
    if (typeof initValues === "function")
      initValues()
        .then(async (val) => {
          initValuesLoaded.current = true;
          setFormValues(val || {});
        })
        .catch((err) => {
          setInitValuesError(err);
        });
    else {
      setFormValues(initValues || {});
      initValuesLoaded.current = true;
    }
  }, []);

  /* Handle form validation on validationSchema change:
      Added this feature because when the user had invalid default values,
      clicking on proceed only showed there was a problem in submitting the form
      but not exactly where the bad input was
  */
  const [validateFormFn, setValidateFormFn] = useState();
  useEffect(() => {
    if (validationSchema && validateFormFn) validateFormFn.run();
  }, [validationSchema, !!validateFormFn]);

  return (
    <div className={`w-full ${className}`}>
      {initValuesLoaded.current ? (
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          innerRef={formRef}
          onSubmit={() => {
            let abstractedValues;

            if (!hideForm)
              abstractedValues = formatBySchema(formValues, {
                type: "object",
                fields: items,
              });
            else abstractedValues = {};

            onSubmit({ ...abstractedValues }, setFieldValueFn.current);
          }}
        >
          {(formProps) => {
            const { values, errors, submitForm, setFieldValue, validateForm } =
              formProps;
            if (!validateFormFn) setValidateFormFn({ run: validateForm });
            if (
              JSON.stringify(values) !== JSON.stringify(formValues) ||
              JSON.stringify(errors) !== JSON.stringify(formErrors)
            ) {
              setFieldValueFn.current = setFieldValue;
              setFormValues(values);
              setFormErrors(errors);

              // console.log(values, errors);

              if (onUpdate)
                onUpdate({
                  values,
                  errors,
                });
            }

            formikSubmitFn.current = submitForm;

            return (
              <Form className="justify-start">
                {items && !hideForm && (
                  <div className="grid grid-cols-3 w-full gap-2">
                    <FormSection
                      fields={items}
                      formProps={{
                        ...formProps,
                        errors: { ...formErrors, ...errors },
                        uploadFile: uploadFileFn,
                        formName,
                        plugins,
                        attemptedSubmit,
                      }}
                      onFileUpload={onFileUpload}
                    />
                  </div>
                )}

                <div className="flex justify-between w-full mt-10">
                  {onCancel && (
                    <input
                      type="button"
                      onClick={() => onCancel()}
                      className="button-secondary"
                      value="Cancel"
                    />
                  )}

                  {!hideSubmit && (
                    <input
                      type="button"
                      onClick={submit}
                      className={`button-primary ${submitButton.className}`}
                      value={submitButton.text || "Proceed"}
                    />
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : initValuesError ? (
        <div>An error occured</div>
      ) : (
        <div>
          <ExpandedLoader />
        </div>
      )}
    </div>
  );
};

export default FormComponent;
