import React from "react";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
// import { GET_COURSE } from './Mycourse';
import { GET_COURSE } from "graphql/MycourseQuery/MyCourse";
export default function Batches() {
  const { data, loading, error } = useQuery(GET_COURSE);

  if (loading) {
    return <div>loading</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <div className="grid grid-cols-2 m-10 gap-4">
      <div className="max-w-sm rounded-md shadow-lg content-center">
        <img className="" src="OIP.jpg" alt="Image not found" />
        <div className="px-6 py-4">
          {/* <p className="text-gray-700 text-base">
      course Name: 
    </p> */}
          <p className="text-md font-semibold text-red-400"> </p>
          <br />
          <p className="text-md font-semibold text-red-400">
            {" "}
            Course Name:<p className="text-black inline-block"></p>
          </p>
          <p className="font semi-bold"> Description: </p>
          <p className="font semi-bold">
            {" "}
            Discount:<p className="text-black inline-block"></p>
          </p>
        </div>
      </div>
      <div>
        <div className="grid column-span-2 mt-10 w-md">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-300">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Batch No.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Batch Duration
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Live Sessions on
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Choose Batches
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10"></div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          Regional Paradigm Technician
                        </div>
                        <div className="text-sm text-gray-500">
                          Optimization
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {/* <Link to="/SelectedBatches"> <button className='text-center bg-green'>Choose</button></Link> */}
                        5:50 PM
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Link to="/SelectedBatches">
                          {" "}
                          <button className="text-center bg-green">
                            Choose
                          </button>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
