/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { options } from "./navbar";

export default function LeftSidebar(props) {
  const dispatch = useDispatch();
  const [auth] = useSelector(({ auth }) => [auth]);
  const isLoggedIn = auth.authenticated;
  const newOptions = options({ dispatch, isLoggedIn });
  const location = useLocation();

  return (
    <div
      className={`w-full hidden lg:block lg:w-2/12 shadow-xl rounded-r-3xl sticky top-0 left-0 h-screen`}
    >
      <ul
        className="flex flex-col justify-between space-y-6 text-md mt-2 w-2/12  
        "
        style={{ height: "80vh", overflow: "scroll", width: "100%" }}
      >
        {newOptions.main.map((option) => (
          <li
            className={`${
              location.pathname === option.to ? "border-r-4 border-orange" : ""
            } w-full`}
          >
            <option.component
              to={option.to}
              href={option.link}
              target={option.target}
              onClick={option.onClick}
              className={`${
                location.pathname === option.to
                  ? "bg-orange-light rounded-tl-3xl"
                  : ""
              } flex items-center cursor-pointer space-x-3 text-gray-700 px-2 py-3 font-medium hover:bg-yellow-50 hover:rounded-tl-3xl rounded-tl-3xl focus:bg-orange-light focus:shadow-outline`}
            >
              {option.icon}
              <span className="font-poppins">
                {option.label}
                {/* {option.label === "Scholarships" && (
                  <div className="bg-orange-light text-xs text-orange rounded-full p-1">
                    Coming Soon!
                  </div>
                )} */}
                {/* {option.label === "Refer A Friend" && (
                  <div className="bg-orange-light text-xs text-orange rounded-full p-1">
                    Coming Soon!
                  </div>
                )} */}
              </span>
            </option.component>
          </li>
        ))}
      </ul>
    </div>
  );
}
