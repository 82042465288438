import { gql } from "@apollo/client";

export const CREATE_USER_BATCH_ENROL_MUTATION = gql`
  mutation createuserbatchenrol(
    $batch_id: Int
    $course_id: Int
    $user_id: Int
    $batch_enrol_date: timestamptz
  ) {
    insert_courses_user_batch_enrollment(
      objects: {
        batch_enrol_date: $batch_enrol_date
        batch_id: $batch_id
        course_id: $course_id
        user_id: $user_id
      }
    ) {
      affected_rows
      returning {
        user_id
        course_id
        batch_id
        batch_enrol_date
      }
    }
  }
`;
