/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from "assets/images/logo.svg";

export default function Footer() {
  return (
    <>
      <footer className="w-auto p-4 bottom-0 h-auto bg-white mt-20 border-t-2 border-b-2">
        <div className="lg:flex  lg:mt-3 md:mx-12 lg:mx-28 lg:justify-between">
          <div className="mb-4 lg:columns-1 w-96">
            <img className="h-8 w-auto sm:h-10" src={Logo} alt="logo" />
            <p className="text-black text-sm my-2">
              The World's Largest Vocational Skilling & Certification Portal.
            </p>
          </div>
          <div className="mb-4 lg:mt-3">
            <h3 className="text-black font-bold mb-2 lg:mb-4"> Social Media</h3>
            <div className="">
              <div className=" flex lg:items-center ">
                <div className="lg:container lg:max-w-screen-lg ">
                  <div>
                    <div className="lg:flex lg:flex-wrap gap-2 ">
                      <a
                        href="https://www.linkedin.com/company/skillstrainer/mycompany/"
                        rel="noopener noreferrer"
                      >
                        <button className="bg-blue-600 p-2 hover:bg-white hover:text-black font-semibold text-white inline-flex items-center space-x-2 rounded">
                          <svg
                            className="w-5 h-5 fill-current"
                            role="img"
                            viewBox="0 0 256 256"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path d="M218.123122,218.127392 L180.191928,218.127392 L180.191928,158.724263 C180.191928,144.559023 179.939053,126.323993 160.463756,126.323993 C140.707926,126.323993 137.685284,141.757585 137.685284,157.692986 L137.685284,218.123441 L99.7540894,218.123441 L99.7540894,95.9665207 L136.168036,95.9665207 L136.168036,112.660562 L136.677736,112.660562 C144.102746,99.9650027 157.908637,92.3824528 172.605689,92.9280076 C211.050535,92.9280076 218.138927,118.216023 218.138927,151.114151 L218.123122,218.127392 Z M56.9550587,79.2685282 C44.7981969,79.2707099 34.9413443,69.4171797 34.9391618,57.260052 C34.93698,45.1029244 44.7902948,35.2458562 56.9471566,35.2436736 C69.1040185,35.2414916 78.9608713,45.0950217 78.963054,57.2521493 C78.9641017,63.090208 76.6459976,68.6895714 72.5186979,72.8184433 C68.3913982,76.9473153 62.7929898,79.26748 56.9550587,79.2685282 M75.9206558,218.127392 L37.94995,218.127392 L37.94995,95.9665207 L75.9206558,95.9665207 L75.9206558,218.127392 Z M237.033403,0.0182577091 L18.8895249,0.0182577091 C8.57959469,-0.0980923971 0.124827038,8.16056231 -0.001,18.4706066 L-0.001,237.524091 C0.120519052,247.839103 8.57460631,256.105934 18.8895249,255.9977 L237.033403,255.9977 C247.368728,256.125818 255.855922,247.859464 255.999,237.524091 L255.999,18.4548016 C255.851624,8.12438979 247.363742,-0.133792868 237.033403,0.000790807055"></path>
                            </g>
                          </svg>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:mt-3 mb-4">
            <h3 className="text-black font-bold mb-2 lg:mb-2">Navigate</h3>
            <div className="columns-2 md:columns-3 lg:columns-2">
              <a className="text-black hover:text-orange" href="/">
                Home
              </a>
              <br />
              <a href="/login" className="text-black hover:text-orange">
                Login
              </a>
              <br />
              <a className="text-black hover:text-orange" href="/signup">
                Signup
              </a>
              <br />
              <a className="text-black hover:text-orange" href="/enterprise">
                Work Partner
              </a>
              <br />
            </div>
          </div>
        </div>
        <div className="border-t-2">
          <div className="flex justify-center mt-2">
            <p className="text-gray-400">
              {" "}
              Copyright © SkillsTrainer | All rights reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
