import { gql } from "@apollo/client";

export const FETCH_POSSIBLE_SKILLS = gql`
  query getSkills {
    courses_skillstrainer_skills {
      id
      name
    }
  }
`;

export const FETCH_POSSIBLE_LANGUAGES = gql`
  query getLanguages {
    courses_skillstrainer_languages {
      id
      name
    }
  }
`;

export const FETCH_POSSIBLE_QUALIFICATIONS = gql`
  query getQualifications {
    twelve: courses_qualifications_lookup(where: { type: { _eq: "12" } }) {
      id
      name
    }
    bachelors: courses_qualifications_lookup(
      where: { type: { _eq: "bachelors" } }
    ) {
      id
      name
    }
    masters: courses_qualifications_lookup(
      where: { type: { _eq: "masters" } }
    ) {
      id
      name
    }
    phd: courses_qualifications_lookup(where: { type: { _eq: "phd" } }) {
      id
      name
    }
  }
`;

export const FETCH_POSSIBLE_TECHNICAL_QUALIFICATION = gql`
  query getTechnicalQualifications {
    NSQF: courses_qualifications_lookup(
      where: { type: { _eq: "nsqf" } }
      order_by: { name: asc }
    ) {
      id
      name
      qp_code
    }
    T0: courses_qualifications_lookup(
      where: { type: { _eq: "diploma" } }
      order_by: { name: asc }
    ) {
      id
      name
      qp_code
    }
    T1: courses_qualifications_lookup(
      where: { type: { _eq: "iti" } }
      order_by: { name: asc }
    ) {
      id
      name
      qp_code
    }
    T2: courses_qualifications_lookup(
      where: { type: { _eq: "other" } }
      order_by: { name: asc }
    ) {
      id
      name
      qp_code
    }
  }
`;

export const FETCH_INSTITUTES_WITH_FILTER = gql`
  query fetchInstitutesWithFilter(
    $institutesSearchExp: courses_institutes_bool_exp!
  ) {
    courses_institutes(where: $institutesSearchExp) {
      id
      name
    }
  }
`;
