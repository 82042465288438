import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Toast from "wrappers/Toast";
import courseSvc from "services/Course";
import { ACTION_TYPES } from "route-actions";

export default function JoinDemoSession(props) {
  /*
    - If demo session exists
      - If the user has booked the demo session
        - If the demo session is online and within the availability time frame
          - Mark user's attendance
          - Redirect to the link
        - Else
          - Show details
      - Else
        - If the demo session has slots available and has not begun
          - Show details
          - Show that the demo is not booked by the user
          - Offer user to book a demo
          - Else
          - Show demo is unavailable
          - Else
          - Show error
  */

  const { params, user, complete } = props;
  const { batchSlotId } = params;

  const history = useHistory();

  const checkBatchSlotToJoin = async () => {
    const loader = Toast.load("Please wait...");

    try {
      if (!batchSlotId) return;

      const batchSlot = await courseSvc
        .getBatchSlotById(batchSlotId)
        .catch(console.log);

      if (batchSlot) {
        // Slot is valid
        const userHasEnrolled = !!batchSlot?.slot_enrolled_users.find(
          (u) => u.user.id === user.id
        );
        const isStarting =
            new Date().getTime() >
            new Date(batchSlot.slot_date).getTime() - 10 * 60 * 1000,
          hasEnded =
            new Date().getTime() > new Date(batchSlot.endto_date).getTime();

        if (userHasEnrolled) {
          // User is enrolled

          if (!hasEnded) {
            if (batchSlot.type === "online" && isStarting) {
              await courseSvc
                .markBatchSlotAttendance(
                  user.id,
                  batchSlot.id,
                  batchSlot.batch_id,
                  true
                )
                .catch(console.error);

              Toast.message(
                <button
                  onClick={() => window.open(batchSlot.meeting_link)}
                  className="btn-primary"
                >
                  Join session
                </button>
              );
              window.open(batchSlot.meeting_link);
            } else {
              Toast.message("The session hasn't started yet");
            }
          } else {
            Toast.error("The session has expired");
          }
        } else {
          // User hasn't enrolled

          const hasAvailableSlots =
            batchSlot.slot_enrolled_users.length < batchSlot.batch.max_learners;
          const hasBegun =
            new Date().getTime() >
            new Date(batchSlot.slot_date).getTime() - 10 * 60 * 1000;

          if (hasAvailableSlots && !hasBegun) {
            Toast.confirm(
              <div>
                <div>
                  You are not enrolled for this demo session. Would you like to
                  book a demo for this course now?
                </div>
              </div>
            ).then((res) => {
              if (res)
                history.push({
                  search: `?action=${ACTION_TYPES.book_demo_course}&courseId=${batchSlot.batch.course_id}`,
                });
            });
          } else Toast.error("The session isn't available");
        }
      } else {
        // Slot is invalid
        Toast.error("The session doesn't exist");
      }
    } catch (err) {
      console.error(err);
    } finally {
      Toast.endLoader(loader);
    }
  };

  useEffect(() => {
    checkBatchSlotToJoin();
  }, []);

  return null;
}
