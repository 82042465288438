import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { AllJobFilters, JobCard } from ".";
import { Link } from "react-router-dom";
import {
  GET_JOBS,
  GET_ALL_JOB_APPLICATIONS,
  GET_STARRED_JOBS,
} from "graphql/queries/Jobs";
import { MULTI_SELECT_JOBS_FILTERS } from "api/Consts";
import JobSearchBox from "./filters/jobsearchbox";
import AppliedJobs from "./appliedjobs";
import StarredJobs from "./starredJobs";
import { IoIosStarOutline, IoIosStar } from "react-icons/io";

export const FiltersContext = React.createContext();

export default function AllJobs() {
  const defaultFilters = {
    [MULTI_SELECT_JOBS_FILTERS.LANGUAGES]: [],
    [MULTI_SELECT_JOBS_FILTERS.SOCIAL_CATEGORIES]: [],
    [MULTI_SELECT_JOBS_FILTERS.PARTNER]: [],
    [MULTI_SELECT_JOBS_FILTERS.QUALIFICATIONS]: [],
    [MULTI_SELECT_JOBS_FILTERS.SKILLS]: [],
    [MULTI_SELECT_JOBS_FILTERS.COUNTRY]: "India",
    [MULTI_SELECT_JOBS_FILTERS.CITIES]: [],
    [MULTI_SELECT_JOBS_FILTERS.SEARCH]: "",
    [MULTI_SELECT_JOBS_FILTERS.SALARY]: [0, 25000],
    [MULTI_SELECT_JOBS_FILTERS.SECTOR]: [],
  };

  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const [jobs, setJobs] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const pageSize = 5;
  const [pageNo, setPageNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const observer = useRef();
  const [applied, setApplied] = useState(false);
  const [starred, setStarred] = useState(false);

  const jobApplications = useQuery(GET_ALL_JOB_APPLICATIONS, {
    variables: {
      userId,
    },
  });

  const starredJobs = useQuery(GET_STARRED_JOBS, {
    variables: {
      userId,
    },
  });

  const whereClause = useMemo(
    () => ({
      _and: [
        { is_active: { _eq: true } },
        filters[MULTI_SELECT_JOBS_FILTERS.LANGUAGES].length
          ? {
              _or: [
                {
                  job_languages_required: {
                    language_id: {
                      _in: filters[MULTI_SELECT_JOBS_FILTERS.LANGUAGES],
                    },
                  },
                },
                { _not: { job_languages_required: {} } },
              ],
            }
          : {},
        filters[MULTI_SELECT_JOBS_FILTERS.SOCIAL_CATEGORIES].length
          ? {
              _or: [
                {
                  social_categories: {
                    _has_keys_any:
                      filters[MULTI_SELECT_JOBS_FILTERS.SOCIAL_CATEGORIES],
                  },
                },
                { social_categories: { _is_null: true } },
              ],
            }
          : {},
        filters[MULTI_SELECT_JOBS_FILTERS.PARTNER].length
          ? {
              _or: [
                {
                  partner_id: {
                    _in: filters[MULTI_SELECT_JOBS_FILTERS.PARTNER],
                  },
                },
                { partner_id: { _is_null: true } },
              ],
            }
          : {},
        filters[MULTI_SELECT_JOBS_FILTERS.QUALIFICATIONS].length
          ? {
              _or: [
                {
                  job_required_qualifications: {
                    qualification_type: {
                      _in: filters[MULTI_SELECT_JOBS_FILTERS.QUALIFICATIONS],
                    },
                  },
                },
                { _not: { job_required_qualifications: {} } },
              ],
            }
          : {},
        filters[MULTI_SELECT_JOBS_FILTERS.SECTOR].length
          ? {
              _or: [
                {
                  sector_id: {
                    _in: filters[MULTI_SELECT_JOBS_FILTERS.SECTOR],
                  },
                },
              ],
            }
          : {},
        filters[MULTI_SELECT_JOBS_FILTERS.SKILLS].length
          ? {
              _or: [
                {
                  job_required_skills_criteria: {
                    st_skill_id: {
                      _in: filters[MULTI_SELECT_JOBS_FILTERS.SKILLS],
                    },
                  },
                },
                { _not: { job_required_skills_criteria: {} } },
              ],
            }
          : {},
        filters[MULTI_SELECT_JOBS_FILTERS.COUNTRY]
          ? {
              _or: [
                {
                  job_location: {
                    country: {
                      _eq: filters[MULTI_SELECT_JOBS_FILTERS.COUNTRY],
                    },
                  },
                },
                { _not: { job_location: {} } },
              ],
            }
          : {},
        filters[MULTI_SELECT_JOBS_FILTERS.CITIES].length
          ? {
              _or: [
                {
                  job_location: {
                    city: { _in: filters[MULTI_SELECT_JOBS_FILTERS.CITIES] },
                  },
                },
                { _not: { job_location: {} } },
                { job_location: { city: { _is_null: true } } },
              ],
            }
          : {},
        {
          _or: [
            {
              salary_ctc: {
                _gte: filters[MULTI_SELECT_JOBS_FILTERS.SALARY][0],
              },
            },
            { salary_ctc: { _is_null: true } },
          ],
        },
        {
          _or: [
            {
              salary_ctc: {
                _lte: filters[MULTI_SELECT_JOBS_FILTERS.SALARY][1],
              },
            },
            { salary_ctc: { _is_null: true } },
          ],
        },
        {
          _or: [
            {
              title: {
                _ilike:
                  filters[MULTI_SELECT_JOBS_FILTERS.SEARCH]?.length != 0
                    ? "%" +
                      filters[MULTI_SELECT_JOBS_FILTERS.SEARCH]?.replace(
                        " ",
                        "%"
                      ) +
                      "%"
                    : "%",
              },
            },
            {
              description: {
                _ilike:
                  filters[MULTI_SELECT_JOBS_FILTERS.SEARCH]?.length != 0
                    ? "%" +
                      filters[MULTI_SELECT_JOBS_FILTERS.SEARCH]?.replace(
                        " ",
                        "%"
                      ) +
                      "%"
                    : "%",
              },
            },
          ],
        },
      ],
    }),
    [filters]
  );

  const { data, fetchMore } = useQuery(GET_JOBS, {
    variables: {
      whereClause,
      limit: 0,
      offset: 0,
      userId: userId,
    },
  });

  const lastJobElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && count > (pageNo + 1) * pageSize) {
          setPageNo((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, count, pageNo]
  );

  const findJobApplication = (job) => {
    for (let ind of jobApplications?.data?.courses_job_applications || []) {
      if (ind.job.id == job.id) return ind;
    }
    return null;
  };

  const isJobStarred = (job) => {
    for (let ind of starredJobs?.data?.courses_starred_jobs || []) {
      if (ind.job_id == job.id) return true;
    }
    return false;
  };

  useEffect(async () => {
    try {
      setLoading(true);

      await fetchMore({
        variables: {
          offset: pageNo * pageSize,
          limit: pageSize,
        },
        updateQuery: (previousJobsResult, { fetchMoreResult }) => {
          setCount(
            fetchMoreResult.courses_skillstrainer_jobs_aggregate.aggregate
              .totalCount
          );
          if (previousJobsResult.courses_skillstrainer_jobs && pageNo != 0) {
            fetchMoreResult.courses_skillstrainer_jobs = [
              ...previousJobsResult.courses_skillstrainer_jobs,
              ...fetchMoreResult.courses_skillstrainer_jobs,
            ];
          }

          setJobs(fetchMoreResult.courses_skillstrainer_jobs);
          setLoading(false);
          return fetchMoreResult;
        },
      });
    } catch (err) {
      console.log(err);
      console.log("Error while fetching");
    }
  }, [pageNo, filters]);

  return (
    <>
      <div>
        <FiltersContext.Provider value={{ filters, setFilters, setPageNo }}>
          <div className="p-4">
            <div className="flex items-center border-b-2 border-gray-100 py-4 md:space-x-10">
              <h1 className="font-bold my-4 text-2xl lg:text-4xl text-japanese_indigo">
                All Jobs
              </h1>
              <JobSearchBox />
              <div className="flex flex-row justify-end">
                <button
                  onClick={() => setApplied(true ^ applied)}
                  className="mx-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
                >
                  {applied ? "Show All Jobs" : "View Applied Jobs"}
                </button>
              </div>

              {!applied && (
                <div className="flex flex-row justify-end">
                  {!starred ? (
                    <IoIosStarOutline
                      onClick={() => setStarred(true ^ starred)}
                      className="text-orange cursor-pointer"
                      size={25}
                    />
                  ) : (
                    <IoIosStar
                      onClick={() => setStarred(true ^ starred)}
                      className="text-orange cursor-pointer"
                      size={25}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-row">
              <button
                // onClick={() => setApplied(true ^ applied)}
                className="mx-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
              >
                India
              </button>
              <button className="ml-10 rounded-md py-3 px-5 bg-orange text-white font-semibold">
                International
              </button>
            </div>

            {starred && !applied ? (
              <StarredJobs
                isJobStarred={isJobStarred}
                starredJobs={starredJobs}
                pageSize={pageSize}
              />
            ) : applied ? (
              <AppliedJobs
                isJobStarred={isJobStarred}
                starredJobsQuery={starredJobs}
                pageSize={pageSize}
              />
            ) : (
              <>
                <div className="w-full bg-orange-light rounded-md p-2 my-2">
                  Before applying, fill all your details in{" "}
                  <span className="text-blue-500 font-semibold">
                    {" "}
                    <Link to="/settings/account-details">My Profile</Link>{" "}
                  </span>
                </div>

                <div class="flex h-screen">
                  <div class="flex-1 flex flex-col overflow-visible">
                    <div class="h-full w-full">
                      <AllJobFilters />
                      {/* <AllJobFilters1/> */}
                      <header class="flex justify-between items-center my-2 mt-5">
                        <p className="font-semibold text-sm">Filtered Jobs</p>
                      </header>
                      <main class="max-h-screen flex flex-col bg-white overflow-x-hidden overflow-y-auto mb-14">
                        <div class="flex w-full mx-auto px-6 py-8">
                          <div class="flex flex-col w-full h-full">
                            {jobs?.map((item, index) => {
                              let jobApplication = findJobApplication(item);
                              let isStarred = isJobStarred(item);
                              if (index + 1 == jobs?.length) {
                                return (
                                  <JobCard
                                    isStarred={isStarred}
                                    starredJobsQuery={starredJobs}
                                    jobApplicationsQuery={jobApplications}
                                    jobApplication={jobApplication}
                                    current_ref={lastJobElementRef}
                                    key={item.id}
                                    title={item.title}
                                    description={item.description}
                                    social_categories={item.social_categories}
                                    gender={item.gender}
                                    organization_name={
                                      item.partner.organization_name
                                    }
                                    userId={userId}
                                    salary_ctc={item.salary_ctc}
                                    min_age={item.min_age}
                                    min_experience={item.min_experience}
                                    max_age={item.max_age}
                                    logo={item.partner.logo}
                                    created_at={item.created_at}
                                    details={item}
                                  />
                                );
                              }
                              return (
                                <JobCard
                                  isStarred={isStarred}
                                  starredJobsQuery={starredJobs}
                                  jobApplicationsQuery={jobApplications}
                                  jobApplication={jobApplication}
                                  key={item.id}
                                  title={item.title}
                                  description={item.description}
                                  social_categories={item.social_categories}
                                  gender={item.gender}
                                  organization_name={
                                    item.partner.organization_name
                                  }
                                  userId={userId}
                                  salary_ctc={item.salary_ctc}
                                  min_age={item.min_age}
                                  min_experience={item.min_experience}
                                  max_age={item.max_age}
                                  logo={item.partner.logo}
                                  created_at={item.created_at}
                                  details={item}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </main>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </FiltersContext.Provider>
      </div>
    </>
  );
}
