import { gql } from "@apollo/client";

export const CREATE_RAZORPAY_ORDER_ID = gql`
  mutation RazorpayOrderCreate(
    $amount: float8
    $currency: String
    $rajorpay_order_id: String
    $order_status: String
    $subscription_order: Boolean
    $customer_id: String
    $order_contact: bigint
    $order_email: String
    $rajorpay_payment_id: String
    $user_id: Int
    $course_id: Int
    $enrollment_id: Int
    $order_create_at: Int
    $coupon_id: uuid
    $subscription_id: String
    $rajorpay_signature: String
  ) {
    insert_courses_user_course_order(
      objects: {
        currency: $currency
        rajorpay_order_id: $rajorpay_order_id
        amount: $amount
        order_status: $order_status
        subscription_order: $subscription_order
        customer_id: $customer_id
        order_contact: $order_contact
        order_email: $order_email
        rajorpay_payment_id: $rajorpay_payment_id
        user_id: $user_id
        course_id: $course_id
        enrollment_id: $enrollment_id
        order_create_at: $order_create_at
        coupon_id: $coupon_id
        subscription_id: $subscription_id
        rajorpay_signature: $rajorpay_signature
      }
    ) {
      returning {
        rajorpay_order_id
        id
      }
    }
  }
`;

export const CREATE_SUBSCRIPTION_RAZORPAY_ORDER_ID = gql`
  mutation RazorpaySubsOrderCreate(
    $amount: float8
    $currency: String
    $rajorpay_order_id: String
    $order_status: String
    $subscription_order: Boolean
    $customer_id: String
    $order_contact: bigint
    $order_email: String
    $rajorpay_payment_id: String
    $user_id: Int
    $course_id: Int
    $enrollment_id: Int
    $order_create_at: Int
    $coupon_id: uuid
    $subscription_id: String
    $rajorpay_signature: String
  ) {
    insert_courses_user_course_order(
      objects: {
        currency: $currency
        rajorpay_order_id: $rajorpay_order_id
        amount: $amount
        order_status: $order_status
        subscription_order: $subscription_order
        customer_id: $customer_id
        order_contact: $order_contact
        order_email: $order_email
        rajorpay_payment_id: $rajorpay_payment_id
        user_id: $user_id
        course_id: $course_id
        enrollment_id: $enrollment_id
        order_create_at: $order_create_at
        coupon_id: $coupon_id
        subscription_id: $subscription_id
        rajorpay_signature: $rajorpay_signature
      }
    ) {
      returning {
        rajorpay_order_id
        id
      }
    }
  }
`;

export const UPDATE_RAZORPAY_ORDER_DETAILS = gql`
  mutation UpdateRazorpayOrder(
    $rajorpay_order_id: String
    $rajorpay_signature: String
  ) {
    update_courses_user_course_order(
      where: { rajorpay_order_id: { _eq: $rajorpay_order_id } }
      _set: { rajorpay_signature: $rajorpay_signature }
    ) {
      returning {
        id
        rajorpay_order_id
        rajorpay_payment_id
        rajorpay_signature
      }
    }
  }
`;

export const LINK_ORDER_TO_SUBSCRIPTION = gql`
  mutation LinkOrderToSubscription(
    $rajorpay_order_id: String
    $subscription_id: String
  ) {
    update_courses_user_course_order(
      where: { rajorpay_order_id: { _eq: $rajorpay_order_id } }
      _set: { subscription_id: $subscription_id }
    ) {
      returning {
        id
        rajorpay_order_id
        rajorpay_payment_id
        rajorpay_signature
      }
    }
  }
`;

export const USER_COURSE_ENROLL = gql`
  mutation UserCourseEnroll(
    $course_id: Int
    $enroll_status: Boolean
    $order_id: String
    $user_id: Int
    $exired_at: timestamp
    $subscription_id: String
  ) {
    insert_courses_user_course_enrolment(
      objects: {
        course_id: $course_id
        enroll_status: $enroll_status
        order_id: $order_id
        user_id: $user_id
        exired_at: $exired_at
        subscription_id: $subscription_id
      }
      on_conflict: { constraint: user_course_enrolment_user_id_course_id_key }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_USER_COURSE_SUBSCRIPTION = gql`
  mutation InserUserCourseSubscription(
    $auth_attempts: Int
    $charge_at: Int
    $course_id: Int
    $current_end: Int
    $current_start: Int
    $end_at: Int
    $expire_by: Int
    $offer_id: Int
    $paid_count: Int
    $payment_method: String
    $plan_id: String
    $rajorpay_customer_id: String
    $remaining_count: Int
    $source: String
    $start_at: Int
    $status: String
    $subscription_created_at: Int
    $subscription_id: String
    $total_count: Int
    $user_id: Int
  ) {
    insert_courses_user_course_subscription(
      objects: {
        auth_attempts: $auth_attempts
        charge_at: $charge_at
        course_id: $course_id
        current_end: $current_end
        current_start: $current_start
        end_at: $end_at
        expire_by: $expire_by
        offer_id: $offer_id
        paid_count: $paid_count
        payment_method: $payment_method
        plan_id: $plan_id
        rajorpay_customer_id: $rajorpay_customer_id
        remaining_count: $remaining_count
        source: $source
        start_at: $start_at
        status: $status
        subscription_created_at: $subscription_created_at
        subscription_id: $subscription_id
        total_count: $total_count
        user_id: $user_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_USER_COURSE_SUBSCRIPTION = gql`
  mutation UpdateUserCourseSubscription(
    $auth_attempts: Int
    $charge_at: Int
    $current_end: Int
    $current_start: Int
    $end_at: Int
    $expire_by: Int
    $offer_id: Int
    $paid_count: Int
    $payment_method: String
    $remaining_count: Int
    $source: String
    $start_at: Int
    $status: String
    $subscription_created_at: Int
    $subscription_id: String
    $total_count: Int
    $paused_at: Int
    $pause_initiated_by: String
  ) {
    update_courses_user_course_subscription(
      where: { subscription_id: { _eq: $subscription_id } }
      _set: {
        auth_attempts: $auth_attempts
        charge_at: $charge_at
        current_end: $current_end
        current_start: $current_start
        end_at: $end_at
        expire_by: $expire_by
        offer_id: $offer_id
        paid_count: $paid_count
        payment_method: $payment_method
        remaining_count: $remaining_count
        source: $source
        start_at: $start_at
        status: $status
        subscription_created_at: $subscription_created_at
        total_count: $total_count
        paused_at: $paused_at
        pause_initiated_by: $pause_initiated_by
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const USER_SUBSCRIPTION_COURSE_ENROLLMENT = gql`
  mutation UserSubscriptionEnroll(
    $user_id: Int
    $subscription_id: String
    $order_id: String
    $exired_at: timestamp
    $enroll_status: Boolean
    $course_id: Int
  ) {
    insert_courses_user_course_enrolment(
      objects: {
        user_id: $user_id
        subscription_id: $subscription_id
        order_id: $order_id
        exired_at: $exired_at
        enroll_status: $enroll_status
        course_id: $course_id
      }
      on_conflict: { constraint: user_course_enrolment_user_id_course_id_key }
    ) {
      returning {
        id
      }
    }
  }
`;

export const GET_SUBSCRIPTION_RECURRING_ORDERS = gql`
  query MyQuery($subscription_id: String) {
    courses_user_course_order(
      where: {
        subscription_id: { _eq: $subscription_id }
        rajorpay_order_id: { _is_null: false }
      }
    ) {
      amount
      ordercourse {
        full_name
      }
      order_status
      order_create_at
      id
      order_contact
      order_email
    }
  }
`;

export const MANUAL_USER_COURSE_ENROLL = gql`
  mutation ManualUserCourseEnroll(
    $course_id: Int
    $enroll_status: Boolean
    $user_id: Int
  ) {
    insert_courses_user_course_enrolment(
      objects: {
        course_id: $course_id
        enroll_status: $enroll_status
        user_id: $user_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;
