import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import liveClassService from "services/liveClass";
// import Cert from './CertificateTemplate/Cert';
// import New from './CertificateTemplate/New';
import { useSelector } from "react-redux";
import { PDFExport } from "@progress/kendo-react-pdf";
import moment from "moment";
import reactStringReplace from "react-string-replace";
import QRCode from "qrcode.react";
import { useLocation } from "react-router";
import queryString from "query-string";
import Toast from "utils/Toast";

export default function CertificateVerify() {
	const pdfExportComponent = React.useRef(null);
	const location = useLocation();
	const params = queryString.parse(location.search);

	console.log("certParams", params);

	const auth = useSelector(({ auth }) => auth);
	const userId = auth.user?.db_user?.id;
	const User = auth.user?.db_user?.name;

	useEffect(async () => {
		getCourseCertificate();
	}, []);

	const [certificate, setCertificate] = useState({});

	const getCourseCertificate = async () => {
		const res = await liveClassService.CheckCertificateVerify(params);
		console.log("certificate data", res);

		if (res) {
			const { certificate_mapping, certificate_user_mapping } = res;

			setCertificate({
				user_name: certificate_user_mapping.name,
				user_x_axis: certificate_mapping.user_x_axis,
				user_y_axis: certificate_mapping.user_y_axis,
				user_text_size: certificate_mapping.user_text_size,
				course_name: certificate_mapping.course_name,
				course_x_axis: certificate_mapping.course_x_axis,
				course_y_axis: certificate_mapping.course_y_axis,
				course_name_size: certificate_mapping.course_name_size,

				percentage_grade: certificate_mapping.percentage_grade,
				per_x_axis: certificate_mapping.per_x_axis,
				per_y_axis: certificate_mapping.per_y_axis,
				per_size: certificate_mapping.per_size,

				nsqf: certificate_mapping.nsqf,
				nsqf_x_axis: certificate_mapping.nsqf_x_axis,
				nsqf_y_axis: certificate_mapping.nsqf_y_axis,
				nsqf_size: certificate_mapping.nsqf_size,

				enrollment_id: res.enrollment_id,
				enroll_x_axis: certificate_mapping.enroll_x_axis,
				enroll_y_axis: certificate_mapping.enroll_y_axis,
				enroll_size: certificate_mapping.enroll_size,

				partner_name: certificate_mapping.partner_name,
				partner_x_axis: certificate_mapping.partner_x_axis,
				partner_y_axis: certificate_mapping.partner_y_axis,
				partner_size: certificate_mapping.partner_size,

				issued_date: moment(res.issue_date).format("YYYY-MM-DD"),
				date_x_axis: certificate_mapping.date_x_axis,
				date_y_axis: certificate_mapping.date_y_axis,
				date_size: certificate_mapping.date_size,

				project_name: "",
				project_x_axis: certificate_mapping.project_x_axis,
				project_y_axis: certificate_mapping.project_y_axis,
				project_size: certificate_mapping.project_size,

				qr_x_axis: certificate_mapping.qr_x_axis,
				qr_y_axis: certificate_mapping.qr_y_axis,
				qr_size: certificate_mapping.qr_size,

				bg_image_url: certificate_mapping.bg_image_url,
				// mapping_id: checkCertissue.mapping_id,
			});

			Toast.success("This Certificate Verified");
		} else {
			Toast.error("No Certificate Found");
		}
	};

	return (
		<div>
			<PDFExport>
				<div className=" w-full overflow-scroll md:h-auto">
					{" "}
					<div
						className="w-full h-full shadow-md   justify-center"
						style={{
							backgroundImage: "url(" + certificate.bg_image_url + ")",
							backgroundPosition: "center",
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat",
							width: "685px",
							height: "547px",
							margin: "0 auto",
							display: "block",
							position: "relative",
						}}
					>
						<div style={{ position: "relative" }}>
							<p
								style={{
									fontSize: parseInt(certificate.user_text_size),
									position: "absolute",
									top: parseInt(certificate.user_x_axis),
									left: parseInt(certificate.user_y_axis),
								}}
								className={` text-black-600 whitespace-nowrap `}
							>
								{certificate.user_name}
							</p>
							<p
								style={{
									fontSize: parseInt(certificate.course_name_size),
									position: "absolute",
									top: parseInt(certificate.course_x_axis),
									left: parseInt(certificate.course_y_axis),
								}}
								className={` text-black-600 whitespace-nowrap absolute  right-18 top${-certificate.course_x_axis}  right${-certificate.course_y_axis}`}
							>
								{certificate?.course_name}
							</p>

							<p
								style={{
									fontSize: parseInt(certificate.enroll_size),
									position: "absolute",
									top: parseInt(certificate.enroll_x_axis),
									left: parseInt(certificate.enroll_y_axis),
								}}
								className={` text-black-600 whitespace-nowrap absolute  right-18 top${-certificate.enroll_x_axis}  right${-certificate.enroll_y_axis}`}
							>
								{certificate.enrollment_id}
							</p>

							{certificate.partner_name && (
								<p
									style={{
										fontSize: parseInt(certificate.partner_size),
										position: "absolute",
										top: parseInt(certificate.partner_x_axis),
										left: parseInt(certificate.partner_y_axis),
									}}
									className="whitespace-nowrap  text-black-600  "
								>
									Partner: {certificate?.partner_name}
								</p>
							)}

							{certificate.partner_name && (
								<p
									style={{
										fontSize: parseInt(certificate.project_size),
										position: "absolute",
										top: parseInt(certificate.project_x_axis),
										left: parseInt(certificate.project_y_axis),
									}}
									className="whitespace-nowrap  text-black-600 "
								>
									Project: {certificate?.project_name}
								</p>
							)}

							<p
								style={{
									fontSize: parseInt(certificate.date_size),
									position: "absolute",
									top: parseInt(certificate.date_x_axis),
									left: parseInt(certificate.date_y_axis),
								}}
								className="whitespace-nowrap text-black-600 "
							>
								{certificate?.issued_date}
							</p>

							<p
								className="text-black-600 absolute "
								style={{
									fontSize: parseInt(certificate.nsqf_size),
									position: "absolute",
									top: parseInt(certificate.nsqf_x_axis),
									left: parseInt(certificate.nsqf_y_axis),
								}}
							>
								{" "}
								{certificate?.nsqf}
							</p>
							<p
								className="text-black-600 absolute whitespace-nowrap "
								style={{
									fontSize: parseInt(certificate.per_size),
									position: "absolute",
									top: parseInt(certificate.per_x_axis),
									left: parseInt(certificate.per_y_axis),
								}}
							>
								{certificate.percentage_grade}
							</p>

							<p
								className="text-black-600 absolute whitespace-nowrap "
								style={{
									fontSize: parseInt(certificate.qr_size),
									position: "absolute",
									top: parseInt(certificate.qr_x_axis),
									left: parseInt(certificate.qr_y_axis),
								}}
							>
								<QRCode
									title="GeeksForGeeks"
									value={`"http://localhost:3001/certificate/verify/?enrollment_id=${certificate.enrollment_id}&&mapping_id=${certificate.mapping_id}"`}
									//value="https://www.tutorialspoint.com/"
									bgColor="#FFFFFF"
									fgColor="#000000"
									size={parseInt(certificate.qr_size)}
								/>
							</p>
						</div>
					</div>
				</div>
			</PDFExport>
		</div>
	);
}
