import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import liveClassService from "services/liveClass";
import { ActionTypes } from "redux/actions/Quiz/ActionTypes";
import { useParams } from "react-router";
import Container from "components/Container";
import { FaCheck } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import { FetchCourseLesson } from "redux/actions/Course";
import { s3QuizImgBaseUrl } from "api/Consts";
import { EvaluateQuizResults } from "api/Course";

export default function Result(props) {
  const dispatch = useDispatch();
  const { nonScoreQuiz } = props;
  const { id } = useParams();
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;

  const [quizResult, setResult] = useState([]);

  const [questions, setQuestions] = useState([]);

  const [correctAnswer, setCorrectAnswer] = useState([]);

  const [attemptData, setAttemptData] = useState({
    quiz_title: null,
  });

  const [windowFocus, setWindow] = useState(false);

  useEffect(() => {
    let questionsdata = props.questions.courses_questions;
    questionsdata.forEach((q) => {
      q.isCorrect = q.quest_answer.every((x) => x.selected === x.isAnswer);
    });

    setQuestions(questionsdata);

    let QuizTitle = props.questions.mode.lessonName;
    setAttemptData({
      quiz_title: QuizTitle,
    });

    let windowFocus = props.windowFocus?.change;

    setWindow(windowFocus);
  }, []);

  useEffect(async () => {
    questions &&
      questions.map((e) => {
        e.quest_answer.filter((a) => {
          return a.isAnswer;
        });
      });

    let totalCorrectAnswerMarks = [];
    questions &&
      questions.filter((e) => {
        if (e.isCorrect == true) {
          totalCorrectAnswerMarks.push(e.marks);
        }
      });

    setCorrectAnswer(totalCorrectAnswerMarks);

    let earnedMarks =
      totalCorrectAnswerMarks.length > 0 &&
      totalCorrectAnswerMarks.reduce((acc, curr) => {
        return acc + curr;
      });

    let result =
      totalCorrectAnswerMarks.length > 0 &&
      (earnedMarks * 100) / props.questions.max_marks;

    setResult(Math.round(result));

    if (result >= props.questions.completion_percentage) {
      console.log(
        "checkCourseCompleteAudit",
        "In Result.js, invoking liveClassService.insertCourseModuleComplete"
      );
      const response =
        // totalCorrectAnswerMarks.length > 0 &&
        questions &&
        (await liveClassService.insertCourseModuleComplete(
          props.questions.mode,
          id,
          userId
        ));

      console.log("checkCourseCompleteAudit", "Dispatching FetchCourseLesson");
      dispatch(FetchCourseLesson(props.attempt.course_id));
    }

    const rep =
      questions &&
      (await liveClassService.UpdateUserQuizAttempt(
        id,
        props.questions.max_marks,
        result,
        earnedMarks,
        userId,
        props.questions.completion_percentage,
        props.attempt.attempt_id,
        props.attempt.course_id
      ));

    if (rep) {
      const quizAttempt = await liveClassService.insertUserQuestionAttempt(
        id,
        questions,
        props.attempt.attempt_id,
        userId,
        props.attempt.course_id
      );
      dispatch({ type: ActionTypes.ResetQuizState });

      if (quizAttempt) {
        setQuestions([]);
      }

      if (windowFocus && props.attempt.newWindow) {
        //  dispatch(Logout())
      }
    }
  }, [questions]);

  const reAttemptQuiz = () => {
    props.reAttempt();
  };

  return (
    <Container className={"hidden"}>
      <div className="hidden">
        <div className="p-10 border-2 shadow-lg bg-white ">
          <div className="p-2">
            {/* <h1>My Course</h1> */}
            <div className="text-inline ">
              <label className="font-semibold">Quiz Name: </label>
              <span> {attemptData.quiz_title}</span>
            </div>

            {!nonScoreQuiz && (
              <div className="text-inline ">
                <label className="font-semibold">Quiz Result: </label>
                <span>
                  {quizResult < props.questions.completion_percentage
                    ? "Failed"
                    : "Passed"}
                </span>
              </div>
            )}
            {/* <div className="text-inline ">
              <label className="font-semibold">Correct Answer: </label>
              <span>
                {correctAnswer.length}/{questions.length}
              </span>
            </div> */}
            {!nonScoreQuiz && (
              <div className="text-inline ">
                <label className="font-semibold">Percentage: </label>
                <span>{quizResult}%</span>
              </div>
            )}
          </div>
          <div className="flex justify-between">
            <p className="underline">attempt again</p>
            <button
              onClick={() => reAttemptQuiz()}
              className="bg-transparent bg-orange text-white font-semibold hover:text-white py-1 px-4 border border-blue-500 hover:border-transparent rounded"
            >
              Re-Attempt
            </button>
          </div>
        </div>
      </div>

      {!nonScoreQuiz &&
        questions &&
        questions.map((q, index) => (
          <div
            className={`border-2 shadow-lg p-10 mt-4 rounded-lg bg-white ${
              q.question_type === 1
                ? q.isCorrect
                  ? "border-green-600"
                  : "border-red-600"
                : ""
            }`}
          >
            <div className="flex justify-end">
              {q.question_type === 1 ? (
                q.isCorrect ? (
                  <FaCheck size={20} className="text-green-600" />
                ) : (
                  <BsXLg size={20} className="text-red-600" />
                )
              ) : (
                ""
              )}
            </div>
            <div key={q.id}>
              {index + 1}. {q.question_text}
              <ul>
                {q.quest_answer.map((option) => (
                  <div className="form-check mt-2" key={option.id}>
                    <input
                      className="form-check-input
                      appearance-none rounded-full h-4 w-4 
                      border border-gray-300 bg-white checked:bg-blue-600 
                      checked:border-blue-600 focus:outline-none 
                      transition duration-200 mt-1 align-top bg-no-repeat 
                      bg-center bg-contain float-left mr-2 cursor-pointer
                      
                      "
                      type="radio"
                      id={option.id}
                      disabled="disabled"
                      checked={option.selected}
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      for="flexRadioDefault1"
                    >
                      {q.question_type === 2 && q.mode === "picture" && (
                        <img
                          src={`${s3QuizImgBaseUrl}/${option?.answer_img}`}
                          width="200"
                          height="100"
                          alt="Example dd"
                        />
                      )}
                      {q.question_type === 2 && q.mode === "text" && (
                        <span className="ml-2  ">{option.answer_text}</span>
                      )}
                      {q.question_type === 1 && (
                        <span className="ml-2  ">{option.answer_text}</span>
                      )}
                    </label>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        ))}
    </Container>
  );
}
