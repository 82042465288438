import { useParams } from "react-router";
import razorpayService from "services/razorpay";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Container from "components/Container";
import razorpaySubscriptionClass from "services/RazorpaySubscriptionApi";
import axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";

export default function SubscriptionView() {
  const { id } = useParams();
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const [orderDetails, setOrderDetails] = useState({});
  const [recurringOrder, setRecurringOrder] = useState({});

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  useEffect(async () => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    const order = await razorpayService.GetSubscriptionOrder(id, userId);

    console.log("order", order);

    const recurringOrder = await razorpayService.GetSubscriptionRecurringOrder(
      order.subscription_id
    );
    console.log("recurringOrder", recurringOrder);

    setRecurringOrder(recurringOrder);

    setOrderDetails(order);
  };

  const {
    status,
    start_at,
    current_start,
    current_end,
    charge_at,
    end_at,
    total_count,
    remaining_count,
    subscription_id,
    course_id,
    rajorpay_customer_id,
    razorpay_payment_id,
    razorpay_signature,
    subscription_created_at,
  } = orderDetails;

  console.log("orderDetail s", orderDetails);

  // const charge_time = charge_at;
  // const date = new Date(charge_time * 1000);
  // const nextPayment =
  //   date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

  // const start = start_at;
  // const startdate = new Date(start * 1000);
  // const SubscriptionStart =
  //   startdate.getDate() +
  //   "/" +
  //   (startdate.getMonth() + 1) +
  //   "/" +
  //   startdate.getFullYear();

  // const currentend = current_end;
  // const currentenddate = new Date(currentend * 1000);
  // const currentendbilling =
  //   currentenddate.getDate() +
  //   "/" +
  //   (currentenddate.getMonth() + 1) +
  //   "/" +
  //   currentenddate.getFullYear();

  // const currentstart = current_start;
  // const currentstartdate = new Date(currentstart * 1000);
  // const currentbilling =
  //   currentstartdate.getDate() +
  //   "/" +
  //   (currentstartdate.getMonth() + 1) +
  //   "/" +
  //   currentstartdate.getFullYear();

  // const end_time = end_at;
  // const end_time_date = new Date(end_time * 1000);
  // const endbilling =
  //   end_time_date.getDate() +
  //   "/" +
  //   (end_time_date.getMonth() + 1) +
  //   "/" +
  //   end_time_date.getFullYear();

  const columns = [
    {
      name: "Order",
      selector: (row, index) => `#${row.id}`,
      sortable: true,
    },

    {
      name: "Course",
      selector: (row, index) => `${row.ordercourse.full_name}`,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row, index) =>
        row.order_status ? `${row.order_status}` : " Created",
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row, index) =>
        row.order_create_at
          ? `${moment.unix(row.order_create_at).format("YYYY/MM/DD")}`
          : "",
      sortable: true,
    },

    {
      name: "Order Total",
      selector: (row, index) => (row.amount != null ? `₹${row.amount}` : ""),
      sortable: true,
    },

    {
      cell: (row) => (
        <Link to={`/order/view/${row.id}`}>
          <button className="text-white rounded-md text-md px-7 py-1 bg-orange font-semibold">
            View
          </button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  return (
    <Container>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <div className="p-4">
                <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
                  Subscription Details
                </h1>
              </div>

              <table className="min-w-full">
                <tbody>
                  <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Status
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {status}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Start Date
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {moment.unix(start_at).format("YYYY/MM/DD")}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Current Biilling Start
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {moment.unix(current_start).format("YYYY/MM/DD")}
                    </td>
                  </tr>

                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Current Biilling End
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {moment.unix(current_end).format("YYYY/MM/DD")}
                    </td>
                  </tr>

                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Next Payment Date
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {moment.unix(charge_at).format("YYYY/MM/DD")}
                    </td>
                  </tr>

                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Last Billing Cycle
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {moment.unix(end_at).format("YYYY/MM/DD")}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="min-w-full">
                <thead className="bg-white border-b">
                  <tr>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Course Name
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Total Billing
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Remaining Billing
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {orderDetails.course_relation
                        ? orderDetails.course_relation.full_name
                        : ""}
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {orderDetails.course_relation
                        ? orderDetails.course_relation.cost
                        : ""}
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {total_count}
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {remaining_count}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4">
        <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          Subscription Orders
        </h1>
      </div>

      <DataTable
        columns={columns}
        pagination
        data-key={columns}
        data={recurringOrder.length > 0 ? recurringOrder : []}
        paginationTotalRows={1000}
        selectableRows={false}
        selectableRowsHighlight={false}
        onChangeRowsPerPage={handlePerRowsChange}
        highlightOnHover
        onChangePage={handlePageChange}
        // onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
        //     handleRowSelection(allSelected, selectedRows);
        // }}
      />
    </Container>
  );
}
