import Axios from "api/Api";
import { domain, env, moodleUri, s3BaseUrl } from "api/Consts";
import { getAccessTokens } from "api/User";
import { apolloClient } from "App";
import {
  FETCH_COUPON_BY_CODE,
  FETCH_COUPON_BY_USER_QUERY,
  FETCH_COURSES_BY_ID,
  FETCH_COURSES_BY_PARTNER,
  FETCH_COURSES_MOODLE_DATA,
  FETCH_COURSE_ENROLLMENT_COUNT,
  FETCH_COURSE_BY_ID,
  FETCH_COURSE_CATEGORIES,
  FETCH_DEFAULT_CATEGORIES,
  FETCH_DEFAULT_COURSES,
  FETCH_USER_COUNT_BY_COUPON_QUERY,
  GET_BATCH_BY_ID,
  GET_BATCH_SLOTS_BY_COURSE_ID,
  GET_BATCH_SLOTS_BY_USER_ID,
  GET_BATCH_SLOT_BY_ID,
  GET_DEMO_BATCHES_BY_COURSE_ID,
  GET_USER_COURSES,
} from "graphql/queries/Courses";
import PreBuildData from "../data.json";
import _ from "lodash";
import { objectToQueryParams } from "utils/object";
import moment from "moment";
import {
  INSERT_USER_BATCH_SLOT,
  INSERT_USER_COUPON,
  INSERT_USER_ATTENDANCE,
  UPDATE_USER_BATCH_SLOT,
} from "../graphql/mutations/Courses";

import {
  GET_COURSE_LESSON_AND_TOPICS,
  GET_USER_COURSE_PROGRESS,
  GET_COURSE_COMPLETION_DESC,
} from "graphql/queries/CourseBuilder/CourseSection";

import { completeUserAttemptByID } from "graphql/mutations/CourseBuilder/CourseMod";
import {
  getCourseEnrollmentCount,
  getCourseEnrollmentCounts,
} from "hooks/courseEnrollement";
import {
  fetchCoursesById,
  fetchCoursesByPartner,
  fetchDefaultCourses,
  fetchUserCourseProgress,
} from "hooks/course";
import {
  fetchCourseCategories,
  fetchDefaultCourseCategories,
  fetchUserCourses,
} from "hooks/courseCategories";
import { getCourseById } from "../hooks/course";
class CourseService {
  coursesEnrolledStudents = {};

  getCourseMetaData(course) {
    const courseId = course.id;
    const preBuildCourseData = PreBuildData.courses.find(
      (c) => c.id === courseId
    );
    const metadata = {
      students_enrolled: this.coursesEnrolledStudents[courseId] || 0,
    };
    if (preBuildCourseData) {
      metadata.modules = preBuildCourseData.modules;
    }
    return metadata;
  }
  async refreshCoursesEnrolledStudents() {
    this.coursesEnrolledStudents = Object.assign(
      this.coursesEnrolledStudents,
      await getCourseEnrollmentCounts()
    );
  }
  postProcessCourseData(course) {
    // Complete video url
    if (course?.video_url)
      course.video_url = s3BaseUrl + "/" + course.video_url;
    //complete certificate image url
    if (course?.certificate_image_url)
      course.certificate_image_url =
        s3BaseUrl + "/" + course.certificate_image_url;

    // Add course metadata
    Object.assign(course, this.getCourseMetaData(course));

    //for certifications partners in course detail page
    if (course?.partners) {
      course.partners = course.partners.map((p) => {
        return {
          ...p,
          ...p.partner,
        };
      });
    }

    // Get demo class availability
    if (course.demo_batches) {
      const availableSlots = [];
      for (const batch of course.demo_batches) {
        const slots = batch.available_batch_slots;
        const availableBatchSlots = slots.filter(
          (slot) => slot.slot_enrolled_users.length < batch.max_learners
        );
        availableSlots.push(...availableBatchSlots);
      }
      if (availableSlots.length) {
        course.isDemoAvailable = true;
        // Limiting number of batch slots to 5
        course.availableSlots = availableSlots
          .sort(
            (a, b) =>
              new Date(a.slot_date).getTime() - new Date(b.slot_date).getTime()
          )
          .slice(0, 5);
      }
    }
  }
  async getCourseById(courseId) {
    await this.refreshCoursesEnrolledStudents();

    const course = await getCourseById(courseId);

    this.postProcessCourseData(course);

    return course;
  }
  async getCoursesById(courseIds) {
    await this.refreshCoursesEnrolledStudents();
    const courses = await fetchCoursesById(courseIds);

    courses.forEach((course) => this.postProcessCourseData(course));

    return courses;
  }

  async getDefaultCourses() {
    await this.refreshCoursesEnrolledStudents();
    const courses = await fetchDefaultCourses();

    courses.forEach((course) => {
      this.postProcessCourseData(course);
    });

    return courses;
  }

  async getCoursesByPartner(partnerId) {
    await this.refreshCoursesEnrolledStudents();
    const courses = await fetchCoursesByPartner(partnerId);

    courses.forEach((course) => {
      this.postProcessCourseData(course);
    });

    return courses;
  }

  getDefaultCategories() {
    return fetchDefaultCourseCategories();
  }
  getCourseCategories() {
    return fetchCourseCategories();
  }

  getUserCourses(user_id) {
    return fetchUserCourses(user_id);
  }

  openCourse({ courseId }, target) {
    let url = `${domain}/api/moodle?${objectToQueryParams(
      getAccessTokens()
    )}&course_id=${courseId}`;
    if (target === "_blank") window.open(url, target);
    else window.location.href = url;
  }

  // Certificate
  async downloadCertificate({ courseId }) {
    const url = `${domain}/api/moodle_certificate?${objectToQueryParams(
      getAccessTokens()
    )}&course_id=${courseId}`;
    const response = await Axios.get(url)
      .then(({ data }) => data)
      .catch(console.error);

    return response;

    // if (response.status) window.open(response?.download_link, "_blank");
    // else alert("No Certificate found for this course");

    // console.log("response", response);
  }

  // SIC
  getCouponByCode(code) {
    return apolloClient
      .query({
        query: FETCH_COUPON_BY_CODE,
        variables: { code },
      })
      .then(({ data }) => data.courses_st_coupons_configuration[0]);
  }
  getUserCouponCodes(userId) {
    return apolloClient
      .query({
        query: FETCH_COUPON_BY_USER_QUERY,
        variables: {
          userId,
        },
      })
      .then(({ data }) => {
        data = _.cloneDeep(data);
        const coupons = data.courses_st_user_coupons
          .map((uc) => uc.coupon)
          .filter((e) => e); // to eliminate null values
        coupons.forEach((c) => {
          c.courses =
            c.criteria?.courses
              ?.map(({ courses: course }) => course)
              .filter((e) => e) || [];
          delete c.criteria;
        });
        return coupons;
      });
  }
  getCouponUserCount(couponId) {
    return apolloClient
      .query({
        query: FETCH_USER_COUNT_BY_COUPON_QUERY,
        variables: {
          couponId,
        },
      })
      .then(
        ({ data }) => data.courses_st_user_coupons_aggregate.aggregate.count
      );
  }
  async applyCouponCode(coupon_code, userId) {
    return Axios.post("/apply_coupon", { user_id: userId, coupon_code }).then(
      ({ data }) => {
        if (!data.success) {
          if (data.message.type === "invalid_coupons")
            throw { msg: "Invalid coupon code" };
          else if (data.message.type === "coupon_already_applied")
            throw { msg: "Coupon already applied" };
          else
            throw {
              msg:
                typeof data.message === "string"
                  ? data.message
                  : "An error occurred",
            };
        } else {
          return this.getUserCouponCodes(userId);
        }
      }
    );
  }

  async validateDiscountCoupon(couponCode, courseId) {
    return Axios.post("/validate_discount_coupon", {
      coupon_code: couponCode,
      course_id: courseId,
    })
      .then(({ data }) => {
        if (data.success) {
          return data.data;
        } else {
          throw {
            msg:
              typeof data.message === "string"
                ? data.message
                : "An error occurred",
          };
        }
      })
      .catch((err) => console.log(err));
  }

  async freeCourseEnrol(courseId, userId, partnerId) {
    return Axios.post("/free_course_enrol", {
      user_id: userId,
      course_id: courseId,
      partner_id: partnerId,
    })
      .then(({ data }) => {
        if (data.success) {
          return data.enrolment || false;
        } else {
          throw {
            msg:
              typeof data.message === "string"
                ? data.message
                : "An error occurred",
          };
        }
      })
      .catch((err) => console.log(err));
  }

  // Batches
  getBatchById(batchId) {
    return apolloClient
      .query({
        query: GET_BATCH_BY_ID,
        variables: { batch_id: batchId },
      })
      .then(({ data, errors }) => {
        if (errors) throw errors;
        return _.cloneDeep(data.courses_course_batches[0]);
      });
  }
  getBatchesByCourseId(courseId) {
    return apolloClient
      .query({
        query: GET_BATCH_SLOTS_BY_COURSE_ID,
        variables: { courseId },
      })
      .then(({ data, errors }) => {
        if (errors) throw errors;
        return data.courses_course_batches;
      });
  }
  getBatchSlotById(batch_slot_id) {
    return apolloClient
      .query({
        query: GET_BATCH_SLOT_BY_ID,
        variables: { batch_slot_id },
      })
      .then(({ data, errors }) => {
        if (errors) throw errors;
        return _.cloneDeep(data.courses_batch_slots[0]);
      });
  }

  getDemoBatchesByCourseId(courseId) {
    return apolloClient
      .query({
        query: GET_DEMO_BATCHES_BY_COURSE_ID,
        variables: {
          courseId,
        },
      })
      .then(({ data, errors }) => {
        if (errors) throw errors;
        return data.courses_course_batches;
      });
  }

  getDemoBatchSlotsByUserId(userId) {
    return apolloClient
      .query({
        query: GET_BATCH_SLOTS_BY_USER_ID,
        variables: { userId },
      })
      .then(({ data, errors }) => {
        if (errors) throw errors;
        return data.courses_user_batch_slots.map((relation) => {
          return relation.batch_slot;
        });
      });
  }

  bookDemo(userId, batchSlotId) {
    return apolloClient
      .mutate({
        mutation: INSERT_USER_BATCH_SLOT,
        variables: {
          objects: { user_id: userId, batch_slot_id: batchSlotId },
        },
      })
      .then(({ data, errors }) => {
        if (errors) throw errors;
        return data.insert_courses_user_batch_slots.returning;
      });
  }

  updateDemo(userId, batchSlotId, currentDemoBatchSlotId) {
    return apolloClient
      .mutate({
        mutation: UPDATE_USER_BATCH_SLOT,
        variables: {
          user_id: userId,
          current_batch_slot_id: currentDemoBatchSlotId,
          new_batch_slot_id: batchSlotId,
        },
      })
      .then(({ data, errors }) => {
        if (errors) throw errors;
        return data.update_courses_user_batch_slots.returning;
      });
  }

  markBatchSlotAttendance(user_id, slot_id, batch_id, attendance_status) {
    return apolloClient
      .mutate({
        mutation: INSERT_USER_ATTENDANCE,
        variables: {
          user_id,
          slot_id,
          batch_id,
          attendance_status,
        },
      })
      .then(({ data, errors }) => {
        if (errors) throw errors;
        data.insert_courses_batch_trainee_attendences.returning[0];
      });
  }

  async getCourseLessonTopics(course_id, user_id) {
    return apolloClient
      .query({
        query: GET_COURSE_LESSON_AND_TOPICS,
        variables: {
          course_id: course_id,
          user_id: user_id,
        },
      })
      .then(({ data }) => data);
  }

  async getUserCourseProgress(course_id, user_id) {
    return await fetchUserCourseProgress(course_id, user_id);
  }

  async completeUserAttempt(attempt_id) {
    return apolloClient
      .mutate({
        mutation: completeUserAttemptByID,
        variables: {
          id: attempt_id,
          attempt_completed: true,
          attempt_completed: true,
          attempt_end: new Date(),
        },
      })
      .then(({ data, errors }) => {
        if (errors) throw errors;
        data.update_courses_course_module_user_attempt;
      });
  }

  async getCourseCompletionDesc(course_id) {
    const result = await apolloClient
      .query({
        query: GET_COURSE_COMPLETION_DESC,
        variables: {
          id: course_id,
        },
      })
      .then((res) => res?.data?.courses_course[0]);

    return result;
  }
}

const courseSvc = new CourseService();
export default courseSvc;
