/* eslint-disable no-unused-vars */
export default function OnboardingInput({ data, onChange, value }) {
  const { id, type, displayName, optional, options, placeHolder } = data;
  return (
    <>
      <label htmlFor={id} className="block text-lg font-semibold text-gray-800">
        {displayName}
      </label>
      <input
        type={type}
        id={id}
        placeholder={placeHolder}
        onChange={onChange}
        className={`block w-full sm:text-sm rounded-md border-none outline-none text-gray-800 bg-japanese_indigo-light mt-4 p-3`}
        value={value}
      />
    </>
  );
}
