/* eslint-disable no-unused-vars */

export default function OnboardingSelect({ data, onChange, value }) {
  const { id, type, displayName, optional, options, placeHolder } = data;
  const defaultOption = options.find((o) => o.default);

  return (
    <>
      <label htmlFor={id} className="block text-lg font-semibold text-gray-800">
        {displayName}
      </label>
      <select
        id={id}
        className="mt-4 block w-full sm:text-sm rounded-md bg-japanese_indigo-light border-none outline-none text-gray-800 p-4"
        onChange={onChange}
        value={value || (defaultOption && defaultOption.value)}
      >
        {!defaultOption && <option>{placeHolder}</option>}
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};
