import { useParams } from "react-router";
import razorpayService from "services/razorpay";
import React, { useState, useEffect } from "react";
import Container from "./Container";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function CourseSubscriptionOrder() {
  const { id } = useParams();
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const [orderDetails, setOrderDetails] = useState({});

  useEffect(async () => {
    const order = await razorpayService.GetSubscriptionOrder(id, userId);
    setOrderDetails(order);
  }, []);

  return (
    <Container>
      {orderDetails && (
        <div className="row">
          <div className="jumbotron">
            <div class="p-4">
              <h1 class="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
                Thanku For Your Subscription Payment!
              </h1>
            </div>

            {orderDetails.status == "active" ? (
              <h1 class="font-semibold  text-center my-2 px-4 text-lg">
                Your Subscription Staus Is Active Now
              </h1>
            ) : (
              <center>
                <h1 class="font-semibold  text-center my-2 px-4 text-lg">
                  Your Subscription Staus Is {orderDetails.status}
                </h1>

                <h1 class="font-semibold  text-center my-2 px-4 text-lg">
                  Your Subscription Will Be Active Shortly. You Will Be Notify
                  By Email Or Phone
                </h1>
              </center>
            )}
            <h1 class="font-semibold  text-center my-2 px-4 text-lg">
              Your Subscription id is: #{orderDetails.subscription_id}{" "}
            </h1>
          </div>
        </div>
      )}
    </Container>
  );
}
