import { apolloClient } from "../App";
import _ from "lodash";
import { CREATE_RAZORPAY_ORDER_ID } from "graphql/mutations/razorpay";
import { UPDATE_RAZORPAY_ORDER_DETAILS } from "graphql/mutations/razorpay";
import { USER_COURSE_ENROLL } from "graphql/mutations/razorpay";
import { GET_ORDER_DETAILS } from "graphql/queries/RazorpayOrder";
import { INSERT_USER_COURSE_SUBSCRIPTION } from "graphql/mutations/razorpay";
import { UPDATE_USER_COURSE_SUBSCRIPTION } from "graphql/mutations/razorpay";
import { GET_SUBSCRIPTION_ORDER } from "graphql/queries/RazorpayOrder";
import { GET_USER_SUBSCRIPTION_ORDER } from "graphql/queries/RazorpayOrder";
import { USER_SUBSCRIPTION_COURSE_ENROLLMENT } from "graphql/mutations/razorpay";
import { CREATE_SUBSCRIPTION_RAZORPAY_ORDER_ID } from "graphql/mutations/razorpay";
import { GET_USER_ALL_ORDERS } from "graphql/queries/RazorpayOrder";
import { GET_SUBSCRIPTION_RECURRING_ORDERS } from "graphql/mutations/razorpay";

class razorpay {
  async CreateRazorpayOrderId(data) {
    const {
      amount,
      currency,
      customer_id,
      contact,
      email,
      order_id,
      status,
      notes,
      created_at,
      id,
      razorpaySignature,
      enrollment_id,
    } = data;

    return await apolloClient
      .mutate({
        mutation: CREATE_RAZORPAY_ORDER_ID,
        variables: {
          amount: amount / 100,
          currency: currency,
          customer_id: customer_id,
          order_contact: contact,
          order_email: email,
          rajorpay_order_id: id,

          subscription_order: false,
          order_status: status,
          order_create_at: created_at,
          user_id: notes.user_id,
          course_id: notes.course_id,
          coupon_id: notes.coupon_id,
        },
      })
      .then(({ data }) => data.insert_courses_user_course_order.returning[0]);
  }

  async CreateSubscriptionRazorpayOrderId(data) {
    const {
      amount,
      currency,
      customer_id,
      contact,
      email,
      id,
      status,
      notes,
      created_at,
      subscription_id,
      payment_id,
      razorpaySignature,
      enrollment_id,
    } = data;

    return await apolloClient
      .mutate({
        mutation: CREATE_SUBSCRIPTION_RAZORPAY_ORDER_ID,
        variables: {
          amount: amount / 100,
          currency: currency,
          customer_id: customer_id,
          order_contact: contact,
          order_email: email,
          rajorpay_order_id: id,
          rajorpay_payment_id: payment_id,
          subscription_order: true,
          order_status: status,
          order_create_at: created_at,
          user_id: notes.user_id,
          course_id: notes.course_id,
          coupon_id: notes.coupon_id,
          subscription_id: subscription_id,
          rajorpay_signature: razorpaySignature,
          enrollment_id: enrollment_id,
        },
      })
      .then(({ data }) => data.insert_courses_user_course_order.returning);
  }

  async UpdateRazorpayOrderDetails(data, enrollment_id) {
    return await apolloClient
      .mutate({
        mutation: UPDATE_RAZORPAY_ORDER_DETAILS,
        variables: {
          rajorpay_order_id: data.orderCreationId,
          rajorpay_signature: data.razorpaySignature,
          enrollment_id: enrollment_id,
        },
      })
      .then(({ data }) => data.update_courses_user_course_order.returning[0]);
  }

  async UserCourseEnroll(data) {
    const { notes, id } = data;
    return await apolloClient
      .mutate({
        mutation: USER_COURSE_ENROLL,
        variables: {
          course_id: notes.course_id,
          enroll_status: false,
          order_id: id,
          user_id: notes.user_id,
        },
      })
      .then(
        ({ data }) => data.insert_courses_user_course_enrolment.returning[0]
      );
  }

  async GetOrderDetails(id) {
    const result = await apolloClient
      .query({
        query: GET_ORDER_DETAILS,
        variables: {
          id: id,
        },
      })
      .then(({ data }) => data.courses_user_course_order[0]);

    return result;
  }

  async InsertUserCourseSubscription(obj) {
    console.log("subs", obj);

    return await apolloClient
      .mutate({
        mutation: INSERT_USER_COURSE_SUBSCRIPTION,
        variables: obj,
      })
      .then(
        ({ data }) => data.insert_courses_user_course_subscription?.returning[0]
      );
  }

  async UpdateUserCourseSubscription(obj) {
    console.log("subs", obj);

    return await apolloClient
      .mutate({
        mutation: UPDATE_USER_COURSE_SUBSCRIPTION,
        variables: obj,
      })
      .then(
        ({ data }) => data.update_courses_user_course_subscription?.returning[0]
      );
  }

  async GetSubscriptionOrder(id, user_id) {
    const result = await apolloClient
      .query({
        query: GET_SUBSCRIPTION_ORDER,
        variables: {
          id: id,
          user_id: user_id,
        },
      })
      .then(({ data }) => data.courses_user_course_subscription[0]);

    return result;
  }

  async GetUserSubscriptionOrder(user_id) {
    const result = await apolloClient
      .query({
        query: GET_USER_SUBSCRIPTION_ORDER,
        variables: {
          user_id: user_id,
        },
      })
      .then(({ data }) => data.courses_user_course_subscription);

    return result;
  }

  async GetUserAllOrder(user_id) {
    const result = await apolloClient
      .query({
        query: GET_USER_ALL_ORDERS,
        variables: {
          user_id: user_id,
        },
      })
      .then(({ data }) => data.courses_user_course_order);

    return result;
  }

  async UserSubscriptionCourseEnroll(data, subscription_id) {
    return await apolloClient
      .mutate({
        mutation: USER_SUBSCRIPTION_COURSE_ENROLLMENT,
        variables: {
          course_id: data.course_id,
          enroll_status: false,
          subscription_id: subscription_id,
          user_id: data.user.id,
        },
      })
      .then(
        ({ data }) => data.insert_courses_user_course_enrolment.returning[0]
      );
  }

  async GetSubscriptionRecurringOrder(subscription_id) {
    const result = await apolloClient
      .query({
        query: GET_SUBSCRIPTION_RECURRING_ORDERS,
        variables: {
          subscription_id: subscription_id,
        },
      })
      .then(({ data }) => data.courses_user_course_order);

    return result;
  }
}

const razorpayService = new razorpay();

export default razorpayService;
