export const GET_USER_ORDERS = `
  subscription GetAllUserOrders($user_id: Int) {
    courses_user_course_order(where: {user_id: {_eq: $user_id}, rajorpay_order_id: {_is_null: false}}, order_by: {id: desc}) {
      id
      subscription_order
      order_status
      order_create_at
      ordercourse {
        full_name
        id
      }
      amount
      coupon_id
      course_id
      currency
      customer_id
      enrollment_id
      order_contact
      order_email
      subscription_id
      rajorpay_signature
      rajorpay_payment_id
      rajorpay_order_id
    }
  }
`;

export const GET_USER_SUBSCRIPTIONS = `
  subscription GetSubscriptionOrder($user_id: Int) {
    courses_user_course_subscription(where: {user_id: {_eq: $user_id}}) {
      id
      payment_method
      paid_count
      current_start
      current_end
      charge_at
      end_at
      course_id
      user_id
      subscription_id
      subscription_created_at
      status
      start_at
      source
      remaining_count
      razorpay_signature
      razorpay_payment_id
      rajorpay_customer_id
      plan_id
      total_count
      course_relation {
        cost
        full_name
      }
    }
  }
`;
