import React, { useState, useContext } from "react";
import { FiltersContext } from "../alljobs";
import { MULTI_SELECT_JOBS_FILTERS } from "api/Consts";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

const Slider = () => {
  const { filters, setFilters, setPageNo } = useContext(FiltersContext);
  const [salary, setSalary] = useState({ min: 0, max: 25000 });

  const handleChange = (value) => {
    setFilters({
      ...filters,
      [MULTI_SELECT_JOBS_FILTERS.SALARY]: [value.min, value.max],
    });
    setPageNo(0);
  };

  return (
    <div className="ml-1 justify-between flex flex-row items-center p-2 rounded-md shadow-md inline-flex w-80">
      <div className="mr-4 text-black-500 text-sm font-medium mx-2 m-0 font-poppins w-72">
        Salary per month
      </div>
      <InputRange
        maxValue={100000}
        minValue={0}
        formatLabel={(value) => `₹${value}`}
        value={salary}
        onChange={(value) => setSalary(value)}
        onChangeComplete={handleChange}
      />
    </div>
  );
};

export default Slider;
