import { useQuery } from "@apollo/client";
import { ScholarshipCard, ScholarshipFilter } from ".";
import { Link } from "react-router-dom";
import { GET_SCHOLARSHIPS } from "graphql/queries/Scholarships";

export default function AllScholarships() {
  const data = useQuery(GET_SCHOLARSHIPS);

  return (
    <>
      <div>
        <div className="p-4">
          <div className="flex gap-5 items-center">
            <h1 className="font-bold my-4 text-2xl lg:text-4xl text-japanese_indigo">
              All Scholarships
            </h1>
            <Link to="/my-scholarships">
              <button className="btn-primary">Go to My Scholarships</button>
            </Link>
          </div>

          <div className="w-full bg-orange-light rounded-md p-2 my-2">
            Before applying, fill all your details in{" "}
            <span className="text-blue-500 font-semibold">
              {" "}
              <Link to="/settings/account-details">My Profile</Link>{" "}
            </span>
          </div>

          <div class="flex h-screen">
            <div class="flex-1 flex flex-col overflow-hidden">
              <header class="flex justify-between items-center my-2">
                <p className="font-semibold text-sm">
                  Showing All Scholarships
                </p>
                {/* <button onClick={() => setScholarshipDetailsOpen(true)}>
                  Show Scholarship Details
                </button> */}
              </header>
              <div class="flex h-full w-full">
                <main class="flex flex-col w-3/4 bg-white overflow-x-hidden overflow-y-auto mb-14">
                  <div class="flex w-full mx-auto px-6 py-8">
                    <div class="flex flex-col w-full h-full">
                      {data?.data?.courses_scholarships
                        ?.filter((item) => {
                          return !["test", "dummy"].find((e) => {
                            return item.title.toLowerCase().includes(e);
                          });
                        })
                        .map((item) => {
                          return (
                            <ScholarshipCard
                              key={item.id}
                              title={item.title}
                              description={item.description}
                              amount={item.amount}
                              social_categories={item.social_categories}
                              gender={item.gender}
                              min_age={item.min_age}
                              max_age={item.max_age}
                              logo={item.partner.logo}
                              name={item.partner.name}
                              created_at={item.created_at}
                              details={item}
                            />
                          );
                        })}
                    </div>
                  </div>
                </main>
                <ScholarshipFilter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
