import { RAZORPAY_KEY_ID } from "api/Consts";

import { create_razorpay_subscription } from "api/RazorpayBackendApi";
import courseSvc from "./Course";

class razorpaySubscriptionApi {
  async SubscriptionProcess({ courseId, courseData, user, partnerId }) {
    if (!courseData) courseData = await courseSvc.getCourseById(courseId);

    // Reformatting data
    courseData.plan_id =
      courseData.course_subscription_relation_array[0]?.razorpay_plan_id;
    courseData.subscription_cost =
      courseData.course_subscription_relation_array[0]?.subscription_cost;
    courseData.interval =
      courseData.course_subscription_relation_array[0]?.interval;

    const taxPercent =
      courseData && courseData.is_taxable
        ? courseData.tax_relation.course_tax_relation_object.tax_percentage
        : "";

    const taxTotal = (courseData?.subscription_cost * taxPercent) / 100;

    const taxitem = {
      name: courseData.is_taxable
        ? courseData.tax_relation.course_tax_relation_object.tax_name
        : "",
      amount: Math.round(taxTotal),
      currency: "INR",
    };

    const params = {
      currency: "INR",
      taxitem,
      user,
      course_id: courseData.id,
      is_subscription: true,
      partner_id: partnerId,
      coupon_id: courseData.coupon_id || undefined,
    };

    const subscription = await create_razorpay_subscription(params).catch(() =>
      error()
    );

    const options = {
      key: RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard

      name: "SkillsTrainer",

      subscription_id: subscription.data.id,
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.mobile_number,
      },

      theme: {
        color: "#3399cc",
      },

      handler: async function (response) {},
    };

    const rzp1 = new window.Razorpay(options);

    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
    });
  }
}

const razorpaySubscriptionClass = new razorpaySubscriptionApi();

export default razorpaySubscriptionClass;
