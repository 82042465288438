import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useSubscription } from "utils/hooks";
import { GET_USER_SUBSCRIPTIONS } from "graphql/subscriptions/Payment";

export default function SubscriptionOrders() {
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const { data: subscriptionsRes } = useSubscription(GET_USER_SUBSCRIPTIONS, {
    variables: { user_id: userId },
  });
  const subscriptions =
    subscriptionsRes?.courses_user_course_subscription || [];

  const columns = [
    {
      name: "Subscription",
      selector: (row, index) => `#${row.id}`,
    },

    {
      name: "Status",
      selector: (row, index) => `${row.status}`,
    },
    {
      name: "Next Payment",
      selector: (row, index) =>
        row.charge_at
          ? `${moment.unix(row.charge_at).format("YYYY/MM/DD")}`
          : "----",
    },

    {
      cell: (row) => (
        <Link to={`/subscription/view/${row.id}`}>
          <button className="text-white rounded-md text-md px-7 py-1 bg-orange font-semibold">
            View
          </button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });
  return (
    <div>
      <div className="p-4">
        <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          All Subscriptions
        </h1>
      </div>
      <DataTable
        columns={columns}
        pagination
        data-key={columns}
        data={subscriptions}
        paginationTotalRows={1000}
        selectableRows={false}
        selectableRowsHighlight={false}
        onChangeRowsPerPage={handlePerRowsChange}
        highlightOnHover
        onChangePage={handlePageChange}
        // onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
        //     handleRowSelection(allSelected, selectedRows);
        // }}
      />
    </div>
  );
}
