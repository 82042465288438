import React from 'react';
import ReactGA from 'react-ga';
const analyticsEvents = (category = 'Blog category') => {
	const eventTracker = (action = 'contact Us', label = 'Contact') => {
		ReactGA.event({ category, action, label });
	};
	return eventTracker;
};
export default analyticsEvents;
/// component for tracking Event/button click
