import { useDispatch, useSelector } from "react-redux";
import { CourseCard as STRLCourseCard } from "skillstrainer-resource-library";
import { courseFormatter } from "skillstrainer-resource-library/utils/course";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { SET_COURSE_TO_DEMO } from "redux/actions/Course";
import { moodleCourseUrl, s3BaseUrl } from "api/Consts";
import { ACTION_TYPES } from "route-actions";

export default function CourseCard(props) {
  const { openCourse, data } = props;
  const [course, setCourse] = useState();

  const activeCourses = useSelector(({ course }) => course.active_courses);
  const isActive = activeCourses[data.id];

  const history = useHistory();
  const dispatch = useDispatch();

  // Bootstrap
  useEffect(() => {
    const course = courseFormatter(data);
    course.partners.map((element) => {
      element.logo = s3BaseUrl + "/" + element.partner.logo;
    });

    //adding 10k users in ui to english star
    if (course.courseId === 31)
      course.students_enrolled = String(
        Number(course.students_enrolled) + 10000
      );

    // //Define course img if not mdl course
    // course["courseImg"] = course.isMoodleCourse
    //   ? course.courseImg
    //   : s3BaseUrl + "/" + course.courseImg;

    setCourse(course);
  }, [data]);

  const { isDemoAvailable, userHasRegisteredDemo, registeredSlotId } =
    data || {};

  console.log("process.env.REACT_APP_BUILD_ENV", moodleCourseUrl);

  return (
    <STRLCourseCard
      data={{
        ...(course || {}),
        userHasRegisteredDemo,
        onViewDemoDetails: () => {
          history.push({
            search: `?action=${ACTION_TYPES.show_demo_details}&slotId=${registeredSlotId}`,
          });
        },
        isDemoAvailable,
        onBookDemo: () => {
          history.push({
            search: `?action=${ACTION_TYPES.book_demo_course}&courseId=${course.courseId}`,
          });
        },
      }}
      isPurchased={isActive}
      viewCourse={(e) => {
        if (data.is_moodle_course) {
          history.push(
            `${moodleCourseUrl}/course/view.php?id=${data.moodle_course_id}`
          );
        } else {
          history.push(`/course/view/${data.id}`);
        }
      }}
      payNow={() => history.push(`/courses/${data.id}`)}
      goToDetailPage={() => history.push(`/courses/${data.id}`)}
    />
  );
}
