/* eslint-disable no-unused-vars */
import moment from "moment";
import Modal from "components/Modal";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { MdCategory, MdVerified } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
import { BsGenderAmbiguous } from "react-icons/bs";
import { IoPeopleSharp } from "react-icons/io5";
import { IoMdClock } from "react-icons/io";
import { GiBackwardTime } from "react-icons/gi";
import { HiBriefcase } from "react-icons/hi2";
import Toast from "../../../utils/Toast";
// import { GET_JOB_APPLICATION } from "graphql/queries/Jobs";
// import { INSERT_JOB_APPLICATION } from "graphql/mutations/Jobs";
import userSvc from "services/User";
import { ACEDEMIC_QUALIFICATION_DETAILS, STUDENT_QUAL_TYPE } from "api/Consts";
import JobChecksModal from "./jobchecksmodal";
import { useMemo, useState, useEffect } from "react";
import { createStepsAndPages } from "pages/components/Settings/AccountDetails/pages";
import { JOB_CHECKS_PAGES, s3BaseUrl } from "api/Consts";
import { addJobApplication } from "api/Jobs";

export default function JobDetailsModal(props) {
  // const tableName = "courses_job_applications";
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const [checksPassed, setchecksPassed] = useState({
    language: false,
    skill: false,
    qualification: false,
  });
  const [pageIndexUpdateModal, setPageIndexUpdateModal] = useState(-1);
  const [pages, setPages] = useState();
  const deps = { user: auth?.user?.db_user };

  useEffect(() => {
    if (Object.values(deps).reduce((acc, cur) => acc && cur)) {
      createStepsAndPages(deps).then(({ _, pages }) => {
        setPages(pages);
      });
    }
  }, Object.values(deps));

  const whereClause = useMemo(
    () => ({
      _and: {
        job_id: { _eq: props.details.id },
        user_id: { _eq: auth.user.db_user.id },
      },
    }),
    [props?.details, auth]
  );

  // const previousApplication = useQuery(GET_JOB_APPLICATION, {
  //   variables: { whereClause },
  // });

  // const [addJobApplication] = useMutation(
  //   INSERT_JOB_APPLICATION
  // );

  const languageCheck = () => {
    let languageCheck = true;
    userSvc.fetchUserLanguages(userId).then((languages) => {
      let userLangs = {};
      for (let lang of languages) {
        userLangs[lang.language_id] = {
          can_speak: lang.can_speak,
          can_read: lang.can_read,
          can_write: lang.can_write,
        };
      }

      for (let lang of props.details.job_languages_required) {
        if (
          !(
            userLangs[lang.language_id] &&
            (userLangs[lang.language_id].can_read ||
              userLangs[lang.language_id].can_read == lang.can_read) &&
            (userLangs[lang.language_id].can_speak ||
              userLangs[lang.language_id].can_speak == lang.can_speak) &&
            (userLangs[lang.language_id].can_write ||
              userLangs[lang.language_id].can_write == lang.can_write)
          )
        ) {
          languageCheck = false;
          break;
        }
      }
      setchecksPassed((prev) => ({ ...prev, language: languageCheck }));
    });
  };

  const skillCheck = () => {
    let skillCheck = true;
    userSvc.fetchUserSkills(userId).then((skills) => {
      let userSkillIds = Object.values(skills).map(
        (skill) => skill.st_skill_id
      );
      for (let skill of props.details.job_required_skills_criteria) {
        if (!userSkillIds.includes(skill.st_skill_id)) {
          skillCheck = false;
          break;
        }
      }
      setchecksPassed((prev) => ({ ...prev, skill: skillCheck }));
    });
  };

  const qualificationCheck = () => {
    let qualificationCheck = true;
    userSvc.fetchUserQualifications(userId).then((quals) => {
      let userQualificationIds = Object.values(quals)
        .map((qual) => qual.qualification_id)
        .filter((qual) => qual);
      let userQualificationType = Object.values(quals)
        .map((qual) => qual.qualifications_lookup?.type)
        .filter((qual) => qual);

      for (let qual of props.details.job_required_qualifications) {
        if (!qual?.required) {
          continue;
        }
        if (
          qual?.qualification?.id ==
          ACEDEMIC_QUALIFICATION_DETAILS[STUDENT_QUAL_TYPE.TWELFTH]
            .qualification_id
        )
          if (userQualificationType.includes("12")) continue;
        if (
          qual?.qualification?.id ==
          ACEDEMIC_QUALIFICATION_DETAILS[STUDENT_QUAL_TYPE.BACHELOR]
            .qualification_id
        )
          if (
            userQualificationType.includes(
              ACEDEMIC_QUALIFICATION_DETAILS[STUDENT_QUAL_TYPE.BACHELOR].key
            )
          )
            continue;
        if (
          qual?.qualification?.id ==
          ACEDEMIC_QUALIFICATION_DETAILS[STUDENT_QUAL_TYPE.MASTER]
            .qualification_id
        )
          if (
            userQualificationType.includes(
              ACEDEMIC_QUALIFICATION_DETAILS[STUDENT_QUAL_TYPE.MASTER].key
            )
          )
            continue;
        if (
          qual?.qualification?.id ==
          ACEDEMIC_QUALIFICATION_DETAILS[STUDENT_QUAL_TYPE.PHD].qualification_id
        )
          if (
            userQualificationType.includes(
              ACEDEMIC_QUALIFICATION_DETAILS[STUDENT_QUAL_TYPE.PHD].key
            )
          )
            continue;

        if (!userQualificationIds.includes(qual?.qualification?.id)) {
          qualificationCheck = false;
          break;
        }
      }
      setchecksPassed((prev) => ({
        ...prev,
        qualification: qualificationCheck,
      }));
    });
  };

  useEffect(() => {
    languageCheck();
    skillCheck();
    qualificationCheck();
  }, [pageIndexUpdateModal]);

  const canApply = useMemo(() => {
    for (let check in checksPassed) {
      if (!checksPassed[check]) return false;
    }
    return true;
  }, [checksPassed]);

  const submitJobApplication = async () => {
    const params = {
      user_id: auth.user.db_user.id,
      job_id: props.details.id,
    };
    const jobApplication = await addJobApplication(params);
    if (jobApplication.data.success) {
      Toast.success("Applied to Job successfully!");
      props.jobApplicationsQuery.refetch();
      props.setJobDetailsOpen(false);
    } else {
      Toast.error(jobApplication.data.error);
    }
  };

  return (
    <>
      <Modal
        isOpen={props.jobDetailsOpen}
        onClose={() => props.setJobDetailsOpen(false)}
      >
        <div style={{ height: "40rem" }} className="flex font-poppins">
          <div className="flex-1 flex flex-col">
            <div className="flex h-full w-full">
              <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-scroll mb-8">
                <div className="flex w-full mx-auto px-6">
                  <div className="flex flex-col w-full h-full">
                    <div className="flex flex-row items-center">
                      <img
                        src={
                          props.details.partner.logo
                            ? s3BaseUrl + "/" + props.details.partner.logo
                            : "http://placehold.jp/150x150.png"
                        }
                        alt="work-partner-img"
                        className="w-16 h-16 rounded-md border-2"
                      />
                      <div className="flex flex-row w-full justify-between">
                        <div className="flex flex-col">
                          <h1 className="text-2xl font-semibold text-japanese_indigo mx-2">
                            {props.details.title}
                          </h1>
                          <h1 className="text-sm font-semibold text-gray-500 mx-2">
                            by {props.details.partner.organization_name}
                          </h1>
                        </div>

                        <div className="flex flex-row item-center justify-center">
                          <GiBackwardTime
                            size={20}
                            className="text-orange my-auto mt-0"
                          />
                          <p className="text-xs text-orange my-auto mt-0.5">
                            Posted{" "}
                            {moment(
                              props.details.created_at,
                              "YYYYMMDD"
                            ).fromNow()}
                          </p>
                        </div>
                      </div>
                    </div>

                    {props.jobApplication && (
                      <>
                        <div
                          className="flex flex-row my-4"
                          style={{ color: "var(--success-green)" }}
                        >
                          <MdVerified size={20} />
                          <p className="font-semibold text-sm mx-2">Applied</p>
                        </div>
                        <p className="font-semibold text-sm text-japanese_indigo">
                          Application Status:
                          <span
                            className="mx-1"
                            style={{
                              color:
                                props.jobApplication.status == "rejected"
                                  ? "var(--red)"
                                  : "var(--success-green)",
                            }}
                          >
                            {props.jobApplication.status}
                          </span>
                        </p>
                        {props.jobApplication.status == "active" && (
                          <p className="font-semibold text-sm text-japanese_indigo">
                            Stage:{" "}
                            <span style={{ color: "var(--orange)" }}>
                              {props.jobApplication.stage}
                            </span>
                          </p>
                        )}
                      </>
                    )}

                    <div className="flex flex-col justify-evenly my-4">
                      {props.social_categories && (
                        <div className="flex flex-row my-2">
                          <MdCategory
                            className="text-japanese_indigo"
                            size={20}
                          />
                          <p className="font-semibold text-sm text-japanese_indigo mx-2">
                            {props.social_categories}
                          </p>
                        </div>
                      )}
                      <div className="flex flex-row my-2">
                        <FaMoneyBillAlt
                          className="text-japanese_indigo"
                          size={20}
                        />
                        <p className="font-semibold text-sm text-japanese_indigo mx-2">
                          ₹{props.details.salary_ctc}
                        </p>
                      </div>
                      {props.genders && (
                        <div className="flex flex-row my-2">
                          <BsGenderAmbiguous
                            className="text-japanese_indigo"
                            size={20}
                          />
                          <p className="font-semibold text-sm text-japanese_indigo mx-2">
                            {props.genders}
                          </p>
                        </div>
                      )}
                      <div className="flex flex-row my-2">
                        <IoPeopleSharp
                          className="text-japanese_indigo"
                          size={20}
                        />
                        <p className="font-semibold text-sm text-japanese_indigo mx-2">
                          Age{" "}
                          {props.details.min_age
                            ? props.details.min_age
                            : "NIL"}{" "}
                          -{" "}
                          {props.details.max_age
                            ? props.details.max_age
                            : "NIL"}{" "}
                          Years
                        </p>
                      </div>
                      <div className="flex flex-row my-2">
                        <IoMdClock className="text-japanese_indigo" size={20} />
                        <p className="font-semibold text-sm text-japanese_indigo mx-2">
                          Shift{" "}
                          {props.details.shift_start_timing
                            ? props.details.shift_start_timing
                            : "NIL"}{" "}
                          -{" "}
                          {props.details.shift_end_timing
                            ? props.details.shift_end_timing
                            : "NIL"}{" "}
                        </p>
                      </div>
                      <div className="flex flex-row my-2">
                        <HiBriefcase
                          className="text-japanese_indigo"
                          size={20}
                        />
                        <p className="font-semibold text-sm text-japanese_indigo mx-2">
                          Experience {props.details.min_experience} Years
                        </p>
                      </div>
                    </div>

                    <h2 className="text-black text-lg font-semibold my-2">
                      Job Locations
                    </h2>
                    {props.details.job_location.length
                      ? props.details.job_location.map((value) => {
                          return (
                            <li>
                              {value.city ? value.city + ", " : ""}
                              {value.country} (Positons:{" "}
                              {value.num_of_positions
                                ? value.num_of_positions
                                : "Null"}
                              )
                            </li>
                          );
                        })
                      : props.is_remote
                      ? "Remote"
                      : "Not Defined"}
                    {props.details.job_location.length > 0 &&
                      props.is_remote && <li>Remote</li>}
                    <div className="flex my-2">
                      <h2 className="text-black text-lg font-semibold">
                        Languages Required
                      </h2>
                      {!checksPassed.language ? (
                        <>
                          <span className="flex flex-row item-center justify-center border-orange rounded-2xl mx-3 border-2 py-1 pl-2 pr-2">
                            <p className="text-xs text-orange">CHECK FAILED</p>
                          </span>
                          <span className="justify-end">
                            <button
                              className="mx-3	px-4 bg-orange text-white font-normal p-1 rounded-md text-sm"
                              onClick={() =>
                                setPageIndexUpdateModal(
                                  JOB_CHECKS_PAGES.LANGUAGES
                                )
                              }
                            >
                              Update
                            </button>
                          </span>
                        </>
                      ) : (
                        <span
                          className="flex flex-row item-center justify-center rounded-2xl mx-3 border-2 py-1 pl-2 pr-2"
                          style={{ borderColor: "var(--success-green)" }}
                        >
                          <p
                            className="text-xs"
                            style={{ color: "var(--success-green)" }}
                          >
                            CHECK PASSED
                          </p>
                        </span>
                      )}
                    </div>
                    {props.details.job_languages_required.length
                      ? props.details.job_languages_required.map((lang) => {
                          return (
                            <li>
                              {lang.language.name}(
                              {"speak: " +
                                lang.can_speak +
                                ", read: " +
                                lang.can_read +
                                ", write: " +
                                lang.can_write}
                              )
                            </li>
                          );
                        })
                      : "None Required"}

                    <div className="flex my-2">
                      <h2 className="text-black text-lg font-semibold">
                        Skills Required
                      </h2>
                      {!checksPassed.skill ? (
                        <>
                          <span className="flex flex-row item-center justify-center border-orange rounded-2xl mx-3 border-2 py-1 pl-2 pr-2">
                            <p className="text-xs text-orange">CHECK FAILED</p>
                          </span>
                          <span className="justify-end">
                            <button
                              className="mx-3	px-4 bg-orange text-white font-normal p-1 rounded-md text-sm"
                              onClick={() =>
                                setPageIndexUpdateModal(JOB_CHECKS_PAGES.SKILLS)
                              }
                            >
                              Update
                            </button>
                          </span>
                        </>
                      ) : (
                        <span
                          className="flex flex-row item-center justify-center rounded-2xl mx-3 border-2 py-1 pl-2 pr-2"
                          style={{ borderColor: "var(--success-green)" }}
                        >
                          <p
                            className="text-xs"
                            style={{ color: "var(--success-green)" }}
                          >
                            CHECK PASSED
                          </p>
                        </span>
                      )}
                    </div>
                    {props.details.job_required_skills_criteria.length
                      ? props.details.job_required_skills_criteria.map(
                          ({ skillstrainer_skill_id: { name } }) => {
                            return <li>{name}</li>;
                          }
                        )
                      : "None Required"}

                    <div className="flex my-2">
                      <h2 className="text-black text-lg font-semibold">
                        Qualifications Required
                      </h2>
                      {!checksPassed.qualification ? (
                        <>
                          <span className="flex flex-row item-center justify-center border-orange rounded-2xl mx-3 border-2 py-1 pl-2 pr-2">
                            <p className="text-xs text-orange">CHECK FAILED</p>
                          </span>
                          <span className="justify-end">
                            <button
                              className="mx-3	px-4 bg-orange text-white font-normal p-1 rounded-md text-sm"
                              onClick={() =>
                                setPageIndexUpdateModal(
                                  JOB_CHECKS_PAGES.QUALIFICATIONS
                                )
                              }
                            >
                              Update
                            </button>
                          </span>
                        </>
                      ) : (
                        <span
                          className="flex flex-row item-center justify-center rounded-2xl mx-3 border-2 py-1 pl-2 pr-2"
                          style={{ borderColor: "var(--success-green)" }}
                        >
                          <p
                            className="text-xs"
                            style={{ color: "var(--success-green)" }}
                          >
                            CHECK PASSED
                          </p>
                        </span>
                      )}
                    </div>
                    {props.details.job_required_qualifications.length
                      ? props.details.job_required_qualifications.map(
                          ({ qualification: { name, qp_code }, required }) => {
                            return (
                              <li>
                                {name}
                                {qp_code ? " (" + qp_code + ")" : ""}
                                {!required && " (Preferred)"}
                              </li>
                            );
                          }
                        )
                      : "None Required"}
                    <h2 className="text-black text-lg font-semibold my-2">
                      Job Description
                    </h2>
                    <p>{props.details.description}</p>

                    {!props.jobApplication && canApply && (
                      <span className="justify-end my-4">
                        <button
                          className="px-4 bg-orange text-white font-semibold p-2 rounded-md text-lg"
                          onClick={submitJobApplication}
                        >
                          Apply
                        </button>
                      </span>
                    )}
                    {!props.jobApplication && !canApply && (
                      <span className="text-black text-orange text-lg justify-end my-4">
                        You must pass all the checks before applying!!
                      </span>
                    )}
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </Modal>
      <JobChecksModal
        pageIndexUpdateModal={pageIndexUpdateModal}
        setPageIndexUpdateModal={setPageIndexUpdateModal}
        lastPage={
          pageIndexUpdateModal == JOB_CHECKS_PAGES.QUALIFICATIONS ? 6 : null
        }
        pages={pages}
      />
    </>
  );
}
