import { getFileUrl, uploadFile } from "api/Api";
import { GENDER_NAMES, QUALIFICATION_NAMES } from "api/Consts";
import { ACEDEMIC_QUALIFICATION_DETAILS } from "api/Consts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import userSvc from "services/User";
import { Form, Toast } from "skillstrainer-resource-library";
import * as yup from "yup";

export const SkillYatraPartialUserDetailsModal = ({
  userData: { userDetails, userQualifications } = {},
  resolveFn,
  rejectFn,
}) => {
  const userId = userDetails?.id;

  const handleSubmit = useCallback(async (values) => {
    try {
      /*
      Form response structure

      {
        "gender": "male",
        "profile_photo": [
          {
            "id": "1",
            "name": "snapshot-1",
            "url": "592521/1a0584bf-564.0"
          }
        ],
        "class": 1,
        "school_name": "Hillwoods Academy"
      }
    */

      const { gender, profile_photo, class: _class, school_name } = values;
      /*
       *
       *
       * Handle user details
       *
       *
       */
      const userDetailsUpdationResponse = await userSvc.updateUserDetails(
        userId,
        { gender, profile_photo: profile_photo[0].url }
      );

      /*
       *
       *
       * Handle user qualifications
       *
       *
       */
      const userQualificationExists = !!userQualifications.find(
        (q) => q.qualification_id + "" == _class + ""
      );
      const userQualificationsInsertionResponse = {};
      if (!userQualificationExists) {
        const qualType = Object.keys(ACEDEMIC_QUALIFICATION_DETAILS).find(
          (qualType) =>
            ACEDEMIC_QUALIFICATION_DETAILS[qualType].qualification_id + "" ===
            _class
        );
        Object.assign(
          userQualificationsInsertionResponse,
          await userSvc.createQualification({
            qualification_id: _class,
            institution_name: school_name,
            qualification_name: QUALIFICATION_NAMES[qualType],
            qualification_type: qualType,
            user_id: userId,
          })
        );
      }

      resolveFn({
        userDetailsUpdationResponse,
        userQualificationsInsertionResponse,
      });
    } catch (err) {
      rejectFn({ type: "internal", err });
    }
  }, []);

  const highestQualification = useMemo(() => {
    if (userQualifications) {
      return userSvc.getHighestQualification(userQualifications);
    }
  }, [userQualifications]);

  return (
    <div className="flex flex-col p-4 px-5">
      <div className="text-lg">
        Please complete your following details before starting the course
      </div>
      <Form
        formBuilder={SkillYatraPartialUserFormBuilder}
        key={userDetails && userQualifications ? "loaded" : "not-loaded"}
        initValues={{
          gender: userDetails && userDetails.gender,
          profile_photo: userDetails &&
            userDetails.profile_photo && [
              {
                url: userDetails.profile_photo,
                name: userDetails.profile_photo,
              },
            ],
          class: highestQualification && highestQualification.qualification_id,
          school_name:
            highestQualification && highestQualification.institution_name,
        }}
        plugins={{
          file: {
            services: {
              uploadFn: (...args) =>
                uploadFile(...args).then((res) => res.data_url),
              getUrl: getFileUrl,
            },
          },
        }}
        className="grid grid-cols-2 gap-3"
        onSubmit={handleSubmit}
        submitButton={{
          className: "btn-primary",
        }}
      />
    </div>
  );
};

export const SkillYatraPartialUserFormBuilder = (values) => {
  const [schema, setSchema] = useState({});

  useEffect(() => {
    const schema = {
      gender: {
        label: "Gender",
        type: "select",
        options: Object.values(GENDER_NAMES).map((g) => ({
          label: g,
          value: g.toLowerCase(),
        })),
        required: true,
        schema: yup.string(),
      },
      profile_photo: {
        label: "Profile picture",
        type: "file",
        showCaptureButton: true,
        schema: yup
          .array()
          .of(yup.object())
          .min(1, "Profile picture is required"),
        required: true,
      },
      class: {
        label: "Class",
        type: "select",
        options: Object.keys(ACEDEMIC_QUALIFICATION_DETAILS).map(
          (qualType) => ({
            value: ACEDEMIC_QUALIFICATION_DETAILS[qualType].qualification_id,
            label: QUALIFICATION_NAMES[qualType],
          })
        ),
        schema: yup.string(),
        required: true,
      },
      school_name: {
        label: "Current College / School Name",
        schema: yup.string(),
        required: true,
      },
    };

    setSchema(schema);
  }, [values]);

  return schema;
};
