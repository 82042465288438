import React, { useState, useEffect } from "react";
import {
  rBoolField,
  rFileField,
  rStringField,
} from "../../../../components/Form/presets";
import { condObj } from "../../../../utils/func";

// Qualifications
export const Form1 = (values) => {
  const [schema, setSchema] = useState();

  const createSchema = () => {
    const schema = {
      academic_achievements: rBoolField(
        "Any Special Academic Achievements",
        {},
        {
          type: "multi-choice",
          required: false,
        }
      ),
      ...condObj(eval(values.academic_achievements), {
        academic_achievements_list: {
          type: "object",
          repeat: true,
          insertable: true,
          repeatClassName: "bg-blue-200",
          insertableProps: {
            button: {
              label: "Add achievement",
              className: "button",
            },
          },
          fields: {
            doc: rFileField("Upload certificate", "Upload", {
              fileFieldProps: {
                accept: ".pdf",
              },
            }),
            description: rStringField("Brief description of the achievement", {
              type: "textarea",
            }),
          },
        },
      }),
      extra_curricular_achievements: rBoolField(
        "Any Extra Curricular Achievements",
        {},
        {
          type: "multi-choice",
          required: false,
        }
      ),
      ...condObj(eval(values.extra_curricular_achievements), {
        extra_curricular_achievements_list: {
          type: "object",
          repeat: true,
          insertable: true,
          insertableProps: {
            button: {
              label: "Add achievement",
              className: "button",
            },
          },
          fields: {
            doc: rFileField("Upload certificate", "Upload", {
              fileFieldProps: {
                accept: ".pdf",
              },
            }),
            description: rStringField("Brief description of the achievement", {
              type: "textarea",
            }),
          },
        },
      }),
    };
    setSchema(schema);
  };

  useEffect(() => createSchema(), [values]);

  return schema;
};

// Recommendation letter from institute
export const Form2 = (values) => {
  const [schema, setSchema] = useState();

  const createSchema = () => {
    const schema = {
      recommendation_letter: rFileField("Recommendation letter", "Upload", {
        required: false,
        fileFieldProps: {
          accept: ".pdf",
        },
      }),
    };
    setSchema(schema);
  };

  useEffect(() => createSchema(), [values]);

  return schema;
};
