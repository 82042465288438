import * as Yup from "yup";

const enterpriseValidationSchema = Yup.object({
  contact_name: Yup.string().trim().required("This is a required field"),
  organisation_name: Yup.string().trim().required("This is a required field"),
  email_id: Yup.string()
    .email("Not a valid email ID!")
    .trim()
    .required("This is a required field"),
  purpose: Yup.string(),
  pincode: Yup.string(),
  contact_number: Yup.number().required("This is a required field"),
  organisation_type: Yup.string().trim().required("This is a required field"),
});

export { enterpriseValidationSchema };
