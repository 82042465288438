import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  ApplyCoupon as ApplyCouponAction,
  SetCouponInFocus,
} from "redux/actions/Course";

export default function ApplyCoupon(props) {
  const { user, params, complete } = props;
  const { code } = params;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SetCouponInFocus(code));
    dispatch(ApplyCouponAction(code, user.id));
    complete();
  }, []);

  return null;
}
