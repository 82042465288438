import { Form } from "skillstrainer-resource-library";
import BillingAddressCheckoutFormBuilder from "./BillingAddressCheckoutFormBuilder";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { GET_USER_PRIMARY_ADDRESS } from "graphql/queries/AccountDetails";
import { useEffect, useState } from "react";
import {
  UPDATE_USER_PRIMARY_ADDRESS,
  UPSERT_USER_ADDRESS,
} from "graphql/mutations/AccountDetails";
import Toast from "utils/Toast";

export default function BillingAddressCheckout(props) {
  const { paymentFunction } = props;
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;

  const [initAddress, setInitAddress] = useState({ key: 0, values: {} });
  const [gstDetails, setGstDetails] = useState({
    gst_no: null,
    business_name: null,
    billing_address_id: null,
  });
  const [disableForm, setDisableForm] = useState({
    form: false,
    updateButton: false,
    checkoutButton: true,
  });

  const { data: userAddressData, refetch } = useQuery(
    GET_USER_PRIMARY_ADDRESS,
    {
      variables: {
        userId: userId,
      },
    }
  );

  const [upsertUserAddress] = useMutation(UPSERT_USER_ADDRESS);
  const [updateUserPrimaryAddress] = useMutation(UPDATE_USER_PRIMARY_ADDRESS);

  useEffect(() => {
    if (userAddressData?.courses_user_address.length !== 0)
      setInitAddress({
        key: initAddress.key + 1,

        values: {
          business_name: undefined,
          gst_number: undefined,
          address: {
            country: "India",
            pincode:
              userAddressData?.courses_user_address[0].pincode || undefined,
            house_number:
              userAddressData?.courses_user_address[0].house_number ||
              undefined,
            location:
              userAddressData?.courses_user_address[0].location || undefined,
            district:
              userAddressData?.courses_user_address[0].district || undefined,
            city_town:
              userAddressData?.courses_user_address[0].city_town || undefined,
            state: userAddressData?.courses_user_address[0].state || undefined,
          },
        },
      });
  }, [userAddressData]);

  const handleCheckout = async () => {
    await paymentFunction([
      gstDetails.gst_no,
      gstDetails.business_name,
      gstDetails.billing_address_id,
    ]);
  };

  const handleSubmit = async (formObject) => {
    if (!formObject.gst_number) {
      let sameAddressFlag = true;
      for (let key in initAddress.values.address) {
        if (initAddress.values.address[key] != formObject.address[key])
          sameAddressFlag = false;
      }
      if (!sameAddressFlag) {
        const addressId =
          (userAddressData?.courses_user_address.length !== 0 &&
            userAddressData?.courses_user_address[0].id) ||
          undefined;
        await upsertUserAddress({
          variables: {
            values: {
              ...formObject.address,
              user_id: userId,
              id: addressId,
              adress_type: "primary",
            },
          },
        })
          .then(async (res) => {
            Toast.success("Address updated successfully");
            if (!addressId) {
              const primaryAdressId =
                res.data?.insert_courses_user_address?.returning[0].id;
              await updateUserPrimaryAddress({
                variables: {
                  user_id: userId,
                  address_id: primaryAdressId,
                },
              });
            }
            setDisableForm({ ...disableForm, checkoutButton: false });

            refetch();
          })
          .catch((err) => {
            console.log(err);
            Toast.error("There was an error while updating address");
          });
      } else setDisableForm({ ...disableForm, checkoutButton: false });
    } else {
      const gst_no = formObject.gst_number;
      const business_name = formObject.business_name;

      if (!business_name) {
        Toast.error("Please enter business name");
        return null;
      }

      const billing_address_id = await upsertUserAddress({
        variables: {
          values: {
            ...formObject.address,
            user_id: userId,
            adress_type: "billing",
          },
        },
      })
        .then((res) => {
          Toast.success("Billing Address Saved");
          return res.data?.insert_courses_user_address?.returning[0].id;
        })
        .catch((err) => {
          console.log(err);
          Toast.error("There was an error");
        });

      setGstDetails({
        gst_no: gst_no,
        billing_address_id: billing_address_id,
        business_name: business_name,
      });
      setDisableForm({ ...disableForm, checkoutButton: false });
    }
  };

  return (
    <div className="px-4 ">
      <h2 className="text-black text-lg opacity-75 font-extrabold ml-4 pd-2 ">
        Billing Address
      </h2>
      {userAddressData && (
        <Form
          formBuilder={BillingAddressCheckoutFormBuilder}
          className=" mx-4 mt-2 grid grid-cols-1 gap-x-2 "
          submitButton={{
            text: "Confirm Address",
            className: "btn-secondary ml-4 mb-1",
          }}
          initValues={initAddress.values}
          key={initAddress.key}
          onSubmit={handleSubmit}
        />
      )}
      <button
        className={`btn-primary ml-44 -mt-10 absolute ${
          disableForm.checkoutButton ? "opacity-40" : ""
        }`}
        disabled={disableForm.checkoutButton}
        onClick={() => handleCheckout()}
      >
        Proceed
      </button>
    </div>
  );
}
