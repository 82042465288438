import axios from "./Api";
import { getLoginCookie, getJwtToken } from "../utils/Auth";

export const api = async (endpoint, payload) => {
  const data = await axios.post("/" + endpoint, { data: payload });

  return data.data;
};

export const getAccessTokens = () => {
  return {
    access_token: getLoginCookie(),
    jwt_token: getJwtToken(),
  };
};
