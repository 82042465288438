import { gql } from "@apollo/client";

export const INSERT_SCHOLARSHIP_APPLICATION = gql`
  mutation insertScholarshipApplication(
    $application: [courses_scholarship_partner_user_insert_input!] = {}
  ) {
    insert_courses_scholarship_partner_user(objects: $application) {
      affected_rows
    }
  }
`;
