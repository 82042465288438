import moment from "moment";
import { useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { JobDetailsModal } from ".";
import { MdCategory } from "react-icons/md";
import Toast from "../../utils/Toast";
import { FaMoneyBillAlt } from "react-icons/fa";
import { IoPeopleSharp } from "react-icons/io5";
import { IoIosAlarm, IoIosStarOutline, IoIosStar } from "react-icons/io";
import { HiBriefcase } from "react-icons/hi2";
import { BsGenderAmbiguous } from "react-icons/bs";
import { SOCIAL_CATEGORY_NAMES, GENDER_NAMES, s3BaseUrl } from "api/Consts";
import { STAR_JOB, REMOVE_FROM_STARRED } from "graphql/mutations/Jobs";

export default function JobCard(props) {
  const { social_categories, gender } = props;

  const [jobDetailsOpen, setJobDetailsOpen] = useState(false);

  const genders_by_name = useMemo(() => {
    const gender_by_name = [];

    (gender || []).forEach((gender_code) => {
      const gender_name = GENDER_NAMES[gender_code];
      gender_by_name.push(gender_name);
    });

    return gender_by_name.join(", ") || null; // "All genders";
  }, [gender]);

  const social_categories_by_name = useMemo(() => {
    const social_categories_by_name = [];

    (social_categories || []).forEach((social_category_code) => {
      const social_category_name = SOCIAL_CATEGORY_NAMES[social_category_code];
      social_categories_by_name.push(social_category_name);
    });

    return social_categories_by_name.join(", ") || null; // "All categories";
  }, [social_categories]);

  const [addStarJob] = useMutation(STAR_JOB);
  const [removeStarredJob] = useMutation(REMOVE_FROM_STARRED);

  const removeFromStarred = async () => {
    const submitValues = {
      variables: {
        job_id: props.details.id,
        user_id: props.userId,
      },
    };
    const removedStarJob = await removeStarredJob(submitValues);
    if (removedStarJob?.data?.delete_courses_starred_jobs?.affected_rows) {
      Toast.success("Removed the job from Starred successfully!");
      props.starredJobsQuery.refetch();
    } else {
      Toast.error("An error occured, please try again later!");
    }
  };

  const addInStarred = async () => {
    const submitValues = {
      variables: {
        star: {
          job_id: props.details.id,
          user_id: props.userId,
        },
      },
    };
    const starredJob = await addStarJob(submitValues);
    if (starredJob?.data?.insert_courses_starred_jobs?.affected_rows) {
      Toast.success("Starred the job successfully!");
      props.starredJobsQuery.refetch();
    } else {
      Toast.error("An error occured, please try again later!");
    }
  };

  return (
    <>
      <JobDetailsModal
        jobApplicationsQuery={props.jobApplicationsQuery}
        jobApplication={props.jobApplication}
        jobDetailsOpen={jobDetailsOpen}
        setJobDetailsOpen={setJobDetailsOpen}
        details={props.details}
        social_categories={social_categories_by_name}
        genders={genders_by_name}
      />
      <div
        ref={props.current_ref}
        class="flex flex-col w-full p-4 my-3 mx-auto bg-white rounded-lg border-2"
      >
        <div className="flex flex-row items-center">
          <img
            src={
              props.logo
                ? s3BaseUrl + "/" + props.logo
                : "http://placehold.jp/150x150.png"
            }
            alt="work-partner-img"
            className="w-16 h-16 rounded-md border-2"
          />
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-semibold text-japanese_indigo mx-2">
                {props.title}
              </h1>
              <h1 className="text-sm font-semibold text-gray-500 mx-2">
                by {props.organization_name}
              </h1>
              <div className="flex flex-row mx-2">
                <IoIosAlarm size={20} className="text-orange my-auto mt-0" />
                <p className="text-xs text-orange my-auto mt-0.5">
                  Posted {moment(props.created_at, "YYYYMMDD").fromNow()}
                </p>
              </div>
            </div>
            <div className="flex flex-row item-center justify-center">
              {props.jobApplication && (
                <>
                  <p className="my-auto mt-0.5">
                    <p className="font-semibold text-sm text-japanese_indigo">
                      Application Status:
                      <span
                        className="mx-1"
                        style={{
                          color:
                            props.jobApplication.status == "rejected"
                              ? "var(--red)"
                              : "var(--success-green)",
                        }}
                      >
                        {props.jobApplication.status}
                      </span>
                    </p>
                    {props.jobApplication.status == "active" && (
                      <p className="font-semibold text-sm text-japanese_indigo">
                        Stage:{" "}
                        <span style={{ color: "var(--orange)" }}>
                          {props.jobApplication.stage}
                        </span>
                      </p>
                    )}
                  </p>
                </>
              )}
              {!props.isStarred && (
                <IoIosStarOutline
                  onClick={addInStarred}
                  className="text-orange mx-2 cursor-pointer"
                  size={25}
                />
              )}
              {props.isStarred && (
                <IoIosStar
                  onClick={removeFromStarred}
                  className="text-orange mx-2 cursor-pointer"
                  size={25}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row mt-3.5">
          {social_categories_by_name && (
            <div className="flex flex-row mx-5">
              <MdCategory className="text-japanese_indigo" size={20} />
              <p className="font-semibold text-sm text-japanese_indigo mx-2">
                {social_categories_by_name}
              </p>
            </div>
          )}
          <div className="flex flex-row mx-5">
            <FaMoneyBillAlt className="text-japanese_indigo" size={20} />
            <p className="font-semibold text-sm text-japanese_indigo mx-2">
              ₹{props.salary_ctc} per month
            </p>
          </div>
          {genders_by_name && (
            <div className="flex flex-row mx-5">
              <BsGenderAmbiguous className="text-japanese_indigo" size={20} />
              <p className="font-semibold text-sm text-japanese_indigo mx-2">
                {genders_by_name}
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-row my-4 ">
          <div className="flex flex-row mx-5">
            <IoPeopleSharp className="text-japanese_indigo" size={20} />
            <p className="font-semibold text-sm text-japanese_indigo mx-2">
              Age {props.min_age ? props.min_age : "NIL"} -{" "}
              {props.max_age ? props.max_age : "NIL"} Years
            </p>
          </div>
          <div className="flex flex-row mx-5">
            <HiBriefcase className="text-japanese_indigo" size={20} />
            <p className="font-semibold text-sm text-japanese_indigo mx-2">
              Experience {props.min_experience} Years
            </p>
          </div>
        </div>
        <p>
          {(props.description || " ").length > 200
            ? props.description.substring(0, 200) + "..."
            : props.description || " "}
        </p>
        <div className="flex flex-row justify-end">
          <button
            onClick={() => setJobDetailsOpen(true)}
            className="mx-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
          >
            View More Details
          </button>
        </div>
      </div>
    </>
  );
}
