import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import CourseTopics from "./CourseTopics";
import { FetchCourseLesson } from "redux/actions/Course";
import { checkCourseComplete } from "api/Course";
import { FaInfoCircle } from "react-icons/fa";
import { openCourseCompletionSettings } from "redux/actions/Course";

/*
  Component functions:
    - Fetch course lessons and topics
    - Fetch user's completion data
    - Check course completion [API](and create course completion record if all completion criteria is met)
*/

function ViewCourseSidebar(props) {
  const { id } = props.params;

  const { auth, ui } = useSelector(({ auth, ui }) => ({
    auth,
    ui,
  }));
  const courseLessonTopicsGQLRes = useSelector(
    ({ course }) => course.courseLessonsTopics
  );
  const viewCourseCompletionSetting = useSelector(
    ({ course }) => course.viewCourseCompletionSetting
  );

  const userId = auth.user?.db_user?.id;

  const quiz = useSelector(({ quiz }) => quiz);

  const [lesson, setLessons] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchCourseLesson(id, userId));
  }, [courseLessonTopicsGQLRes]);

  useEffect(async () => {
    if (courseLessonTopicsGQLRes?.courses_course_section) {
      setLessons(courseLessonTopicsGQLRes?.courses_course_section);
      const params = {
        user_id: userId,
        course_id: id,
      };
      console.log("checkCourseCompleteAudit", "invoking checkCourseComplete");
      await checkCourseComplete(params, true);
    }
  }, [courseLessonTopicsGQLRes]);

  return (
    quiz.QuizMode &&
    !quiz.ScormFullScreen && (
      <div
        className={`w-full ${
          ui.isLeftSidebarOpen ? "block" : "hidden"
        } lg:block absolute top-20 bg-white z-10 lg:sticky lg:top-0 lg:left-0 lg:w-2/12 shadow-xl overflow-y-scroll h-screen p-3 pl-16 lg:pl-2 zindex-custm`}
      >
        <div className="text-center font-bold my-5 rounded-md border p-2 flex flex-inline gap-8">
          {" "}
          Course Modules{" "}
          <FaInfoCircle
            className="text-orange my-auto cursor-pointer"
            size={20}
            onClick={() =>
              dispatch(
                openCourseCompletionSettings(!viewCourseCompletionSetting)
              )
            }
          />
        </div>

        {(lesson || []).map((data, i) => (
          <CourseTopics
            key={i}
            title={data}
            module={data.coursesec || []}
            // restrict={restrict}
            view={id}
          />
        ))}
      </div>
    )
  );
}

export default ViewCourseSidebar;
