import { BiLink } from "react-icons/bi";
import { MdContentCopy } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";

export default function GiftPaymentSuccess() {
  return (
    <>
      <div className="flex items-center justify-center p-10">
        <div className="flex flex-col px-4">
          <img className="mx-auto animate-pulse" width={60} src="./gift-confirm-tick.png" alt="Payment Success" />
          <div className="my-4">
            <h1 className="text-3xl text-center font-semibold text-japanese_indigo">
              Course Purchased Successfully!
            </h1>
            <p className="text-center text-japanese_indigo text-lg">
              Here's what you have purchased
            </p>
          </div>

          <div className="mb-8 mt-2 bg-white rounded-md border">
            <div className="w-full rounded-md my-3">
              <div className="flex flex-row items-center justify-between bg-white p-4">
                <img
                  className="h-20 w-1/3 object-cover object-center border-2 rounded-md"
                  src="https://lms.skillstrainer.in/moodle/pluginfile.php/1550098/course/overviewfiles/3-SWAR%20logo%20options_25.09.2021.jpg"
                  alt="course-img"
                />
                <p className="text-japanese_indigo text-lg">
                  SWAR English Course
                </p>
                <p className="font-semibold text-lg text-japanese_indigo">
                  ₹1000
                </p>
              </div>
            </div>

            <div className="flex flex-col w-full my-3 border-t-2 p-3">
              <div className="flex flex-row justify-between w-full my-3">
                <p className="text-japanese_indigo text-lg">Tax</p>
                <p className="text-japanese_indigo font-semibold">₹0</p>
              </div>

              <div className="flex flex-row justify-between w-full my-3">
                <p className="text-japanese_indigo text-lg font-semibold">
                  Total
                </p>
                <p className="text-orange font-semibold text-lg">₹1000</p>
              </div>
            </div>
          </div>

          <h2 className="text-xl text-center text-japanese_indigo font-semibold">
            Share this link to the beneficiary
          </h2>
          <div className="flex flex-row items-center justify-center">
            <BiLink size={20} className="text-japanese_indigo mx-3" />
            <a
              className="text-center text-lg text-orange my-2 underline"
              href="https://skillstrainer.in"
            >
              https://skillstrainer.in/course/swar-english-course/12341
            </a>
            <MdContentCopy size={20} className="text-orange mx-3" />
          </div>

          <div className="flex flex-col lg:flex-row w-full">
            <button className="flex items-center justify-center w-full bg-orange-light text-japanese_indigo font-semibold p-3 rounded-md m-2">
              <MdContentCopy className="mx-2" size={16} />
              Copy Link
            </button>
            <button className="flex items-center justify-center w-full bg-orange text-white font-semibold p-3 rounded-md m-2">
              <BsWhatsapp className="mx-2" size={16} />
              Share via WhatsApp
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
