import React, { useState, useEffect } from "react";
import {
  addressField,
  rFileField,
  rStringField,
} from "../../../../components/Form/presets";

export const Form1 = (values) => {
  const [schema, setSchema] = useState();

  const createSchema = () => {
    const schema = {
      address: addressField("Address"),
    };

    setSchema(schema);
  };

  useEffect(() => createSchema(), []);

  return schema;
};

export const Form2 = (values) => {
  const [schema, setSchema] = useState();

  const createSchema = () => {
    const schema = {
      aadhar_doc: rFileField("Aadhar document", "Upload", {
        fileFieldProps: {
          accept: ".pdf",
        },
      }),
    };

    setSchema(schema);
  };

  useEffect(() => createSchema(), []);

  return schema;
};
