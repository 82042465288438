import React from "react";

import * as YourDetailsPage1 from "form-builders/AccountDetails/personal-details/your-details/Page1";
import * as YourDetailsPage2 from "form-builders/AccountDetails/personal-details/your-details/Page2";

import * as FamilyDetailsPage1 from "form-builders/AccountDetails/personal-details/family-details/Page1";
import * as FamilyDetailsPage2 from "form-builders/AccountDetails/personal-details/family-details/Page2";

import * as EducationDetailsPage2 from "form-builders/AccountDetails/education-details/schooling-details/Page2";
import * as EducationDetailsPage3 from "form-builders/AccountDetails/education-details/schooling-details/Page3";
import * as OtherDocumentsPage1 from "form-builders/AccountDetails/work-details/other-documents/Page1";
import userSvc from "services/User";
import { condObj, resolveValue } from "utils/func";
import {
  DOCUMENT_TYPE,
  FAMILY_MEMBER,
  TECHNICAL_QUALIFICATION_TYPE,
} from "api/Consts";
import _ from "lodash";
import Qualifications from "./Qualifications";
import Languages from "./Languages";
import Skills from "./Skills";
import WorkDetailsForm from "form-builders/AccountDetails/work-details/work-details";
import { splitByCrudActions } from "utils/form";

export const createStepsAndPages = async ({ user }) => {
  // helper functions
  let stepId = 1,
    substepId = 1,
    pageId = 1,
    formId = 1;
  const step = (name, substeps) => ({ id: stepId++, name, substeps });
  const substep = (name, pages) => ({ id: substepId++, name, pages });
  const page = (forms) => ({ id: pageId++, forms });
  const form = (name, formBuilder, initValuesFn, submitFn) => ({
    id: formId++,
    name,
    formBuilder,
    initValuesFn,
    submitFn,
  });

  // structure
  const steps = [
    step("Personal Details", [
      substep("Personal Details", [
        page([
          // Basic details Form
          form(
            null,
            YourDetailsPage1.Form1,
            async () => {
              try {
                const userDetails = await userSvc
                  .fetchUserDetails(user.id)
                  .then((res) => _.cloneDeep(res));

                userDetails["profile_photo"] = userDetails.profile_photo
                  ? [
                      {
                        url: userDetails.profile_photo,
                        name: "user-profile-photo",
                      },
                    ]
                  : [];

                return userDetails;
              } catch (err) {
                console.log(err);
              }
            },
            async (values) => {
              if (values.profile_photo?.length !== 0) {
                const profilePhoto = values.profile_photo[0]?.url || "";
                values.profile_photo = profilePhoto;
              } else values.profile_photo = null;

              return await userSvc.updateUserDetails(user.id, values);
            }
          ),
        ]),
        page([
          // Address form
          resolveValue(() => {
            const ref = {};
            return form(
              "Full Address",
              YourDetailsPage2.Form1,
              async () => {
                const address = await userSvc.fetchUserDetail(
                  user.id,
                  "address"
                );
                ref.address = address;
                return { address };
              },
              async (values) => {
                return await userSvc
                  .createOrUpdateAddress({
                    ...values.address,
                    id: ref.address?.id,
                    adress_type: "primary",
                  })
                  .then((res) => (ref.address = res))
                  .then((res) => userSvc.attachAddressToUser(res.id, user.id));
              }
            );
          }),
        ]),
        { render: (props) => <Languages {...props} /> },
      ]),
      substep("Family Details", [
        page([
          // Father's details form
          resolveValue(() => {
            const ref = {};
            return form(
              "Father's details",
              FamilyDetailsPage1.Form1,
              async () => {
                const father = await userSvc.fetchMemberByType(
                  FAMILY_MEMBER.FATHER,
                  user.id
                );
                ref.father = father && father[0];
                return ref.father;
              },
              (values) => {
                return userSvc
                  .createOrUpdateMemberDetails({
                    ...values,
                    id: ref.father?.id,
                    member_type: FAMILY_MEMBER.FATHER,
                    user_id: user.id,
                  })
                  .then((res) => (ref.father = res));
              }
            );
          }),
          // Mother's details form
          resolveValue(() => {
            const ref = {};
            return form(
              "Mother's Details",
              FamilyDetailsPage1.Form2,
              async () => {
                const mother = await userSvc.fetchMemberByType(
                  FAMILY_MEMBER.MOTHER,
                  user.id
                );
                ref.mother = mother && mother[0];
                return ref.mother;
              },
              (values) => {
                return userSvc
                  .createOrUpdateMemberDetails({
                    ...values,
                    id: ref.mother?.id,
                    member_type: FAMILY_MEMBER.MOTHER,
                    user_id: user.id,
                  })
                  .then((res) => (ref.mother = res));
              }
            );
          }),
        ]),
        page([
          // Family member's form
          resolveValue(() => {
            const ref = {};
            return form(
              "Siblings' Details",
              FamilyDetailsPage2.Form1,
              async () => {
                const members = await userSvc.fetchUserMembers(user.id);
                const siblings = members.filter((m) =>
                  [FAMILY_MEMBER.SISTER, FAMILY_MEMBER.BROTHER].includes(
                    m.member_type
                  )
                );
                ref.siblings = siblings;
                return { siblings };
              },
              async (values) => {
                try {
                  const { siblings } = values;
                  siblings.forEach((sibling) => {
                    if (sibling.annual_income) sibling.annual_income += "";
                  });
                  const oldSiblings = siblings.filter((s) => s.id);
                  const newSiblings = siblings.filter((s) => !s.id);
                  const deletedSiblings =
                    ref.siblings?.filter(
                      (s) => !oldSiblings.find((os) => os.id === s.id)
                    ) || [];

                  let updateResponse = true;
                  for (const oldSibling of oldSiblings)
                    updateResponse =
                      updateResponse &&
                      (await userSvc
                        .updateMemberDetails(oldSibling.id, oldSibling)
                        .then((res) => (oldSibling.id = res.id)));
                  if (!updateResponse) return;

                  let createResponse = true;
                  for (const newSibling of newSiblings)
                    createResponse =
                      createResponse &&
                      (await userSvc.createMemberDetails({
                        ...newSibling,
                        member_type: FAMILY_MEMBER.BROTHER,
                        user_id: user.id,
                      }));
                  if (!createResponse) return;
                  ref.siblings = oldSiblings.concat(newSiblings);

                  const deleteResponse = await userSvc.deleteMemberDetails(
                    deletedSiblings.map((e) => e.id)
                  );
                  if (!deleteResponse && deleteResponse !== 0) return;

                  return true;
                } catch (err) {
                  console.error(err);
                }
              }
            );
          }),
          // Total family income form
          resolveValue(() => {
            const ref = {};
            return form(
              "Total Family Income",
              FamilyDetailsPage2.Form2,
              async () => {
                ref.doc = await userSvc.fetchIdentityDocument({
                  user_id: user.id,
                  document_type: DOCUMENT_TYPE.FAMILY_INCOME_CERT,
                });
                ref.doc = ref.doc && ref.doc[0];
                return (
                  ref.doc?.url && {
                    familyIncomeDocument: [
                      { url: ref.doc.url, name: ref.doc.name },
                    ],
                  }
                );
              },
              async (values) => {
                const { familyIncomeDocument } = values;
                if (ref.doc) {
                  await userSvc.deleteIdentityDocuments([ref.doc.id]);
                  ref.doc = undefined;
                }
                if (familyIncomeDocument && familyIncomeDocument[0])
                  return userSvc
                    .createOrUpdateIdentityDocument({
                      id: ref.doc?.id,
                      document_type: DOCUMENT_TYPE.FAMILY_INCOME_CERT,
                      name: familyIncomeDocument[0].name,
                      url: familyIncomeDocument[0].url,
                      user_id: user.id,
                    })
                    .then((res) => (ref.doc = res));
                return true;
              }
            );
          }),
        ]),
      ]),
    ]),
    step("Education Details", [
      substep("Education Details", [
        { render: (props) => <Qualifications {...props} /> },
        page([
          // Technical qualifications
          resolveValue(() => {
            const ref = {};
            return form(
              "Technical Qualifications",
              EducationDetailsPage2.Form1,
              async () => {
                const qualifications = await userSvc
                  .fetchUserQualifications(user.id)
                  .then((res) =>
                    res
                      .filter(
                        (qual) =>
                          Object.values(TECHNICAL_QUALIFICATION_TYPE).indexOf(
                            qual.qualification_type
                          ) > -1
                      )
                      .map((qual) => {
                        const doc = qual.document_proof;
                        return {
                          ...qual,
                          contact_person: _.pick(qual, [
                            "contact_person_type",
                            "contact_person_name",
                            "contact_person_mobile_number",
                          ]),
                          // Default value for doc
                          doc: doc && doc.url && [_.pick(doc, ["url", "name"])],
                        };
                      })
                  );
                ref.docs = qualifications || [];
                return { qualifications };
              },
              async (values) => {
                const { qualifications } = values;
                const _quals = qualifications
                  .map((qual) => ({
                    ...qual,
                    ...qual.contact_person,
                    fresh: !qual.id,
                    document_proof: {
                      ...qual.document_proof,
                      url: qual.doc && qual.doc[0] && qual.doc[0].url,
                      name: qual.doc && qual.doc[0] && qual.doc[0].name,
                    },
                  }))
                  .concat(
                    ref.docs
                      .filter(
                        (oldQual) =>
                          !qualifications.find((q) => q.id === oldQual.id)
                      )
                      .map((q) => ({ ...q, disabled: true }))
                  );
                const newQuals = await userSvc
                  .setUserQualifications(_quals, user.id)
                  .then((res) => (ref.docs = res));
                return newQuals;
              }
            );
          }),
        ]),
        { render: (props) => <Skills {...props} /> },
        page([
          // Extra certificates form
          resolveValue(() => {
            const ref = {};
            return form(
              null,
              EducationDetailsPage3.Form1,
              async () => {
                let academic_docs = await userSvc
                  .fetchIdentityDocument({
                    user_id: user.id,
                    document_type: DOCUMENT_TYPE.ACADEMIC_CERT,
                  })
                  .then((id_docs) =>
                    id_docs.map((id_doc) => {
                      id_doc.doc = [
                        {
                          name: id_doc.name,
                          url: id_doc.url,
                        },
                      ];
                      return id_doc;
                    })
                  );
                academic_docs = !!academic_docs.length && academic_docs;

                let extra_docs = await userSvc
                  .fetchIdentityDocument({
                    user_id: user.id,
                    document_type: DOCUMENT_TYPE.EXTRA_CURRICULAR_CERT,
                  })
                  .then((id_docs) =>
                    id_docs.map((id_doc) => {
                      id_doc.doc = [
                        {
                          name: id_doc.name,
                          url: id_doc.url,
                        },
                      ];
                      return id_doc;
                    })
                  );
                extra_docs = !!extra_docs.length && extra_docs;

                ref.docs = {
                  academic_docs,
                  extra_docs,
                };
                const result = {
                  academic_achievements: !!academic_docs,
                  academic_achievements_list: academic_docs,
                  extra_curricular_achievements: !!extra_docs,
                  extra_curricular_achievements_list:
                    !!extra_docs && extra_docs,
                };
                return result;
              },
              async (values) => {
                const {
                  academic_achievements,
                  academic_achievements_list: academic_docs,
                  extra_curricular_achievements,
                  extra_curricular_achievements_list: extra_docs,
                } = values;

                // Delete old documents
                await userSvc.deleteIdentityDocuments(
                  (ref.docs.academic_docs || [])
                    .concat(ref.docs.extra_docs || [])
                    .map((doc) => doc.id)
                );

                // Create new ones
                const academicResponse =
                  (academic_achievements &&
                    academic_docs &&
                    academic_docs.length &&
                    (await userSvc
                      .createIdentityDocument(
                        academic_docs.map((id_doc) => ({
                          document_type: DOCUMENT_TYPE.ACADEMIC_CERT,
                          ...condObj(id_doc.doc[0], {
                            name: id_doc.doc[0].name,
                            url: id_doc.doc[0].url,
                          }),
                          user_id: user.id,
                          description: id_doc.description,
                        }))
                      )
                      .catch((e) => false))) ||
                  null;

                const extraResponse =
                  (extra_curricular_achievements &&
                    extra_docs &&
                    extra_docs.length &&
                    (await userSvc
                      .createIdentityDocument(
                        extra_docs.map((id_doc) => ({
                          document_type: DOCUMENT_TYPE.EXTRA_CURRICULAR_CERT,
                          ...condObj(id_doc.doc[0], {
                            name: id_doc.doc[0].name,
                            url: id_doc.doc[0].url,
                          }),
                          user_id: user.id,
                          description: id_doc.description,
                        }))
                      )
                      .catch((e) => false))) ||
                  null;

                if (academicResponse !== false && extraResponse !== false) {
                  ref.docs.academic_docs = academicResponse;
                  ref.docs.extra_docs = extraResponse;
                  return true;
                }
              }
            );
          }),
          // Recommendation letter form
          resolveValue(() => {
            const ref = {};
            return form(
              null,
              EducationDetailsPage3.Form2,
              async () => {
                const res = await userSvc.fetchIdentityDocument({
                  user_id: user.id,
                  document_type:
                    DOCUMENT_TYPE.RECOMMENDATION_CERT_FROM_INSTITUTE,
                });
                if (res && res[0]) {
                  ref.doc_id = res[0].id;
                  return (
                    res[0]?.url && {
                      recommendation_letter: [
                        { name: res[0].name, url: res[0].url },
                      ],
                    }
                  );
                }
                return {};
              },
              async ({ recommendation_letter: doc }) => {
                doc = doc && doc[0];
                if (ref.doc_id)
                  await userSvc.deleteIdentityDocuments([ref.doc_id]);
                const response = await userSvc.createIdentityDocument({
                  user_id: user.id,
                  document_type:
                    DOCUMENT_TYPE.RECOMMENDATION_CERT_FROM_INSTITUTE,
                  url: doc && doc.url,
                  name: doc && doc.name,
                });
                if (response) ref.doc_id = response.id;
                return response;
              }
            );
          }),
        ]),
      ]),
    ]),
    step("Work Details", [
      substep("Work Details", [
        // Work details page
        page([
          // New work details form
          resolveValue(() => {
            const ref = {};
            return form(
              null,
              WorkDetailsForm,
              async () => {
                const works = await userSvc.fetchWorkDetails(user.id);
                ref.works = works || [];
                return { works };
              },
              async (values) => {
                const { fresh, same, removed } = splitByCrudActions({
                  newRecords: values.works,
                  oldRecords: ref.works,
                });

                // Adding user id to the fresh entries
                fresh.forEach((r) => (r.user_id = user.id));

                if (fresh && fresh.length)
                  await userSvc.createWorkDetails(fresh);
                if (removed && removed.length)
                  await userSvc.deleteWorkDetails(removed.map((r) => r.id));
                for (const record of same) {
                  const correspondingOldRecord = ref.works.find(
                    (r) => r.id === record.id
                  );
                  if (!_.isEqual(record, correspondingOldRecord))
                    await userSvc.updateWorkDetails(record);
                }

                ref.works = same.concat(fresh);

                return true;
              }
            );
          }),
        ]),
      ]),
      substep("Other Documents", [
        page([
          // Other documents form
          resolveValue(() => {
            const ref = {};
            return form(
              null,
              OtherDocumentsPage1.Form1,
              async () => {
                const otherCert = await userSvc.fetchIdentityDocument({
                  user_id: user.id,
                  document_type: DOCUMENT_TYPE.OTHER_CERT,
                });
                ref.other = otherCert && otherCert[0];
                return {
                  other_cert: ref.other && [
                    { name: ref.other.name, url: ref.other.url },
                  ],
                };
              },
              async ({ other_cert: other }) => {
                await userSvc.deleteIdentityDocuments(
                  [].concat((ref.other && [ref.other.id]) || [])
                );
                other = other && other[0];
                const otherResponse =
                  other &&
                  (await userSvc.createIdentityDocument({
                    user_id: user.id,
                    document_type: DOCUMENT_TYPE.OTHER_CERT,
                    url: other.url,
                    name: other.name,
                  }));

                ref.other = otherResponse;
                return ref;
              }
            );
          }),
        ]),
      ]),
    ]),
  ];
  const pages = collectPages(steps);
  return { steps, pages };
};

const collectPages = (steps) => {
  // Converts the tree structure from 'steps' variable to pages
  // that contain information about the corresponding step and substep
  const pages = [];
  steps.map((step, stepIndex) => {
    step.substeps.map((substep, substepIndex) => {
      substep.pages.map((page, pageIndex) => {
        pages.push({
          ...page,
          step,
          stepIndex,
          substep,
          substepIndex,
          index: pageIndex,
          pageIndex: pages.length,
        });
      });
    });
  });
  return pages;
};
