import React, { useEffect } from "react";
import { useHistory } from "react-router";
import courseSvc from "services/Course";
import { useState } from "react";
import Loader from "assets/animated-images/Loader";
import moment from "moment";
import { GET_BATCH_SLOT_BY_ID_SUB } from "graphql/queries/Courses";
import { useSubscription } from "utils/hooks";
import { useSelector } from "react-redux";
import Toast from "wrappers/Toast";

export default function JoinBatch(props) {
  const history = useHistory();
  const { batch_id } = props.match.params;
  const [authenticated, user] = useSelector(({ auth }) => [
    auth.authenticated,
    auth.user?.db_user,
  ]);

  // Batch slot id
  const [batchSlotId, setBatchSlotId] = useState(null);

  // Batch slot details
  const { data: batchSlotRes } = useSubscription(GET_BATCH_SLOT_BY_ID_SUB, {
    variables: { batch_slot_id: batchSlotId },
  });
  const batchSlot = batchSlotRes?.courses_batch_slots[0];

  // Finding the upcoming batch slot
  useEffect(() => {
    window.scrollTo({ top: 0 });

    if (authenticated) {
      courseSvc
        .getBatchById(batch_id)
        .then((batch) => {
          batch.batch_slots.sort(
            (a, b) =>
              new Date(a.slot_date).getTime() - new Date(b.slot_date).getTime()
          );

          const targetSlot = batch.batch_slots.find(
            (bs) =>
              new Date(bs.slot_date) > new Date() ||
              new Date(bs.endto_date) > new Date()
          );

          setBatchSlotId(targetSlot.id);
        })
        .catch(() => setBatchSlotId(false));
    } else {
      Toast.error("Please login to join the batch");
      history.push("/");
    }
  }, [authenticated, user]);

  // Open meeting link if batch slot is online and has meeting link
  const openVideoCall = async (user, batchSlot) => {
    if (
      batchSlot &&
      user &&
      batchSlot.type === "online" &&
      batchSlot.meeting_link
    ) {
      await courseSvc
        .markBatchSlotAttendance(
          user.id,
          batchSlot.id,
          batchSlot.batch_id,
          true
        )
        .catch(console.error);
      window.open(batchSlot.meeting_link, "_blank");
    }
  };
  useEffect(() => openVideoCall(user, batchSlot), [batchSlot, user]);

  if (authenticated)
    return (
      <div className="flex justify-center w-full items-center">
        {batchSlotId === null ? (
          <div className="h-96 items-center flex">
            <Loader style={{ width: "300px" }} />
          </div>
        ) : batchSlotId === false ? (
          <div className="h-96 text-3xl flex items-center font-semibold text-gray-500">
            Couldn't find batch
          </div>
        ) : batchSlot && batchSlot.type === "offline" ? (
          <div className="flex flex-col items-center">
            <h1 className="text-3xl font-semibold my-10">
              The upcoming batch session is offline
            </h1>
            <div className="w-max">
              <div>
                <span className="font-bold">Date: </span>{" "}
                <span>{moment(batchSlot.slot_date).format("DD/MM/YYYY")}</span>
              </div>
              <div>
                <span className="font-bold">Time: </span>{" "}
                <span>
                  {moment(batchSlot.slot_date).format("HH:mm")} -{" "}
                  {moment(batchSlot.endto_date).format("HH:mm")}
                </span>
              </div>
              <div>
                <span className="font-bold">Address: </span>{" "}
                <span>{batchSlot.location}</span>
              </div>
            </div>
          </div>
        ) : batchSlot &&
          batchSlot.type === "online" &&
          !batchSlot.meeting_link ? (
          <div className="flex flex-col items-center">
            <h1 className="text-3xl font-semibold my-10">
              Please wait while the trainer sets up the video call...
            </h1>
            <div className="h-96 items-center flex">
              <Loader style={{ width: "300px" }} />
            </div>
          </div>
        ) : batchSlot &&
          batchSlot.type === "online" &&
          batchSlot.meeting_link ? (
          <div className="h-96 flex flex-col items-center">
            <h1 className="text-3xl font-semibold my-10">
              <button
                className="btn-primary text-3xl"
                onClick={() => openVideoCall(user, batchSlot)}
              >
                Click here
              </button>{" "}
              to join the video call
            </h1>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  else return null;
}
