import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 240000, // Refetch every 2 minutes by default
      staleTime: 600000, // Data is fresh for 5 minutes
      cacheTime: 1800000, // Cache cleaned after 30 minutes
    },
  },
});


export default queryClient;