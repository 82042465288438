import React, { useState, useEffect } from "react";
import {
  annualIncomeField,
  rBoolField,
  rFileField,
  rNumField,
  rStringField,
} from "components/Form/presets";
import { condObj } from "../../../../utils/func";
import _ from "lodash";
import * as yup from "yup";

// Family member's details
export const Form1 = (values) => {
  const [schema, setSchema] = useState();

  const createSchema = () => {
    const schema = {
      siblings: {
        type: "object",
        label: ({ value }) => `You have ${value?.length || 0} siblings`,
        repeat: true,
        insertable: true,
        insertableProps: {
          button: {
            label: "Add sibling",
            className: "button",
          },
        },
        repeatClassName: "repeatable-item flex flex-col items-end",
        fields: ({ value }) => ({
          id: { schema: yup.string(), type: "hidden" },
          name: rStringField("Name"),
          is_currently_working: rBoolField("Currently Working", {}),
          ...condObj(eval(value?.is_currently_working), {
            annual_income: annualIncomeField("Annual income (INR)"),
          }),
        }),
      },
    };
    setSchema(schema);
  };

  useEffect(() => createSchema(), []);

  return schema;
};

// Total family income
export const Form2 = (values) => {
  const [schema, setSchema] = useState();

  const createSchema = () => {
    const schema = {
      familyIncomeDocument: rFileField("Family income document", "Upload", {
        fileFieldProps: {
          accept: ".pdf",
        },
      }),
    };
    setSchema(schema);
  };

  useEffect(() => createSchema(), []);

  return schema;
};
