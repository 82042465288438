import React, { useEffect, useRef, useState } from "react";
import * as cocossd from "@tensorflow-models/coco-ssd";
import Webcam from "react-webcam";
import QuizToast from "./QuizTemplate/QuizToast";
import { drawRect } from "./QuizTemplate/utilities";


const  CameraObjectDetection=({ submitQuiz, webCamLoaded })=>{
   const webcamRef = useRef(null);
   const canvasRef = useRef(null);
   const [toast, setToast] = useState({ delayTimer: false });
   const [warningCount, setWarningCount] = useState(0);
   const [startDetect, setStartDetect] = useState(true);

   useEffect(() => {
     if (toast.delayTimer) {
       if (warningCount <= 3)   
        {   
         setWarningCount(warningCount + 1);

         const runDetect = () => {
           QuizToast.error(
             "More than one person found please avoid these"
           );
           setToast({
             delayTimer: false,
           });
         };

         const intervalId = setInterval(() => {
           runDetect();
         }, 4000 * 2); // in milliseconds

         return () => {
           clearInterval(intervalId);
         };
       } else {
         QuizToast.error(
           "Sorry your warning limit reached and your this attempt now automatically submitted"
         );
         setStartDetect(false);
        submitQuiz();
       }
     }
   }, [toast.delayTimer]);

   console.log("warningCount", warningCount);

   const runCoco = async () => {
     const net = await cocossd.load();
     console.log("Webcam loaded.");

      webCamLoaded();
     //  Loop and detect hands

     if (startDetect) {
       setInterval(() => {
         detect(net);
       }, 5000);
     }
   };

   const detect = async (net) => {
     // Check data is available
     if (
       typeof webcamRef.current !== "undefined" &&
       webcamRef.current !== null &&
       webcamRef.current.video.readyState === 4
     ) {
       console.log("All ok.");

       // Get Video Properties
       const video = webcamRef.current.video;
       const videoWidth = webcamRef.current.video.videoWidth;
       const videoHeight = webcamRef.current.video.videoHeight;

       // Set video width
       webcamRef.current.video.width = videoWidth;
       webcamRef.current.video.height = videoHeight;

       // Set canvas height and width
       canvasRef.current.width = videoWidth;
       canvasRef.current.height = videoHeight;

       // Make Detections
       const obj = await net.detect(video);

       console.log("obj", obj);

       let person = [];

       obj.length > 0 &&
         obj.filter((e) => {
           if (e.class === "person") {
             person.push(e.class);
           }
         });

       console.log("person", person.length);

       if (person.length >= 2) {
         setToast({
           delayTimer: true,
         });
       }

       // Draw mesh
       const ctx = canvasRef.current.getContext("2d");
       drawRect(obj, ctx);
     }
   };

   useEffect(() => {
     runCoco();
   }, []);

   const [position, setPosition] = useState({ x: 0, y: 100 });


  const handleDrag = (event) => {
    setPosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handleDragEnd = (event) => {
    // Update the position state with the new coordinates of the div
    setPosition({
      x: event.clientX,
      y: event.clientY,
    });
  };


   return (
     <>
       <div
         style={{
           position: "fixed",
           left: position.x,
           top: position.y,
           cursor:"pointer",
            width: "100",
            height: '100',
            zindex: 1
         }}
         draggable
         onDrag={handleDrag}
         onDragEnd={handleDragEnd}
       >
         <Webcam
           ref={webcamRef}
           muted={true}
           style={{
             position: "fixed",
             left: position.x,
             top: position.y,
             textAlign: "center",
             zindex: 9,
             width: 250,
             cursor:"pointer"
           }}
         />

         <canvas
           ref={canvasRef}
           style={{
             position: "fixed",
             left: position.x,
             top: position.y,
             textAlign: "center",
             zindex: 8,
             width: 208,
             height: 220,
           }}
         />
       </div>
     </>
   );
}

export default CameraObjectDetection;
