import { resolvers } from "./resources/pincode-resolvers";
import countries from "./resources/countries.json";

// Address
export const getCountries = () =>
  Promise.resolve(
    countries.map((c) => ({
      id: c["alpha-3"],
      name: c.name,
      iso: c["alpha-3"],
    }))
  );

export const getDataFromPincode = async ({ country, pincode }) => {
  return resolvers[country] && (await resolvers[country](pincode));
};

export const changeNameOnDownload = async (url, filename) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
};
