import Axios, { refreshAxios } from "./Api";
import { setLoginCookie, setJwtToken } from "../utils/Auth";
import { PROVIDERS } from "./Consts";
import userSvc from "services/User";

export const setUser = (cookie, jwt_token) => {
  setLoginCookie(cookie);
  setJwtToken(jwt_token);
  refreshAxios();
};

export const LoginUser = async (data) => {
  const response = await Axios.post("/login", data);

  if (response.data.success) {
    setUser(response.data.access_token, response.data.jwt_token);
    const isUserOnboarded = await userSvc
      .fetchTags(response.data.db_user.id)
      .then(
        (tags) =>
          !!tags.find((tag) => tag.name === "onboarded" && tag.value === "1")
      );
    response.data.is_user_onboarded = isUserOnboarded;
  }
  return response;
};

export const LogoutUser = () => setUser("", "");

export const SignupUser = async (data) => {
  const response = await Axios.post("/signup", data);

  if (response.data.success)
    setUser(response.data.access_token, response.data.jwt_token);
  return response;
};

export const LoginUserWithProvider = async (provider, payload) => {
  payload =
    provider === PROVIDERS.GOOGLE
      ? { type: "google", auth_token: payload.tokenId }
      : { type: "facebook", auth_token: payload.accessToken };
  const response = await Axios.post("/social_auth", payload);
  if (response.data.success) {
    setUser(response.data.access_token, response.data.jwt_token);
    const isUserOnboarded = await userSvc
      .fetchTags(response.data.db_user.id)
      .then(
        (tags) =>
          !!tags.find((tag) => tag.name === "onboarded" && tag.value === "1")
      );
    response.data.is_user_onboarded = isUserOnboarded;
  }
  return response;
};
