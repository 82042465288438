/* eslint-disable jsx-a11y/alt-text */
import Loader from "assets/animated-images/Loader";
import React from "react";

export default function ExpandedLoader(props) {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <Loader className="h-20" />
    </div>
  );
}
