import React, { useState, useRef } from "react";
import { Signup } from "../../../redux/actions/Auth";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SocialAuthOptions from "./Login/SocialAuthOptions";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Recaptcha from "react-google-recaptcha";
import Toast from "wrappers/Toast";
import Loader from "assets/animated-images/Loader";
import Axios from "api/Api";
import { emailRegex, phoneNumberRegex } from "api/Consts";

const SignUpComponent = (props) => {
  const onChange = (value) => {
    let captcha_token = value;
    console.log("value===", captcha_token);
    setInput({ ...input, captcha_token });
  };

  const [loaderOn, setLoaderOn] = useState(false);
  const [input, setInput] = useState({
    full_name: "",
    id: "",
    password: "",
    coupon_code: "",
    captcha_token: "",
  });
  const REACT_APP_SITE_KEY = "6Lf4LCsjAAAAAEOfqNnxS246-BDK8XXtGqcZUDcu";

  const [passWordErrorText, setPassWordErrorText] = useState("");

  const onInputChange = (e) => {
    const dataKey = e.target.name;

    if (dataKey == "password") {
      const password = e.target.value;
      const re = new RegExp(/^[\S]{5,30}$/, "g");
      const passwordMatches = password.match(re);

      if (!passwordMatches) {
        setPassWordErrorText(
          "Password length should be min 5 characters and max 30 without any spaces"
        );
      } else {
        setInput({ ...input, [e.target.name]: e.target.value });
        setPassWordErrorText("");
      }
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const error = useSelector(({ auth }) => auth.signup_error);
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaderOn(true);

    /*
     *
     *
     * Checking and confirming force create
     *
     *
     */
    let loader = Toast.load("Please wait...");
    const email =
      emailRegex.test(input.id.toLowerCase()) && input.id.toLowerCase();
    const mobile_number = phoneNumberRegex.test(input.id) && input.id;

    const traineeSearchResponse = await Axios.post("/search_trainees", {
      email,
      mobile_number,
    }).then(({ data }) => data);
    const { data: duplicateTrainees } = traineeSearchResponse;
    Toast.endLoader(loader);

    if (duplicateTrainees?.length) {
      const forceCreate = await Toast.confirm(
        <DuplicateTraineesMessage duplicateTrainees={duplicateTrainees} />
      );
      if (!forceCreate) return;
    }

    /*
     *
     *
     * Registering user
     *
     *
     */
    if (emailRegex.test(input.id) || phoneNumberRegex.test(input.id)) {
      dispatch(Signup({ ...input })).then(() => {
        setLoaderOn(false);
      });
    } else {
      Toast.error("Invalid Email or Phone Number");
      setLoaderOn(false); 
    }
  };

  return (
    <div className="col-span-3 md:col-span-1 bg-white shadow-xl w-full rounded-lg p-10 z-1">
      <h1 className="text-2xl font-semibold text-japanese_indigo">Sign Up</h1>
      {props.notice && (
        <div className="w-full text-center px-4 py-2 rounded-md bg-yellow-100 text-orange-500 mt-3">
          {props.notice}
        </div>
      )}
      <p className="text-xs my-5 text-gray-600">
        Already have an account?&nbsp;
        <Link to="/login" className="text-orange">
          Login
        </Link>
      </p>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="flex flex-wrap -mx-3">
          <div className="w-full  px-3 mb-6">
            <label
              className="block tracking-wide text-japanese_indigo text-xs mb-2"
              htmlFor="grid-full-name"
            >
              Full Name*
            </label>
            <input
              className="input"
              id="grid-full-name"
              type="text"
              placeholder="Your Full Name"
              name="full_name"
              onChange={onInputChange}
            />
            {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
          </div>
          {/* <div className="w-full sm:w-1/2 px-3 mb-6">
            <label
              className="block tracking-wide text-japanese_indigo text-xs mb-2"
              htmlFor="grid-last-name"
            >
              Last Name*
            </label>
            <input
              className="input"
              id="grid-last-name"
              type="text"
              placeholder="Your Last Name"
              name="last_name"
              onChange={onInputChange}
            />
          </div> */}
        </div>
        <div className="flex flex-wrap -mx-3">
          <div className="w-full px-3 sm:w-1/2 mb-6">
            <label
              className="block tracking-wide text-japanese_indigo text-xs mb-2"
              htmlFor="grid-id"
            >
              Email or Phone number *
            </label>
            <input
              className="input"
              id="grid-id"
              type="text"
              placeholder="Your Email or Phone Number"
              name="id"
              data-key="password"
              onChange={onInputChange}
            />
            {/* <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
          </div>
          <div className="w-full px-3 sm:w-1/2 mb-6 relative">
            <label
              className="block tracking-wide text-japanese_indigo text-xs mb-2"
              htmlFor="grid-password"
            >
              Password
            </label>
            {showPassword ? (
              <BsEye
                onClick={() => setShowPassword(false)}
                className="absolute mt-4 right-6 opacity-60"
              />
            ) : (
              <BsEyeSlash
                onClick={() => setShowPassword(true)}
                className="absolute mt-4 right-6 opacity-60"
              />
            )}

            <input
              className="input"
              id="grid-password"
              type={showPassword ? "text" : "password"}
              placeholder="********"
              name="password"
              onChange={onInputChange}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label
              className="block tracking-wide text-japanese_indigo text-xs mb-2"
              htmlFor="grid-password"
            >
              Skillstrainer Coupon (If any)
            </label>
            <input
              className="input"
              type="text"
              placeholder="Skillstrainer Coupon"
              name="coupon_code"
              onChange={onInputChange}
            />
          </div>
        </div>
        {error && <h2 className="text-orange">{error}</h2>}
        {passWordErrorText.length > 0 && (
          <h2 className="text-orange">{passWordErrorText}</h2>
        )}
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <button
              className="w-full mt-4 font-semibold bg-orange text-white rounded-md p-2 text-lg"
              type="submit"
            >
              {!loaderOn ? (
                <div className="h-8">Create an account</div>
              ) : (
                <Loader color="#fff" className="h-8 mx-auto" />
              )}
            </button>
          </div>
        </div>
      </form>
      <SocialAuthOptions />
      <Recaptcha
        sitekey={REACT_APP_SITE_KEY}
        onChange={onChange}
        className="g-recaptcha mt-6"
        name="captcha_token"
      />
    </div>
  );
};

export default SignUpComponent;

const DuplicateTraineesMessage = ({ duplicateTrainees }) => (
  <div>
    <p>We have found trainees with the same email / phone number:</p>
    <ul>
      {duplicateTrainees.map((dt) => (
        <li key={dt.username}>
          <span className="font-semibold">{dt.name.familyName}</span> (
          {dt.username})
        </li>
      ))}
    </ul>

    <p>Force create a new user?</p>
  </div>
);
