import { ActionTypes } from "redux/actions/Quiz/ActionTypes";

let initialQuiz = {
  quiz: {
    config: {
      allowBack: true,
      allowReview: true,
      autoMove: true, // if true, it will move to next question automatically when answered.
      duration: 3600, // indicates the time in which quiz needs to be completed. 0 means unlimited.
      pageSize: 1,
      requiredAll: false, // indicates if you must answer all the questions before submitting.
      richText: false,
      shuffleQuestions: false,
      shuffleOptions: false,
      showClock: true,
      showPager: true,
      theme: "none",
    },
    questions: [],
  },
  questionAnswered: {},
  mode: "quiz",
  pager: {
    index: 0,
    size: 1,
    count: 1,
  },

  QuizMode: true,
  ScormFullScreen: false,
  QuizUserCount: 0,

  timer: {
    QuizTimer: 50000,
  },
};

export const quiz = (state = { ...initialQuiz }, action) => {
  switch (action.type) {
    case ActionTypes.PagerUpdate:
      return {
        ...state,
        pager: action.payload,
        mode: "quiz",
      };

    case ActionTypes.TimerUpdate:
      return {
        ...state,
        timer: action.payload,
      };

    case ActionTypes.QuizLoad:
      return {
        ...state,
        quiz: action.payload,
      };
    case ActionTypes.QuizSubmit:
      return {
        ...state,
        mode: action.payload,
      };
    case ActionTypes.QuizAnswer:
      return {
        ...state,
        quiz: action.payload.quiz,
        questionAnswered: {
          ...state.questionAnswered,
          ...action.payload.questionAnswered,
        },
      };

    case ActionTypes.QuizWindowStart:
      return {
        ...state,
        QuizMode: action.payload,
      };

    case ActionTypes.ResetQuiz:
      return initialQuiz;

    case ActionTypes.ScormFullScreen:
      return {
        ...state,
        ScormFullScreen: action.payload,
      };

    case ActionTypes.ResetQuizState:
      return {
        ...state,
        mode: "blank",
      };

    default:
      return state;
  }
};
