import axios from "./Api";

export function addPayoffDetails(data) {
  return axios.post("/payoff_details", data);
}

export function updatePayoffDetails(data) {
  return axios.put("/update_payoff_details", data);
}

export function getRedeemableAmount(userId) {
  return axios.get(`/redeemable_amount?user_id=${userId}`);
}

export function redeemRewards(data) {
  return axios.post("/redeem_rewards", data);
}
