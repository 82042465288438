import { toast } from "react-toastify";

const QuizToast = {
  success: (msg = "Success!") =>
    toast.success(msg, {
      position: "top-center",
      closeOnClick: true,
      autoClose: 1000,
    }),
  error: (msg = "An error occured") =>
    toast.error(msg, {
      position: "top-center",
      closeOnClick: true,
      autoClose: 2000,
    }),
};

export default QuizToast;
