import React, { useState, useEffect } from "react";
import Container from "components/Container";
import { useSelector } from "react-redux";
import courseSvc from "services/Course";
import CourseCard from "components/CourseCard";
import _ from "lodash";

const Dashboard = () => {
  const { auth, course } = useSelector(({ auth, course }) => ({
    auth,
    course,
  }));
  const user = auth.user.db_user;

  // FETCHING COURSES AND CATEGORIES
  const [courses, setCourses] = useState();
  const [categories, setCategories] = useState();

  const fetchCourses = async () => {
    if (user.partner_id)
      setCourses(await courseSvc.getCoursesByPartner(user.partner_id));
    else setCourses(await courseSvc.getDefaultCourses());
  };

  const fetchCourseCategories = () =>
    courseSvc.getDefaultCategories().then(setCategories);

  useEffect(() => {
    fetchCourses();
    fetchCourseCategories();
  }, []);
  // :FETCHING COURSES AND CATEGORIES

  const [isSearchingCoursesResult, setIsSearchingCoursesResult] = useState();
  const [coursesResult, setCoursesResult] = useState({});

  return (
    <Container>
      <div className="p-4">
        {!_.isEmpty(course.active_courses) && (
          <div className="mb-10">
            <div className="text-2xl font-semibold mb-5">Active courses</div>
            <div className="courses-list">
              {Object.values(course.active_courses)
                .filter(
                  (course) =>
                    !isSearchingCoursesResult || coursesResult[course.id]
                )
                .map((course) => (
                  <CourseCard
                    className="cursor-pointer"
                    onClick={() =>
                      courseSvc.openCourse({
                        moodle_course_id: course.moodle_course_id,
                        courseId: course.id,
                      })
                    }
                    data={course}
                  />
                ))}
            </div>
          </div>
        )}
        <div>
          <div className="text-2xl font-semibold mb-5">Recommended courses</div>
          <div className="courses-list">
            {courses &&
              courses.map((course, idx) => (
                <CourseCard
                  className="cursor-pointer"
                  onClick={() =>
                    courseSvc.openCourse({
                      moodle_course_id: course.moodle_course_id,
                      courseId: course.id,
                    })
                  }
                  data={course}
                />
              ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
