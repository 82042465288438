import { gql } from "@apollo/client";

export const INSERT_USER_COURSE_QUESTION_ATTEMPT = gql`
  mutation MyMutation(
    $mapping_id: Int
    $question_id: Int
    $question_correct_answer: String
    $response: Boolean
    $user_id: Int
    $marks: Int
    $attempt_quiz_id: Int
    $course_id: Int
    $user_answer_text: String
    $answer_id: Int
  ) {
    insert_courses_user_course_question_attemept(
      objects: {
        mapping_id: $mapping_id
        question_id: $question_id
        question_correct_answer: $question_correct_answer
        response: $response
        user_id: $user_id
        marks: $marks
        attempt_quiz_id: $attempt_quiz_id
        course_id: $course_id
        user_answer_text: $user_answer_text
        answer_id: $answer_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_USER_COURSE_QUIZ_ATTEMPT = gql`
  mutation InsertQuizAttempt(
    $attempt_completed: Boolean
    $attempt_number: Int
    $attempt_start: timestamptz
    $course_id: Int
    $mapping_id: Int
    $user_id: Int
  ) {
    insert_courses_user_course_quiz_attempt(
      objects: {
        attempt_completed: $attempt_completed
        attempt_number: $attempt_number
        attempt_start: $attempt_start
        course_id: $course_id
        mapping_id: $mapping_id
        user_id: $user_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export const UPDATE_USER_QUIZ_ATTEMPT = gql`
  mutation MyMutation(
    $attempt_completed: Boolean
    $attempt_end: timestamptz
    $attempt_percentage: Int
    $course_id: Int
    $mapping_id: Int
    $max_marks: Int
    $total_score: Int
    $user_id: Int
    $result: String
    $id: Int
  ) {
    update_courses_user_course_quiz_attempt(
      where: { id: { _eq: $id } }
      _set: {
        attempt_completed: $attempt_completed
        attempt_end: $attempt_end
        attempt_percentage: $attempt_percentage
        total_score: $total_score
        max_marks: $max_marks
        user_id: $user_id
        mapping_id: $mapping_id
        course_id: $course_id
        result: $result
      }
    ) {
      returning {
        id
        attempt_percentage
        attempt_completed
      }
    }
  }
`;
