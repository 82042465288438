import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { Login, SET_SIGNUP_NOTICE } from "redux/actions/Auth";
import _ from "lodash";
import Toast from "wrappers/Toast";

export default function RouteActionsSwitch(props) {
  const { actions } = props;

  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const dispatch = useDispatch();

  const [isAuthenticated, isOnboarding, user] = useSelector(({ auth }) => [
    auth.authenticated,
    auth.onboarding,
    auth.user?.db_user,
  ]);

  const [currentActions, setCurrentActions] = useState([]);

  useEffect(() => {
    if (params && params.action) {
      // Register action
      const action = actions.find((action) => action.type === params.action);
      if (action) {
        currentActions.push({ action, params: _.cloneDeep(params) });
        setCurrentActions([...currentActions]);
        history.push({ search: "" });
      }
    }

    // Handle login
    const loginId = params._id?.trim();
    const loginPassword = params._password?.trim();
    if (loginId && loginPassword) {
      const loader = Toast.load("Logging you in. Please wait...");
      dispatch(
        Login(
          { id: loginId, password: loginPassword },
          {
            onSuccess: () => {
              Toast.endLoader(loader);
            },
            onError: () => {
              Toast.error("Couldn't log you in");
              Toast.endLoader(loader);
            },
          }
        )
      );
      history.push({ search: "" });
    }
  }, [location.search]);

  useEffect(() => {
    // Handling signup notice
    if (currentActions.filter(({ action }) => action.isProtected).length) {
      dispatch({
        type: SET_SIGNUP_NOTICE,
        payload:
          currentActions[0]?.action?.promptMessage ||
          "Please login or signup to continue",
      });
    } else
      dispatch({
        type: SET_SIGNUP_NOTICE,
        payload: null,
      });
  }, [currentActions.length]);

  return (
    <>
      {currentActions.map(({ action, params }, index) => {
        console.log(
          "Before route action is fired",
          action.isProtected,
          isAuthenticated,
          isOnboarding
        );
        if (
          !action.isProtected ||
          (action.isProtected &&
            isAuthenticated === true &&
            isOnboarding === false)
        )
          return (
            <action.component
              params={params}
              user={user}
              complete={() => {
                currentActions.splice(index, 1);
                setCurrentActions([...currentActions]);
              }}
            />
          );
      })}
    </>
  );
}
