import { gql } from "@apollo/client";
import { BATCH_SLOT_COLUMNS, COUPON_COLUMNS } from "graphql/queries/Courses";

export const INSERT_USER_COUPON = gql`
  mutation InsertUserCoupon($userId: bigint, $couponId: uuid) {
    insert_courses_st_user_coupons(
      objects: { user_id: $userId, coupon_id: $couponId }
    ) {
      returning {
        user_id
        coupon_id
        coupon: st_coupons_configuration {
          ${COUPON_COLUMNS}
        }
      }
    }
  }
`;

export const INSERT_USER_BATCH_SLOT = gql`
  mutation insertUserBatchSlot($objects: [courses_user_batch_slots_insert_input!]!) {
    insert_courses_user_batch_slots(
      objects: $objects
    ) {
      returning {
        id
        batch_slot {
          ${BATCH_SLOT_COLUMNS}
        }
      }
    }
  }
`;

export const UPDATE_USER_BATCH_SLOT = gql`
  mutation updateUserBatchSlot(
    $user_id: bigint
    $current_batch_slot_id: bigint
    $new_batch_slot_id: bigint
  ) {
    update_courses_user_batch_slots(
      where: {
        user_id: { _eq: $user_id }
        batch_slot_id: { _eq: $current_batch_slot_id }
      }
      _set: { batch_slot_id: $new_batch_slot_id }
    ) {
      returning {
        id
        user_id
        batch_slot_id
      }
    }
  }
`;

export const INSERT_USER_ATTENDANCE = gql`
  mutation (
    $batch_id: Int
    $slot_id: Int
    $user_id: Int
    $attendance_status: Boolean
  ) {
    insert_courses_batch_trainee_attendences(
      objects: {
        batch_id: $batch_id
        slot_id: $slot_id
        user_id: $user_id
        attendance_status: $attendance_status
      }
    ) {
      affected_rows
      returning {
        id
        batch_id
        slot_id
        user_id
        attendance_status
      }
    }
  }
`;

export const DELETE_BATCH = gql`
  mutation deleteBatch($batch_ids: [Int!]) {
    user_batch_slots: delete_courses_user_batch_slots(
      where: { batch_slot: { batch: { id: { _in: $batch_ids } } } }
    ) {
      affected_rows
    }

    user_batch_enrollments: delete_courses_user_batch_enrollment(
      where: { batch: { id: { _in: $batch_ids } } }
    ) {
      affected_rows
    }

    batch_slots: delete_courses_batch_slots(
      where: { batch_id: { _in: $batch_ids } }
    ) {
      affected_rows
    }

    batch_relations: delete_courses_coupon_batch_relations(
      where: { batch_id: { _in: $batch_ids } }
    ) {
      affected_rows
    }

    batches: delete_courses_course_batches(where: { id: { _in: $batch_ids } }) {
      affected_rows
    }
  }
`;
