import Container from "components/Container";
import React, { useEffect, useState, Fragment } from "react";
import liveClassService from "services/liveClass";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineDownload } from "react-icons/ai";

import { FetchCourseLesson } from "redux/actions/Course";
import { s3BaseUrl } from "api/Consts";

export default function ResourceView(props) {
  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const {
    lessonName,
    completion_criteria,
    courseName,
    module_resources,
    course_id,
    description,
    user_id,
  } = props.modData;

  useEffect(() => {
    if (props.modData?.completion_criteria === 2) {
      markComplete(props.modData);
    }
  }, [props.modData]);

  const markComplete = async (data) => {
    console.log(
      "checkCourseCompleteAudit",
      "In ResourceView.js, invoked markComplete"
    );
    if (data.completionStatus) return false;

    console.log(
      "checkCourseCompleteAudit",
      "Calling liveClassService.ManuallyMarkComplete"
    );
    await liveClassService.ManuallyMarkComplete(data, userId);

    console.log("checkCourseCompleteAudit", "Dispatching FetchCourseLesson");
    dispatch(FetchCourseLesson(data.course_id, userId));
  };

  const downloadFile = async (file_name) => {
    window.open(s3BaseUrl + "/" + file_name, "_blank");
  };

  return (
    <Container>
      <div className="p-2">
        <h1 className="text-4xl text-center font-semibold leading-10 text-japanese_indigo mb-6">
          {lessonName}
        </h1>
        <h1 className="font-bold text-center my-4 text-xl  text-japanese_indigo">
          Downlaod Resources
        </h1>
      </div>
      <div className="w-full bg-white rounded-lg shadow-lg">
        <ul className="divide-y-2 divide-gray-400 border-b	border-black-500  bg-gray-300">
          {(module_resources || []).map((e, i) => {
            return (
              <li
                key={e.id}
                className="flex justify-between p-3 hover:bg-orange hover:text-white"
              >
                {e.actual_file_name}
                <div className="flex justify-end gap-3 p-3 hover:bg-orange hover:text-white">
                  <AiOutlineDownload
                    className="cursor-pointer mr-0"
                    size={22}
                    onClick={() => downloadFile(e.file_name)}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Container>
  );
}
