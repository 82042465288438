import { FILTERS } from "graphql/queries/Jobs";
import MultiSelectFilter from "./multiselectfilter";
import { MULTI_SELECT_JOBS_FILTERS, SOCIAL_CATEGORY_NAMES } from "api/Consts";
import SelectLocation from "./selectlocation";
import SalarySlider from "./salaryslider";

export default function AllJobFilters() {
  const MULTI_SELECT_FILTER_OPTIONS_DETAILS = [
    {
      query: FILTERS.GET_LANGUAGES,
      table: "courses_skillstrainer_languages",
      filtername: MULTI_SELECT_JOBS_FILTERS.LANGUAGES,
    },
    {
      options: Object.keys(SOCIAL_CATEGORY_NAMES)
        .sort((key1, key2) => {
          if (SOCIAL_CATEGORY_NAMES[key1] > SOCIAL_CATEGORY_NAMES[key2])
            return 1;
          return -1;
        })
        .map((key) => ({ id: key, name: SOCIAL_CATEGORY_NAMES[key] })),
      table: "courses_skillstrainer_skills",
      filtername: MULTI_SELECT_JOBS_FILTERS.SOCIAL_CATEGORIES,
    },
    {
      query: FILTERS.GET_PARTNERS,
      table: "courses_partner",
      filtername: MULTI_SELECT_JOBS_FILTERS.PARTNER,
    },
    {
      query: FILTERS.GET_QUALIFICATIONS,
      table: "courses_qualifications_lookup",
      filtername: MULTI_SELECT_JOBS_FILTERS.QUALIFICATIONS,
    },
    {
      query: FILTERS.GET_SKILLS,
      table: "courses_skillstrainer_skills",
      filtername: MULTI_SELECT_JOBS_FILTERS.SKILLS,
    },
    {
      query: FILTERS.GET_SECTOR,
      table: "courses_course_categories",
      filtername: MULTI_SELECT_JOBS_FILTERS.SECTOR,
    },
  ];

  return (
    <>
      <div class="w-full flex mx-auto my-2">
        <div class="w-full h-full">
          <div className="flex flex-row flex-wrap justify-center gap-2">
            <SelectLocation />

            {MULTI_SELECT_FILTER_OPTIONS_DETAILS.map((filterDetails) => {
              return (
                <MultiSelectFilter
                  key={filterDetails.filtername}
                  filterDetails={filterDetails}
                />
              );
            })}

            <SalarySlider />
          </div>
        </div>
      </div>
    </>
  );
}
