import React, { Component } from "react";

export default class Review extends Component {
  isAnswered = (q) => {
    return q.quest_answer.some((x) => x.selected) ? "Answered" : "Not Answered";
  };

  render() {
    return (
      <div className="md:max-w-lg  ">
        <div className="md:w-80">
          {this.props.quiz.courses_questions &&
            this.props.quiz.courses_questions.map((q, index) => (
              <div
                key={q.id}
                className=" cursor-pointer mb-2  inline-block p-2  "
              >
                {/* <div
									id={index}
									onClick={this.props.move}
									className={`p-3 mb-2 ${
										this.isAnswered(q) === 'Answered'
											? 'bg-green-400'
											: 'bg-yellow-400'
									}`}
								>
									{index + 1}. {this.isAnswered(q)}
								</div> */}

                <div className="w-10 h-7 rounded-md  ">
                  <div
                    id={index}
                    className={` bg-gray-400 w-7 h-7   text-white text-center p-1  ${
                      this.isAnswered(q) === "Answered"
                        ? "bg-green-400"
                        : "bg-gray-400"
                    }`}
                    onClick={this.props.move}
                  >
                    {" "}
                    {index + 1}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="m-2">
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs  mt-2 font-bold leading-none text-red-100 bg-yellow-400 rounded-md">
            &nbsp;
          </span>
          Not Answered &nbsp;
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs  mt-2 font-bold leading-none text-red-100 bg-green-400 rounded-md">
            &nbsp;
          </span>
          Answered
          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 ml-1 text-xs  mt-2 font-bold leading-none text-red-100 bg-gray-400 rounded-md">
            &nbsp;
          </span>
          not visited
        </div>
      </div>
    );
  }
}
