import { gql } from "@apollo/client";

export const GET_COURSE_SECTION = gql`
  query getcoursesection($course_id: Int) {
    courses_course_section(
      where: { course_id: { _eq: $course_id }, visible: { _eq: true } }
      order_by: { order: asc }
    ) {
      name
      id
      course_id
      coursesec(where: { visible: { _eq: true } }, order_by: { order: asc }) {
        name
        description
        mod_id
        id
        publish_url
        section_id
        completion_percentage
        completion_criteria
        course_id
        Module {
          name
          type
        }
      }
    }
  }
`;

export const GET_COURSE_SECTION_MODULE = gql`
  query getSectionModule($course_id: Int, $section_id: Int) {
    courses_course_module_mapping(
      where: {
        course_id: { _eq: $course_id }
        section_id: { _eq: $section_id }
      }
    ) {
      id
      name
      description
      section_id
      Module {
        name
        id
      }
      section {
        name
        id
      }
    }
  }
`;

export const GET_COURSE_MAPPING_DATA = gql`
  query MyQuery($id: Int) {
    courses_course_module_mapping(where: { id: { _eq: $id } }) {
      id
      course_id
      mod_id
      section_id
      completion_criteria
      completion_percentage
      name
      require_webcam
      scorm_track_data {
        lesson_status
        mapping_id
        mod_id
        score_max
        score_min
        score_raw
        session_time
        success_status
        suspend_data
        user_id
        attempt_id
        scorm_attempt {
          attempt_completed
          attempt_end
          attempt_number
          attempt_start
          course_id
          id
          mapping_id
          user_id
        }
      }
      scorm_completion_criteria {
        passed
        completed
        scorm_version
        mapping_id
      }
      publish_url
      maxattempt
      moodle_published
      attemtsgrade
      course_module {
        full_name
      }
      enable_timer
      timelimit_number
      description
      module_resources {
        actual_file_name
        file_name
        mapping_id
        id
      }
    }
  }
`;

export const CHECK_USER_SCORM_STATUS = gql`
  query checkUserScormStatus(
    $mapping_id: Int
    $user_id: Int
    $attempt_id: Int = 10
  ) {
    courses_course_scorm_track_data(
      where: {
        mapping_id: { _eq: $mapping_id }
        user_id: { _eq: $user_id }
        attempt_id: { _eq: $attempt_id }
      }
    ) {
      id
    }
  }
`;

export const CHECK_MOD_TYPE = gql`
  query checkModType($mod_id: Int) {
    courses_course_module_mapping(where: { mod_id: { _eq: $mod_id } }) {
      Module {
        type
      }
    }
  }
`;

export const CHECK_USER_COMPLETION_BY_SECTION_TOPIC = gql`
  query checkUserCompletionBySection(
    $user_id: Int
    $section_id: Int
    $mapping_id: Int
  ) {
    courses_course_module_completion(
      where: {
        user_id: { _eq: $user_id }
        section_id: { _eq: $section_id }
        mapping_id: { _eq: $mapping_id }
      }
    ) {
      user_id
    }
  }
`;

export const GET_COURSE_CERTIFICATE_TEMP = gql`
  query getCertificateTemplate($mapping_id: Int, $course_id: Int) {
    courses_course_certificate(
      where: {
        mapping_id: { _eq: $mapping_id }
        course_id: { _eq: $course_id }
      }
    ) {
      cert_id
      cert_enable_criteria
      Course {
        full_name
        nsqf_level
      }
      certificate_relation {
        certificate_name
        certificate_url
        director_name
        partnerlogo1
        id
        partnerlogo2
        partnerlogo3
      }
    }
  }
`;

export const GET_MODULE_RESTRICTION = gql`
  query ModuleRestriction($mapping_id: Int) {
    courses_module_completion_resrtriction(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      restriction_ids
    }
  }
`;

export const GET_RESTRICTION_COMPLETION_STATUS = gql`
  query GetRestrictionCompletionStatus($mapping_id: [Int!], $user_id: Int) {
    courses_course_module_completion(
      where: { user_id: { _eq: $user_id }, mapping_id: { _in: $mapping_id } }
    ) {
      id
      mapping_id
      completion_status
    }
  }
`;

// export const GET_USER_ATTEMPT=gql`
// query MyQuery($mapping_id: Int, $course_id: Int, $user_id: Int) {
//   courses_course_scorm_track_data(where: {
//     mapping_id: {_eq: $mapping_id},
//     course_id: {_eq: $course_id},
//     user_id: {_eq: $user_id}})
//     {
//     scorm_attempt {
//       attempt_completed
//       attempt_end
//       attempt_number
//       attempt_start
//       course_id
//       id
//       mapping_id
//       user_id
//     }
//   }
// }
// `

export const INSERT_USER_ATTEMPT = gql`
  mutation InsertUserAttempt(
    $attempt_completed: Boolean
    $attempt_end: timestamptz
    $attempt_number: Int
    $attempt_start: timestamptz
    $course_id: Int
    $mapping_id: Int
    $user_id: Int
  ) {
    insert_courses_course_module_user_attempt(
      objects: {
        attempt_completed: $attempt_completed
        attempt_end: $attempt_end
        attempt_number: $attempt_number
        attempt_start: $attempt_start
        course_id: $course_id
        mapping_id: $mapping_id
        user_id: $user_id
      }
      on_conflict: {
        constraint: course_module_user_attempt_user_id_course_id_mapping_id_attempt
      }
    ) {
      returning {
        id
        attempt_number
      }
    }
  }
`;

export const UPDATE_USER_ATTEMPT = gql`
  mutation MyMutation(
    $id: Int
    $attempt_completed: Boolean
    $attempt_end: timestamptz
  ) {
    update_courses_course_module_user_attempt(
      where: { id: { _eq: $id } }
      _set: { attempt_completed: $attempt_completed, attempt_end: $attempt_end }
    ) {
      returning {
        id
      }
    }
  }
`;

export const GET_USER_MODULE_ATTEMPT = gql`
  query MyQuery($course_id: Int, $mapping_id: Int, $user_id: Int) {
    courses_course_module_user_attempt(
      where: {
        course_id: { _eq: $course_id }
        mapping_id: { _eq: $mapping_id }
        user_id: { _eq: $user_id }
      }
    ) {
      id
      attempt_completed
      attempt_end
      attempt_number
      attempt_start
      course_id
      mapping_id
      user_id
    }
  }
`;

export const GET_SCORM_TRACK_DATA_BY_ATTEMPT = gql`
  query GetScormByAttempt($attempt_id: Int) {
    courses_course_scorm_track_data(
      where: { attempt_id: { _eq: $attempt_id } }
    ) {
      suspend_data
      success_status
      session_time
      score_raw
      score_min
      score_max
      mod_id
      mapping_id
      lesson_status
      last_intersection
      course_id
      attempt_id
      user_id
    }
  }
`;

export const GET_COURSE_ENROLLMENTID = gql`
  query GetUserEnrollmentId($course_id: Int, $user_id: Int) {
    courses_user_course_enrolment(
      where: { course_id: { _eq: $course_id }, user_id: { _eq: $user_id } }
    ) {
      id
      user_id
    }
  }
`;

export const GET_COURSE_COMPLETION_TOPICS = gql`
  query GetCourseCompletionTopics($course_id: Int) {
    courses_course_completion_criteria(
      where: { course_id: { _eq: $course_id } }
    ) {
      mapping_id
      id
    }
  }
`;

export const GET_USER_COURSE_COMPLETED_TOPICS = gql`
  query GetUserCompletedTopics($course_id: Int, $user_id: Int) {
    courses_course_module_completion(
      where: { course_id: { _eq: $course_id }, user_id: { _eq: $user_id } }
    ) {
      mapping_id
      course_id
    }
  }
`;

export const GET_COURSE_LESSON_AND_TOPICS = gql`
  query getUserCourseProgress($course_id: Int, $user_id: Int) {
    courses_course_section(
      where: { course_id: { _eq: $course_id }, visible: { _eq: true } }
      order_by: { order: asc }
    ) {
      id
      name
      coursesec(order_by: { order: asc }, where: { visible: { _eq: true } }) {
        name
        id
        section_id
        course_id
        course_complete_module_array(where: { user_id: { _eq: $user_id } }) {
          completion_status
          mapping_id
          completion_date
          user_id
        }
        completion_criteria
        restriction {
          restriction_ids
        }
        resources {
          file_name
          name
          mapping_id
        }
      }
    }
  }
`;

export const GET_USER_COURSE_PROGRESS = gql`
  query MyQuery($course_id: Int, $user_id: Int) {
    courses_course_module_mapping(where: { course_id: { _eq: $course_id } }) {
      id
    }
    courses_course_module_completion(
      where: { user_id: { _eq: $user_id }, course_id: { _eq: $course_id } }
    ) {
      id
    }
  }
`;

export const GET_COURSE_COMPLETION_DESC = gql`
  query MyQuery($id: bigint) {
    courses_course(where: { id: { _eq: $id } }) {
      course_completion_desc
    }
  }
`;
