import React, { useEffect } from "react";
import courseSvc from "services/Course";

export default function RedirectToMoodleCourse(props) {
  const { user, params, complete } = props;

  useEffect(() => {
    (async () => {
      courseSvc.openCourse({ courseId: params.course_id });
      complete();
    })();
  }, [params, user]);

  return null;
}
