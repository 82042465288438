import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import React, { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  GET_COURSE_BATCHES,
  GET_USER_BATCH_ENROLLMENT_BY_COURSE_ID,
} from "graphql/queries/Courses";
import { useHistory } from "react-router-dom";
import courseSvc from "services/Course";
import SelectBatch from "./SelectBatch";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Modal } from "skillstrainer-resource-library";
import moment from "moment";
import Axios from "api/Api";
import Toast from "wrappers/Toast";
import { useFetchCourseBatches } from "hooks/courseBatches";

export default function OngoingCourseListItem(props) {
  const { user, course } = props;
  const { id: userId } = user;

  const courseHasLiveClasses = course.course_type > 1;

  const history = useHistory();

  /*
   *
   *
   * Modal state
   *
   *
   */
  const [modalState, setModalState] = useState({
    open: false,
    type: "",
  });
  const MODAL_STATE_TYPE = {
    SELECT_BATCHES: "select-batches",
    SHOW_CALENDAR: "show-calendar",
  };
  const openCalendar = () =>
    setModalState({ type: MODAL_STATE_TYPE.SHOW_CALENDAR, open: true });
  const openBatchSelector = () =>
    setModalState({ type: MODAL_STATE_TYPE.SELECT_BATCHES, open: true });

  /*
   *
   *
   * Enrol in a batch
   *
   *
   */
  const { data: courseBatchesRes } = useQuery(GET_COURSE_BATCHES, {
    variables: { courseId: course.id },
    fetchPolicy: "cache-only"
  });


  const availableCourseBatches = useMemo(
    () =>
      courseBatchesRes &&
      courseBatchesRes.courses_course_batches.length &&
      courseBatchesRes.courses_course_batches.filter(
        (batch) => new Date().getTime() < new Date(batch.from_date).getTime()
      ),
    [courseBatchesRes]
  );
  const chooseBatch = ({ batch_id }) =>
    Axios.post("/enrol-in-batch", { batch_id })
      .then((res) => {
        console.log(res);
        Toast.success("Successfully enrolled in batch");
      })
      .catch(toastError);

  /*
   *
   *
   * Batch enrollment details
   *
   *
   */
  const { data: userCourseBatchEnrollmentsRes } = useQuery(
    GET_USER_BATCH_ENROLLMENT_BY_COURSE_ID,
    {
      variables: { user_id: userId, course_id: course.id },
    }
  );
  const userCourseBatchEnrollment =
    userCourseBatchEnrollmentsRes?.courses_user_batch_enrollment[0];

  const { batch } = userCourseBatchEnrollment || {};

  const batchSlotsAsEvents = useMemo(() => {
    if (userCourseBatchEnrollment)
      return userCourseBatchEnrollment.Batch_slots.map((bs) => ({
        allDay: true,
        title: bs.type === "offline" ? "Offline" : "Online",
        start: new Date(bs.slot_date),
        end: new Date(bs.endto_date),
      }));
  }, [userCourseBatchEnrollment]);

  /*
   *
   *
   * Upcoming batch slot
   *
   *
   */
  const upcomingBatchSlot = useMemo(() => {
    const batchSlots =
      (userCourseBatchEnrollment && userCourseBatchEnrollment.Batch_slots) ||
      [];

    return (
      batchSlots &&
      batchSlots.find(
        (bs) =>
          new Date().getTime() < new Date(bs.slot_date).getTime() ||
          new Date().getTime() < new Date(bs.endto_date).getTime()
      )
    );
  }, [userCourseBatchEnrollment]);

  console.log(upcomingBatchSlot);

  const nearestDate =
    upcomingBatchSlot && new Date(upcomingBatchSlot.slot_date);

  const isUpcomingBatchAvailable =
    upcomingBatchSlot &&
    new Date().getTime() >
      new Date(upcomingBatchSlot.slot_date).getTime() - 10 * 60 * 1000;

  const joinClass = (courseId) => {
    const enrollment = availableCourseBatches.find(
      (e) => e.course_id === courseId
    );
    history.push("/join-batch/" + enrollment.batch_id);
  };

  return (
    <div>
      <div className="bg-transparent rounded-lg p-4" key={course.id}>
        <div className="bg-white flex flex-col md:flex-row rounded-lg shadow-md p-4 gap-x-5 items-center">
          <div className="cover-image w-full h-full md:w-1/4 overflow-hidden rounded-md ">
            <div className="w-full h-full max-h-80 bg-gray-200 flex justify-center">
              <img
                alt="course-img"
                src={course.image_url}
                className="shadow-md h-full w-full"
              />
            </div>
          </div>
          <div className="flex flex-col w-full box-border md:w-3/5 mt-4 md:mt-0">
            <span className="flex w-max flex-row items-center justify-around bg-gray-200 px-3 py-1 text-japanese_indigo rounded-md">
              <p className="text-center">{course.course_category.name}</p>
            </span>
            <h1 className="font-semibold text-orange my-4 text-lg">
              {course.full_name}
            </h1>
            <div className="rounded-md h-full">
              <div className="text-xs font-semibold">
                <p>
                  NSQF Level: {course.nsqf_level ? course.nsqf_level : "N.A"}
                </p>
              </div>

              <p className="mb-1 mt-4">
                Course progress: {course.progress ? course.progress : 0}%
              </p>
              {!!userCourseBatchEnrollment && (
                <div>
                  {nearestDate ? (
                    <p className="my-4">
                      Upcoming Class:{" "}
                      {nearestDate.toLocaleString("en-US", {
                        day: "numeric", // numeric, 2-digit
                        year: "numeric", // numeric, 2-digit
                        month: "long", // numeric, 2-digit, long, short, narrow
                        hour: "numeric", // numeric, 2-digit
                      })}
                    </p>
                  ) : (
                    <p>No upcoming classes</p>
                  )}
                  {batch?.institute && (
                    <div>
                      <div>At {batch.institute.name}</div>
                      <div>{batch.institute.location}</div>
                      <div>{batch.institute.city_town}</div>
                      <div>{batch.institute.district}</div>
                      <div>
                        {batch.institute.state} - {batch.institute.pincode}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded progress-bar w-1/2">
                <div
                  style={{
                    width: `${course.progress ? course.progress : "0"}%`,
                  }}
                  className="progress-bar2 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>

              <div className="flex flex-row border-t-2 py-4 mt-4">
                {!!userCourseBatchEnrollment ? (
                  <>
                    <button
                      className="rounded-md bg-gray-100 py-2 mr-2 border-2 font-semibold text-japanese_indigo px-6"
                      onClick={openCalendar}
                    >
                      Open Calendar
                    </button>

                    {upcomingBatchSlot &&
                      upcomingBatchSlot.type === "online" && (
                        <button
                          className={`rounded-md py-2 mr-2 text-white font-semibold px-6 ${
                            isUpcomingBatchAvailable
                              ? "bg-orange"
                              : "bg-gray-500"
                          }`}
                          disabled={!isUpcomingBatchAvailable}
                          onClick={() => joinClass(course.id)}
                        >
                          {isUpcomingBatchAvailable
                            ? "Join current session"
                            : "Upcoming session not available yet"}
                        </button>
                      )}
                  </>
                ) : (
                  courseHasLiveClasses && (
                    <button
                      className="rounded-md bg-gray-100 p-2 mx-2 border-2 font-semibold text-japanese_indigo px-6"
                      onClick={openBatchSelector}
                    >
                      Select Batch
                    </button>
                  )
                )}

                <button
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-center p-2 text-white font-semibold bg-orange rounded-md px-6"
                  onClick={() => {
                    if (course.is_moodle_course) {
                      courseSvc.openCourse({ courseId: course.id }, "_blank");
                    } else {
                      history.push("/course/view/" + course.id);
                    }
                  }}
                >
                  View Course
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal state */}
      <Modal
        isOpen={modalState.open}
        onClose={() => setModalState({ open: false })}
      >
        {modalState.type === MODAL_STATE_TYPE.SELECT_BATCHES ? (
          <SelectBatch
            availableBatches={availableCourseBatches}
            onSelect={chooseBatch}
          ></SelectBatch>
        ) : modalState.type === MODAL_STATE_TYPE.SHOW_CALENDAR ? (
          <div style={{ height: 400 }}>
            <Calendar
              localizer={moment.locale("en-GB") && momentLocalizer(moment)}
              events={batchSlotsAsEvents}
              step={60}
              startAccessor="start"
              endAccessor="end"
              defaultView="month"
              theme="ios"
              themeVariant="light"
              defaultDate={new Date()}
            />
          </div>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}
