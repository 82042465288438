const { default: Toast } = require("wrappers/Toast");

const error = (message = "An error occurred", status = 500) => {
  throw { message, status };
};

const translateApiError = (err) => err.response.data;

const toastError = (err) => Toast.error(err.message);
const toastApiError = (err) => toastError(translateApiError(err));

module.exports = { error, translateApiError, toastError, toastApiError };
