import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import { GET_COURSE } from "graphql/MycourseQuery/MyCourse";
import Modal from "components/Modal";
import { GET_COURSE_BY_SELECTED_ID } from "graphql/MycourseQuery/MyCourse";
import { useSelector } from "react-redux";

import { GET_BATCH_SLOTS_BY_COURSE_ID } from "graphql/queries/Courses";
import { GET_USER_BATCH_SLOTS_BY_USER_AND_BATCH_ID } from "graphql/queries/Courses";
import { CREATE_USER_BATCH_ENROL_MUTATION } from "graphql/mutations/MyCourseMutation";
import { GET_USER_BATCH_ENROLLMENT_BY_COURSE_ID } from "graphql/queries/Courses";
import { GET_USER_BATCH_COURSE_BY_UID } from "graphql/queries/UserQueries";
import moment from "moment";
import { get_moodle_user, get_moodle_courses } from "api/MoodleApi";

function LiveCourse() {
  const { data, loading, error } = useQuery(GET_COURSE);
  const [forgotOTPDialogOpen, setForgotOTPDialogOpen] = useState(false);

  const [getUserDetails, setUserDetails] = useLazyQuery(
    GET_USER_BATCH_COURSE_BY_UID
  );
  const [searchSlots, setCourseSlots] = useLazyQuery(
    GET_BATCH_SLOTS_BY_COURSE_ID
  );

  const [searchUserBatch, setUserBatchSlots] = useLazyQuery(
    GET_USER_BATCH_SLOTS_BY_USER_AND_BATCH_ID
  );

  const [getUserCourseBatch, setUserCourseBatch] = useLazyQuery(
    GET_USER_BATCH_ENROLLMENT_BY_COURSE_ID
  );

  const [insertUserBatchEnrol, mutationEnrol] = useMutation(
    CREATE_USER_BATCH_ENROL_MUTATION,
    {
      refetchQueries: [GET_USER_BATCH_COURSE_BY_UID],
    }
  );

  const [batch, setBatchData] = useState();
  const [course, setCourse] = useState([]);
  const [liveCourse, setLiveCourse] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const User = auth.user?.db_user?.name;

  const [userBatchCourse, setUserBatchCourse] = useState();

  const chooseBatch = (course_id, batch_id) => {
    var arr = [];
    arr["batch_id"] = batch_id;
    arr["course_id"] = course_id;
    arr["user_id"] = userId;
    arr["User"] = User;
    arr["batch_enrol_date"] = new Date();
    insertUserBatchEnrol({ variables: arr });
  };

  useEffect(() => {
    var user = [];
    user["user_id"] = userId;

    getUserDetails({ variables: user });
  }, []);

  useEffect(() => {
    if (setUserDetails && setUserDetails.data) {
      setUserBatchCourse(setUserDetails.data);
    }
  }, [setUserDetails]);

  const openSlots = (e) => {
    e.preventDefault();
    const id = e.target.getAttribute("attr");
    getUserCourseBatch({
      variables: {
        course_id: id,
        user_id: userId,
      },
    });
    searchSlots({ variables: { course_id: id } });
  };

  const manageHide = (e) => {
    var user = [];
    user["user_id"] = userId;

    getUserDetails({ variables: user });
    setForgotOTPDialogOpen(false);
  };

  useEffect(() => {
    var user = [];
    user["user_id"] = userId;

    getUserDetails({ variables: user });
  }, []);

  useEffect(() => {
    if (setCourseSlots.data) {
      setForgotOTPDialogOpen(true);

      const update = [];
      setCourseSlots.data.courses_course_batches.map((batch_key) => {
        batch_key.batch_slots.map((batch_val) => {
          update["full_name"] = batch_key.course.full_name;
          update["slot_date"] = batch_val.slot_date;
          update["slots_days"] = batch_val.slots_days;

          update["slots_days"] = batch_val.slots_days;
          update["batch_id"] = batch_val.batch_id;
        });
      });
      setBatchData(update);
    }
  }, [setCourseSlots]);

  useEffect(() => {
    if (mutationEnrol && mutationEnrol.data) {
      const userBatchId =
        mutationEnrol.data.insert_courses_user_batch_enrollment.returning[0]
          .batch_id;
      searchUserBatch({ variables: { batch_id: userBatchId } });
    }
  }, [mutationEnrol.data]);

  useEffect(() => {
    const update = {};
    if (setUserCourseBatch && setUserCourseBatch.data) {
      if (setUserCourseBatch.data.courses_user_batch_enrollment.length > 0) {
        // setInsertmsg("your below Batch and Live Sessions have been confirmed:")
        setUserCourseBatch.data.courses_user_batch_enrollment.map(
          (userbatch) => {
            update["full_name"] = userbatch.course.full_name;
          }
        );
        setCourseData(update);
      }
    }
  }, [setUserCourseBatch]);

  useEffect(() => {
    if (data && course) {
      const uniqueResultArrayObjOne = data.courses_course.filter(function (
        objOne
      ) {
        return course.some(function (objTwo) {
          return objOne.moodle_course_id == objTwo.id;
        });
      });

      setLiveCourse(uniqueResultArrayObjOne);
    }
  }, [data, course]);

  return (
    <>
      <Modal
        isOpen={forgotOTPDialogOpen}
        onClose={() => setForgotOTPDialogOpen(false)}
      >
        {batch ? (
          <div className="grid grid-cols-3 m-4 gap-5">
            <div className=" h-45 rounded-md shadow-lg content-center p-10">
              <img className="" src="OIP.jpg" alt="Image not found" />
              <div className="px-6 py-2">
                <p className="text-md font-semibold text-red-400"> </p>
                <p className="text-md font-semibold text-red-400">
                  {" "}
                  Course Name:{" "}
                  <p className="text-black inline-block">
                    {courseData.full_name
                      ? courseData.full_name
                      : batch.full_name}
                  </p>
                </p>
              </div>
            </div>
            <div className="col-span-2">
              <div className=" w-full">
                <p className="inline">Dear</p>{" "}
                <h1 className="text-blue-500 text-inline"> {User}</h1>
                <p className="text-green-500">
                  {setUserBatchSlots && setUserBatchSlots.data
                    ? "your below Batch and Live Sessions have been confirmed:"
                    : setUserCourseBatch.data &&
                      setUserCourseBatch.data.courses_user_batch_enrollment
                        .length > 0
                    ? "Following are selected batches"
                    : ""}
                </p>
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-300">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Batch No.
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Batch Date
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Batch Duration
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Batch Instructor
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            ></th>
                          </tr>
                        </thead>

                        {setUserCourseBatch.data &&
                        setUserCourseBatch.data.courses_user_batch_enrollment
                          .length > 0
                          ? setUserCourseBatch.data.courses_user_batch_enrollment.map(
                              (userbatch) => {
                                return (
                                  <>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                      <tr>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0 h-10 w-10">
                                              <p>
                                                {userbatch.Batch_slots.batch_id}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                              <p>
                                                {new Date(
                                                  userbatch.Batch_slots.slot_date
                                                ).toLocaleString("en-US", {
                                                  timeZone: "Asia/Kolkata",
                                                  dateStyle: "short",
                                                })}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                              <p>
                                                {new Date(
                                                  userbatch.Batch_slots.slot_date
                                                ).toLocaleString("en-US", {
                                                  timeZone: "Asia/Kolkata",
                                                  weekday: "short",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                }) +
                                                  " to " +
                                                  new Date(
                                                    userbatch.Batch_slots.endto_date
                                                  ).toLocaleString("en-US", {
                                                    timeZone: "Asia/Kolkata",
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                  })}
                                              </p>
                                            </div>
                                          </div>
                                        </td>

                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <p>
                                            {
                                              userbatch.Batch_slots.instructor
                                                .name
                                            }
                                          </p>
                                        </td>

                                        <td>
                                          <a
                                            href={
                                              userbatch.Batch_slots.meeting_link
                                            }
                                          >
                                            <button className="bg-red-300 hover:bg-red-500 text-white font-bold py-2 px-4 rounded">
                                              Join Now
                                            </button>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </>
                                );
                              }
                            )
                          : setUserBatchSlots && setUserBatchSlots.data
                          ? setUserBatchSlots.data.courses_batch_slots.map(
                              (slots) => {
                                return (
                                  <>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                      <tr>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0 h-10 w-10">
                                              <p>{slots.batch_id}</p>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                              <p>
                                                {new Date(
                                                  slots.slot_date
                                                ).toLocaleString("en-US", {
                                                  timeZone: "Asia/Kolkata",
                                                  dateStyle: "short",
                                                })}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                              <p>
                                                {new Date(
                                                  slots.slot_date
                                                ).toLocaleString("en-US", {
                                                  timeZone: "Asia/Kolkata",
                                                  weekday: "short",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                }) +
                                                  " to " +
                                                  new Date(
                                                    slots.endto_date
                                                  ).toLocaleString("en-US", {
                                                    timeZone: "Asia/Kolkata",
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                  })}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <p>{slots.instructor.name}</p>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <a
                                            href={
                                              slots.meeting_link
                                                ? slots.meeting_link
                                                : "#"
                                            }
                                          >
                                            <button className="bg-red-300 hover:bg-red-500 text-white font-bold py-2 px-4 rounded">
                                              Join Now
                                            </button>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </>
                                );
                              }
                            )
                          : setCourseSlots.data
                          ? setCourseSlots.data.courses_course_batches.map(
                              (batch_key) => {
                                return (
                                  <>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                      <tr>
                                        <td className="px-6 py-4 whitespace-nowrap w-100">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0 h-10 w-10">
                                              <p>{batch_key.batch_name}</p>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                              {batch_key.batch_slots.map(
                                                (batch_val) => {
                                                  return (
                                                    <>
                                                      <p>
                                                        {new Date(
                                                          batch_val.slot_date
                                                        ).toLocaleString(
                                                          "en-US",
                                                          {
                                                            timeZone:
                                                              "Asia/Kolkata",
                                                            dateStyle: "short",
                                                          }
                                                        )}
                                                      </p>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                              {batch_key.batch_slots.map(
                                                (batch_val) => {
                                                  return (
                                                    <>
                                                      <p>
                                                        {new Date(
                                                          batch_val.slot_date
                                                        ).toLocaleString(
                                                          "en-US",
                                                          {
                                                            timeZone:
                                                              "Asia/Kolkata",
                                                            weekday: "short",
                                                            hour: "numeric",
                                                            minute: "numeric",
                                                          }
                                                        ) +
                                                          " to " +
                                                          new Date(
                                                            batch_val.endto_date
                                                          ).toLocaleString(
                                                            "en-US",
                                                            {
                                                              timeZone:
                                                                "Asia/Kolkata",
                                                              hour: "numeric",
                                                              minute: "numeric",
                                                            }
                                                          )}
                                                      </p>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          {batch_key.batch_slots.map(
                                            (batch_val) => {
                                              return (
                                                <>
                                                  <p>
                                                    {batch_val.instructor.name}
                                                  </p>
                                                </>
                                              );
                                            }
                                          )}
                                        </td>

                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <button
                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                            onClick={() =>
                                              chooseBatch(
                                                batch_key.course.id,
                                                batch_key.id
                                              )
                                            }
                                          >
                                            Select Batch
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </>
                                );
                              }
                            )
                          : ""}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          "no"
        )}
      </Modal>
      <div className="mt-8 ml-9">
        <h2 className="text-2xl text-start font-bold text-gray-800">
          Live Classes!
        </h2>
      </div>

      <div className="grid grid-cols-4 gap-4 mb-2 ml-8 ">
        {liveCourse.map((course, index) => {
          const found = userBatchCourse.courses_user_batch_enrollment.some(
            (cID) => cID.course_id == course.id
          );

          return (
            <>
              <div className=" rounded-md shadow-lg p-10 ">
                <img
                  className=" w-md"
                  src={course.image_url}
                  alt="Image not found"
                />
                <div className="" key={course.id}>
                  <p className="text-md font-semibold text-red-400 text-center">
                    Course Name:
                    <span className="text-black inline-block">
                      {course.full_name}
                    </span>
                  </p>
                  <p className="text-md font-semibold text-red-400 text-center">
                    {" "}
                    Course Id: {course.id}
                  </p>
                </div>

                <div className="flex justify-center">
                  <div className="px-6  ">
                    <button
                      className=" bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 border border-blue-700 rounded "
                      attr={course.id}
                      onClick={openSlots}
                    >
                      {" "}
                      {found ? "View Batches" : "Choose Batches"}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div />
    </>
  );
}
export default LiveCourse;
