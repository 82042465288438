import React from "react";

export default ({ className, children }) => {
  return (
    <div
      className={`px-4 xl:px-0 lg:max-w-7xl lg:mx-auto pt-3 ${className} relative z-0`}
    >
      {children}
    </div>
  );
};
