import { useState } from "react";
import { Link } from 'react-router-dom';
import { CourseCard } from ".";
import { SiRazorpay } from "react-icons/si";

export default function ChooseCourse() {
  const [showUserDetails, setShowUserDetails] = useState(false);
  return (
    <>
      {showUserDetails ? (
        <>
          <div
            style={{
              backgroundImage: "url(./onboarding-leftpane.png)",
              height: "100vh",
            }}
            className="mx-auto"
          >
            <div className="container px-4 sm:px-8 lg:px-16 xl:px-20 mx-auto">
              <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-6/12">
                  <div className="p-0 lg:p-12">
                    <h1 className="text-4xl text-japanese_indigo font-semibold my-3">
                      Experience the Joy
                    </h1>
                    <h1 className="text-4xl text-japanese_indigo font-semibold my-3">
                      of Gifting!
                    </h1>
                    <div className="w-24 text-transparent border-2 bg-orange border-orange h-0">
                      .
                    </div>

                    <p className="my-4 text-japanese_indigo text-lg">
                      Please provide us with the information and proceed to
                      checkout
                    </p>

                    <p className="font-base text-japanese_indigo font-semibold">
                      Details
                    </p>
                    <div className="mb-8 mt-2 bg-white rounded-md border">
                      <div className="w-full rounded-md my-3">
                        <div className="flex flex-row items-center justify-between bg-white p-4">
                          <img
                            className="h-20 w-1/3 object-cover object-center border-2 rounded-md"
                            src="https://lms.skillstrainer.in/moodle/pluginfile.php/1550098/course/overviewfiles/3-SWAR%20logo%20options_25.09.2021.jpg"
                            alt="course-img"
                          />
                          <p className="text-japanese_indigo text-lg">
                            SWAR English Course
                          </p>
                          <p className="font-semibold text-lg text-japanese_indigo">
                            ₹1000
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-col w-full my-3 border-t-2 p-3">
                        <div className="flex flex-row justify-between w-full my-3">
                          <p className="text-japanese_indigo text-lg">Tax</p>
                          <p className="text-japanese_indigo font-semibold">₹0</p>
                        </div>

                        <div className="flex flex-row justify-between w-full my-3">
                          <p className="text-japanese_indigo text-lg font-semibold">
                            Total
                          </p>
                          <p className="text-orange font-semibold text-lg">
                            ₹1000
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full lg:w-6/12 lg:px-4">
                  <div className="bg-white shadow-lg rounded-lg px-3 lg:px-10 py-4 mt-4 lg:mt-14">
                    <div className="my-4 flex flex-col lg:flex-row w-full">
                      <div className="flex flex-col g:mx-2 w-full">
                        <p className="text-japanese_indigo font-semibold">
                          Your Name
                        </p>
                        <input
                          placeholder="Enter Your Name"
                          type="text"
                          className="w-full border border-gray-300 rounded-lg py-2 px-4"
                        />
                      </div>

                      <div className="flex flex-col lg:mx-2 w-full">
                        <p className="text-japanese_indigo font-semibold">
                          Your Email ID
                        </p>
                        <input
                          placeholder="Enter your Email ID"
                          type="email"
                          className="w-full border border-gray-300 rounded-lg py-2 px-4"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col w-full p-2">
                      <p className="text-japanese_indigo font-semibold">
                        Your Phone Number
                      </p>
                      <input
                        placeholder="Enter your Phone Number"
                        type="tel"
                        className="w-full border border-gray-300 rounded-lg py-2 px-4"
                      />
                    </div>

                    <div className="flex flex-col w-full p-2">
                      <p className="text-japanese_indigo font-semibold">
                        Your Message (optional)
                      </p>
                      <textarea
                        className="w-full border border-gray-300 rounded-lg py-2 px-4"
                        placeholder="Additional Information / Message"
                        name=""
                        id=""
                        cols="30"
                        rows="3"
                      ></textarea>
                    </div>

                    <div className="my-4 mx-2">
                    <Link to="/giftpaymentsuccess">
                      <button className="flex flex-row items-center justify-center rounded-md w-full bg-orange text-white p-4 text-lg font-semibold">
                        <SiRazorpay className="mx-2"/>
                        Proceed to Checkout
                      </button>
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="p-10 text-center">
            <h1 className="text-2xl lg:text-4xl font-semibold text-japanese_indigo">
              To Start, Select a Course
            </h1>
            <h2 className="my-4 text-japanese_indigo">
              Select one course from a wide range of courses we have
            </h2>
            <div className="flex flex-col lg:flex-row items-center justify-center w-full">
              <CourseCard />
              <CourseCard />
            </div>

            <div className="w-full flex item-center justify-center">
              <button className="bg-orange-light p-2 text-japanese_indigo rounded-md font-semibold my-6">
                View all courses
              </button>
              <button
                onClick={() => setShowUserDetails(true)}
                className="bg-orange-light p-2 text-japanese_indigo rounded-md font-semibold my-6"
              >
                Show User Detail Page
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
