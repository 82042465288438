import { useQuery } from "@apollo/client";
import { HiXCircle } from "react-icons/hi";
import {
  FETCH_POSSIBLE_SKILLS
} from "graphql/queries/SkillsTrainer";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import userSvc from "services/User";
import { wireEventValue } from "utils/func";
import Toast from "wrappers/Toast";


export default function Skills(props) {
  const SKILLS_POSSIBLE = useQuery(FETCH_POSSIBLE_SKILLS);
  const [userSkills, setUserSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState("-1");
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;

  const fetchSkills = () =>
    userSvc.fetchUserSkills(userId).then((skills) => {
      if(!skills){
        Toast.error("There was an error while fetching data");
        return;
      }

      // adding name to the object in all skills and setting in the userSkills
      setUserSkills(skills?.map((skill) => ({
        ...skill,
        name: skill.skillstrainer_skill.name
      })))
    });

  useEffect(() => {
    fetchSkills();
  }, []);

  // Actions

  const isSkillChoosed = (id) => {
    for(let skill of userSkills){
      if(skill.st_skill_id == id && !skill.disabled) return true
    }
    return false;
  }

  const addSkill = () => {
    if(selectedSkill != "-1"){
      let prevSkill = userSkills?.find(skill => skill.st_skill_id === parseInt(selectedSkill));
      if(prevSkill){
        prevSkill.disabled = undefined
        setUserSkills([
          ...userSkills?.filter((skill)=>skill.st_skill_id != parseInt(selectedSkill)),
          prevSkill
        ])
      }else{
        setUserSkills([
          ...userSkills,
          {
            st_skill_id: selectedSkill,
            name: SKILLS_POSSIBLE?.data?.courses_skillstrainer_skills?.find(skill => skill.id === parseInt(selectedSkill)).name,
            fresh: true
          }
        ])
      }
      setSelectedSkill("-1");
    }else{
      Toast.error("Please select a skill to add!");
    }
  }

  const removeSkill = (skillToRemove) => {
    skillToRemove.disabled = true;
    setSelectedSkill([...userSkills.filter((skill)=>skill.st_skill_id!=skillToRemove.st_skill_id), skillToRemove]);
  }

  const submit = async () => {
    let newAllSkills = await userSvc.setUserSkills(userSkills, userId);
    if (newAllSkills) {
      setUserSkills(newAllSkills);
      Toast.success("Successfully updated!");
      if(props.isLastPage)
        props.finishFn()
      else
        props.next();
    }
  };

  // Actions End

  return (
    <div className="card p-4 mb-20">
      <b className="mb-5 text-lg font-bold">Skills</b>
      <div>
        <select
          onChange={wireEventValue(setSelectedSkill)}
          value={selectedSkill}
          className="my-2 mt-4"
        >
          {<option value="-1">No Skill</option>}
          {SKILLS_POSSIBLE?.data?.courses_skillstrainer_skills?.map((skill) => {
            return (
              !isSkillChoosed(skill.id) && <option value={skill.id} id={skill.id} disabled={isSkillChoosed(skill.id)}>
                {skill.name}
              </option>
            );
          })}
        </select>
        <span className="justify-end my-4">
          <button
            className="mx-3	px-4 bg-orange text-white font-semibold p-2 rounded-md text-lg"
            onClick={addSkill}
          >
            Add
          </button>
        </span>

        <div className="flex flex-wrap flex-row w-full">
          {userSkills.map((skill) => {
            return !skill.disabled && <span className="flex flex-row item-center justify-center border-orange rounded-2xl mx-3 border-2 py-1 pl-3 pr-1 my-2">
              <p className="text-xs text-orange my-auto mt-0.5 pr-1">
                {skill.name}
              </p>
              <HiXCircle size={20} className="text-orange my-auto mt-0 cursor-pointer" 
                onClick={() => removeSkill(skill)} />
            </span>
          })}
        </div>

        <div className="w-full flex justify-end my-4">
          <button
            className="w-1/3 bg-orange text-white font-semibold p-2 rounded-md text-lg"
            onClick={submit}
          >
            Save and Proceed
          </button>
        </div>
      </div>
    </div>
  );
}
