export const TOGGLE_LEFT_SIDEBAR = "toggle-left-sidebar";

const initialState = {
  isLeftSidebarOpen: false,
};

export default function UIReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_LEFT_SIDEBAR:
      return {
        ...state,
        isLeftSidebarOpen:
          action.payload === undefined
            ? !state.isLeftSidebarOpen
            : action.payload,
      };
    default:
      return state;
  }
}
