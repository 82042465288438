import { useState, useEffect } from "react";
import * as yup from "yup";

function BillingAddressCheckoutFormBuilder(values) {
  const [schema, setSchema] = useState({});

  useEffect(() => {
    const schema = {
      business_name: {
        type: "text",
        label: "Business Name",
        placeholder: "Optional",
        schema: yup.string(),
      },
      gst_number: {
        type: "text",
        label: "GST No.",
        placeholder: "Optional",
        schema: yup
          .string()
          .matches(
            "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
            "Please enter valid GST no."
          ),
      },
      address: {
        label: "Address",
        required: true,
        type: "address",
        schema: yup.object(),
      },
    };

    setSchema(schema);
  }, [values]);

  return schema;
}
export default BillingAddressCheckoutFormBuilder;
