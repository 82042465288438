import React, { Component } from "react";
import { connect } from "react-redux";
import Quiz from "./QuizTemplate/Quiz";
import { ActionTypes } from "redux/actions/Quiz/ActionTypes";
import liveClassService from "services/liveClass";
import Container from "components/Container";
import Hotkeys from "react-hot-keys";
import Result from "./QuizTemplate/Result";
import Toast from "utils/Toast";
import { apolloClient } from "App";
import {
  GET_QUIZ_TRANSLATIONS,
  GET_QUESTION_ANSWER_TRANSLATIONS,
} from "graphql/queries/CourseBuilder/Quiz";
import { wireEventValue } from "utils/func";
import Blank from "./QuizTemplate/Blank";

const mapStateToProps = (state) => {
  return { ...state.quiz };
};

const mapDispatchToProps = (dispatch) => ({
  onReset: () => dispatch({ type: ActionTypes.ResetQuiz }),
  onQuizLoad: (payload) => dispatch({ type: ActionTypes.QuizLoad, payload }),
  onPagerUpdate: (payload) =>
    dispatch({ type: ActionTypes.PagerUpdate, payload }),
  onTimerUpdate: (payload) =>
    dispatch({
      type: ActionTypes.TimerUpdate,
      payload,
    }),
  onSubmit: (payload) => dispatch({ type: ActionTypes.QuizSubmit, payload }),
  QuizWindowStart: (payload) =>
    dispatch({ type: ActionTypes.QuizWindowStart, payload }),
});

async function getQuizLanguages(moduleMappingId) {
  const quizTranslations = await apolloClient
    .query({
      query: GET_QUIZ_TRANSLATIONS,
      variables: {
        moduleMappingId: moduleMappingId,
      },
    })
    .then(({ data }) => {
      return data.courses_course_module_mapping[0].tags?.languages || [];
    });
  return quizTranslations;
}

class QuizView extends Component {
  state = {
    quizes: [
      { id: "/data/javascript.json", name: "Javascript" },
      { id: "data/aspnet.json", name: "Asp.Net" },
      { id: "data/csharp.json", name: "C Sharp" },
      { id: "data/designPatterns.json", name: "Design Patterns" },
    ],
    quizId: "data/javascript.json",
    max_marks: "",
    completion_percentage: "",
    loadComplete: false,
    enterQuiz: false,
    attemtsgrade: null,
    lessonName: null,
    maxattempt: null,
    userId: null,
    userTotalAttempt: null,
    attempt_id: null,
    new: null,
    course_id: null,
    QuizTimer: true,
    timelimit_number: null,
    enable_timer: null,
    newWindow: false,
    highestAttempt: null,
    instructionWindow: false,
    hasWebcam: true,
    require_webcam: false,
    quizLanguage: "English",
    nonScoreQuiz: true,
  };

  pager = {
    index: 0,
    size: 1,
    count: 1,
  };

  secondsToHms = (d) => {
    var d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h == 1 ? " hr " : "hr ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " min " : "min ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " sec" : "sec") : "";
    return hDisplay + mDisplay + sDisplay;
  };

  componentDidMount() {
    this.load();
    this.props.onReset();

    this.detectWebcam();
  }

  quizInstructionLookup(language) {
    const showWebcamPoint = !this.state.hasWebcam;
    const showTimer = !this.state.QuizTimer;
    const instructionLanguages = [
      "English",
      "Hindi",
      "Gujarati",
      "Kannada",
      "Marathi",
    ];
    if (language === "English" || !instructionLanguages.includes(language))
      return (
        <div className="flex gap-10">
          <div className="w-3/4 p-10  shadow-lg border-2 bg-white rounded-lg ">
            {" "}
            <span className="text-orange text-lg">Instructions:-</span>
            <div>
              <ul className="list-disc pl-6">
                <li className="font poppins mt-2 ">
                  The Quiz consist of{" "}
                  <span className="font-bold">{this.pager.count}</span> multiple
                  type Question
                </li>
                {showTimer && (
                  <li className="font poppins mt-2">
                    Test Duration{" "}
                    <span className="font-bold">
                      {this.secondsToHms(this.state.timelimit_number)}
                    </span>
                  </li>
                )}
                {showWebcamPoint && (
                  <li className="font poppins mt-2">
                    It is mandatory for users to keep webcam on during the exam
                  </li>
                )}
                <li className="font poppins mt-2">
                  1 mark provided for each correct answer
                </li>
                <li className="font poppins mt-2">
                  There is no negative marking
                </li>
                <li className="font poppins mt-2">
                  If you close Quiz window then your Quiz attempt automatically
                  submit.
                </li>
                <li className="font poppins mt-2">
                  If you try to change or swap Quiz window then you
                  automatically logout and your current attempt also submit
                  automatically
                </li>
              </ul>
            </div>
            <div className="w-full flex justify-center mt-7">
              <button
                className="bg-transparent bg-orange text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={this.QuizStart}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      );
    if (language === "Hindi")
      return (
        <div className="flex gap-10">
          <div className="w-3/4 p-10  shadow-lg border-2 bg-white rounded-lg ">
            {" "}
            <span className="text-orange text-lg">निर्देश:-:-</span>
            <div>
              <ul className="list-disc pl-6">
                <li className="font poppins mt-2 ">
                  क्विज़ में{" "}
                  <span className="font-bold">{this.pager.count}</span> बहु
                  प्रकार के प्रश्न शामिल हैं
                </li>
                {showTimer && (
                  <li className="font poppins mt-2">
                    परीक्षण की अवधि{" "}
                    <span className="font-bold">
                      {this.secondsToHms(this.state.timelimit_number)}
                    </span>
                  </li>
                )}
                {showWebcamPoint && (
                  <li className="font poppins mt-2">
                    परीक्षा के दौरान उपयोगकर्ता को वेबकैम चालू रखना अनिवार्य है
                  </li>
                )}
                <li className="font poppins mt-2">
                  प्रत्येक सही उत्तर के लिए 1 अंक प्रदान किया गया
                </li>
                <li className="font poppins mt-2">
                  कोई नकारात्मक अंकन नहीं है
                </li>
                <li className="font poppins mt-2">
                  यदि आप क्विज़ विंडो बंद करते हैं तो आपका क्विज़ प्रयास
                  स्वचालित रूप से सबमिट हो जाएगा।
                </li>
                <li className="font poppins mt-2">
                  यदि आप क्विज़ विंडो को बदलने या स्वैप करने का प्रयास करते हैं
                  तो आप स्वचालित रूप से लॉगआउट हो जाते हैं और आपका वर्तमान
                  प्रयास भी स्वचालित रूप से सबमिट हो जाता है
                </li>
              </ul>
            </div>
            <div className="w-full flex justify-center mt-7">
              <button
                className="bg-transparent bg-orange text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={this.QuizStart}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      );
    if (language === "Gujarati")
      return (
        <div className="flex gap-10">
          <div className="w-3/4 p-10  shadow-lg border-2 bg-white rounded-lg ">
            {" "}
            <span className="text-orange text-lg">સૂચનાઓ:-</span>
            <div>
              <ul className="list-disc pl-6">
                <li className="font poppins mt-2 ">
                  ક્વિઝમાં <span className="font-bold">{this.pager.count}</span>{" "}
                  બહુવિધ પ્રકારના પ્રશ્નો હોય છે
                </li>
                {showTimer && (
                  <li className="font poppins mt-2">
                    ટેસ્ટ સમયગાળો{" "}
                    <span className="font-bold">
                      {this.secondsToHms(this.state.timelimit_number)}
                    </span>
                  </li>
                )}
                {showWebcamPoint && (
                  <li className="font poppins mt-2">
                    યુઝર માટે પરીક્ષા દરમિયાન વેબકેમ ચાલુ રાખવું ફરજિયાત છે
                  </li>
                )}
                <li className="font poppins mt-2">
                  દરેક સાચા જવાબ માટે 4:1 માર્ક આપવામાં આવ્યા છે
                </li>
                <li className="font poppins mt-2">કોઈ નેગેટિવ માર્કિંગ નથી</li>
                <li className="font poppins mt-2">
                  જો તમે ક્વિઝ વિન્ડો બંધ કરો તો તમારો ક્વિઝ પ્રયાસ આપોઆપ સબમિટ
                  થઈ જશે.
                </li>
                <li className="font poppins mt-2">
                  જો તમે ક્વિઝ વિન્ડોને બદલવા અથવા સ્વેપ કરવાનો પ્રયાસ કરો છો,
                  તો તમે આપમેળે લોગઆઉટ થઈ જશો અને તમારો વર્તમાન પ્રયાસ પણ આપમેળે
                  સબમિટ થશે
                </li>
              </ul>
            </div>
            <div className="w-full flex justify-center mt-7">
              <button
                className="bg-transparent bg-orange text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={this.QuizStart}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      );
    if (language === "Kannada")
      return (
        <div className="flex gap-10">
          <div className="w-3/4 p-10  shadow-lg border-2 bg-white rounded-lg ">
            {" "}
            <span className="text-orange text-lg">ಸೂಚನೆಗಳು:-</span>
            <div>
              <ul className="list-disc pl-6">
                <li className="font poppins mt-2 ">
                  ರಸಪ್ರಶ್ನೆಯು{" "}
                  <span className="font-bold">{this.pager.count}</span> ಬಹು ವಿಧದ
                  ಪ್ರಶ್ನೆಗಳನ್ನು ಒಳಗೊಂಡಿದೆ
                </li>
                {showTimer && (
                  <li className="font poppins mt-2">
                    ಪರೀಕ್ಷೆಯ ಅವಧಿ{" "}
                    <span className="font-bold">
                      {this.secondsToHms(this.state.timelimit_number)}
                    </span>
                  </li>
                )}
                {showWebcamPoint && (
                  <li className="font poppins mt-2">
                    ಪರೀಕ್ಷೆಯ ಸಮಯದಲ್ಲಿ ಬಳಕೆದಾರರು ವೆಬ್‌ಕ್ಯಾಮ್ ಅನ್ನು ಆನ್ ಮಾಡುವುದು
                    ಕಡ್ಡಾಯವಾಗಿದೆ
                  </li>
                )}
                <li className="font poppins mt-2">
                  ಪ್ರತಿ ಸರಿಯಾದ ಉತ್ತರಕ್ಕೆ 4: 1 ಅಂಕವನ್ನು ಒದಗಿಸಲಾಗಿದೆ
                </li>
                <li className="font poppins mt-2">
                  ಯಾವುದೇ ನಕಾರಾತ್ಮಕ ಗುರುತು ಇಲ್ಲ
                </li>
                <li className="font poppins mt-2">
                  ನೀವು ರಸಪ್ರಶ್ನೆ ವಿಂಡೋವನ್ನು ಮುಚ್ಚಿದರೆ ನಿಮ್ಮ ರಸಪ್ರಶ್ನೆ ಪ್ರಯತ್ನವು
                  ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಸಲ್ಲಿಸಿ.
                </li>
                <li className="font poppins mt-2">
                  ನೀವು ರಸಪ್ರಶ್ನೆ ವಿಂಡೋವನ್ನು ಬದಲಾಯಿಸಲು ಅಥವಾ ಸ್ವ್ಯಾಪ್ ಮಾಡಲು
                  ಪ್ರಯತ್ನಿಸಿದರೆ ನೀವು ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಲಾಗ್‌ಔಟ್ ಆಗುತ್ತೀರಿ ಮತ್ತು
                  ನಿಮ್ಮ ಪ್ರಸ್ತುತ ಪ್ರಯತ್ನವು ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಸಲ್ಲಿಸುತ್ತದೆ
                </li>
              </ul>
            </div>
            <div className="w-full flex justify-center mt-7">
              <button
                className="bg-transparent bg-orange text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={this.QuizStart}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      );
    if (language === "Marathi")
      return (
        <div className="flex gap-10">
          <div className="w-3/4 p-10  shadow-lg border-2 bg-white rounded-lg ">
            {" "}
            <span className="text-orange text-lg">सूचना:-</span>
            <div>
              <ul className="list-disc pl-6">
                <li className="font poppins mt-2 ">
                  क्विझमध्ये{" "}
                  <span className="font-bold">{this.pager.count}</span> एकाधिक
                  प्रकारचे प्रश्न असतात
                </li>
                {showTimer && (
                  <li className="font poppins mt-2">
                    चाचणी कालावधी{" "}
                    <span className="font-bold">
                      {this.secondsToHms(this.state.timelimit_number)}
                    </span>
                  </li>
                )}
                {showWebcamPoint && (
                  <li className="font poppins mt-2">
                    परीक्षेदरम्यान वापरकर्त्याने वेबकॅम चालू ठेवणे बंधनकारक आहे
                  </li>
                )}
                <li className="font poppins mt-2">
                  प्रत्येक बरोबर उत्तरासाठी 4: 1 गुण दिले आहेत
                </li>
                <li className="font poppins mt-2">
                  कोणतेही नकारात्मक चिन्हांकन नाही
                </li>
                <li className="font poppins mt-2">
                  तुम्ही क्विझ विंडो बंद केल्यास तुमचा क्विझचा प्रयत्न आपोआप
                  सबमिट होईल.
                </li>
                <li className="font poppins mt-2">
                  तुम्ही क्विझ विंडो बदलण्याचा किंवा स्वॅप करण्याचा प्रयत्न
                  केल्यास तुम्ही आपोआप लॉगआउट कराल आणि तुमचा सध्याचा प्रयत्न
                  देखील आपोआप सबमिट होईल
                </li>
              </ul>
            </div>
            <div className="w-full flex justify-center mt-7">
              <button
                className="bg-transparent bg-orange text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={this.QuizStart}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      );
  }

  detectWebcam() {
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) {
      return false;
    } else {
      md.enumerateDevices().then((devices) => {
        const detectStatus = devices.some(
          (device) => "videoinput" === device.kind
        );

        if (this.props.modData.require_webcam) {
          this.setState({
            hasWebcam: detectStatus,
          });
        }
      });
    }
  }

  componentWillReceiveProps(bar) {
    if (this.props.courseView != this.props.modData.mapping_id) {
      this.setState({
        enterQuiz: false,
        instructionWindow: false,
        loadComplete: false,
      });
      this.load();
    }
  }

  setQuizLanguage = (language) => {
    if (language !== "English") {
      this.setState({ quizLanguage: language });
      this.load();
    }
  };

  async translateQuestions(questions) {
    if (this.state.quizLanguage !== "English") {
      questions.forEach(async (question, index) => {
        const questionKey =
          "question." +
          question.id +
          "." +
          this.state.quizLanguage.toLowerCase();
        const answerKeys = (question.quest_answer || []).map((ans) => {
          return (
            "answer." + ans.id + "." + this.state.quizLanguage.toLowerCase()
          );
        });
        const multiLangKeys = [questionKey, ...answerKeys];

        const translations = await apolloClient
          .query({
            query: GET_QUESTION_ANSWER_TRANSLATIONS,
            variables: {
              key_array: multiLangKeys,
            },
          })
          .then(({ data }) => {
            return data.courses_multi_lang_contents;
          });

        for (const trans in translations) {
          const keySplitArray = translations[trans].key.split(".");
          if (keySplitArray[0] === "question") {
            const questionTranslation = translations[trans].content.question;
            questions[index].question_text = questionTranslation;
          } else {
            const answerTranslation = translations[trans].content.answer;
            const answerId = keySplitArray[1];

            questions[index].quest_answer.forEach((ans) => {
              if (String(ans.id) === answerId) {
                ans.answer_text = answerTranslation;
              }
            });
          }
        }
      });
    }

    return questions;
  }

  async load() {
    try {
      const response = await liveClassService.getCourseQuiz(
        this.props.courseView
      );
      //this.props.courseView implies module_mapping_id
      this.state.translationOptions = await getQuizLanguages(
        this.props.courseView
      );

      const tags = response?.courses_course_module_mapping[0]?.tags || {};
      if (tags.blockNext) {
        response.blockNext = true;
      }

      var data = [];
      response &&
        response.courses_questions.map((e) => {
          data.push(e.marks);
          this.state.max_marks = data.reduce(function (
            previousValue,
            currentValue
          ) {
            return previousValue + currentValue;
          });
        });

      const completion_percentage = await liveClassService.getCourseMapping(
        this.props.courseView
      );

      response &&
        response.courses_questions.forEach((q) => {
          q.quest_answer.forEach((o) => (o.selected = false));
        });
      response.config = Object.assign(
        this.props.quiz.config || {},
        response.config
      );
      this.pager.count = response.courses_questions.length / this.pager.size;
      response.max_marks = this.state.max_marks;
      response.completion_percentage =
        completion_percentage.completion_percentage;
      response.mode = this.props.modData;
      // this.props.quiz.config.duration=50000

      if (this.state.quizLanguage === "English")
        this.props.onQuizLoad(response);
      else {
        const translatedQuestions = await this.translateQuestions(
          response.courses_questions
        );

        this.props.onQuizLoad({
          ...response,
          courses_questions: translatedQuestions,
        });
      }
      this.state.loadComplete = true;

      this.state.attemtsgrade = this.props.modData.attemtsgrade;
      this.state.lessonName = this.props.modData.lessonName;
      this.state.require_webcam = this.props.modData.require_webcam;
      this.state.maxattempt =
        this.props.modData.maxattempt == 0
          ? "-1"
          : this.props.modData.maxattempt;
      this.state.userId = this.props.modData.user_id;
      this.state.course_id = this.props.modData.course_id;
      this.state.enable_timer = this.props.modData.enable_timer;
      this.state.timelimit_number = this.props.modData.timelimit_number;
      this.state.completion_percentage =
        completion_percentage.completion_percentage;

      const userAttempt = await liveClassService.getUserQuizAttempt(
        this.props,
        this.state.userId
      );

      this.state.userTotalAttempt = userAttempt.length;

      const allCompleted =
        userAttempt.length > 0 &&
        userAttempt.filter((data) => {
          return data.attempt_completed == true;
        });

      const highestAttempt =
        allCompleted.length > 0 &&
        allCompleted.reduce((prev, current) =>
          prev.attempt_percentage > current.attempt_percentage ? prev : current
        );

      this.state.highestAttempt = highestAttempt.attempt_percentage;

      const filterAttempt =
        userAttempt &&
        userAttempt.filter((data) => {
          return data.attempt_completed == false;
        });

      filterAttempt.length > 0
        ? this.setState({
            attempt_id: filterAttempt[0].id,
            new: false,
          })
        : this.setState({
            attempt_id: null,
            new: true,
          });

      this.props.onPagerUpdate(this.pager);
    } catch (error) {
      console.log(error);
    }
  }

  handleClick = (e) => {
    this.setState({
      instructionWindow: true,
    });
  };

  QuizStart = async (e) => {
    if (this.state.hasWebcam) {
      if (this.state.new) {
        const attemptData = await liveClassService.insertUserQuizAttempt(
          this.props,
          this.state.userId,
          this.state.userTotalAttempt
        );

        this.setState({
          attempt_id: attemptData.id,
          new: true,
        });
      }

      this.setState({
        enterQuiz: true,
        QuizTimer: false,
        newWindow: true,
      });

      this.props.QuizWindowStart(false);
    } else {
      Toast.error("Please install or configure webcam on your system");
    }
  };

  popoutClosed() {
    this.setState({ newWindow: false });
  }

  exitWindow = (e) => {
    // alert("sssss")
    this.props.onSubmit("submit");
    this.props.QuizWindowStart(true);
  };

  onKeyUp(keyName, e, handle) {
    if (
      keyName == "ctrl+r" ||
      keyName == "ctrl+w" ||
      keyName == "f12" ||
      keyName == "f5"
    ) {
      e.preventDefault();
      return false;
    }
  }
  onKeyDown(keyName, e, handle) {
    if (
      keyName == "ctrl+r" ||
      keyName == "ctrl+w" ||
      keyName == "f12" ||
      keyName == "f5"
    ) {
      e.preventDefault();
      return false;
    }
  }

  renderMode() {
    if (this.props.mode === "submit") {
      return (
        <div className="">
          <Result
            questions={this.props.quiz || []}
            attempt={this.state}
            reAttempt={this.reAttempt}
            nonScoreQuiz={this.state.nonScoreQuiz}
          />
        </div>
      );
    }
  }

  renderBlank() {
    if (this.props.mode === "blank") {
      return (
        <Blank
          quizTitle={this.props.quiz.mode.lessonName || "NA"}
          reAttemptQuiz={this.reAttempt}
          attemptId={this.state.attempt_id}
        />
      );
    }
  }

  reAttempt = async () => {
    this.setState({
      enterQuiz: false,
      instructionWindow: false,
      loadComplete: false,
    });
    this.load();
  };

  render() {
    return (
      <Container>
        {this.renderMode()}
        {this.renderBlank()}

        {this.state.loadComplete &&
          this.state.enterQuiz &&
          this.state.newWindow &&
          this.state.instructionWindow &&
          this.props.mode !== "submit" &&
          this.props.mode === "quiz" && (
            <Hotkeys
              keyName="shift+a,alt+s,tab,alt,ctrl+r,ctrl+w,f12,f5"
              onKeyDown={this.onKeyDown}
              onKeyUp={this.onKeyUp}
            >
              <Quiz
                pagesCount={this.pager.count}
                quiz={this.state.quiz}
                quizId={this.state.quizId}
                mode={this.state.mode}
                attempt={this.state}
                quizLanguage={this.state.quizLanguage}
              />
            </Hotkeys>
          )}

        {this.state.loadComplete && !this.state.instructionWindow && (
          <div
            className="
            absolute  top-1/2 left-1/2 transform -translate-x-1/2 mt-10  max-w-xl w-full bg-white rounded-xl overflow-hidden shadow-lg  flex flex-row border-2 border-orange
            "
          >
            <div className="px-6 py-4  ">
              <h1 className="text-lg text-center font-semibold">
                {this.state.lessonName}
              </h1>
              <div className="flex">
                <span>Number of Questions: &nbsp;</span>
                <span className="font-bold"> {this.pager.count}</span>
              </div>
              <div>
                <div className="flex flex-col gap-1  mt-5">
                  <span className="display inline-block text-sm font-poppins ">
                    Attempt Allowed:{" "}
                    {this.state.maxattempt == -1
                      ? "Unlimited"
                      : this.state.maxattempt + " Attempts"}
                  </span>
                  <span className="display inline-block mr-2  font-poppins">
                    Number of attempt you have made:{" "}
                    {this.state.userTotalAttempt}
                  </span>

                  {!this.state.nonScoreQuiz && (
                    <span className="display inline-block mr-2 text-sm font-poppins">
                      To pass: {this.state.completion_percentage}% or Higher
                    </span>
                  )}
                  {!this.state.nonScoreQuiz && (
                    <span className="display inline-block mr-2 text-sm font-poppins">
                      Your Best Result:{" "}
                      {`${
                        this.state.highestAttempt
                          ? this.state.highestAttempt + "%"
                          : "No Attempt Till Now"
                      }`}
                    </span>
                  )}

                  <label
                    for="countries"
                    class="block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select Language
                  </label>
                  <select
                    onChange={wireEventValue((value) =>
                      this.setQuizLanguage(value)
                    )}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange focus:border-orange block w-full p-2 mb-2"
                  >
                    <option selected>English</option>
                    {this.state.translationOptions
                      .filter((lang) => lang !== "English")
                      .map((lang) => {
                        return (
                          <option key={lang} value={lang}>
                            {lang}
                          </option>
                        );
                      })}
                  </select>
                </div>

                {this.state.maxattempt == this.state.userTotalAttempt ? (
                  <div className="mt-4">
                    <span className="text-red-600 font-bold		text-sm	">
                      You have reached all the attempts. You can't attempt this
                      quiz any more
                    </span>
                  </div>
                ) : (
                  <button
                    onClick={this.handleClick}
                    className="bg-transparent  bg-orange text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded item-center"
                  >
                    Attempt
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {this.state.loadComplete &&
          this.state.instructionWindow &&
          this.state.quizLanguage &&
          !this.state.enterQuiz &&
          this.quizInstructionLookup(this.state.quizLanguage)}
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizView);
