import { SOCIAL_CATEGORY, SOCIAL_CATEGORY_NAMES } from "api/Consts";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import {
  rEmailField,
  rFileField,
  rPhoneField,
  rStringField,
} from "../../../../components/Form/presets";

export const Form1 = (values) => {
  const [schema, setSchema] = useState();

  const createSchema = () => {
    const schema = {
      name: rStringField("First name"),
      date_of_birth: {
        type: "date",
        schema: yup.date().required("Date of birth is required"),
        label: "Date of birth",
      },
      gender: rStringField("Gender", {
        type: "select",
        options: [
          { value: "male", label: "Male" },
          { value: "female", label: "Female" },
          { value: "other", label: "Other" },
        ],
      }),
      social_category: rStringField("Category", {
        type: "select",
        options: Object.keys(SOCIAL_CATEGORY).map((category_name) => ({
          value: SOCIAL_CATEGORY[category_name],
          label: SOCIAL_CATEGORY_NAMES[SOCIAL_CATEGORY[category_name]],
        })),
      }),
      mobile_number: rPhoneField("Phone number"),
      email: rEmailField("Email ID"),
      profile_photo: rFileField("Profile Photo"),
    };

    setSchema(schema);
  };

  useEffect(() => createSchema(), []);

  return schema;
};
