import { COURSES_REF } from "api/Consts";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import userSvc from "services/User";
import { Toast } from "skillstrainer-resource-library";
import { SkillYatraPartialUserDetailsModal } from "./SpecialConditions/SkillYatraPartialUserDetailsModal";

export default function ViewModSpecialConditions(props) {
  const { userId, courseId } = props;
  const history = useHistory();

  const handleProfileDetailsRequirement = useCallback(async (userId) => {
    const [userDetails, userQualifications] = await Promise.all([
      userSvc.fetchUserDetails(userId),
      userSvc
        .fetchUserQualifications(userId)
        .then((res) =>
          res.filter(
            (q) =>
              q.qualifications_lookup?.type &&
              ["pre_12", "12"].includes(q.qualifications_lookup.type)
          )
        ),
    ]);

    // Checking profile photo, gender and qualification existence
    const isProfilePhotoSet = userDetails.profile_photo;
    const isGenderSet =
      typeof userDetails.gender === "string" && userDetails.gender !== "";
    const isQualificationSet = userQualifications.find(
      (q) => q.institution_name && q.qualification_type
    );

    if (!(isGenderSet && isQualificationSet && isProfilePhotoSet)) {
      try {
        const formSubmissionResponse = await Toast.prompt(
          SkillYatraPartialUserDetailsModal,
          {
            userData: {
              userDetails,
              userQualifications,
            },
          }
        );
        console.log(formSubmissionResponse);
        Toast.success("Details updated successfully");
      } catch (err) {
        console.error(err);
        if (err && err.type === "internal") {
          Toast.error(
            "There was an error updating your details. Please refresh and try again"
          );
        } else {
          Toast.error(
            "You can't start the course without filling in the details"
          );
        }
        history.push("/");
      }
    }
  }, []);

  useEffect(() => {
    if (COURSES_REF.SKILL_YATRA.includes(courseId) && userId)
      handleProfileDetailsRequirement(userId);
  }, [userId, courseId]);

  return null;
}
