import React, { Component } from "react";
import { ActionTypes } from "redux/actions/Quiz/ActionTypes";
import { connect } from "react-redux";
import Result from "./Result";
import Review from "./Review";
import Questions from "./Questions";
import { Logout } from "redux/actions/Auth";
import { Timer } from "react-countdown-clock-timer";
import WebCamWrapper from "./WebCamWrapper";
import Container from "components/Container";
import Toast from "utils/Toast";
import ExpandedLoader from "components/ExpandedLoader";

const mapStateToProps = (state) => {
  return {
    ...state.quiz,
    ...state.mode,
    ...state.pager,
    ...state.questionAnswered,
  };
};

//const dispatch = useDispatch();

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (payload) => dispatch({ type: ActionTypes.QuizSubmit, payload }),
  onPagerUpdate: (payload) =>
    dispatch({ type: ActionTypes.PagerUpdate, payload }),
  onTimerUpdate: (payload) =>
    dispatch({
      type: ActionTypes.TimerUpdate,
      payload,
    }),

  signIn: () => dispatch(Logout()),
  onReset: () => dispatch({ type: ActionTypes.ResetQuiz }),
  QuizWindowStart: (payload) =>
    dispatch({ type: ActionTypes.QuizWindowStart, payload }),

  //Logoutt:()=>dispatch(Logout())
});

class Quiz extends Component {
  state = {
    change: false,
    count: 0,
    webCamLoaded: false,
    tabchangeWarnCount: 0,
    webCamrequired: false,
  };

  componentDidMount() {
    this.setState({
      webCamLoaded: this.props.quiz.mode.require_webcam ? false : true,
      webCamrequired: this.props.quiz.mode.require_webcam ? true : false,
    });

    console.log(
      "webcam",
      console.log("webcam", this.props.quiz.mode.require_webcam)
    );
  }

  componentWillUnmount() {
    window.onpopstate = null;

    window.removeEventListener("blur", this.handleChange, false);
    window.removeEventListener("focusout", this.handleChange, false);
    window.removeEventListener("pagehide", this.handleChange, false);
    window.removeEventListener("hidden", this.handleChange, false);
    window.onpageshow = null;
    window.onpagehide = null;
    window.onfocus = null;
    window.onblur = null;
    window.onunload = null;
    window.onbeforeunload = null;
  }

  handleChange = (evt) => {
    if (this.state.webCamLoaded) {
      var visible = true;
      var propName = "hidden";
      evt = evt || window.event;
      if (
        visible &&
        (["blur", "focusout", "pagehide"].includes(evt.type) ||
          (this && this[propName]))
      ) {
        visible = false;

        const TotalCount = this.state.tabchangeWarnCount;

        this.setState({
          tabchangeWarnCount: TotalCount + 1,
        });

        if (TotalCount >= 4) {
          this.setState({
            change: true,
            webCamLoaded: false,
            tabchangeWarnCount: 0,
          });
          this.props.onSubmit("submit");
          this.props.QuizWindowStart(true);
        } else {
          Toast.error("Please avoid to change current tab window");
        }

        this.setState({
          count: 0,
        });
      } else if (
        !visible &&
        (["focus", "focusin", "pageshow"].includes(evt.type) ||
          (this && !this[propName]))
      ) {
        visible = true;
      }
    }
  };

  move = (e) => {
    let id = e.target.id;
    let index = 0;
    if (id === "first") index = 0;
    else if (id === "prev") index = this.props.pager.index - 1;
    else if (id === "next") index = this.props.pager.index + 1;
    else if (id === "last") index = this.props.pager.count - 1;
    else index = parseInt(e.target.id, 10);

    if (index >= 0 && index < this.props.pager.count) {
      let pager = {
        index: index,
        size: 1,
        count: this.props.pager.count,
      };
      this.props.onPagerUpdate(pager);
    }
  };

  setMode = (e) => {
    this.props.onSubmit(e.target.id);
    this.props.QuizWindowStart(true);
    this.setState({
      tabchangeWarnCount: 0,
    });
  };
  timeFinish = (e) => {
    this.props.onSubmit("submit");
    this.props.QuizWindowStart(true);
    this.setState({
      tabchangeWarnCount: 0,
    });
  };

  handleClick = (event) => {
    event.preventDefault();
    // return false;
  };

  copy = (event) => {
    event.preventDefault();
    return false;
  };

  onDoubleClick = (e) => {
    e.preventDefault();
    return false;
  };

  onClickOutside = (e) => {
    e.preventDefault();
    alert("outside");
  };

  submitQuiz = () => {
    this.props.onSubmit("submit");
    this.props.QuizWindowStart(true);
    this.setState({
      tabchangeWarnCount: 0,
    });
  };

  webCamLoadedCall = () => {
    Toast.success("Ready For Quiz");
    this.setState({
      webCamLoaded: true,
    });
  };

  securityFunction = () => {
    /*******window close script ******/

    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();

      e.returnValue = "";
    });

    /****After window close event *****/

    window.addEventListener("unload", this.submitQuiz);

    /****Disable Back button ******/

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    /***Detect Window focus out or In */

    var eventName;
    var visible = true;
    var propName = "hidden";

    if (propName in document) eventName = "visibilitychange";
    else if ((propName = "msHidden") in document)
      eventName = "msvisibilitychange";
    else if ((propName = "mozHidden") in document)
      eventName = "mozvisibilitychange";
    else if ((propName = "webkitHidden") in document)
      eventName = "webkitvisibilitychange";
    if (eventName) document.addEventListener(eventName, this.handleChange);

    if ("onfocusin" in document)
      document.onfocusin = document.onfocusout = this.handleChange; //IE 9
    window.onpageshow =
      window.onpagehide =
      window.onfocus =
      window.onblur =
        this.handleChange; // Changing tab with alt+tab

    // Initialize state if Page Visibility API is supported
    if (document[propName] !== undefined) {
      this.handleChange({ type: document[propName] ? "blur" : "focus" });
    }
  };

  renderMode() {
    if (this.props.mode === "quiz") {
      this.securityFunction();

      return (
        <div className="">
          <Questions move={this.move} language={this.props.quizLanguage} />
        </div>
      );
    }
    // else if (this.props.mode === "review") {
    //   return <Review quiz={this.props.quiz} move={this.move} />;
    // } else {
    //   return (
    //     <Result
    //       questions={this.props.quiz || []}
    //       attempt={this.props.attempt}
    //       windowFocus={this.state}
    //     />
    //   );
    // }
  }

  render() {
    return (
      <Container>
        {!this.state.webCamLoaded && <ExpandedLoader />}

        {this.state.webCamLoaded && this.props.mode !== "submit" && (
          <div
            className="quiz-container timer-wrapper "
            onContextMenu={this.handleClick}
            onCopy={this.copy}
            onDoubleClick={this.onDoubleClick}
            style={{ userSelect: "none" }}
            onClickOutside={this.onClickOutside}
            // resizable={this.resizable}
          >
            {this.props.attempt.enable_timer && (
              <div className="inline-flex gap-4">
                <p className=" font-bold">Quiz Time Remaining:</p>

                <div className="  text-red-600 font-bold	">
                  <Timer
                    durationInSeconds={this.props.attempt.timelimit_number}
                    formatted={true}
                    isPaused={this.state.webCamLoaded ? false : true}
                    showPauseButton={false}
                    showResetButton={false}
                    onFinish={this.timeFinish}
                    className={`text-red-600`}
                  />
                </div>
              </div>
            )}
            <div className="flex justify-between">
              {this.renderMode()}

              <div className="">
                <Review quiz={this.props.quiz} move={this.move} />
              </div>
            </div>
            <div className="flex py-8 ml-24 -mt-20 ">
              {(this.props.quiz.blockNext
                ? this.props.questionAnswered[this.props?.pagesCount - 1]
                : this.props.pager.index == this.props?.pagesCount - 1) && (
                <button
                  type="button"
                  id="submit"
                  className={`hover:opacity-70 text-white bg-green-700  font-medium rounded-full text-sm px-4 py-2.5 ml-2 `}
                  onClick={this.setMode}
                  disabled={
                    this.props?.pager.index + 1 != this.props.pagesCount
                  }
                >
                  Submit Quiz
                </button>
              )}
            </div>
          </div>
        )}

        {this.props.mode !== "submit" && this.state.webCamrequired && (
          <div className="max-w-md">
            <WebCamWrapper
              submitQuiz={this.submitQuiz}
              webCamLoaded={this.webCamLoadedCall}
            />
          </div>
        )}

        {/* { this.props.mode === "submit" && (
          <div
            className="quiz-container timer-wrapper p-20"
            onContextMenu={this.handleClick}
            onCopy={this.copy}
            onDoubleClick={this.onDoubleClick}
            style={{ userSelect: "none" }}
            onClickOutside={this.onClickOutside}
            // resizable={this.resizable}
          >
             {this.renderMode()}
            </div>
            )} */}
      </Container>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
