import { useState } from 'react';
import EnterpriseCarousel from './enterprisecarousel';
import EnterpriseModal from './enterprisemodal';
import PartnerMarquee from './partnermarquee';
import analyticsEvents from './analyticsEvents';
export default function Enterprise() {
	const contactUs = analyticsEvents();
	const [modalOpen, setModalOpen] = useState(false);
	const multiEvent = () => {
		setModalOpen(true);
		console.log('contact Us called');
		contactUs();
	};
	return (
		<>
			<div className="bg-gray-50 p-4">
				<EnterpriseCarousel />
				<div className="flex items-center justify-center w-full my-3">
					<button
						onClick={multiEvent}
						className="bg-orange text-white rounded-xl text-center text-lg px-7 py-2"
					>
						Contact Us
					</button>
				</div>
			</div>
			<PartnerMarquee />
			<EnterpriseModal width="40%" isOpen={modalOpen} onClose={setModalOpen} />
		</>
	);
}
