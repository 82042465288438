export const ActionTypes = {
  QuizLoad: "QuizLoad",
  QuizAnswer: "QuizAnswer",
  QuizSubmit: "QuizSubmit",
  PagerUpdate: "PagerUpdate",
  ResetQuiz: "ResetQuiz",
  TimerUpdate: "TimerUpdate",
  QuizWindowStart: "QuizWindowStart",
  ScormFullScreen: "ScormFullScreen",
  ResetQuizState: "ResetQuizState",
};
