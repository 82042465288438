/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Route, Switch, Redirect, matchPath } from "react-router-dom";
import { Navbar, LeftSidebar, Footer } from "../components/shared";
import LandingHomePage from "./LandingHomePage";
import { useSelector } from "react-redux";

import CustomRoute from "components/CustomRoute";
import Settings from "pages/Settings";
import NotFound from "components/NotFound";
import LiveCourse from "components/LiveCourse";
import Batches from "components/Batches";
import SelectedBatches from "components/SelectedBatches";
import Coupons from "./Coupons";

import { RecommendedCourses } from "components/recommendedcourses";
import { OnboardingUser } from "components/onboarding";
import { AllScholarships } from "components/scholarships";
import { AllJobs } from "components/jobs";
import { Referrals } from "components/referrals";
import ExpandedLoader from "components/ExpandedLoader";
import { ChooseCourse, GiftPaymentSuccess } from "components/giftcourse";
import Enterprise from "components/enterprise/enterprise";
import Viewcourse from "components/userdashboard/viewcourse";
import ViewMod from "components/userdashboard/ViewMod";
import CourseDetail from "components/recommendedcourses/course-detail";

import { UserDashboard } from "components/userdashboard";

import CourseOrder from "components/CourseOrder";
import CourseSubscriptionOrder from "components/CourseSubscriptionOrder";
import SubscriptionOrders from "components/CourseBuilder/RazorpayOrder/SubscriptionOrders";
import SubscriptionView from "components/CourseBuilder/RazorpayOrder/SubscriptionView";
import AllOrders from "components/CourseBuilder/RazorpayOrder/AllOrders";
import OrderView from "components/CourseBuilder/RazorpayOrder/OrderView";
import ViewCourseSidebar from "components/shared/ViewCourseSidebar";
import Mycourse from "components/userdashboard/Mycourse";
import { useLocation } from "react-router-dom";
import CertificateVerify from "./CertificateVerify";
import JoinBatch from "./JoinBatch";
import demo1 from "components/userdashboard/demo1";
import SignupForOlympiad from "./SignupForOlympiad";
import UserScholarshipApplications from "components/scholarships/UserScholarshipApplications";
import MyCertificates from "components/completedCourses/myCertificates";
const commonRoutes = [];
export const GlobalControllers = {};

const Index = () => {
  const quiz = useSelector(({ quiz }) => quiz);

  const auth = useSelector(({ auth }) => {
    return auth;
  });
  const currentURL = window.location.pathname;

  const loaction = useLocation();

  const courseViewPath = matchPath(loaction.pathname, {
    path: `/course/view/:id`,
  });

  const courseViewTopicPath = matchPath(loaction.pathname, {
    path: `/course/view/:id/lesson/:id`,
  });

  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState();
  GlobalControllers.toggleSidebar = (targetValue = !isLeftSidebarOpen) =>
    setIsLeftSidebarOpen(targetValue);

  return (
    <>
      {auth.authenticated === null ? (
        <div className="h-screen w-screen flex items-center justify-center">
          <ExpandedLoader />
        </div>
      ) : auth.authenticated ? (
        auth.onboarding === null ? (
          <div className="h-screen w-screen flex items-center justify-center">
            <ExpandedLoader />
          </div>
        ) : (
          <>
            {currentURL !== "/course/mod/view" ||
            currentURL !== "/course/view/id" ? (
              <Navbar />
            ) : (
              ""
            )}

            {!auth.onboarding ? (
              <>
                <div className="flex flex-wrap w-full">
                  {courseViewPath || courseViewTopicPath ? (
                    <ViewCourseSidebar
                      params={
                        courseViewPath
                          ? courseViewPath.params
                          : courseViewTopicPath
                          ? courseViewTopicPath.params
                          : ""
                      }
                      isOpen={isLeftSidebarOpen}
                    />
                  ) : (
                    <LeftSidebar isOpen={isLeftSidebarOpen} />
                  )}
                  <div
                    className={`w-full ${
                      quiz.QuizMode ? "lg:w-10/12" : ""
                    } cource_bg p-8`}
                  >
                    <Switch>
                      <CustomRoute path="/settings" component={Settings} />
                      <Route path={"/"} exact component={UserDashboard} />
                      <Route
                        path={"/LiveCourse"}
                        exact
                        component={LiveCourse}
                      ></Route>
                      <Route
                        path={"/Batches"}
                        exact
                        component={Batches}
                      ></Route>
                      <Route
                        path={"/SelectedBatches"}
                        exact
                        component={SelectedBatches}
                      ></Route>
                      {/* Courses */}
                      <Route
                        path="/courses/:courseId"
                        exact
                        component={CourseDetail}
                      />
                      <Route
                        path={"/courses"}
                        exact
                        component={RecommendedCourses}
                      ></Route>

                      <Route
                        path={"/course/order/:id"}
                        exact
                        component={CourseOrder}
                      ></Route>

                      <Route
                        path={"/course/subscription/:id"}
                        exact
                        component={CourseSubscriptionOrder}
                      ></Route>

                      <Route
                        path={"/subscription"}
                        exact
                        component={SubscriptionOrders}
                      ></Route>

                      <Route
                        path={"/subscription/view/:id"}
                        exact
                        component={SubscriptionView}
                      ></Route>

                      <Route
                        path={"/orders"}
                        exact
                        component={AllOrders}
                      ></Route>

                      <Route
                        path={"/order/view/:id"}
                        exact
                        component={OrderView}
                      ></Route>

                      <Route
                        path="/my-course"
                        exact
                        component={Mycourse}
                      ></Route>
                      <Route
                        path="/course/view/:id"
                        exact
                        component={Viewcourse}
                      ></Route>
                      <Route
                        path="/course/view/:id/lesson/:id"
                        exact
                        component={ViewMod}
                      ></Route>
                      <Route
                        path="/course/demo"
                        exact
                        component={demo1}
                      ></Route>
                      <CustomRoute path="/coupons" component={Coupons} />
                      <Route
                        path={"/allscholarships"}
                        exact
                        component={AllScholarships}
                      ></Route>
                      <Route
                        path={"/my-scholarships"}
                        exact
                        component={UserScholarshipApplications}
                      ></Route>

                      <Route path={"/jobs"} exact component={AllJobs}></Route>

                      <Route
                        path="/join-batch/:batch_id"
                        component={JoinBatch}
                      />

                      <Route
                        path={"/myCertificates"}
                        exact
                        component={MyCertificates}
                      ></Route>
                      <Route
                        path={"/referral"}
                        exact
                        component={Referrals}
                      ></Route>

                      <CustomRoute path="/coupons" component={Coupons} />
                      <Route
                        path={["/login", "/signup"]}
                        component={() => <Redirect to="/" />}
                      />
                      {commonRoutes.map((route) => route)}

                      <Route component={NotFound} />
                    </Switch>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* <Onboarding /> */}
                <OnboardingUser />
              </>
            )}
          </>
        )
      ) : (
        <>
          <Navbar />
          <Switch>
            <Route
              path={["/signup", "/login", "/"]}
              exact
              render={(props) => <LandingHomePage {...props} />}
            />
            <Route
              path="/signup-for-olympiad"
              exact
              render={(props) => <SignupForOlympiad {...props} />}
            />

            <Route path={"/giftcourse"} exact component={ChooseCourse}></Route>
            <Route path={"/enterprise"} exact component={Enterprise}></Route>
            <Route
              path={"/giftpaymentsuccess"}
              exact
              component={GiftPaymentSuccess}
            ></Route>
            <Route
              path={"/certificate/verify"}
              exact
              component={CertificateVerify}
            ></Route>

            {commonRoutes}
            <Route
              path="/"
              render={() => {
                return <Redirect to="/login" />;
              }}
            />
          </Switch>
          <Footer />
        </>
      )}
    </>
  );
};

export default Index;
