import { useParams } from "react-router";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Container from "components/Container";
import moment from "moment";
import { changeNameOnDownload } from "utils/resources";
import { useQuery } from "@apollo/client";
import { GET_ORDER_DETAILS } from "graphql/queries/RazorpayOrder";

export default function OrderView() {
  const { id } = useParams();
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const [orderDetails, setOrderDetails] = useState({
    order_status: null,
    ordercourse: {
      full_name: null,
      cost: null,
    },
    order_create_at: null,
    rajorpay_payment_id: null,
    order_contact: null,
    order_email: null,
    amount: null,
  });
  const [downloadLoad, setDownlaodLoad] = useState(false);
  const { data: orderData } = useQuery(GET_ORDER_DETAILS, {
    variables: {
      id: id,
    },
  });

  useEffect(async () => {
    if (orderData) {
      setOrderDetails({
        invoice: orderData.courses_user_course_order[0].invoice,
        invoice_id: orderData.courses_user_course_order[0].invoice_id,
        order_status: orderData.courses_user_course_order[0].order_status,
        discount: orderData.courses_user_course_order[0].discount,
        ordercourse: {
          full_name:
            orderData.courses_user_course_order[0].ordercourse.full_name,
          cost: orderData.courses_user_course_order[0].cost || 0,
        },
        tax: orderData.courses_user_course_order[0].tax || "NA",
        order_create_at: orderData.courses_user_course_order[0].order_create_at,
        rajorpay_payment_id:
          orderData.courses_user_course_order[0].rajorpay_payment_id,
        order_contact: orderData.courses_user_course_order[0].order_contact,
        order_email: orderData.courses_user_course_order[0].order_email,
        amount: orderData.courses_user_course_order[0].amount,
      });
    }
  }, [orderData]);

  const downloadInvoice = async () => {
    setDownlaodLoad(true);

    await changeNameOnDownload(
      orderDetails.invoice,
      "Invoice-" + orderDetails.ordercourse.full_name + "-" + userId
    );
    setDownlaodLoad(false);
  };

  return (
    <Container>
      <div className="p-4">
        <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          Order Details
        </h1>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <tbody>
                  <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Order Status
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {orderDetails.order_status}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Course Name
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {orderDetails.ordercourse.full_name}
                    </td>
                  </tr>

                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Course Price
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      ₹{orderDetails.ordercourse.cost}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Order Date
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {moment
                        .unix(orderDetails.order_create_at)
                        .format("YYYY/MM/DD")}
                    </td>
                  </tr>

                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Coupon
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {orderDetails.ordercoupon
                        ? orderDetails.ordercoupon.code
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Payment Id
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {orderDetails.rajorpay_payment_id}
                    </td>
                  </tr>

                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Order Contact
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {orderDetails.order_contact}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Order email
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {orderDetails.order_email}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Discount
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      {Boolean(orderDetails?.discount)
                        ? `₹${orderData.discount}`
                        : "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Tax
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      ₹{orderDetails.tax}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Order Amount
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      ₹{orderDetails.amount}
                    </td>
                  </tr>

                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Invoice
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      <button
                        class="text-white rounded-md text-md px-7 py-1 bg-orange font-semibold"
                        onClick={downloadInvoice}
                      >
                        {downloadLoad ? "Please Wait..." : "Download"}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
