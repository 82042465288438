import { env } from "api/Consts";
import { get_moodle_user } from "api/MoodleApi";
import {
  SignupUser,
  LoginUser,
  LogoutUser,
  LoginUserWithProvider,
} from "../../../api/Auth";
import { ApplyCoupon } from "../Course";
import Toast from "wrappers/Toast";

const LOGIN = "LOGIN";
const SIGNUP = "SIGNUP";
const SET_SIGNUP_NOTICE = "SIGNUP_NOTICE";
const LOGIN_ERROR = "LOGIN_ERROR";
const SIGNUP_ERROR = "SIGNUP_ERROR";
const LOGOUT = "LOGOUT";

const SET_ONBOARDING = "SET_ONBOARDING";
const SET_USER = "SET_USER";
const SET_DB_USER_PROPS = "SET_DB_USER_PROPS";

export const Login = (data, { onError, onSuccess } = {}) => {
  return async (dispatch) => {
    try {
      const response = await LoginUser(data);
      if (!response.data.success) onError(response.data);
      // @label: removed moodle user
      // else {
      //   const moodle_user = await get_moodle_user();
      //   response.data.moodle_user = moodle_user?.data;
      // }

      dispatch({
        type: SET_ONBOARDING,
        payload: !response.data.is_user_onboarded,
      });
      dispatch({
        type: response.data.success ? LOGIN : LOGIN_ERROR,
        payload: response.data,
      });

      if (typeof onSuccess === "function") onSuccess();
    } catch (err) {
      dispatch({
        type: LOGIN_ERROR,
        payload: { message: "An unknown error occurred" },
      });
      if (typeof onError === "function") onError();
      else Toast.error("An error occurred during sign in");
    }
  };
};

export const Logout = (data) => {
  return async (dispatch) => {
    LogoutUser();
    return dispatch({
      type: LOGOUT,
      payload: {},
    });
  };
};

export const Signup = (data, options) => {
  const { onError } = options || {};
  return async (dispatch) => {
    try {
      const response = await SignupUser(data);
      dispatch({
        type: response.data.success ? SIGNUP : SIGNUP_ERROR,
        payload: response.data,
      });

      // @label: removed moodle user
      // if (response.data.success) {
      //   const moodle_user = await get_moodle_user();
      //   response.data.moodle_user = moodle_user?.data;
      // }

      if (response.data.success && data.coupon_code)
        dispatch(ApplyCoupon(data.coupon_code, response.data.db_user.id));
    } catch (err) {
      dispatch({
        type: SIGNUP_ERROR,
        payload: { message: "An unknown error occured" },
      });
      if (typeof onError === "function") onError();
      else Toast.error("An error occured during sign up");
    }
  };
};

export const LoginWithProvider = (provider, payload, onEnd) => {
  return async (dispatch) => {
    const apiResponse = await LoginUserWithProvider(provider, payload);

    // @label: removed moodle user
    // if (apiResponse.data.success) {
    //   try {
    //     const moodle_user = await get_moodle_user();
    //     apiResponse.data.moodle_user = moodle_user?.data;
    //   } catch (err) {
    //     if (env.isProduction) throw err;
    //   }
    // }

    if (typeof onEnd === "function") onEnd();
    dispatch({
      type: SET_ONBOARDING,
      payload: !apiResponse.data.is_user_onboarded,
    });
    return dispatch({
      type: apiResponse.data.success ? LOGIN : LOGIN_ERROR,
      payload: apiResponse.data,
    });
  };
};

export {
  LOGIN,
  SIGNUP,
  SET_SIGNUP_NOTICE,
  LOGOUT,
  LOGIN_ERROR,
  SIGNUP_ERROR,
  SET_ONBOARDING,
  SET_USER,
  SET_DB_USER_PROPS,
};
