import React, { useRef, useState, useEffect } from "react";
import * as cocossd from "@tensorflow-models/coco-ssd";
import Webcam from "react-webcam";
import { drawRect } from "./utilities";
import QuizToast from "./QuizToast";

const WebCamWrapper = ({ submitQuiz, webCamLoaded }) => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [toast, setToast] = useState({ delayTimer: false });
  const [warningCount, setWarningCount] = useState(0);
  const [startDetect, setStartDetect] = useState(true);

  useEffect(() => {
    if (toast.delayTimer) {
      if (warningCount <= 3) {
        setWarningCount(warningCount + 1);

        const runDetect = () => {
          QuizToast.error(
            "One mischievous person found  avoid these kind of things"
          );
          setToast({
            delayTimer: false,
          });
        };

        const intervalId = setInterval(() => {
          runDetect();
        }, 4000 * 2); // in milliseconds

        return () => {
          clearInterval(intervalId);
        };
      } else {
        QuizToast.error(
          "Sorry your warning limit reached and your this quiz attempt automatically submitted"
        );
        setStartDetect(false);
        submitQuiz();
      }
    }
  }, [toast.delayTimer]);

  console.log("warningCount", warningCount);

  const runCoco = async () => {
    const net = await cocossd.load();
    console.log("Webcam loaded.");

    webCamLoaded();
    //  Loop and detect hands

    if (startDetect) {
      setInterval(() => {
        detect(net);
      }, 5000);
    }
  };

  const detect = async (net) => {
    // Check data is available
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      console.log("All ok.");

      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      // Set canvas height and width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      // Make Detections
      const obj = await net.detect(video);

      console.log("obj", obj);

      let person = [];

      obj.length > 0 &&
        obj.filter((e) => {
          if (e.class === "person") {
            person.push(e.class);
          }
        });

      console.log("person", person.length);

      if (person.length >= 2) {
        setToast({
          delayTimer: true,
        });
      }

      // Draw mesh
      const ctx = canvasRef.current.getContext("2d");
      drawRect(obj, ctx);
    }
  };

  useEffect(() => {
    runCoco();
  }, []);

  return (
    <>
      <Webcam
        ref={webcamRef}
        muted={true}
        style={{
          position: "absolute",
          right: 0,
          bottom: -112,
          textAlign: "center",
          zindex: 9,
          width: 250,
        }}
      />

      <canvas
        ref={canvasRef}
        style={{
          position: "absolute",
          bottom: -139,
          right: 0,
          textAlign: "center",
          zindex: 8,
          width: 208,
          height: 220,
        }}
      />
    </>
  );
};
export default WebCamWrapper;
