import { toast } from "react-toastify";

const Toast = {
  success: (msg = "Success!") =>
    toast.success(msg, {
      position: "top-center",
      closeOnClick: true,
      autoClose: 5000,
    }),
  error: (msg = "An error occured") =>
    toast.error(msg, {
      position: "top-center",
      closeOnClick: true,
      autoClose: 5000,
    }),
};

export default Toast;
