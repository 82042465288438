import React from "react";
import { Link } from "react-router-dom";

export default function PurchaseDemoedCourse(props) {
  const { course } = props;

  return (
    <div className="w-full flex flex-col sm:flex-row sm:h-52 md:h-60 purchase-demoed-course-card">
      <div className="relative w-full overflow-hidden">
        <img
          src={course.image_url}
          style={{ minWidth: "100%", minHeight: "100%" }}
        />
        <div className="h-full w-full absolute top-0 left-0 thumbnail-overlay" />
      </div>
      <div className="w-full p-5 text flex flex-col items-center justify-center sm:items-start sm:h-full">
        <span className="font-semibold text-lg">
          You attended a demo for {course.full_name}
        </span>
        <span className="my-3">Why not purchase it?</span>
        <Link to={`/buy-course/` + course.id}>
          <button className="btn-primary">Get enrolled now</button>
        </Link>
      </div>
    </div>
  );
}
