import { wireEventValue } from "utils/func";
import { useState, useEffect } from "react";
import Toast from "utils/Toast";

export const DURATION_TYPE = {
  yearsAndMonths: "yearsAndMonths",
};

export default function OnboardingDuration({
  value = { months: 0, years: 0 },
  displayName,
  optional,
  onChange,
}) {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (errors.year_error)
      Toast.error("Years of Experience should be greater than 0");
  }, [errors]);

  return (
    <div>
      <label className="block text-lg font-semibold text-gray-800">
        {displayName}
      </label>
      <div className="flex">
        <input
          min="0"
          type="number"
          className={`block w-full sm:text-sm rounded-md border-none outline-none text-gray-800 bg-japanese_indigo-light mt-4 p-3 mr-2`}
          placeholder="Years"
          onChange={wireEventValue((val) => {
            if (val >= 0) {
              setErrors({ ...errors, year_error: false });
              onChange({ ...value, years: val || 0 });
            } else {
              setErrors({ ...errors, year_error: true });
            }
          })}
        />
        <select
          className={`block w-full sm:text-sm rounded-md border-none outline-none text-gray-800 bg-japanese_indigo-light mt-4 p-3 md:p-4`}
          onChange={wireEventValue((val) =>
            onChange({ ...value, months: val })
          )}
        >
          <option>Months</option>
          {new Array(12)
            .fill("")
            .map((e, index) => index)
            .map((monthNum) => (
              <option value={monthNum}>{monthNum}</option>
            ))}
        </select>
      </div>
    </div>
  );
}
