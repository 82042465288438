import { GET_COURSE_MAPPING_DATA } from "graphql/queries/CourseBuilder/CourseSection";
import { apolloClient } from "../App";
import queryClient from './queryClient';
import { useQuery } from "@tanstack/react-query";

// Async function to fetch course module mapping data

export const fetchCourseModuleMapping = async (id) => {
  const data = await queryClient.fetchQuery({
    queryKey: ["fetchCourseModuleMapping", id],
    queryFn: async () => {
      const response = await apolloClient.query({
        query: GET_COURSE_MAPPING_DATA,
        variables: { id },
      });
      // Directly return the first item from the course module mapping data
      return response.data.courses_course_module_mapping[0];
    },
    enabled: !!id, // Only fetch if id is provided
  });

  console.log("🚀 ~ fetchCourseModuleMapping ~ mapping:", data);

  return data;
};


export const useFetchCourseModuleMapping = (id) => {
  return useQuery(
    ["fetchCourseModuleMapping", id],
    () => fetchCourseModuleMapping(id),
    {
      enabled: !!id, // Only attempt to fetch if an id is provided
      // Additional options such as staleTime, cacheTime, etc., can be specified if needed
    }
  );
};