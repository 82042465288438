import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "skillstrainer-resource-library/css/styles.css";
import "assets/css/index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";

import Store from "./redux/store";

window.logout = () => {
  document.cookie = "access_token=";
  document.cookie = "jwt_token=";
};

setInterval(() => {
  const iframes = document.getElementsByTagName("iframe");
  for (const iframe of iframes)
    if (iframe.style.zIndex == "2147483647") iframe.remove();
}, 1000);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
