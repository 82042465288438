import { gql, useQuery } from "@apollo/client";
import { SignupUser } from "api/Auth";
import { rStringField } from "components/Form/presets";
import { useEffect, useRef, useState } from "react";
import { Form } from "skillstrainer-resource-library";
import * as yup from "yup";
import userSvc from "services/User";
import Toast from "wrappers/Toast";
import { error } from "utils/error";
import { Login } from "redux/actions/Auth";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { SCHOOL_QUALIFICATIONS_HEIRARCHY } from "api/Consts";
import { useHistory } from "react-router-dom";

export default function SignupForOlympiad(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const formRef = useRef({});

  const [submittingForm, setSubmittingForm] = useState();
  const onSubmit = async (values) => {
    console.log("Submitting form");
    setSubmittingForm(true);

    const {
      full_name,
      id,
      password,
      highest_school_qualification,
      highest_school_qualification_institute_name,
    } = values;

    // Signup user
    const signupResponse = await SignupUser({
      full_name,
      id,
      password,
    })
      .then((res) => {
        if (!res.data.success) error(res.data.message);
        return res.data.db_user;
      })
      .catch((err) => {
        console.error(err);
        Toast.error(err.message || "Couldn't register user");
      });

    if (!signupResponse) return;

    try {
      // Add qualification
      const qualificationResponse = await userSvc
        .setHighestQualification(
          {
            id: highest_school_qualification,
            institutionName: highest_school_qualification_institute_name,
          },
          signupResponse.id
        )
        .catch((err) => {
          console.error(err);
          error("Couldn't insert qualifications");
        });

      // Add skip onboarding form tag
      const tagResponse = await userSvc
        .createUserTag({
          name: "onboarded",
          value: "1",
          user_id: signupResponse.id,
        })
        .catch((err) => {
          console.log("Couldn't insert tag");
          console.error(err);
        });

      console.log("Success", {
        signupResponse,
        qualificationResponse,
        tagResponse,
      });
    } catch (err) {
      console.log(err);
      Toast.error(err.message || "Couldn't create qualifications");
    }

    dispatch(Login({ id, password }, { onSuccess: () => history.push("/") }));
  };

  return (
    <div className="w-full flex flex-col items-center justify-center py-14">
      <div className="font-semibold text-2xl">Sign up for Olympiad</div>
      <div className="w-1/2 my-5">
        <Form
          className="grid grid-cols-2 gap-x-4"
          formBuilder={SignupForOlympiadFormBuilder}
          ref={formRef}
          hideSubmit
          onSubmit={onSubmit}
        />
      </div>

      <button
        className="btn-primary"
        disabled={submittingForm}
        onClick={() =>
          console.log(formRef.current.submit) || formRef.current.submit()
        }
      >
        {submittingForm ? "Submitting form..." : "Submit"}
      </button>
    </div>
  );
}

const SignupForOlympiadFormBuilder = (values) => {
  const [schema, setSchema] = useState({});
  const qualificationsOptions = useMemo(
    () =>
      SCHOOL_QUALIFICATIONS_HEIRARCHY.reduce((allOptions, cur) => {
        allOptions.push(...cur);
        return allOptions;
      }, []),
    []
  );

  useEffect(() => {
    const schema = {
      full_name: rStringField("Name", { required: true }),
      id: rStringField("Email / Phone number", { required: true }),
      password: rStringField("Password", {
        type: "password",
        required: true,
        schema: yup
          .string()
          .matches(
            /^[\S]{5,30}$/g,
            "Password length should be min 5 characters and max 30 without any spaces"
          ),
      }),

      // Details (Institute Name)
      highest_school_qualification_institute_name: {
        label: "School Name",
        required: true,
        schema: yup.string(),
      },
      // Highest school qualification
      highest_school_qualification: {
        label: "Standard",
        required: true,
        type: "select",
        options: qualificationsOptions.map((q) => ({
          value: q.id,
          label: q.name,
        })),
        schema: yup.string(),
      },
    };

    setSchema(schema);
  }, []);

  return schema;
};
