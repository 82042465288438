import razorpayService from "services/razorpay";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useSubscription } from "utils/hooks";
import { GET_USER_ORDERS } from "graphql/subscriptions/Payment";

export default function AllOrders() {
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const { data: ordersRes } = useSubscription(GET_USER_ORDERS, {
    variables: { user_id: userId },
  });
  const orders = ordersRes?.courses_user_course_order || [];

  const columns = [
    {
      name: "Order",
      selector: (row, index) => `#${row.id}`,
      sortable: true,
    },

    {
      name: "Course",
      selector: (row, index) => `${row.ordercourse.full_name}`,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row, index) =>
        row.order_status ? `${row.order_status}` : " Created",
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row, index) =>
        row.order_create_at
          ? `${moment.unix(row.order_create_at).format("YYYY/MM/DD")}`
          : "",
      sortable: true,
    },

    {
      name: "Order Total",
      selector: (row, index) => (row.amount != null ? `₹${row.amount}` : ""),
      sortable: true,
    },

    {
      cell: (row) => (
        <Link to={`/order/view/${row.id}`}>
          <button className="text-white rounded-md text-md px-7 py-1 bg-orange font-semibold">
            View
          </button>
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });
  return (
    <div>
      <div className="p-4">
        <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          All Orders
        </h1>
      </div>
      <DataTable
        columns={columns}
        pagination
        data-key={columns}
        data={orders}
        selectableRows={false}
        selectableRowsHighlight={false}
        onChangeRowsPerPage={handlePerRowsChange}
        highlightOnHover
        onChangePage={handlePageChange}
        // onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
        //     handleRowSelection(allSelected, selectedRows);
        // }}
      />
    </div>
  );
}
