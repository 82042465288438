import React, { useState, useEffect } from "react";
import {
  annualIncomeField,
  occupationField,
  rPhoneField,
  rStringField,
} from "../../../../components/Form/presets";

// Father's details
export const Form1 = (values) => {
  const [schema, setSchema] = useState();

  const createSchema = () => {
    const schema = {
      name: rStringField("Name"),
      occupation: occupationField("Occupation"),
      annual_income: annualIncomeField("Annual income (INR)"),
      mobile_number: rPhoneField("Mobile number"),
    };
    setSchema(schema);
  };

  useEffect(() => createSchema(), []);

  return schema;
};

// Mother's details
export const Form2 = (values) => {
  const [schema, setSchema] = useState({});

  const createSchema = () => {
    const schema = {
      name: rStringField("Name"),
      occupation: occupationField("Occupation"),
      annual_income: annualIncomeField("Annual income (INR)"),
      mobile_number: rPhoneField("Mobile number"),
    };
    setSchema(schema);
  };

  useEffect(() => createSchema(), []);

  return schema;
};
