import React, {useContext} from "react";
import {MULTI_SELECT_JOBS_FILTERS} from 'api/Consts';
import { wireEventValue } from "utils/func";
import { FiltersContext } from '../alljobs';


export default function JobSearchBox() {
  const {filters, setFilters, setPageNo} = useContext(FiltersContext);
  let wait;

  return (
    <div className="hidden md:block bg-white relative p-1 rounded-md border-2 border-orange w-auto">
      <div className="absolute top-3 left-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 mr-1 mb-2 text-orange"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
      <input
        className="ml-10 border-white rounded-md focus:outline-none mx-2 w-80"
        type="search"
        placeholder="Search by Title / Role / Description"
        onChange={wireEventValue((value) =>{
          clearTimeout(wait);
          wait = setTimeout(() => {
            setFilters({...filters, [MULTI_SELECT_JOBS_FILTERS.SEARCH]: value})
            setPageNo(0);
          }, 1000);
          
        })}
        // value={filters[MULTI_SELECT_JOBS_FILTERS.SEARCH]}
      />
    </div>
  );
}
