import Modal from "components/Modal";
import _ from "lodash";
import userSvc from "services/User";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { BiChevronDown } from "react-icons/bi";
import { USER_ACCOUNT_TYPE } from "api/Consts";
import Toast from "wrappers/Toast";
import { redeemRewards, getRedeemableAmount } from "api/referrals";

export default function RedeemRewardModal(props) {
  console.log("props==", props);
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const [amountToRedeem, setAmountToRedeem] = useState("");
  const [redeemableAmount, setRedeemableAmount] = useState(0);

  useEffect(async () => {
    if (props.redeemRewardsOpen) {
      const redeemableAmount = await getRedeemableAmount(userId);
      if (redeemableAmount.data.success) {
        console.log(
          "redeemableAmount==",
          redeemableAmount.data.redeemable_amount
        );
        setRedeemableAmount(redeemableAmount.data.redeemable_amount);
      } else {
        Toast.error(redeemableAmount.data.message);
      }
    }
  }, [props.redeemRewardsOpen]);

  console.log("props.redeemRewardsOpen===", props.redeemRewardsOpen);

  const redeemAmount = async () => {
    if (amountToRedeem == "") {
      Toast.error("Please fill the amount to redeem");
      return;
    }
    const redeemRewardsResult = await redeemRewards({
      user_id: userId,
      amount_to_redeem: amountToRedeem,
    });

    if (redeemRewardsResult.data.success) {
      Toast.success("Payout successfull!");
      amountToRedeem("");
      props.setRedeemRewardsOpen(false);
    } else {
      Toast.error(redeemRewardsResult.data.message);
    }
  };

  return (
    <>
      <Modal
        isOpen={props.redeemRewardsOpen}
        onClose={() => props.setRedeemRewardsOpen(false)}
      >
        <div style={{ maxHeight: "40rem" }} className="flex font-poppins">
          <div className="flex-1 flex flex-col">
            <div className="flex h-full w-full">
              <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-scroll mb-8">
                <div className="flex w-full mx-auto px-6">
                  <div className="flex flex-col w-full h-full">
                    <h1 className="text-2xl font-semibold text-japanese_indigo mx-2">
                      Redeem Reward
                    </h1>
                    <h5 className=" font-semibold text-japanese_indigo mx-2">
                      Maximum Redeemable Reward:{" "}
                      <span className="text-orange">
                        Rs. {redeemableAmount}
                      </span>
                    </h5>
                    <div className="flex flex-col justify-evenly my-4">
                      <div className="flex p-4">
                        <div className="my-auto text-orange font-semibold rounded-md mr-4 w-52">
                          Amount To Redeem
                        </div>
                        <input
                          type="tel"
                          className="input"
                          placeholder="Enter amount you want to redeem"
                          value={amountToRedeem}
                          onChange={({ target: { value } }) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              (value == "" || re.test(value)) &&
                              redeemableAmount >= value
                            )
                              setAmountToRedeem(value);
                          }}
                        />
                      </div>
                    </div>
                    <span className="justify-end my-4">
                      <button
                        className="px-4 bg-orange text-white font-semibold p-2 rounded-md text-lg"
                        onClick={redeemAmount}
                      >
                        Redeem
                      </button>
                    </span>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
