/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <>
      <img
        onClick={onClick}
        className="absolute top-12 lg:top-52 -right-11 lg:-right-16 w-10 h-10 cursor-pointer"
        src="./right-arrow.png"
        alt="right"
      />
    </>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <>
      <img
        onClick={onClick}
        className="absolute top-12 lg:top-52 -left-11 lg:-left-16 w-10 h-10 cursor-pointer"
        src="./left-arrow.png"
        alt="left"
      />
    </>
  );
}

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="rounded-md p-6 mt-4 w-11/12 lg:w-9/12 mx-auto">
        <Slider {...settings}>
          <img src="./enterprise-1.png" alt="ent-1" />
          <img src="./enterprise-2.png" alt="ent-2" />
          <img src="./enterprise-3.png" alt="ent-3" />
          <img src="./enterprise-4.png" alt="ent-4" />
        </Slider>
      </div>
    );
  }
}
