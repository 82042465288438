import { setCookie, getCookie } from "./Cookie";

export const setLoginCookie = (value) => {
  setCookie("access_token", value, 3650);
};

export const setJwtToken = (value) => {
  setCookie("jwt_token", value, 3650);
};

export const getLoginCookie = () => {
  return getCookie("access_token");
};

export const getJwtToken = (value) => {
  return getCookie("jwt_token");
};

export const getIdentifierType = (identifier) =>
  identifier.indexOf("@") !== -1 ? "email" : "mobile_number";
