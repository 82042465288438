import { Route } from "react-router-dom";

export default function CustomRoute(props) {
  const { component: Component, path, exact, render } = props;
  let prefix = props.prefix || path;

  return (
    <Route
      render={
        render
          ? (...args) => render(...args, prefix)
          : () => <Component prefix={prefix} />
      }
      path={path}
      exact={exact}
    />
  );
}
