import React, { useState, useEffect } from "react";
import { rFileField } from "../../../../components/Form/presets";

// Other documents
export const Form1 = (values) => {
  const [schema, setSchema] = useState();

  const createSchema = () => {
    const schema = {
      other_cert: rFileField(
        "Any other documents (Rotary certificate, Differently abled, Orphan etc)",
        "Upload",
        {
          fileFieldProps: {
            accept: ".pdf",
          },
        }
      ),
    };
    setSchema(schema);
  };

  useEffect(() => createSchema(), [values]);

  return schema;
};
