import React from "react";
import Modal from "components/Modal";
import ExpandedLoader from "components/ExpandedLoader";
import FormPage from "pages/components/Settings/AccountDetails/FormPage";

function JobChecksModal({
  pageIndexUpdateModal,
  setPageIndexUpdateModal,
  pages,
  lastPage,
}) {
  const finish = () => {
    setPageIndexUpdateModal(-1);
  };
  if (pages) {
    const currentPage = pages[pageIndexUpdateModal];

    const goToPage = (pageIndex) => {
      if (pageIndex < 0) setPageIndexUpdateModal(-1);
      else if (pageIndex >= pages.length) console.log("Finished");
      else setPageIndexUpdateModal(pageIndex);
    };

    return (
      <Modal isOpen={pageIndexUpdateModal >= 0} onClose={finish}>
        <div
          style={{ maxHeight: "40rem" }}
          className="overflow-scroll flex flex-col"
        >
          {currentPage &&
            (currentPage.forms ? (
              <FormPage
                page={currentPage}
                key={currentPage.id}
                nextFn={() => goToPage(pageIndexUpdateModal + 1)}
                finishFn={finish}
                isLastPage={!lastPage || pageIndexUpdateModal == lastPage}
              />
            ) : (
              currentPage.render({
                finishFn: finish,
                next: () => goToPage(pageIndexUpdateModal + 1),
                isLastPage: !lastPage || pageIndexUpdateModal == lastPage,
              })
            ))}
        </div>
      </Modal>
    );
  } else
    return (
      <h1>
        <ExpandedLoader />
      </h1>
    );
}

export default JobChecksModal;
