import { useQuery } from "@tanstack/react-query";
import {
  FETCH_COURSE_BY_ID,
  FETCH_COURSES_BY_ID,
  FETCH_COURSES_BY_PARTNER,
  FETCH_DEFAULT_COURSES,
} from "graphql/queries/Courses";
import { apolloClient } from "../App";
import queryClient from "./queryClient";
import {
  GET_COURSE_COMPLETION_TOPICS,
  GET_USER_COURSE_COMPLETED_TOPICS,
  GET_USER_COURSE_PROGRESS,
} from "graphql/queries/CourseBuilder/CourseSection";
import courseSvc from "services/Course";

// Async function to fetch course data by ID and process it
export const getCourseById = async (courseId) => {
  const data = await queryClient.fetchQuery({
    queryKey: ["getCourseById", courseId],
    queryFn: async () => {
      const response = await apolloClient.query({
        query: FETCH_COURSE_BY_ID,
        variables: { courseId },
      });
      const course = _.cloneDeep(response.data.courses_course[0]);
      if (course) {
        courseSvc.postProcessCourseData(course); // Assuming this function is defined elsewhere
        return course;
      }
      return null; // Return null if course not found
    },
    enabled: !!courseId,
  });

  console.log("🚀 ~ getCourseById ~ course:", data);

  return data;
};

// Custom hook to fetch course data by ID
export const useCourseById = (courseId) => {
  return useQuery(["getCourseById", courseId], () => getCourseById(courseId), {
    enabled: !!courseId, // Only fetch when courseId is truthy
  });
};

// Async function to fetch default courses and process them
export const fetchDefaultCourses = async () => {
  const data = await queryClient.fetchQuery({
    queryKey: ["getDefaultCourses"],
    queryFn: async () => {
      const response = await apolloClient.query({
        query: FETCH_DEFAULT_COURSES,
        variables: {},
      });
      const courses = JSON.parse(JSON.stringify(response.data.courses_course));

      if (courses && Array.isArray(courses)) {
        return courses;
      }
      return []; // Return an empty array if no courses are found
    },
    // Optionally, you can set options like staleTime, cacheTime, etc.
  });

  console.log("🚀 ~ getDefaultCourses ~ courses:", data);

  return data;
};

// Custom hook to fetch default courses
export const useDefaultCourses = () => {
  return useQuery(
    ["getDefaultCourses"],
    fetchDefaultCourses, // Directly reference the async function
    {
      // You can specify options like staleTime, cacheTime, etc., if needed.
    }
  );
};

// Async function to fetch courses by their IDs and process them
export const fetchCoursesById = async (courseIds) => {
  const data = await queryClient.fetchQuery({
    queryKey: ["getCoursesById", courseIds],
    queryFn: async () => {
      const response = await apolloClient.query({
        query: FETCH_COURSES_BY_ID,
        variables: { courseIds },
      });
      const courses = _.cloneDeep(response.data.courses_course);

      if (courses && Array.isArray(courses)) {
        return courses;
      }
      return []; // Return an empty array if no courses are found
    },
    enabled: !!courseIds && courseIds.length > 0, // Only fetch if courseIds array is not empty
  });

  console.log("🚀 ~ getCoursesById ~ courses:", data);

  return data;
};

export const useCoursesById = (courseIds) => {
  return useQuery(
    ["getCoursesById", courseIds],
    () => getCoursesById(courseIds),
    {
      enabled: !!courseIds && courseIds.length > 0, // Only attempt to fetch if courseIds array is not empty
      // Additional options like staleTime, cacheTime, etc., can be specified here
    }
  );
};

// Async function to fetch courses by partner ID and process them
export const fetchCoursesByPartner = async (partnerId) => {
  const data = await queryClient.fetchQuery({
    queryKey: ["fetchCoursesByPartner", partnerId],
    queryFn: async () => {
      const response = await apolloClient.query({
        query: FETCH_COURSES_BY_PARTNER,
        variables: { partnerId },
      });
      const courses =
        response.data.courses_course_criteria[0]?.courses?.map(
          (c) => c.courses
        ) || [];

      if (courses && Array.isArray(courses)) {
        courses.forEach((course) => {
          courseSvc.postProcessCourseData(course); // Ensure this function is defined and accessible
        });
        return courses;
      }
      return []; // Return an empty array if no courses are found
    },
    enabled: !!partnerId, // Only fetch if partnerId is truthy
  });

  console.log("🚀 ~ fetchCoursesByPartner ~ courses:", data);

  return data;
};

// Custom hook to fetch courses by partner ID
export const useFetchCoursesByPartner = (partnerId) => {
  return useQuery(
    ["fetchCoursesByPartner", partnerId],
    () => fetchCoursesByPartner(partnerId),
    {
      enabled: !!partnerId, // Only attempt to fetch if partnerId is provided
      // Here you can specify additional options such as staleTime, cacheTime, etc.
    }
  );
};

// Async function to fetch and calculate a user's course progress
export const fetchUserCourseProgress = async (courseId, userId) => {
  const data = await queryClient.fetchQuery({
    queryKey: ["fetchUserCourseProgress", courseId, userId],
    queryFn: async () => {
      const response = await apolloClient.query({
        query: GET_USER_COURSE_PROGRESS,
        variables: { course_id: courseId, user_id: userId },
      });
      const courseTotalTopic =
        response.data.courses_course_module_mapping.length;
      const userCompletedTopic =
        response.data.courses_course_module_completion.length;

      // Calculate progress as a percentage
      const progress =
        courseTotalTopic > 0
          ? (userCompletedTopic * 100) / courseTotalTopic
          : 0;

      return progress;
    },
    enabled: !!courseId && !!userId, // Only fetch if both courseId and userId are provided
  });

  console.log("🚀 ~ fetchUserCourseProgress ~ progress:", data);

  return data;
};

export const useFetchUserCourseProgress = (courseId, userId) => {
  return useQuery(
    ["fetchUserCourseProgress", courseId, userId],
    () => fetchUserCourseProgress(courseId, userId),
    {
      enabled: !!courseId && !!userId, // Only attempt to fetch if both courseId and userId are provided
      // You can specify additional options like staleTime, cacheTime, etc., if needed.
    }
  );
};

// Async function to fetch course completion topics
export const fetchCourseCompletionTopics = async (courseId) => {
  const data = await queryClient.fetchQuery({
    queryKey: ["fetchCourseCompletionTopics", courseId],
    queryFn: async () => {
      const response = await apolloClient.query({
        query: GET_COURSE_COMPLETION_TOPICS,
        variables: { course_id: courseId },
      });
      // Access and return the course completion criteria directly from the response
      return response.data?.courses_course_completion_criteria;
    },
    enabled: !!courseId, // Only fetch if courseId is provided
  });

  console.log("🚀 ~ fetchCourseCompletionTopics ~ topics:", data);

  return data;
};

// Custom hook to fetch course completion topics
export const useFetchCourseCompletionTopics = (courseId) => {
  return useQuery(
    ["fetchCourseCompletionTopics", courseId],
    () => fetchCourseCompletionTopics(courseId),
    {
      enabled: !!courseId, // Only attempt to fetch if a courseId is provided
      // Additional options such as staleTime, cacheTime, etc., can be specified if needed
    }
  );
};

// Async function to fetch completed course topics for a user
export const fetchUserCourseCompletionTopics = async (courseId, userId) => {
  const data = await queryClient.fetchQuery({
    queryKey: ["fetchUserCourseCompletionTopics", courseId, userId],
    queryFn: async () => {
      const response = await apolloClient.query({
        query: GET_USER_COURSE_COMPLETED_TOPICS,
        variables: {
          course_id: courseId,
          user_id: userId,
        },
      });
      // Directly return the course module completion data from the response
      return response.data.courses_course_module_completion;
    },
    enabled: !!courseId && !!userId, // Only fetch if both courseId and userId are provided
  });

  console.log("🚀 ~ fetchUserCourseCompletionTopics ~ topics:", data);

  return data;
};

// Custom hook to fetch completed course topics for a user
export const useGetUserCourseCompletionTopics = (courseId, userId) => {
  return useQuery(
    ["fetchUserCourseCompletionTopics", courseId, userId],
    () => fetchUserCourseCompletionTopics(courseId, userId),
    {
      enabled: !!courseId && !!userId, // Only attempt to fetch if both courseId and userId are provided
      // Here, additional options such as staleTime, cacheTime, etc., can be specified if needed
    }
  );
};
