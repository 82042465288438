import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router";
import queryString from "query-string";
import liveClassService from "services/liveClass";
import Accordions from "components/CourseBuilder/Accordions";
import { useSelector } from "react-redux";

import { useParams } from "react-router";
import { Route, Switch, Redirect, matchPath } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Toast from "utils/Toast";

export default function Viewcourse() {
  const history = useHistory();

  const { id } = useParams();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const [course, setCourse] = useState([]);

  const fetchcoursesection = async () => {
    if (id) {
      const enrollData = await liveClassService.GetUserEnrollmentId(userId, id);

      if (enrollData) {
        const res = await liveClassService.getCourseSection(id);
        console.log("🚀 ~ fetchcoursesection ~ res:", res)

        setCourse(res?.filter((e) => e.coursesec.length !== 0));
      } else {
        Toast.error("You are not enrolled into course");
        history.push("/courses/" + id);
      }
    }
  };

  useEffect(() => {
    fetchcoursesection();
  }, []);

  return (
    <div>
      <div className="flex flex-col content-between space-y-2">
        <div className="bg-orange rounded-lg  p-2">
          <h2 className="text-3xl">Course Content</h2>
        </div>
        <div>
          {course &&
            course.map((data, i) => {
              if (i == 0) {
                return (
                  <Switch>
                    <Route
                      path="/course/view/:id/"
                      exact
                      component={() => (
                        <Redirect
                          to={`${id + "/lesson/" + data.coursesec?.[0].id}`}
                        />
                      )}
                    ></Route>
                  </Switch>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}
