import { useQuery } from "@apollo/client";
import Loader from "assets/animated-images/Loader";
import { USER_APPLIED_SCHOLARSHIPS } from "graphql/queries/Scholarships";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ScholarshipCard from "./scholarshipcard";

export const SCHOLARSHIP_CARD_TYPE = {
  appliedScholarship: "applied-scholarship",
};

export default function UserScholarshipApplications(props) {
  const user = useSelector(({ auth }) => auth.user.db_user);

  const { data: myScholarshipsRes } = useQuery(USER_APPLIED_SCHOLARSHIPS, {
    variables: { userId: user?.id },
  });
  const myScholarships = myScholarshipsRes?.courses_scholarship_partner_user;

  return (
    <div>
      <div className="p-4">
        <div className="flex gap-5 items-center">
          <h1 className="font-bold my-4 text-2xl lg:text-4xl text-japanese_indigo">
            My Scholarships
          </h1>
          <Link to="/allscholarships">
            <button className="btn-primary">Go to All Scholarships</button>
          </Link>
        </div>
        <div>
          {!myScholarships ? (
            <Loader style={{ width: "300px" }} />
          ) : (
            <div>
              {myScholarships.map(
                ({ scholarship, accept, contribution, created_at }) => {
                  const name = scholarship.partner.name;
                  let fa;
                  const year = created_at.slice(0, 4);
                  if (year == 2022) fa = "2022-23";
                  if (year == 2023) fa = "2023-24";
                  const color =
                    accept === false
                      ? "bg-red-600"
                      : accept === true
                      ? "bg-green-600"
                      : "bg-gray-500";
                  const text =
                    accept === false
                      ? "Rejected"
                      : accept === true
                      ? "Accepted"
                      : "Processing";

                  return (
                    <div className="relative ">
                      <div
                        className={`absolute right-0 mt-40 mr-4 top-1 px-4 py-2 text-black text-white font-semibold rounded ${color}`}
                      >
                        {text}
                      </div>

                      <ScholarshipCard
                        {...{
                          ...scholarship,
                          details: scholarship,
                          name: name,
                          contribution: contribution,
                          year: fa,
                        }}
                        type={SCHOLARSHIP_CARD_TYPE.appliedScholarship}
                      />
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
