import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import userSvc from "services/User";
import PayoutDetailsModal from "./payoutdetailsmodal";
import RedeemRewardModal from "./redeemrewardmodal";
import ReferralSchemes from "./referralschemes";
import DataTable from "react-data-table-component";
import { getRedeemableAmount } from "api/referrals";
import { FETCH_REFEREE_NAME } from "../../graphql/queries/Referrals";
import moment from "moment";
import { Modal } from "skillstrainer-resource-library";

import { useLazyQuery, useQuery } from "@apollo/client";

export default function Referrals(props) {
  const { user, complete } = props;
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  // const firstName = auth.user?.db_user?.name?.split(" ")[0];
  const [payoutDetailOpen, setPayoutDetailOpen] = useState(false);
  const [redeemRewardsOpen, setRedeemRewardsOpen] = useState(false);
  const [referralScheme, setReferralScheme] = useState(false);
  const [referralCodesDetail, setReferralCodesDetail] = useState([]);
  const [redeemableAmount, setRedeemableAmount] = useState(0);
  const [selectedReferralCode, setSelectedReferralCode] = useState(null);

  useEffect(async () => {
    const redeemableAmount = await getRedeemableAmount(userId);
    if (redeemableAmount.data.success) {
      setRedeemableAmount(redeemableAmount.data.redeemable_amount);
    } else {
      Toast.error(redeemableAmount.data.message);
    }
  }, []);

  const columns = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
    },
    {
      name: "Code",
      selector: (row, index) => `${row.referral_code}`,
      sortable: true,
    },
    {
      name: "Referrals Count",
      selector: (row, index) => {
        return `${row.referrer_commission_transactions.length}`;
      },
      sortable: true,
    },
    {
      name: "Reward",
      selector: (row) => {
        const rewards = row.referrer_commission_transactions.map(
          (transaction) => transaction.reward
        );
        const totalReward = rewards.reduce(
          (total, reward) => total + reward,
          0
        );
        return totalReward;
      },
      sortable: true,
    },
    {
      name: "Action",

      cell: (row) => (
        <div className="flex">
          <button
            className="mx-2 mt-3 rounded-md py-3 px-5 bg-orange text-white font-semibold"
            onClick={(e) => handleButtonClick(e, row, true)}
          >
            View Code Details
          </button>
          <button
            className="mx-2 mt-3 rounded-md py-3 px-5 bg-orange text-white font-semibold"
            onClick={(e) => handleButtonClick(e, row, false)}
          >
            Show Referees
          </button>
        </div>    
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "15rem", 
    },
  ];
  const columnsReferee = [
    {
      name: "S.No.",
      cell: (row, index) => index + 1,
    },
    {
      name: "UserId",
      selector: (row, index) => {
        return row.referee_user_id;
      },
      sortable: true,
    },
    {
      name: "Referee Name",
      selector: (row, index) => {
        const { data: referee_name_data } = useQuery(FETCH_REFEREE_NAME, {
          variables: {
            id: row.referee_user_id,
          },
        });

        return referee_name_data?.courses_users.map((data) => {
          return data.name;
        });
      },
      sortable: true,
    },
    {
      name: "Date of Referal",
      selector: (row, index) => moment(row.created_at).format("YYYY-MM-DD"),
      sortable: true,
    },
  ];

  const fetchReferralCode = () => {
    userSvc
      .fetchReferralDetails(userId)
      .then((res) => {
        setReferralCodesDetail(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initialTotal = 0;

  const totalReferees = referralCodesDetail.reduce(
    (runningTotal, currentItem) => {
      const numberOfTransactions = currentItem.referrer_commission_transactions
        ? currentItem.referrer_commission_transactions.length
        : 0;

      return runningTotal + numberOfTransactions;
    },
    initialTotal
  );

  const handleButtonClick = (event, row, value) => {
    console.log('row==', row, value);
    if (selectedReferralCode === row) {
      setSelectedReferralCode(null); // Toggle off if already selected
    } else {
      const newRow = { ...row, button: value }; // Create a new object with the 'button' property
    console.log('newRow===', newRow);
    setSelectedReferralCode(newRow);
    }
  };

  const closeModal = () => {
    setSelectedReferralCode(null);
  };

  useEffect(() => {
    fetchReferralCode();
  }, [referralScheme]);

  console.log('selectedReferralCode===', selectedReferralCode);
  return (
    <div>
      <PayoutDetailsModal
        payoutDetailOpen={payoutDetailOpen}
        setPayoutDetailOpen={setPayoutDetailOpen}
      />
      <RedeemRewardModal
        redeemRewardsOpen={redeemRewardsOpen}
        setRedeemRewardsOpen={setRedeemRewardsOpen}
      />

      <div className="flex justify-between items-center border-b-2 border-gray-100 py-4">
        <h1 className="font-bold my-4 text-2xl lg:text-4xl text-japanese_indigo">
          Referrals
        </h1>

        <div className="flex flex-row justify-end ">
          <button
            onClick={() => setRedeemRewardsOpen(redeemRewardsOpen ^ true)}
            className="mx-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
          >
            Redeem Rewards
          </button>
          {referralScheme ? (
            <button
              onClick={() => setReferralScheme(false)}
              className="mx-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
            >
              Show Referrals
            </button>
          ) : (
            <button
              onClick={() => setReferralScheme(true)}
              className="mx-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
            >
              Create Referral Code
            </button>
          )}
          <button
            onClick={() => setPayoutDetailOpen(true)}
            className="mx-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
          >
            Payout Details
          </button>
        </div>
      </div>

      {!referralScheme && (
        <div className="flex flex-row text-center items-center border-b-2 border-gray-100 py-4">
          <div
            className="rounded-md p-4 mr-14"
            style={{
              background: "#ffffff",
              border: "2px solid #e76f51",
              borderRadius: "19px",
            }}
          >
            <h2 className="text-3xl font-bold display-6 text-orange">
              Rs {redeemableAmount}
            </h2>
            <h5 className="text-lg font-medium ">Money Earned</h5>
          </div>
          <div
            className="rounded-md p-3 mr-14"
            style={{
              background: "#ffffff",
              border: "2px solid #e76f51",
              borderRadius: "19px",
            }}
          >
            <h2 className="text-3xl font-bold display-6 text-orange ">
              {totalReferees}
            </h2>
            <h5 className="text-lg font-medium ">People Referred</h5>
          </div>
          <div
            className="rounded-md p-3 mr-14"
            style={{
              background: "#ffffff",
              border: "2px solid #e76f51",
              borderRadius: "19px",
            }}
          >
            <h2 className="text-3xl font-bold display-6 text-orange ">
              {referralCodesDetail.length}
            </h2>
            <h5 className="text-lg font-medium ">No. of Codes</h5>
          </div>
        </div>
      )}
        <div>
      
       {referralCodesDetail.length === 1 && referralCodesDetail[0]?.referrer_commission_transactions.length === 0 && (
        <div className="text-center text-xl font-semibold mb-3">
          Referral Code: {referralCodesDetail[0]?.referral_code}
        </div>
        )}
        {!referralScheme && referralCodesDetail.length === 1 && (
        <>
         <DataTable
          columns={columnsReferee}
          noDataComponent="No users have applied this referral code"
          data-key={columnsReferee}
          data={referralCodesDetail[0]?.referrer_commission_transactions}
          selectableRows={false}
          selectableRowsHighlight={false}
          highlightOnHover
        />
        </>
       
      )}
    </div>
  
      {!referralScheme && referralCodesDetail.length !== 1 && (
        <DataTable
          columns={columns}
          data-key={columns}
          noDataComponent="You have not created any referral code yet"
          data={referralCodesDetail}
          selectableRows={false}
          selectableRowsHighlight={false}
          highlightOnHover
        />
      )}

      {!referralScheme && selectedReferralCode && (
        <Modal
          isOpen={true}
          onClose={closeModal}
          size="lg"
          className="h-full w-full"
        >
          <DataTable
            className="flex font-poppins"
            columns={columnsReferee}
            data-key={columnsReferee}
            data={selectedReferralCode.referrer_commission_transactions}
            selectableRows={false}
            selectableRowsHighlight={false}
            highlightOnHover
          />
        </Modal>
      )}

      {!referralScheme && selectedReferralCode?.button && (
         <Modal isOpen={true} onClose={closeModal}>
         <div style={{ maxHeight: "40rem" }} className="flex font-poppins">
           <div className="flex-1 flex flex-col">
             <div className="flex h-full w-full px-6">
               <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-scroll mb-8">
                 <div className="flex w-full mx-auto px-6">
                   <div className="flex flex-col w-full h-full">
                     <h1 className="text-2xl font-semibold text-japanese_indigo mb-4">
                       Referral Code Details
                     </h1>
                     <div className="my-auto  font-semibold rounded-md mb-3">
                           <span className="text-orange">Code:</span> {selectedReferralCode?.referral_code
}
                         </div>
                         <div className="my-auto  font-semibold rounded-md mb-3">
                           <span className="text-orange">Referee Reward: </span> {selectedReferralCode?.referral_scheme?.referee_reward?.value}% discount
                         </div>
                         <div className="my-auto  font-semibold rounded-md mb-3">
                         <span className="text-orange">Referrer Reward: </span> {selectedReferralCode?.referral_scheme?.referrer_reward?.value}% commission
                       </div>
                   </div>
                 </div>
               </main>
             </div>
           </div>
         </div>
       </Modal>
      )}

      {referralScheme && <ReferralSchemes />}
    </div>
  );
}
