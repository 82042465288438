import { gql } from "@apollo/client";

export const GET_COURSE_QUIZ = gql`
  query getCourseQuiz($module_mapping_id: Int) {
    courses_course_module_mapping(where: { id: { _eq: $module_mapping_id } }) {
      id
      tags
    }
    courses_questions(
      where: {
        quest_course: { module_mapping_id: { _eq: $module_mapping_id } }
      }
    ) {
      question_text
      question_type
      id
      marks
      mode
      quest_answer {
        isAnswer
        question_id
        answer_text
        id
        answer_description
        answer_img
      }
      quest_course {
        course_id
      }
    }
  }
`;

export const GET_USER_QUIZ_ATTEMPT = gql`
  query GeTUserQuizAttempt($mapping_id: Int, $user_id: Int, $course_id: Int) {
    courses_user_course_quiz_attempt(
      where: {
        mapping_id: { _eq: $mapping_id }
        user_id: { _eq: $user_id }
        course_id: { _eq: $course_id }
      }
    ) {
      id
      attempt_completed
      attempt_percentage
    }
  }
`;

export const GET_QUIZ_TRANSLATIONS = gql`
  query getQuizLanguages($moduleMappingId: Int) {
    courses_course_module_mapping(where: { id: { _eq: $moduleMappingId } }) {
      id
      tags
    }
  }
`;

export const GET_QUESTION_ANSWER_TRANSLATIONS = gql`
  query getTranslations($key_array: [String!]!) {
    courses_multi_lang_contents(where: { key: { _in: $key_array } }) {
      id
      key
      content
    }
  }
`;
