import { DATE_TIME_FORMATS } from "api/Consts";
import moment from "moment/moment";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ACTION_TYPES } from "route-actions";
import courseSvc from "services/Course";
import { Modal } from "skillstrainer-resource-library";
import BookDemoList from "wrappers/Course/BookDemoList";
import Toast from "wrappers/Toast";

export default function DemoDetailsModal(props) {
  const { demoBatchSlot, onClose } = props;

  const userId = useSelector(({ auth }) => auth?.user?.db_user.id);
  const history = useHistory();

  const allowJoinMeet = useMemo(() => {
    if (!demoBatchSlot) return;

    const { slot_date } = demoBatchSlot;
    if (new Date().getTime() > new Date(slot_date).getTime() - 10 * 60 * 1000)
      return true;
    else return false;
  }, [demoBatchSlot]);

  const [availableCourseDemoSlots, setAvailableCourseDemoSlots] = useState();

  async function edit_timing() {
    try {
      const course = await courseSvc.getCourseById(
        demoBatchSlot.batch.course_id
      );
      setAvailableCourseDemoSlots({
        availableSlots: course.availableSlots,
        course,
      });
    } catch (e) {
      console.log("failing somewhere", e);
    }
  }

  const updateUserDemoSession = (selectedSlot) => {
    const selectedSlotId = availableCourseDemoSlots.availableSlots.find(
      (s) => s.id === selectedSlot
    );
    courseSvc
      .updateDemo(userId, selectedSlotId.id, demoBatchSlot.id)
      .then((res) => {
        Toast.success("Demo is updated!");
        onClose();
      });
  };

  return (
    <>
      <Modal isOpen={!!demoBatchSlot} onClose={onClose}>
        <div className="p-3">
          <div className="text-2xl font-semibold">Demo batch details</div>
          {demoBatchSlot && (
            <div className="text-lg text-orange font-semibold -mt-1">
              {demoBatchSlot.batch.course.full_name}
            </div>
          )}
          {demoBatchSlot && (
            <>
              <BatchSlotDetailsFragment batchSlot={demoBatchSlot} />
              {demoBatchSlot.type === "online" && (
                <button
                  className={`btn-primary w-full ${
                    !allowJoinMeet ? "bg-gray-500" : ""
                  }`}
                  disabled={!allowJoinMeet}
                  onClick={() =>
                    history.push(
                      `?action=${ACTION_TYPES.join_demo_session}&batchSlotId=${demoBatchSlot.id}`
                    )
                  }
                >
                  Join Meeting
                </button>
              )}
            </>
          )}

          <button className="btn-primary w-full mt-2" onClick={edit_timing}>
            Edit Timings
          </button>
        </div>
      </Modal>
      <BookDemoList
        courseDemoBatchSlots={availableCourseDemoSlots}
        onSlotConfirm={updateUserDemoSession}
        onComplete={() => setAvailableCourseDemoSlots()}
      />
    </>
  );
}

export function BatchSlotDetailsFragment(props) {
  const { batchSlot } = props;
  return (
    <div
      className="grid grid-cols-2 my-5"
      style={{ gridTemplateColumns: "1fr 2fr", minWidth: "360px" }}
    >
      <span className="font-semibold">Demo start: </span>
      <span>
        {moment(batchSlot.slot_date).format(
          DATE_TIME_FORMATS.DEMO_BATCH_SLOTS_DATE
        )}
        , {moment(batchSlot.slot_date).format("HH:mm")}
      </span>

      <span className="font-semibold">Demo end: </span>
      <span>
        {moment(batchSlot.endto_date).format(
          DATE_TIME_FORMATS.DEMO_BATCH_SLOTS_DATE
        )}
        , {moment(batchSlot.endto_date).format("HH:mm")}
      </span>

      <span className="font-semibold">Type: </span>
      <span>{batchSlot.type == "online" ? "Online" : "Offline"}</span>

      {batchSlot.type === "offline" && (
        <>
          <span className="font-semibold">Address: </span>
          <span>{batchSlot.location}</span>
        </>
      )}
    </div>
  );
}
