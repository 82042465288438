import moment from "moment";
import { useMemo, useState } from "react";
import { MdCategory } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
import { IoPeopleSharp } from "react-icons/io5";
import { GiBackwardTime } from "react-icons/gi";
import { BsGenderAmbiguous } from "react-icons/bs";
import { ScholarshipDetailsModal } from ".";
import { SOCIAL_CATEGORY_NAMES, s3BaseUrl } from "api/Consts";
import { SCHOLARSHIP_CARD_TYPE } from "./UserScholarshipApplications";

export default function ScholarshipCard(props) {
  const {
    social_categories,
    details,
    logo,
    title,
    name,
    created_at,
    description,
    amount,
    min_age,
    max_age,
    gender,
    type,
    contribution,
    year,
  } = props;

  console.log(logo);

  const [scholarshipDetailsOpen, setScholarshipDetailsOpen] = useState(false);

  const social_categories_by_name = useMemo(() => {
    const social_categories_by_name = [];

    (social_categories || []).forEach((social_category_code) => {
      const social_category_name = SOCIAL_CATEGORY_NAMES[social_category_code];
      social_categories_by_name.push(social_category_name);
    });

    return social_categories_by_name.join(", ") || "All categories";
  }, [social_categories]);

  return (
    <>
      <ScholarshipDetailsModal
        scholarshipDetailsOpen={scholarshipDetailsOpen}
        setScholarshipDetailsOpen={setScholarshipDetailsOpen}
        details={details}
      />
      <div class="flex flex-col w-full p-4 my-3 h-60 mx-auto bg-white rounded-lg border-2">
        <div className="flex flex-row items-center">
          <img
            src={
              logo ? s3BaseUrl + "/" + logo : "http://placehold.jp/150x150.png"
            }
            alt="work-partner-img"
            className="w-16 h-16 rounded-md border-2 object-contain h-3 w-3 "
          />
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-semibold text-japanese_indigo mx-2">
                {title}
              </h1>
              <h1 className="text-sm font-semibold text-gray-500 mx-2">
                by {name}
              </h1>
            </div>
            <div className="flex items-center justify-center">
              <GiBackwardTime size={20} className="text-orange my-auto" />
              <span className="text-xs text-orange my-auto">
                Posted {moment(created_at, "YYYYMMDD").fromNow()}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-evenly my-4">
          <div className="flex flex-row mx-5">
            <MdCategory className="text-japanese_indigo" size={20} />
            <p className="font-semibold text-sm text-japanese_indigo mx-2">
              {social_categories_by_name}
            </p>
          </div>
          <div className="flex flex-row mx-5">
            <FaMoneyBillAlt className="text-japanese_indigo" size={20} />
            <p className="font-semibold text-sm text-japanese_indigo mx-2">
              {!amount || amount == 0
                ? "Not Fixed"
                : "₹" + amount + "/per year"}
            </p>
          </div>
          <div className="flex flex-row mx-5">
            <BsGenderAmbiguous className="text-japanese_indigo" size={20} />
            <p className="font-semibold text-sm text-japanese_indigo mx-2">
              {gender === 0
                ? "Male Only"
                : gender === 1
                ? "Female Only"
                : gender === 2
                ? "Others"
                : "All Genders"}
            </p>
          </div>
          <div className="flex flex-row mx-5">
            <IoPeopleSharp className="text-japanese_indigo" size={20} />
            <p className="font-semibold text-sm text-japanese_indigo mx-2">
              Age{" "}
              {min_age !== null && max_age !== null
                ? `${min_age} - ${max_age} Years`
                : "Not Specified"}
            </p>
          </div>
        </div>
        <p>{description}</p>
        {type !== SCHOLARSHIP_CARD_TYPE.appliedScholarship && (
          <div className="flex flex-row justify-end">
            <button
              onClick={() => setScholarshipDetailsOpen(true)}
              className="mx-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
            >
              View More Details
            </button>
          </div>
        )}
        {type == SCHOLARSHIP_CARD_TYPE.appliedScholarship && (
          <div className="flex flex-col mt-6 ">
            <span className="text-md font-semibold ">
              Scholarship Received:&nbsp;
              <span className="text-green-600">₹{contribution}</span>
            </span>
            <span className="text-md font-semibold">
              Financial Year:&nbsp;
              <span className="text-green-600"> {year}</span>
            </span>
          </div>
        )}
      </div>
    </>
  );
}
