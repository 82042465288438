import { idField, rStringField } from "components/Form/presets";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { condObj } from "utils/func";
import * as yup from "yup";

export default function WorkDetailsForm(values) {
  const [schema, setSchema] = useState();
  const valuesRef = useRef(values);

  const createSchema = () => {
    const schema = {
      works: {
        type: "object",
        repeat: true,
        insertable: true,
        insertableProps: {
          button: {
            label: "Add Work Experience",
            className: "button",
          },
        },
        collapsible: true,
        collapsibleProps: {
          title: ({ company_name, title }) =>
            (title || company_name) &&
            `${title} ${company_name ? `@ ${company_name}` : ""}`,
          emptyTitle: "Company Name",
        },
        fields: (item) => {
          return {
            id: idField(),
            title: rStringField("Title", { required: true }),
            company_name: rStringField("Company name", { required: true }),
            start_date: rStringField("From", { type: "date", required: true }),
            end_date: rStringField("To", {
              type: "date",
              schema: yup
                .string()
                .test(
                  "End date",
                  "End date should be greater than start date",
                  (value) => {
                    return (
                      moment(value).toDate().getTime() >
                      moment(item.start_date).toDate().getTime()
                    );
                  }
                ),
            }),
            place: rStringField("Place", { required: true }),
          };
        },
      },
    };
    setSchema(schema);
  };

  useEffect(() => {
    valuesRef.current = values;
    createSchema();
  }, [values]);

  return schema;
}
