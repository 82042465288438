import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Container from "components/Container";
import { _es } from "utils/func";
import FormPage from "pages/components/Settings/AccountDetails/FormPage";
import { createStepsAndPages } from "pages/components/Settings/AccountDetails/pages";
import BreadCrumbs, {
  getSubstepsNum,
} from "pages/components/Settings/AccountDetails/BreadCrumbs";
import Navigator from "components/Navigator";
import ExpandedLoader from "components/ExpandedLoader";

export default function AccountDetails(props) {
  const auth = useSelector(({ auth }) => auth);
  const history = useHistory();

  const [steps, setSteps] = useState();
  const [pages, setPages] = useState();

  // Creates pages after all the dependencies are available
  const deps = { user: auth?.user?.db_user };
  useEffect(() => {
    if (Object.values(deps).reduce((acc, cur) => acc && cur)) {
      createStepsAndPages(deps).then(({ steps, pages }) => {
        setSteps(steps);
        setPages(pages);
      });
    }
  }, Object.values(deps));

  const [currentCollectionPageIndex, setcurrentCollectionPageIndex] =
    useState(0);

  // When pages are loaded
  if (pages) {
    const isLastPage = currentCollectionPageIndex === pages.length - 1;
    const currentPage = pages[currentCollectionPageIndex];
    const currentStep = currentPage.step;
    const {
      stepIndex,
      substepIndex,
      index: _currentCollectionPageIndex,
    } = currentPage;
    const allSubsteps = steps
      .map((step) => step.substeps)
      .reduce((acc, cur) => acc.concat(cur));
    const substepsForBreadCrumbs = allSubsteps.map((substep) => ({
      id: substep.id,
      label: substep.name,
      substeps: substep.pages.length,
    }));

    const goToStep = (stepId) => {
      const page = pages.find((p) => p.step.id === stepId);
      setcurrentCollectionPageIndex(
        (
          page || {
            pageIndex: currentCollectionPageIndex,
          }
        ).pageIndex
      );
    };
    const goToSubstep = (substepId) => {
      const page = pages.find((p) => p.substep.id === substepId);
      setcurrentCollectionPageIndex(
        (
          page || {
            pageIndex: currentCollectionPageIndex,
          }
        ).pageIndex
      );
    };
    const goToPage = (pageIndex) => {
      if (pageIndex < 0) setcurrentCollectionPageIndex(0);
      else if (pageIndex >= pages.length) console.log("Finished");
      else setcurrentCollectionPageIndex(pageIndex);
    };

    return (
      <Container>
        <Navigator back={{ name: "Settings", link: "/settings" }} />
        <h1 className="font-bold text-2xl mt-4 text-japanese_indigo">
          My Profile
        </h1>
        <div className="flex flex-col mt-5">
          <div className="my-4">
            <BreadCrumbs
              steps={substepsForBreadCrumbs}
              substepWidth="11%"
              step={
                steps
                  .slice(0, stepIndex)
                  .reduce((acc, cur) => acc + cur.substeps.length, 0) +
                substepIndex
              }
              substep={_currentCollectionPageIndex}
              className="w-full"
              onClick={(substepIndex, pageIndex) => {
                const index = getSubstepsNum(
                  substepsForBreadCrumbs,
                  substepIndex,
                  pageIndex
                );
                goToPage(index);
              }}
            />
          </div>
          {currentPage &&
            (currentPage.forms ? (
              <FormPage
                page={currentPage}
                key={currentPage.id}
                nextFn={() => goToPage(currentCollectionPageIndex + 1)}
                finishFn={() => history.push("/")}
                isLastPage={isLastPage}
              />
            ) : (
              currentPage.render({
                next: () => goToPage(currentCollectionPageIndex + 1),
                finishFn: () => history.push("/"),
                isLastPage,
              })
            ))}
        </div>
      </Container>
    );
  } else
    return (
      <h1>
        <ExpandedLoader />
      </h1>
    );
}
