import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { FiltersContext } from "../alljobs";
import { BiChevronDown } from "react-icons/bi";
import { wireEventValue } from "utils/func";

export default function MultiSelectFilter({ filterDetails }) {
  // const [opened, setOpened] = useState(false);
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [search, setSearch] = useState("");
  let optionsResult = filterDetails.query
    ? useQuery(filterDetails.query)?.data?.[filterDetails.table]
    : filterDetails.options;
  const [options, setOptions] = useState([]);
  const { filters, setFilters, setPageNo } = useContext(FiltersContext);

  useEffect(() => {
    if (optionsResult) {
      if (search.length > 0)
        setOptions(
          optionsResult.filter((option) =>
            option.name.toLowerCase().includes(search.toLowerCase())
          )
        );
      else setOptions(optionsResult);
    }
  }, [search, optionsResult]);

  const handleFilter = (event, id) => {
    let filter = filters[filterDetails.filtername];
    event.target.checked
      ? filter.push(id)
      : filter.indexOf(id) >= 0 && filter.splice(filter.indexOf(id), 1);

    setFilters({ ...filters, [filterDetails.filtername]: filter });
    setPageNo(0);
  };

  // const getOptions = () => {
  //   if(!options) return options;
  //   let sortedOpt = [...options];
  //   sortedOpt.sort((o1,o2) => {
  //     if(o1.name>o2.name) return 1;
  //     return -1;

  //   });
  //   return sortedOpt;
  // }

  return (
    <div className="ml-1">
      <div
        onClick={() => setFilterDropdown(!filterDropdown)}
        className="flex flex-row items-center p-2 rounded-md cursor-pointer shadow-md inline-flex"
      >
        <p className="w-max	text-black-500 text-sm font-medium mx-2 m-0 font-poppins hover:text-blue-500 transition duration-300">
          {filters[filterDetails.filtername]?.length
            ? `${filters[filterDetails.filtername].length} ${
                filterDetails.filtername
              } selected`
            : `Select ${filterDetails.filtername}`}
        </p>
        <div className="flex flex-row text-gray-500 font-poppins transition duration-300">
          <BiChevronDown className="text-orange my-auto" size={20} />
        </div>
      </div>
      {filterDropdown && (
        <div
          onMouseLeave={() => setFilterDropdown(false)}
          className="max-h-80 overflow-y-auto absolute bg-white w-auto px-1.5 py-1.5 pr-4 text-base text-center z-50 flex flex-col rounded-md my-4 shadow-lg"
          style={{ wordBreak: "break-word" }}
        >
          <input
            className="text-sm w-auto border-white rounded-md focus:outline-none mx-1 "
            type="search"
            placeholder="Search..."
            onChange={wireEventValue((value) => {
              setSearch(value);
            })}
            value={search}
          />
          {options?.map((item) => {
            return (
              <label
                className="py-2 px-2 cursor-pointer flex flex-row hover:text-orange"
                key={item.id}
              >
                <input
                  type="checkbox"
                  checked={
                    filters[filterDetails.filtername].indexOf(item.id) != -1
                  }
                  value={item.name}
                  onChange={(e) => handleFilter(e, item.id)}
                />
                <p className="mx-2 text-left">{item.name}</p>
              </label>
            );
          })}
        </div>
      )}
    </div>
    // <div>
    //   <p className="text-sm font-semibold my-3">{filterDetails.filtername}</p>
    //   {options?.map((item, ind) => {
    //     if(opened || ind<3){
    //       return(
    //         <label className="flex flex-row" key={item.id}>
    //           <input type="checkbox" value={item.name} onChange={(e)=>handleFilter(e,item.id)} />
    //           <p className="mx-2">{item.name}</p>
    //         </label>
    //       )
    //     }
    //   })}
    //   {opened && options?.length > 3 && <p onClick={()=>setOpened(!opened)} className="mt-1 cursor-pointer text-xs">show less...</p>}
    //   {!opened && options?.length > 3 && <p onClick={()=>setOpened(!opened)} className="mt-1 cursor-pointer text-xs">show more...</p>}
    // </div>
  );
}
