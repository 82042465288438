import { useCallback } from "react";
import Toast from "wrappers/Toast";

export default function SelectBatch(props) {
  const { availableBatches, onSelect } = props;

  const course =
    availableBatches && availableBatches.length && availableBatches[0].course;

  const confirmBatch = useCallback(async (batch) => {
    const isConfirmed = await Toast.confirm(
      `Are you sure you want to enrol in the batch "${batch.batch_name}"?`
    );
    if (isConfirmed) onSelect({ batch_id: batch.id });
  }, []);

  return (
    <>
      {!availableBatches || !availableBatches.length ? (
        <div className="font-poppins p-4">
          <div className="py-2">
            <p className="text-lg font-semibold text-red-400 text-center">
              We couldn't find any batches for this course
            </p>
          </div>
        </div>
      ) : (
        <div className="font-poppins p-4">
          <div className="py-2">
            <p className="text-lg font-semibold text-red-400">
              {" "}
              Course Name:{" "}
              <p className="text-black inline-block">{course.full_name}</p>
            </p>
          </div>

          <div className="w-full">
            <h1 className="font-bold text-japanese_indigo text-lg my-3">
              Select Preferable Batch
            </h1>
            <div className="align-middle ">
              <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <div className="h-96 overflow-y-scroll">
                  {availableBatches.map((batch) => {
                    const {
                      id,
                      batch_name,
                      from_date,
                      to_date,
                      slots_days,
                      slot_start_time,
                      slot_end_time,
                    } = batch;

                    if (new Date(from_date) >= new Date()) {
                      return (
                        <div
                          key={id}
                          className="w-full shadow-lg my-6 border-2 border-orange rounded-lg"
                        >
                          <div className="flex flex-col lg:flex-row w-full px-4">
                            <div className="flex flex-col mx-auto my-auto lg:w-1/5">
                              <h1 className="text-japanese_indigo font-semibold">
                                Batch name.
                              </h1>
                              <p>{batch_name}</p>
                            </div>

                            <div className="flex flex-col mx-auto my-auto lg:w-1/5">
                              <h1 className="text-japanese_indigo font-semibold">
                                Batch Duration
                              </h1>
                              <p>
                                {new Date(from_date).toLocaleString("en-US", {
                                  timeZone: "Asia/Kolkata",
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                }) +
                                  " to " +
                                  new Date(to_date).toLocaleString("en-US", {
                                    timeZone: "Asia/Kolkata",
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })}
                              </p>
                            </div>

                            <div className="p-4 my-auto w-1/5">
                              <div className="text-japanese_indigo font-semibold">
                                Days
                              </div>
                              <p>
                                {slots_days
                                  .split(",")
                                  .map((d) => {
                                    d.trim();
                                    return {
                                      MO: "Mon",
                                      TU: "Tue",
                                      WE: "Wed",
                                      TH: "Thu",
                                      FR: "Fri",
                                      SA: "Sat",
                                      SU: "Sun",
                                    }[d];
                                  })
                                  .join(", ")}
                              </p>
                            </div>

                            <div className="flex flex-col mx-auto my-auto p-4 w-1/5">
                              <div className="flex flex-col">
                                <h1 className="text-japanese_indigo font-semibold">
                                  Time slot
                                </h1>
                                <p>
                                  {slot_start_time} to {slot_end_time}
                                </p>
                              </div>
                            </div>

                            <button
                              className="w-1/5 text-white my-auto h-1/2 py-2 px-4 bg-orange font-semibold text-sm lg:text-lg rounded-lg"
                              onClick={() => confirmBatch(batch)}
                            >
                              Select Batch
                            </button>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
