import { FETCH_COURSE_ENROLLMENT_COUNT, FETCH_COURSES_MOODLE_DATA } from "graphql/queries/Courses";
import { useQuery } from "@tanstack/react-query";
import queryClient from './queryClient';
import { apolloClient } from "../App";

export const useCourseEnrollmentCount = (courseId) => {
  return useQuery(
    ["getCourseEnrollmentCount", courseId],
    async () => {
      return await getCourseEnrollmentCount(courseId);
    },
    {
      enabled: !!courseId,
    }
  );
};

// removed discontined from moodle at from FETCH_COURSE_ENROLMENT_COUNT to optimise ASDM, will display wrong course enrolment count( double enrolments counted when enrolments were created at both moodle and hasura) 

export const getCourseEnrollmentCount = async (courseId) => {
  const data = await queryClient.fetchQuery(
    {
      queryKey: ["getCourseEnrollmentCount", courseId],
      queryFn: async () => {
      const result = await apolloClient
        .query({
          query: FETCH_COURSE_ENROLLMENT_COUNT,
          variables: {
            courseId: courseId,
            // discontinuedFromMoodleAt: "1990-01-01T00:00:00Z",
          },
        })
        .then(
          ({ data }) =>
            data?.courses_user_course_enrolment_aggregate?.aggregate?.count
        );

      return result;
      },
      enabled: !!courseId,
      // refetchInterval: 10000,
      // staleTime: 300000,
      // cacheTime: 1800000,
    }
  );
  console.log("🚀 ~ getCourseEnrollmentCount ~ data:", data)

  console.log("🚀 ~ data ~ data:", data);

  return data;
};


export const getCourseEnrollmentCounts = async () => {
  const data = await queryClient.fetchQuery(
    {
      queryKey: ["coursesEnrollmentCount"],
      queryFn: 
      async () => {
        const {data} = await apolloClient
        .query({
          query: FETCH_COURSES_MOODLE_DATA,
          variables: {},
        });

        const coursesEnrolledStudents = {};
        
        const courseEnrollmentCounts =   await Promise.all(
          (data?.courses_course || []).map(async (course) => {
            let total = 0;

            const isOnMoodle = course.is_moodle_course,
              wasOnMoodle =
                !course.is_moodle_course && course.discontinued_from_moodle_at,
              wasNeverOnMoodle =
                !course.is_moodle_course && !course.discontinued_from_moodle_at;

            if (isOnMoodle || wasOnMoodle)
              total += course.moodle_user_stats?.total_users || 0;

            if (wasOnMoodle || wasNeverOnMoodle) {
              total += await getCourseEnrollmentCount(course?.id);

            }

            coursesEnrolledStudents[course.id] = total;
          })
        );

        return coursesEnrolledStudents;
      },
      // refetchInterval: 10000,
      // staleTime: 300000,
      // cacheTime: 1800000,
    }
  );
  console.log("🚀 ~ getCourseEnrollmentCounts ~ data:", data)
  console.log("🚀 ~ data ~ data:", data);
  return data;
}