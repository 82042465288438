import Container from "components/Container";
import { EvaluateQuizResults } from "api/Course";
import { useEffect } from "react";

export default function Blank(props) {
  const { reAttemptQuiz, quizTitle, attemptId } = props;

  const computeQuiz = async () => {
    await EvaluateQuizResults({
      quiz_attempt_id: attemptId,
    });
  };

  useEffect(() => computeQuiz(), []);

  return (
    <Container>
      <div>
        <div className="p-10 border-2 shadow-lg bg-white ">
          <div className="p-2">
            {/* <h1>My Course</h1> */}
            <div className="text-inline ">
              <label className="font-semibold">Quiz Name: </label>
              <span> {quizTitle || "NA"}</span>
            </div>
          </div>
          <div className="flex justify-between">
            <p className="underline">attempt again</p>
            <button
              onClick={() => reAttemptQuiz()}
              className="bg-transparent bg-orange text-white font-semibold hover:text-white py-1 px-4 border border-blue-500 hover:border-transparent rounded"
            >
              Re-Attempt
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
}
