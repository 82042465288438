import React from "react";
import { useSelector } from "react-redux";

import JobCard from "./jobcard";

function StarredJobs({ starredJobs, isJobStarred }) {
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;

  return (
    <div className="p-4">
      <div class="flex h-screen">
        <div class="flex-1 flex flex-col overflow-visible">
          <div class="h-full w-full">
            <main class="max-h-screen flex flex-col bg-white overflow-x-hidden overflow-y-auto mb-14">
              <div class="flex w-full mx-auto px-6 py-8">
                <div class="flex flex-col w-full h-full">
                  {starredJobs?.data?.courses_starred_jobs?.map(
                    ({ job: item }) => {
                      let isStarred = isJobStarred(item);
                      return (
                        <JobCard
                          userId={userId}
                          isStarred={isStarred}
                          starredJobsQuery={starredJobs}
                          key={item.id}
                          title={item.title}
                          description={item.description}
                          social_categories={item.social_categories}
                          gender={item.gender}
                          organization_name={item.partner.organization_name}
                          salary_ctc={item.salary_ctc}
                          min_age={item.min_age}
                          min_experience={item.min_experience}
                          max_age={item.max_age}
                          logo={item.partner.logo}
                          created_at={item.created_at}
                          details={item}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StarredJobs;
