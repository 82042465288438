import React, { useEffect, useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import queryString from "query-string";
import { Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { FcKindle } from "react-icons/fc";
import liveClassService from "services/liveClass";
import Container from "components/Container";
import Collapsible from "react-collapsible";

import { useHistory } from "react-router-dom";
import { FaAngleRight, FaBeer } from "react-icons/fa"; //react-icon
import {
  FetchCourseLesson,
  FindCourseTopicResources,
} from "redux/actions/Course";
import { toast } from "react-toastify";
import { Menu, Transition } from "@headlessui/react";

/*
  Component functions:
  - 
*/

export default function CourseTopics(props) {
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const viewesource = useSelector(({ course }) => course.viewResource);

  const currentLesson = useLocation().pathname;
  const dispatch = useDispatch();
  const [restrict, setRestrict] = useState([]);
  const [filterLesson, setFilterLesson] = useState([]);

  useEffect(() => {
    const filterLessonByCompletion = (props.module || []).map((data) => {
      if (data) {
        const checked =
          data.course_complete_module_array.length > 0 ? true : false;
        return { ...data, checked: checked };
      }
    });

    setFilterLesson(filterLessonByCompletion);
  }, [props.module]);

  useEffect(() => {
    (filterLesson || []).map(async (e) => {
      if (e.restriction?.restriction_ids) {
        const st = await liveClassService.getModuleRestrictionStatus(
          userId,
          e.restriction?.restriction_ids
        );

        const restIds = e.restriction?.restriction_ids
          .split(",")
          .map(function (strVale) {
            return Number(strVale);
          });

        console.log("st", st);

        const elmts = restIds.filter(function (i) {
          return this.indexOf(i) < 0;
        }, st);

        if (elmts.length > 0) {
          setRestrict((restrict) => [...restrict, { id: e.id }]);
        } else {
          let ar = restrict.filter((ee) => ee.id !== e.id);
          setRestrict(() => ar);
        }
      }
    });
  }, [filterLesson]);

  const markComplete = async (data, status) => {
    console.log(
      "checkCourseCompleteAudit",
      "In CoursTopics.js, invoked markComplete"
    );
    if (data.completion_criteria !== 0) {
      toast.error("You can't manually markrd this topic");
      return false;
    }

    if (status) {
      return false;
    }

    data["mapping_id"] = data.id;
    console.log("invoking liveClassService.ManuallyMarkComplete");
    await liveClassService.ManuallyMarkComplete(data, userId);

    console.log("dispatching FetchCourseLesson");
    dispatch(FetchCourseLesson(data.course_id, userId));
  };

  const viewResource = (id) => {
    const lessonResourecs = filterLesson.find((e) => e.id === id).resources;

    dispatch(FindCourseTopicResources(viewesource.active, id, lessonResourecs));
  };
  return (
    <Collapsible
      open={true}
      lazyRender={true}
      trigger={[
        props.title.name,
        <FaAngleRight
          size={20}
          className="absolute right-1 bottom-3 text-black lesson-arow"
        />,
      ]}
      contentHiddenWhenClosed={false}
      transitionTime={300}
      tabIndex={0}
    >
      {filterLesson &&
        filterLesson.map((data) => {
          if (data) {
            const st =
              restrict.length > 0 && restrict.some((c) => c.id == data.id);

            return (
              <div key={data.id} className="flex ">
                <div
                  key={data.id}
                  className={` w-full text-left border p-2 rounded-md mt-2 text-black ${
                    currentLesson.includes(data.id) == true
                      ? " bg-orange  text-white"
                      : ""
                  } ${
                    st ? "border-red-500 border-dotted cursor-not-allowed" : ""
                  }`}
                >
                  <div className="">
                    <NavLink
                      to={`${
                        st
                          ? "#"
                          : "/course/view/" + props.view + "/lesson/" + data.id
                      }`}
                      className={`${st ? "cursor-not-allowed" : ""}`}
                    >
                      {data.name}
                    </NavLink>
                  </div>
                  <div className="">
                    <input
                      type="checkbox"
                      checked={data.checked}
                      name="completion"
                      className="float-right	pl-2"
                      // disabled={data.completion_criteria !== 0 ? true : false}
                      onChange={() => markComplete(data, st)}
                    />
                  </div>
                  {data.resources.length > 0 && (
                    <div className="py-4" onClick={() => viewResource(data.id)}>
                      <span className="underline font-medium cursor-pointer">
                        View Resources
                      </span>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        })}
    </Collapsible>
  );
}
