import axios from "./Api";
import Toast from "wrappers/Toast";

export function checkCourseComplete(data, invokedAfterModuleCompletion) {
  const loader =
    invokedAfterModuleCompletion &&
    Toast.load("Checking course completion. Please wait...");
  console.log("checkCourseCompleteAudit", "invoked checkCourseComplete");
  invokedAfterModuleCompletion && Toast.endLoader(loader);
  return axios.post("/check-course-completion", data);
}

export function GetOrderDetailsById(data) {
  return axios.post("/order-detail", data);
}

export function GetUserCourseProgress(data) {
  return axios.post("/course-progress", data);
}

export function EvaluateQuizResults(data) {
  return axios.post("/compute_quiz_attempt", data);
}
