import { combineReducers } from "redux";
import AuthReducer from "./Auth";
import { CourseReducer } from "./Course";
import { quiz } from "./Quiz/quiz";
import UIReducer from "./UI";

export default combineReducers({
  auth: AuthReducer,
  course: CourseReducer,
  quiz: quiz,
  ui: UIReducer,
});
