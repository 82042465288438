import React from "react";
import { Link } from "react-router-dom";

export default function Navigator({ back, title }) {
  return (
    <div className="flex justify-between">
      <Link to={back.link}>&lt; Go to {back.name}</Link>
      <span className="small-title">{title}</span>
    </div>
  );
}
