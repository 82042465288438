import { useState } from "react";
import OrderSummaryCheckout from "./OrderSummary";
import BillingAddressCheckout from "./BillingAddressCheckout";

export default function CheckoutModal(props) {
  const {
    courseData,
    paymentFunction,
    paymentEvent,
    setCourseData,
    closeFunction,
  } = props;

  const [paymentStage, setPaymentStage] = useState({
    orderSummary: true,
    billingAddress: false,
  });

  return (
    <div>
      {paymentStage.orderSummary && (
        <OrderSummaryCheckout
          courseData={courseData}
          paymentFunction={paymentFunction}
          paymentEvent={paymentEvent}
          setCourseData={setCourseData}
          closeFunction={closeFunction}
          setPaymentStage={setPaymentStage}
          paymentStage={paymentStage}
        />
      )}
      {paymentStage.billingAddress && (
        <BillingAddressCheckout paymentFunction={paymentFunction} />
      )}
    </div>
  );
}
