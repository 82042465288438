import { api } from "../.././../api/User";
import { LOGIN, LOGIN_ERROR, SET_ONBOARDING } from "./index";
import { get_moodle_user } from "api/MoodleApi";
import { setUser } from "api/Auth";
import userSvc from "services/User";

export const validateToken = () => {
  return function (dispatch) {
    return api("validate_cookie", {})
      .then((response) => response)
      .catch((err) => ({ success: false, data: err }))
      .then(async (response) => {
        if (response.success) {
          // @label: removed moodle user
          // const moodle_user = await get_moodle_user();
          // response.data.moodle_user = moodle_user?.data;

          const isUserOnboarded = await userSvc
            .fetchTags(response.data.db_user.id)
            .then(
              (tags) =>
                !!tags.find(
                  (tag) => tag.name === "onboarded" && tag.value === "1"
                )
            );

          dispatch({
            type: SET_ONBOARDING,
            payload: !isUserOnboarded,
          });
          dispatch({
            type: LOGIN,
            payload: response.data,
          });
        } else {
          setUser("", "");
          dispatch({
            type: LOGIN_ERROR,
            payload: response.data,
          });
        }
      });
  };
};
