import React from "react";
import courseSvc from "services/Course";

export default function EnglishStarAdvertisement(props) {
  return (
    <div>
      <div className="flex flex-wrap course-banner bg-yellow-50">
        <div className="w-full lg:w-5/12">
          <div className="p-3 sm:w-md">
            <img
              className="h-40 w-full object-cover rounded-xl object-center border-b-2"
              src="./english-star-logo.jpg"
              alt="course-img"
            />
            <p className="text-base font-bold text-japanese_indigo text-center my-2 font-poppins">
              Learn Spoken English in 36 hours & 3 months
            </p>
            <button
              className="flex flex-row p-2 my-2 bg-white text-center mx-auto shadow-md border-2 rounded-md font-poppins"
              onClick={() =>
                courseSvc.openCourse(
                  { moodle_course_id: 19, courseId: 31 },
                  "new"
                )
              }
            >
              <img alt="banner-img" src="./cart.svg" className="mx-2" />
              Get Enrolled
            </button>
          </div>
        </div>
        <div className="course-banner-rt lg:w-7/12 ">
          <img
            src="/banner.png"
            alt="banner require here"
            className="lg:w-full sm:w-2/6 h-42"
          ></img>
        </div>
      </div>
    </div>
  );
}
