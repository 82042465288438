import { ScholarshipDetailsModal } from "components/scholarships";
import {
  GET_SCHOLARSHIP_BY_ID,
  GET_USER_SCHOLARSHIPS,
} from "graphql/queries/Scholarships";
import { useState, useEffect } from "react";
import Modal from "components/Modal";
import Loader from "assets/animated-images/Loader";
import { apolloClient } from "App";
import Toast from "wrappers/Toast";

export default function ApplyForScholarship(props) {
  const { params, complete, user } = props;
  const { scholarshipId } = params;
  const userId = user.id;
  const [scholarship, setScholarship] = useState();
  const [scholarshipModal, setScholarshipModal] = useState();

  useEffect(() => {
    (async () => {
      const scholarship = await apolloClient
        .query({
          query: GET_SCHOLARSHIP_BY_ID,
          variables: {
            id: scholarshipId,
          },
        })
        .then(({ data }) => {
          return data.courses_scholarships;
        });

      if (scholarship?.length === 0) {
        Toast.error("The scholarship doesn't exist");
        complete();
        return;
      }

      const userScholarshipdata = await apolloClient.query({
        query: GET_USER_SCHOLARSHIPS,
        variables: { user_id: userId },
      });

      if (
        userScholarshipdata?.data.courses_scholarship_partner_user.find(
          (c) => c.scholarship_id == scholarshipId
        )
      ) {
        Toast.error("You have already applied to this scholarship");
        complete();
      } else {
        setScholarship(scholarship[0]);
        setScholarshipModal(true);
      }
    })();
  }, []);

  return (
    <>
      {scholarship === undefined && (
        <Modal isOpen={true} onClose={complete}>
          <div className="flex justify-center p-5 w-full ml-4">
            <Loader style={{ width: "130px" }} />
          </div>
        </Modal>
      )}
      {scholarship === false && (
        <Modal isOpen={true} onClose={complete}>
          <div className="flex justify-center p-5 w-full">
            <span className="text-lg font-semibold text-gray-400">
              Couldn't find the Scholarship
            </span>
          </div>
        </Modal>
      )}
      {scholarship && (
        <ScholarshipDetailsModal
          scholarshipDetailsOpen={scholarshipModal}
          setScholarshipDetailsOpen={setScholarshipModal}
          details={scholarship}
        />
      )}
    </>
  );
}
