/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { COURSE_STATUS, englishStarCourseId } from "api/Consts";
import CourseSearchBox from "components/CourseSearchBox";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetSearchProps, FetchCourseProgress } from "redux/actions/Course";

import courseSvc from "services/Course";
import { wireEventValue } from "utils/func";
import { CourseCard } from ".";

export default function RecommendedCourses() {
  const dispatch = useDispatch();
  const [authStore, courseStore] = useSelector(({ auth, course }) => [
    auth,
    course,
  ]);
  const user = authStore.user?.db_user;
  const userId = user?.id;

  const { searchProps } = courseStore;

  const [courses, setCourses] = useState();
  const [categories, setCategories] = useState();

  const fetchCourses = async () => {
    let courses = await courseSvc.getDefaultCourses();

    courses = courses
      .map((course) => {
        course.students_enrolled = Number(course.students_enrolled || "0");
        return course;
      })
      .sort((a, b) => b.students_enrolled - a.students_enrolled);

    // Set english as the first course
    const englishCourse = courses.find((e) => e.id === englishStarCourseId);
    if (englishCourse) {
      const index = courses.indexOf(englishCourse);
      courses.splice(index, 1);
      courses.splice(0, 0, englishCourse);
    }

    setCourses(courses);
  };

  const fetchCategories = () =>
    courseSvc.getCourseCategories().then(setCategories);
  const fetchProgress = () => dispatch(FetchCourseProgress(userId));

  useEffect(() => {
    fetchCourses();
    fetchProgress();
    fetchCategories();
    return () =>
      dispatch(SetSearchProps({ selectedCategory: null, selectedStatus: "" }));
  }, []);

  useEffect(() => {
    // Update purchased courses in all courses list with their respective data in active_courses object
    if (courses && courseStore.active_courses) {
      Object.values(courseStore.active_courses).map((course) => {
        const foundCourse = courses.find((c) => {
          if (c) return c.id == course.id;
        });
        if (foundCourse) Object.assign(foundCourse, course);
      });
      setCourses([...courses]);
    }

    // For each course associated to a coupon, the course gets appended the below mentioned fields from the coupon data
    // Abhishek Challa: Don't know what is its purpose
    if (courses && courseStore.coupons) {
      Object.values(courseStore.coupons).map((coupon) => {
        const foundCourse = courses.find((userCourse) => {
          const courseExists = coupon.courses.find(
            (couponCourse) => couponCourse.id === userCourse.id
          );
          return courseExists;
        });

        if (foundCourse)
          Object.assign(foundCourse, {
            abs_discount: coupon.abs_discount,
            active: coupon.active,
            code: coupon.code,
            per_discount: coupon.discount,
            coupon_id: coupon.id,
          });
      });
      setCourses([...courses]);
    }
  }, [JSON.stringify(courses), courseStore.active_courses]);

  useEffect(() => {
    // Determine for which courses has the user booked a demo session
    if (courses && courseStore.user_demo_batches) {
      const enrolled_batch_slots = courseStore.user_demo_batches;

      for (const course of courses) {
        // Get registered demo batch slot for this course (if any)
        for (const batch_slot of enrolled_batch_slots) {
          if (
            batch_slot.batch?.course?.id === course.id &&
            new Date().getTime() < new Date(batch_slot.endto_date).getTime()
          ) {
            course.userHasRegisteredDemo = true;
            course.registeredSlotId = batch_slot.id;
            break;
          }
        }
      }

      setCourses([...courses]);
    }
  }, [courseStore.user_demo_batches, JSON.stringify(courses)]);

  const courseList =
    courses &&
    searchCourses(courses, {
      ...searchProps,
      activeCourses: courseStore.active_courses,
    });

  return (
    <>
      <div>
        <div className="p-4">
          <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
            All Courses
          </h1>
        </div>

        <div className="flex items-center justify-between">
          <CourseSearchBox />
          <div className="flex flex-row items-center justify-center m-4">
            <div className="font-poppins my-auto m-4 grid grid-cols-2 gap-2">
              <select
                className="rounded-md border-orange bg-transparent focus:outline-none"
                onChange={wireEventValue((value) =>
                  dispatch(SetSearchProps({ selectedCategory: value }))
                )}
                value={searchProps.selectedCategory}
              >
                <option value="">All Categories</option>
                {categories &&
                  categories.map((cat) => (
                    <option value={cat.id}>{cat.name}</option>
                  ))}
              </select>
              <select
                className="rounded-md border-orange bg-transparent focus:outline-none"
                onChange={wireEventValue((value) =>
                  dispatch(SetSearchProps({ selectedStatus: value }))
                )}
                value={searchProps.selectedStatus}
              >
                <option value="">All status</option>
                <option value={COURSE_STATUS.ONGOING}>Ongoing</option>
                <option value={COURSE_STATUS.COMPLETED}>Completed</option>
              </select>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-4">
          {(courses &&
            courseList.length &&
            courseList.map((course, idx) => {
              return (
                <>
                  <CourseCard
                    data={course}
                    openCourse={() =>
                      courseSvc.openCourse({
                        moodle_course_id: course.moodle_course_id,
                        courseId: course.id,
                      })
                    }
                    onBookDemo={() => fetchCourses()}
                  />
                </>
              );
            })) || (
            <h4 className="w-full flex-center mt-5 text-2xl font-semibold text-gray-300">
              No courses to display
            </h4>
          )}
        </div>
      </div>
    </>
  );
}

export const searchCourses = (courses, searchProps) => {
  const { selectedCategory, selectedStatus, keywords } = searchProps;
  const result = courses
    .filter((course) => course)
    // Category check
    .filter(
      (course) =>
        !selectedCategory || course.course_category.id == selectedCategory
    )
    // Status check
    .filter((course) => {
      if (!selectedStatus) return true;

      if (course.status) return course.status === selectedStatus;

      if (
        selectedStatus === COURSE_STATUS.ONGOING ||
        selectedStatus === COURSE_STATUS.COMPLETED
      ) {
        return searchProps.activeCourses[course.id];
      } else return !searchProps.activeCourses[course.id];
    })
    // Name filter
    .filter(
      (course) =>
        [course.full_name, course.course_category.name]
          .join(" ")
          .toLowerCase()
          .indexOf(keywords.toLowerCase()) > -1
    );

  return result;
};
