import { PROVIDERS, providers } from "api/Consts";
import Loader from "assets/animated-images/Loader";
import React from "react";
import { useState } from "react";
import ReactFacebookLogin from "react-facebook-login";
import { useDispatch } from "react-redux";
import { LoginWithProvider } from "redux/actions/Auth";

export default function FbButton(props) {
  const dispatch = useDispatch();
  const [loggingIn, setLoggingIn] = useState();

  return (
    <div className={props.className}>
      <ReactFacebookLogin
        appId={providers.facebook.app_id}
        callback={(response) => {
          if (response.accessToken) {
            setLoggingIn(true);
            dispatch(
              LoginWithProvider(PROVIDERS.FACEBOOK, response, () =>
                setLoggingIn()
              )
            );
          }
        }}
        cssClass={`fb-button w-full`}
        textButton={
          <>
            <img
              src={require("assets/icons/fb-icon.svg").default}
              className="button-icon"
            />
            {loggingIn ? (
              <Loader style={{ height: "20px" }} color="#fff" />
            ) : (
              "Facebook"
            )}
          </>
        }
        scope="public_profile,name,email"
      />
    </div>
  );
}
