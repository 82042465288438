import Modal from "components/Modal";
import _ from "lodash";
import userSvc from "services/User";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { BiChevronDown } from "react-icons/bi";
import { USER_ACCOUNT_TYPE } from "api/Consts";
import Toast from "wrappers/Toast";
import { addPayoffDetails, updatePayoffDetails } from "api/referrals";

export default function PayoutDetailsModal(props) {
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const [accountTypeDropdown, setAccountTypeDropdown] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    account_type: -1,
    address: "",
    account_holder_name: "",
    ifsc: "",
    account_number: "",
    fresh: true,
  });

  useEffect(() => {
    userSvc.fetchPayoutDetails(userId).then((res) => {
      if (res.length > 0) {
        setDetails({
          name: res[0].name || "",
          account_type: res[0].account_type
            ? res[0].account_type == USER_ACCOUNT_TYPE[0]
              ? 0
              : 1
            : -1,
          address: res[0].address || "",
          account_holder_name: res[0].account_holder_name || "",
          ifsc: res[0].ifsc || "",
          account_number: res[0].account_number || "",
          fresh: res[0].id ? false : true,
        });
      }
    });
  }, [props.payoutDetailOpen]);

  const checkAndAddPaymentDetails = async () => {
    // more specified checks can be applied using regex
    if (details.name.length < 3 || details.name.length > 50) {
      Toast.error("Length of name must be in 3-50 characters");
      return;
    }
    if (details.account_type != 0 && details.account_type != 1) {
      Toast.error("Please select the account type");
      return;
    }
    if (details.account_type == 0) {
      if (
        details.account_holder_name.length < 3 ||
        details.account_holder_name.length > 120
      ) {
        Toast.error("Please enter valid account holder's name");
        return;
      }
      if (details.ifsc.length != 11) {
        Toast.error("Please enter valid ifsc code");
        return;
      }
      if (
        details.account_number.length < 5 ||
        details.account_number.length > 35
      ) {
        Toast.error("Please enter valid account number");
        return;
      }
    } else {
      if (details.address.length < 3 || details.address.length > 100) {
        Toast.error("Please enter valid address(UPI Id)");
        return;
      }
    }
    addOrUpdatePaymentDetails();
  };

  const getApiDetails = () => {
    if (details.account_type == 0) {
      return {
        account_type: USER_ACCOUNT_TYPE[details.account_type],
        user_id: userId.toString(),
        ..._.pick(details, [
          "name",
          "account_holder_name",
          "ifsc",
          "account_number",
        ]),
      };
    } else {
      return {
        account_type: USER_ACCOUNT_TYPE[details.account_type],
        user_id: userId.toString(),
        ..._.pick(details, ["name", "address"]),
      };
    }
  };

  const addOrUpdatePaymentDetails = async () => {
    let params = getApiDetails();
    if (details.fresh) {
      const paymentDetails = await addPayoffDetails(params);
      if (paymentDetails.data.success) {
        Toast.success("Payout details added successfully!");
        props.setPayoutDetailOpen(false);
      } else {
        Toast.error(paymentDetails.data.message);
      }
    } else {
      const paymentDetails = await updatePayoffDetails(params);
      if (paymentDetails.data.success) {
        Toast.success("Payout details updated successfully!");
        props.setPayoutDetailOpen(false);
      } else {
        Toast.error(paymentDetails.data.message);
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={props.payoutDetailOpen}
        onClose={() => props.setPayoutDetailOpen(false)}
      >
        <div style={{ maxHeight: "40rem" }} className="flex font-poppins">
          <div className="flex-1 flex flex-col">
            <div className="flex h-full w-full">
              <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-scroll mb-8">
                <div className="flex w-full mx-auto px-6">
                  <div className="flex flex-col w-full h-full">
                    <h1 className="text-2xl font-semibold text-japanese_indigo mx-2">
                      Payout Details
                    </h1>
                    <div className="flex flex-col justify-evenly my-4">
                      <div className="flex p-4">
                        <div className="my-auto text-orange font-semibold rounded-md mr-4 w-52">
                          Name
                        </div>
                        <input
                          type="text"
                          className="input"
                          placeholder="Enter the name here (3-50 characters)"
                          value={details.name}
                          onChange={({ target: { value } }) =>
                            setDetails({ ...details, name: value })
                          }
                        />
                      </div>
                      <div
                        className="m-auto"
                        onClick={() =>
                          setAccountTypeDropdown(!accountTypeDropdown)
                        }
                      >
                        <div className="w-72 flex flex-row items-center p-2 rounded-md cursor-pointer justify-center shadow-md">
                          <p className="text-black-500 text-sm font-medium mx-2 m-0 font-poppins hover:text-orange transition duration-300">
                            {details.account_type != -1
                              ? USER_ACCOUNT_TYPE[details.account_type]
                              : "Select Account Type"}
                          </p>
                          <div className="flex flex-row text-gray-500 font-poppins transition duration-300">
                            <BiChevronDown
                              className="text-orange my-auto"
                              size={20}
                            />
                          </div>
                        </div>
                        {accountTypeDropdown && (
                          <div
                            onMouseLeave={() => setAccountTypeDropdown(false)}
                            className="max-h-80 overflow-y-auto absolute bg-white text-base w-auto z-50 flex flex-col rounded-md my-4 shadow-lg px-4 py-1.5"
                          >
                            {USER_ACCOUNT_TYPE?.map((option, index) => (
                              <div
                                key={index}
                                onClick={() => {
                                  setDetails({
                                    ...details,
                                    account_type: index,
                                  });
                                }}
                                className="cursor-pointer py-2  w-28 text-sm font-medium hover:text-orange"
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      {details.account_type == 0 && (
                        <>
                          <div className="flex p-4">
                            <div className="my-auto text-orange font-semibold rounded-md mr-4 w-52">
                              Bank Holder's Name
                            </div>
                            <input
                              type="text"
                              className="input"
                              placeholder="Enter the holder's name"
                              value={details.account_holder_name}
                              onChange={({ target: { value } }) =>
                                setDetails({
                                  ...details,
                                  account_holder_name: value,
                                })
                              }
                            />
                          </div>
                          <div className="flex p-4">
                            <div className="my-auto text-orange font-semibold rounded-md mr-4 w-52">
                              IFSC Code
                            </div>
                            <input
                              type="text"
                              className="input"
                              placeholder="Enter the ifsc code (contains 11 characters)"
                              value={details.ifsc}
                              onChange={({ target: { value } }) =>
                                setDetails({
                                  ...details,
                                  ifsc: value,
                                })
                              }
                            />
                          </div>
                          <div className="flex p-4">
                            <div className="my-auto text-orange font-semibold rounded-md mr-4 w-52">
                              Account Number
                            </div>
                            <input
                              type="text"
                              className="input"
                              placeholder="Enter the account number"
                              value={details.account_number}
                              onChange={({ target: { value } }) =>
                                setDetails({
                                  ...details,
                                  account_number: value,
                                })
                              }
                            />
                          </div>
                        </>
                      )}
                      {details.account_type == 1 && (
                        <>
                          <div className="flex p-4">
                            <div className="my-auto text-orange font-semibold rounded-md mr-4 w-52">
                              Address ( UPI ID )
                            </div>
                            <input
                              type="text"
                              className="input"
                              placeholder="Enter the holder's name here"
                              value={details.address}
                              onChange={({ target: { value } }) =>
                                setDetails({
                                  ...details,
                                  address: value,
                                })
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <span className="justify-end my-4">
                      <button
                        className="px-4 bg-orange text-white font-semibold p-2 rounded-md text-lg"
                        onClick={checkAndAddPaymentDetails}
                      >
                        Done
                      </button>
                    </span>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
