import React, { useState } from "react";
import { Switch } from "react-router-dom";
import Container from "components/Container";
import CustomRoute from "components/CustomRoute";
import AccountDetails from "./Settings/AccountDetails";
import Navigator from "components/Navigator";
import ToggleList, { ToggleListItem } from "components/Form/ToggleList";
import { useHistory } from "react-router-dom";
import EmailAndPassword from "./components/Settings/EmailAndPassword";
import Privacy from "./components/Settings/Privacy";
import { AccordionItem } from "components/AccordionItem";

export default function Settings(props) {
  const history = useHistory();

  return (
    <>
      <Switch>
        <CustomRoute
          path={props.prefix + "/account-details"}
          component={AccountDetails}
        />
        <CustomRoute
          path="/"
          component={(props) => {
            return (
              <Container>
                <Navigator
                  back={{ name: "Dashboard", link: "/" }}
                  title="Settings"
                />
                <ToggleList className="mt-10">
                  <AccordionItem
                    title="Profile details"
                    isNotToggleItem
                    onClick={() => history.push("/settings/account-details")}
                  />
                  <ToggleListItem>
                    {(args) => (
                      <AccordionItem {...args} title="Email and password">
                        <EmailAndPassword />
                      </AccordionItem>
                    )}
                  </ToggleListItem>
                  <ToggleListItem>
                    {(args) => (
                      <AccordionItem {...args} title="Privacy">
                        <Privacy />
                      </AccordionItem>
                    )}
                  </ToggleListItem>
                </ToggleList>
              </Container>
            );
          }}
        />
      </Switch>
    </>
  );
}
