import { useQuery } from "@apollo/client";
import { HiXCircle } from "react-icons/hi";
import {
  FETCH_POSSIBLE_LANGUAGES
} from "graphql/queries/SkillsTrainer";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import userSvc from "services/User";
import { wireEventValue } from "utils/func";
import Toast from "wrappers/Toast";


export default function Languages(props) {
  const LANGUAGES_POSSIBLE = useQuery(FETCH_POSSIBLE_LANGUAGES);
  const [userLanguages, setUserLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("-1");
  const [selectedLanguageProficiency, setSelectedLanguageProficiency] = useState(0);
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;

  const fetchLanguages = () =>
    userSvc.fetchUserLanguages(userId).then((languages) => {
      if(!languages){
        Toast.error("There was an error while fetching data");
        return;
      }

      // adding name to the object in all language and setting in the userLanguages
      setUserLanguages(languages?.map((language) => ({
        ...language,
        name: language.st_language.name
      })))
    });

  useEffect(() => {
    fetchLanguages();
  }, []);

  // Actions

  const isLanguageChoosed = (id) => {
    for(let language of userLanguages){
      if(language.language_id == id && !language.disabled) return true
    }
    return false;
  }

  const handleProficiency = (position) => {
    if(selectedLanguage == "-1"){
      Toast.error("Please select a language to set proficiency!");
      return;
    }
    let newProficiency = selectedLanguageProficiency^(1<<position);
    setSelectedLanguageProficiency(newProficiency)
  }

  const addLanguage = () => {
    let can_speak = Boolean(selectedLanguageProficiency & 1);
    let can_read = Boolean((selectedLanguageProficiency>>1) & 1);
    let can_write = Boolean((selectedLanguageProficiency>>2) & 1);
    if(selectedLanguage != "-1"){
      let prevLanguage = userLanguages?.find(language => language.language_id === parseInt(selectedLanguage));
      if(prevLanguage){
        prevLanguage.disabled = undefined
        prevLanguage.can_read = can_read
        prevLanguage.can_speak = can_speak
        prevLanguage.can_write = can_write
        setUserLanguages([
          ...userLanguages?.filter((language)=>language.language_id != parseInt(selectedLanguage)),
          prevLanguage
        ])
      }else{
        setUserLanguages([
          ...userLanguages,
          {
            language_id: selectedLanguage,
            name: LANGUAGES_POSSIBLE?.data?.courses_skillstrainer_languages?.find(language => language.id === parseInt(selectedLanguage)).name,
            can_read,
            can_speak,
            can_write,
            fresh: true
          }
        ])
      }
      setSelectedLanguageProficiency(0);
      setSelectedLanguage("-1");
    }else{
      Toast.error("Please select a skill to add!");
    }
  }

  const removeLanguage = (languageToRemove) => {
    languageToRemove.disabled = true;
    setSelectedLanguage([...userLanguages.filter((language)=>language.language_id!=languageToRemove.language_id), languageToRemove]);
  }

  const submit = async () => {
    let newAllLanguages = await userSvc.setUserLanguages(userLanguages, userId);
    if (newAllLanguages) {
      setUserLanguages(newAllLanguages);
      Toast.success("Successfully updated!");
      if(props.isLastPage)
        props.finishFn()
      else
        props.next();
    }
  };

  // Actions End

  return (
    <div className="card p-4 mb-20">
      <b className="mb-5 text-lg font-bold">Languages</b>
      <div>
        <select
          onChange={wireEventValue(setSelectedLanguage)}
          value={selectedLanguage}
          className="my-2 mt-4"
        >
          {<option value="-1">No Language</option>}
          {LANGUAGES_POSSIBLE?.data?.courses_skillstrainer_languages?.map((language) => {
            return (
              !isLanguageChoosed(language.id) && <option key={language.id} value={language.id} id={language.id}>
                {language.name}
              </option>
            );
          })}
        </select>
        <label className="ml-2 py-2 px-2 cursor-pointer inline-flex flex-row hover:text-orange">
          <input type="checkbox" checked={selectedLanguageProficiency & 1} value="CAN SPEAK" onChange={()=>handleProficiency(0)}/>
          <p className="mx-2">CAN SPEAK</p>
        </label>
        <label className="ml-2 py-2 px-2 cursor-pointer inline-flex flex-row hover:text-orange">
          <input type="checkbox" checked={(selectedLanguageProficiency>>1) & 1} value="CAN READ" onChange={()=>handleProficiency(1)}/>
          <p className="mx-2">CAN READ</p>
        </label>
        <label className="ml-2 py-2 px-2 cursor-pointer inline-flex flex-row hover:text-orange">
          <input type="checkbox" checked={(selectedLanguageProficiency>>2) & 1} value="CAN WRITE" onChange={()=>handleProficiency(2)}/>
          <p className="mx-2">CAN WRITE</p>
        </label>
        <span className="justify-end my-4">
          <button
            className="mx-3	px-4 bg-orange text-white font-semibold p-2 rounded-md text-lg"
            onClick={addLanguage}
          >
            Add
          </button>
        </span>

        <div className="flex flex-wrap flex-row w-full">
          {userLanguages.map((language) => {
            return !language.disabled && <span key={language.language_id} className="flex flex-row item-center justify-center border-orange rounded-2xl mx-3 border-2 py-1 pl-3 pr-1 my-2">
              <p className="text-xs text-orange my-auto mt-0.5 pr-1">
                {language.name} {language.can_speak? "(speak) ":""}{language.can_read? "(read) ":""}{language.can_write? "(write) ":""}
              </p>
              <HiXCircle size={20} className="text-orange my-auto mt-0 cursor-pointer" 
                onClick={() => removeLanguage(language)} 
                />
            </span>
          })}
        </div>

        <div className="w-full flex justify-end my-4">
          <button
            className="w-1/3 bg-orange text-white font-semibold p-2 rounded-md text-lg"
            onClick={submit}
          >
            Save and Proceed
          </button>
        </div>
      </div>
    </div>
  );
}
