import { gql } from "@apollo/client";

export const UPDATE_COURSE_SCORM_STATUS_1_2 = gql`
  mutation UpdateCourseScorm(
    $user_id: Int
    $suspend_data: String
    $success_status: String
    $mod_id: Int
    $mapping_id: Int
    $lesson_status: String
    $last_intersection: String
    $course_id: Int
    $score_max: String
    $score_min: String
    $score_raw: String
    $session_time: String
    $attempt_id: Int
  ) {
    update_courses_course_scorm_track_data(
      where: {
        mapping_id: { _eq: $mapping_id }
        user_id: { _eq: $user_id }
        attempt_id: { _eq: $attempt_id }
      }
      _set: {
        course_id: $course_id
        last_intersection: $last_intersection
        lesson_status: $lesson_status
        mod_id: $mod_id
        success_status: $success_status
        suspend_data: $suspend_data
        user_id: $user_id
        score_max: $score_max
        score_min: $score_min
        score_raw: $score_raw
        session_time: $session_time
      }
    ) {
      returning {
        id
        mapping_id
        lesson_status
        attempt_id
      }
    }
  }
`;

export const UPDATE_COURSE_SCORM_STATUS_2004 = gql`
  mutation UpdateCourseScorm(
    $user_id: Int
    $suspend_data: String
    $success_status: String
    $mod_id: Int
    $mapping_id: Int
    $lesson_status: String
    $last_intersection: String
    $course_id: Int
    $score_max: String
    $score_min: String
    $score_raw: String
    $session_time: String
    $attempt_id: Int
  ) {
    update_courses_course_scorm_track_data(
      where: {
        mapping_id: { _eq: $mapping_id }
        user_id: { _eq: $user_id }
        attempt_id: { _eq: $attempt_id }
      }
      _set: {
        course_id: $course_id
        last_intersection: $last_intersection
        lesson_status: $lesson_status
        mod_id: $mod_id
        success_status: $success_status
        suspend_data: $suspend_data
        user_id: $user_id
        score_max: $score_max
        score_min: $score_min
        score_raw: $score_raw
        session_time: $session_time
      }
    ) {
      returning {
        id
        mapping_id
        lesson_status
        attempt_id
      }
    }
  }
`;

export const INSERT_SCORM_DATA = gql`
  mutation InsertScormData(
    $course_id: Int
    $mapping_id: Int
    $mod_id: Int
    $user_id: Int
    $attempt_id: Int
  ) {
    insert_courses_course_scorm_track_data(
      objects: {
        mod_id: $mod_id
        mapping_id: $mapping_id
        course_id: $course_id
        user_id: $user_id
        attempt_id: $attempt_id
      }
      on_conflict: {
        constraint: course_scorm_track_data_user_id_mapping_id_attempt_id_key
        where: { mapping_id: { _eq: $mapping_id }, user_id: { _eq: $user_id } }
        update_columns: []
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const COURSE_MODULE_COMPLETION = gql`
  mutation courseModuleCompletion(
    $completion_date: timestamptz
    $completion_status: Int
    $course_id: Int
    $mod_id: Int
    $section_id: Int
    $user_id: Int
    $mapping_id: Int
  ) {
    insert_courses_course_module_completion(
      objects: {
        user_id: $user_id
        mod_id: $mod_id
        course_id: $course_id
        section_id: $section_id
        completion_status: $completion_status
        completion_date: $completion_date
        mapping_id: $mapping_id
      }
    ) {
      returning {
        completion_status
        user_id
      }
    }
  }
`;

export const INSERT_USER_COURSE_CERTIFICATE_ISSUE = gql`
  mutation InsertUserCertificateIssue(
    $enrollment_id: Int
    $issue_date: timestamptz
    $mapping_id: Int
    $user_id: Int
    $course_id: Int
  ) {
    insert_courses_course_user_certificate_issue(
      objects: {
        enrollment_id: $enrollment_id
        issue_date: $issue_date
        mapping_id: $mapping_id
        user_id: $user_id
        course_id: $course_id
      }
    ) {
      returning {
        mapping_id
        user_id
        enrollment_id
        issue_date
        course_id
      }
    }
  }
`;

export const USER_COURSE_COMPLETE = gql`
  mutation UserCourseComplete(
    $completed: Boolean
    $completed_on: timestamptz
    $course_id: Int
    $percentage: Int
    $user_id: Int
  ) {
    insert_courses_user_course_complete(
      objects: {
        completed: $completed
        completed_on: $completed_on
        course_id: $course_id
        percentage: $percentage
        user_id: $user_id
      }
      on_conflict: {
        constraint: user_course_complete_course_id_user_id_key
        update_columns: []
      }
    ) {
      returning {
        id
        completed
        course_id
      }
    }
  }
`;


export const completeUserAttemptByID=gql`
mutation MyMutation(
  $attempt_completed: Boolean, 
  $attempt_end: timestamptz , 
  $id: Int
  ) {
  update_courses_course_module_user_attempt(where: {
    id: {_eq: $id}}, 
    _set: {
      attempt_completed: $attempt_completed, 
      attempt_end: $attempt_end
    }) {
    affected_rows
  }
}

`