import { gql } from "@apollo/client";

export const SCHOLARSHIP_COLUMNS = `
  created_at
  amount
  description
  gender
  id
  is_active
  max_age
  min_age
  no_of_positions
  partner_id
  project_id
  title
  updated_at
  social_categories
  partner {
    logo
    organization_name
    name
  }
`;

export const GET_SCHOLARSHIPS = gql`
  query getScholarships {
    courses_scholarships(order_by: { created_at: desc }) {
      ${SCHOLARSHIP_COLUMNS}
    }
  }
`;

export const USER_APPLIED_SCHOLARSHIPS = gql`
  query getMyScholarships($userId: bigint) {
    courses_scholarship_partner_user(where: { user_id: { _eq: $userId } }) {
      id
      scholarship_id
      partner_id
      user_id
      accept
      created_at
      updated_at
      contribution
      contribution_percentage
      qualification_id
      applied_at
      selected_at
      scholarship {
        ${SCHOLARSHIP_COLUMNS}
      }
    }
  }
`;

export const GET_SCHOLARSHIP_BY_ID = gql`
  query getScholarshipById($id: uuid) {
    courses_scholarships(where: { id: { _eq: $id } }) {
      created_at
      amount
      description
      gender
      id
      is_active
      max_age
      min_age
      no_of_positions
      partner_id
      project_id
      title
      updated_at
      social_categories
      partner {
        logo
        organization_name
        name
      }
    }
  }
`;

export const GET_USER_SCHOLARSHIPS = gql`
  query getUserScholarships($user_id: bigint) {
    courses_scholarship_partner_user(where: { user_id: { _eq: $user_id } }) {
      user_id
      id
      scholarship_id
    }
  }
`;
