import { gql } from "@apollo/client";


export const GET_COURSE_TAX=gql`

query GetCourseTax($course_id: Int) {
      courses_course_tax_relation(where: {course_id: {_eq: $course_id}}) {
        course_tax_relation_object {
          tax_name
          tax_percentage
        }
      }
    }
    `