import React, { useRef } from "react";
import { Subject } from "rxjs";
import { uploadFile } from "api/Api";
import FormComponent from "components/Form/index";
import { useLoader } from "utils/hooks";
import Toast from "wrappers/Toast";
import { plugins } from "components/FormResources";

/*
  FormPage handles all the forms that are being passed to it:
    - renders form based on dependencies
    - manages multiple form submissions as a single process
*/

export default function FormPage(props) {
  const { page, nextFn, finishFn, isLastPage } = props;
  const { forms } = page || {};

  const $submitSignal = useRef(new Subject());
  const submitCurrentForm = () => {
    startLoader();
    $submitSignal.current.next(true);
  };
  const { loading: submittingForm, startLoader, stopLoader } = useLoader();

  const submissionProcessId = useRef(new Date().getTime());
  const submitCount = useRef(0);
  const afterSubmit = ({ error, pid, status }) => {
    const current_pid = submissionProcessId.current;

    if (pid === current_pid) {
      if (status.ok) submitCount.current++;

      let isFinished = false;
      if (!status.ok) isFinished = true;
      if (submitCount.current === forms.length) isFinished = true;

      if (isFinished) {
        submitCount.current = 0;
        submissionProcessId.current = new Date().getTime();
        stopLoader();

        if (!status.ok) {
          Toast.error(error?.msg || "An error occurred");
        } else {
          Toast.success();
          if (!isLastPage) nextFn();
          else finishFn();
        }
      }
    }
  };

  return (
    <div className="w-full">
      {forms.map((form) => (
        <div className="flex flex-col">
          <div className="rounded-lg shadow-md p-4 pb-0 pt-5 mb-5">
            <div className="mb-5 text-lg font-bold">{form.name}</div>
            <div className="w-full">
              <FormComponent
                name={form.name}
                formBuilder={form.formBuilder}
                $submitSignal={$submitSignal.current}
                initValues={form.initValuesFn}
                onSubmit={(values) => {
                  const pid = submissionProcessId.current;
                  form
                    .submitFn(values)
                    .then((res) => {
                      afterSubmit({
                        formName: form.name,
                        pid,
                        status: { ok: !!res },
                      });
                    })
                    .catch((err) => {
                      // Errors in the after submit event
                      console.error(err);
                      afterSubmit({
                        formName: form.name,
                        pid,
                        status: { ok: false },
                      });
                    });
                }}
                onSubmitError={(error) => {
                  // Errors in form validation or file upload
                  afterSubmit({
                    pid: submissionProcessId.current,
                    status: { ok: false },
                    error,
                    formName: form.name,
                  });
                }}
                uploadFileFn={(...args) =>
                  uploadFile(...args).then((res) => res.data_url)
                }
                hideSubmit={true}
                key={form.id}
                plugins={plugins}
              />
            </div>
          </div>
        </div>
      ))}

      <div className="w-full flex justify-end items-center my-2">
        <button
          onClick={submitCurrentForm}
          className="bg-orange text-white font-semibold p-2 rounded-md text-lg w-1/3"
          disabled={submittingForm}
        >
          {submittingForm
            ? "Please wait..."
            : isLastPage
            ? "Finish"
            : "Save and Proceed"}
        </button>
      </div>
    </div>
  );
}
