import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import userSvc from "services/User";
import { socket, SocketStatus } from "utils/websocket";
import { validateToken } from "../redux/actions/Auth/validate";

export default function AuthWrapper(props) {
  const auth = useSelector(({ auth }) => auth);
  const { db_user: user } = auth.user || {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(validateToken());
  }, []);

  useEffect(() => {
    if (!user) return;

    // Mark the migrated users arrived upon successful login
    if (user.source === "onboarded")
      userSvc.updateUserDetails(user.id, { source: "onboarded_arrived" });

    return () => socket.destroy();
  }, [auth.user]);

  window.setHighestQualification = () =>
    userSvc.setHighestQualification(
      {
        id: 3,
        institutionName: "Hillwoods Academy",
      },
      user?.id
    );

  // When user is changing
  useEffect(() => {
    (async function () {
      if (socket.status === SocketStatus.OPEN) await socket.destroy();
      if (user?.id) socket.create();
    })();
  }, [user?.id]);

  return <div>{props.children}</div>;
}
