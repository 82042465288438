import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import queryString from "query-string";
import liveClassService from "services/liveClass";
import { useSelector, useDispatch } from "react-redux";
import ScormView from "components/CourseBuilder/ScormView";
import QuizView from "components/CourseBuilder/QuizView";
import Modal from "components/Modal";
import {
  FindCourseTopicResources,
  openCourseCompletionSettings,
} from "redux/actions/Course";
import { s3BaseUrl } from "api/Consts";
import ResourceView from "components/CourseBuilder/ResourceView";
import { AiOutlineDownload } from "react-icons/ai";
import { TOGGLE_LEFT_SIDEBAR } from "redux/reducers/UI";
import ViewModSpecialConditions from "./ViewMod/SpecialConditions";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import courseSvc from "services/Course";

export default function ViewMod() {
  const location = useLocation();
  console.log("🚀 ~ ViewMod ~ location:", location)
  const params = queryString.parse(location.search);

  const [auth, isOpen] = useSelector(({ auth, ui }) => [
    auth,
    ui.isLeftSidebarOpen,
  ]);
  const viewesource = useSelector(({ course }) => course.viewResource);
  const viewCourseCompletionSetting = useSelector(
    ({ course }) => course.viewCourseCompletionSetting
  );

  const dispatch = useDispatch();

  const quiz = useSelector(({ quiz }) => quiz);

  const userId = auth.user?.db_user?.id;
  const { id } = useParams();

  const [modData, setModData] = useState({
    course_id: null,
    mod_id: null,
    completion_criteria: null,
    section_id: null,
  });

  const [completionStatus, setCompletion] = useState(false);
  const [mod, setModType] = useState("");
  const [loadData, setLoadData] = useState(false);
  const [nextId, setNextId] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [courseCompletionDesc, setCourseCompletionDesc] = useState(null);

  useEffect(() => {
    getModType();
    setLoadData(false);
  }, [id]);

  const getModType = async () => {
    if (id) {
      const res = await liveClassService.getCourseMapping(id);

      if (res) {
        const modType = await liveClassService.checkModType(res.mod_id);

        setModType(modType);

        const checkComletionBySection =
          await liveClassService.checkUserCompletionBySection(
            res.section_id,
            userId,
            id
          );

        checkComletionBySection ? setCompletion(true) : setCompletion(false);

        const enrollData = await liveClassService.GetUserEnrollmentId(
          userId,
          res.course_id
        );

        setModData({
          course_id: res.course_id,
          mod_id: res.mod_id,
          completion_criteria: res.completion_criteria,
          section_id: res.section_id,
          mapping_id: res.id,
          scorm_completion_criteria: res.scorm_completion_criteria,
          scorm_track_data: res.scorm_track_data,
          attemtsgrade: res.attemtsgrade,
          maxattempt: res.maxattempt,
          courseName: res.course_module.full_name,
          description: res.description,
          lessonName: res.name,
          publish_url: res.publish_url,
          user_id: userId,
          enable_timer: res.enable_timer,
          timelimit_number: res.timelimit_number,
          enrollment_id: enrollData.id,
          require_webcam: res.require_webcam,
          module_resources: res.module_resources,
          completionStatus: checkComletionBySection,
        });

        setLoadData(true);
        const lessons = await courseSvc.getCourseLessonTopics(
          res.course_id,
          userId
        );

        // get Course completion Desc

        const courseCompletionDesc = await courseSvc.getCourseCompletionDesc(
          res.course_id
        );

        setCourseCompletionDesc(courseCompletionDesc?.course_completion_desc);

        const extractIds = (lessons?.courses_course_section || [])
          .map((E) => E.coursesec)
          .flatMap((arr) => arr.map((obj) => obj.id));

        // Find the index of the given ID in the array of IDs
        const currentIndex = extractIds.indexOf(res.id);

        // Find the next ID if it exists
        const nextId =
          currentIndex === extractIds.length - 1
            ? null
            : extractIds[currentIndex + 1];

        // Find the previous ID if it exists
        const prevId = currentIndex === 0 ? null : extractIds[currentIndex - 1];

        setNextId(nextId);
        setPrevId(prevId);
      }
    }
  };

  const downloadFile = async (file_name) => {
    window.open(s3BaseUrl + "/" + file_name, "_blank");
  };

  return (
    <div className="pl-7">
      <Modal
        width="45%"
        isOpen={viewCourseCompletionSetting}
        onClose={() =>
          dispatch(openCourseCompletionSettings(!viewCourseCompletionSetting))
        }
      >
        <h2 className="text-2xl text-center font-semibold leading-10 text-japanese_indigo mb-6">
          Course Completion Instruction
        </h2>
        <div
          className="list-wrapper p-5"
          dangerouslySetInnerHTML={{
            __html: courseCompletionDesc,
          }}
        ></div>
      </Modal>
      <div
        className="h-4/5 w-12 py-0 lg:hidden bg-gray-200 fixed left-0 z-20 flex flex-col justify-center"
        onClick={() => dispatch({ type: TOGGLE_LEFT_SIDEBAR })}
      >
        <div
          className="w-32"
          style={{ transform: "rotate(-90deg) translateY(-40px)" }}
        >
          {isOpen ? "Hide" : "Show"} Activities
        </div>
      </div>

      <div className="ml-6 lg:ml-0">
        {quiz.QuizMode && !quiz.ScormFullScreen && (
          <div className="mt-5">
            {prevId && (
              <div style={{ float: "left" }}>
                <NavLink
                  to={`${
                    "/course/view/" + modData?.course_id + "/lesson/" + prevId
                  }`}
                  className={`bg-orange hover:opacity-90 text-white font-semibold rounded-lg p-3 mt-3 w-full sm:w-auto`}
                >
                  {"Back to Previous Module"}
                </NavLink>
              </div>
            )}
            {nextId && (
              <div style={{ float: "right" }}>
                <NavLink
                  to={`${
                    "/course/view/" + modData?.course_id + "/lesson/" + nextId
                  }`}
                  className={`bg-orange hover:opacity-90 text-white font-semibold rounded-lg p-3 mt-3 w-full sm:w-auto`}
                >
                  {"Jump to Next Module"}
                </NavLink>
              </div>
            )}
          </div>
        )}
        <div style={{ clear: "both" }}></div>
        {mod == "scorm-package" && loadData ? (
          <ScormView
            modData={modData}
            completionStatus={completionStatus ? true : false}
          />
        ) : mod == "quiz" && loadData ? (
          <QuizView modData={modData} courseView={id} />
        ) : mod == "files-attachment" && loadData ? (
          <ResourceView modData={modData} />
        ) : (
          <div></div>
        )}
      </div>

      <Modal
        width="45%"
        className=""
        isOpen={viewesource.active}
        onClose={() =>
          dispatch(FindCourseTopicResources(viewesource.active, null, null))
        }
      >
        <div className="p-2">
          <h1 className="text-4xl text-center font-semibold leading-10 text-japanese_indigo mb-6">
            Downlaod Resources
          </h1>
          <h1 className="font-bold text-center my-4 text-xl  text-japanese_indigo">
            Resources Lists
          </h1>
        </div>
        <div className="w-full bg-white rounded-lg shadow-lg">
          <ul className="divide-y-2 divide-gray-400">
            {viewesource.lessonResourecs?.map((e, i) => {
              return (
                <li
                  key={e.id}
                  className="flex justify-between p-3 hover:bg-orange hover:text-white"
                >
                  {e.name}
                  <div className="flex justify-end gap-3 p-3 hover:bg-orange hover:text-white">
                    <AiOutlineDownload
                      className="cursor-pointer mr-0"
                      size={22}
                      onClick={() => downloadFile(e.file_name)}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>

      <ViewModSpecialConditions userId={userId} courseId={modData?.course_id} />
    </div>
  );
}
