/* eslint-disable no-unused-vars */
import QRCode from "react-qr-code";
import Modal from "components/Modal";
import { Formik, Field, Form } from "formik";
import { enterpriseValidationSchema } from "../../utils/schema";
import { useMutation } from "@apollo/client";
import { INSERT_ENTERPRISE_CONTACT } from "graphql/mutations/Enterprise";
import { useState } from "react";
import { enterpriseQrCode } from "utils/func";
import { getDataFromPincode } from "utils/resources";
import Toast from "../../utils/Toast";
import { ORGANISATION_TYPE } from "api/Consts";
import { FaWhatsapp } from "react-icons/fa";

export default function EnterpriseModal(props) {
  const [addEnterpriseContact, { data, loading, error }] = useMutation(
    INSERT_ENTERPRISE_CONTACT
  );
  const [qrCodeLink, setQrCodeLink] = useState(
    "https://api.whatsapp.com/send?phone=919810109292"
  );
  const [showQr, setShowQr] = useState(false);

  const initialValues = {};
  let submitValues = {};

  const submitHandler = async (values) => {
    Toast.success("Please wait while we submit your details");
    console.log("Values", values);
    let pin;

    const handlePincodeChange = async (pincode) => {
      await getDataFromPincode({ country: "IND", pincode: pincode })
        .then((res) => {
          console.log(res);
          pin = res;
          return res;
        })
        .catch(() => (pin = null));
    };
    await handlePincodeChange(values.pincode);

    if (pin) {
      values.address = pin.city_town + " , " + pin.district;
      submitValues = {
        variables: {
          enterprises: values,
        },
      };

      // Generate QR code
      if (pin.city_town && pin.district) {
        console.log("Generating QR Code");
        const qrLink = await enterpriseQrCode(values, values.purpose, pin);
        setQrCodeLink(qrLink);
      }

      const res = await addEnterpriseContact(submitValues);
      if (res.data.insert_courses_signup_enterprises.affected_rows) {
        console.log(res);
        Toast.success("Contact details submitted successfully!");
        setShowQr(true);
      } else {
        Toast.error("An error occured, please try again later!");
      }
    } else {
      Toast.error("Invalid Pincode! Please try again!");
    }
  };

  return (
    <>
      <Modal
        width="45%"
        isOpen={props.isOpen}
        onClose={() => props.onClose(false)}
      >
        {!showQr ? (
          <>
            <div className="font-poppins flex flex-col px-0 lg:px-8">
              <h1 className="text-lg text-japanese_indigo font-semibold">
                Fill in your details
              </h1>
              <p className="italic mb-3 text-xs">
                Fields marked with <span className="text-red-500">*</span> are
                compulsory
              </p>
              <Formik
                initialValues={initialValues}
                onSubmit={submitHandler}
                validateOnBlur={false}
                validationSchema={enterpriseValidationSchema}
                enableReinitialize
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div>
                        <p className="mt-1 font-semibold">
                          Your Name <span className="text-red-500">*</span>
                        </p>
                        <Field
                          name="contact_name"
                          style={{
                            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.12)",
                          }}
                          className="w-full rounded-lg border-white my-2"
                          type="text"
                        />
                        {touched.contact_name && errors.contact_name && (
                          <div className="text-red-500 text-sm -mt-2 mb-3">
                            {errors.contact_name}
                          </div>
                        )}
                      </div>

                      <div>
                        <p className="mt-1 font-semibold">
                          Organization Name{" "}
                          <span className="text-red-500">*</span>
                        </p>
                        <Field
                          name="organisation_name"
                          style={{
                            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.12)",
                          }}
                          className="w-full rounded-lg border-white my-2"
                          type="text"
                        />
                        {touched.organisation_name &&
                          errors.organisation_name && (
                            <div className="text-red-500 text-sm -mt-2 mb-3">
                              {errors.organisation_name}
                            </div>
                          )}
                      </div>

                      <div>
                        <p className="mt-1 font-semibold">
                          Email ID <span className="text-red-500">*</span>
                        </p>
                        <Field
                          name="email_id"
                          style={{
                            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.12)",
                          }}
                          className="w-full rounded-lg border-white my-2"
                          type="email"
                        />
                        {touched.email_id && errors.email_id && (
                          <div className="text-red-500 text-sm -mt-2 mb-3">
                            {errors.email_id}
                          </div>
                        )}
                      </div>

                      <div>
                        <p className="mt-1 font-semibold">Purpose</p>
                        <Field
                          name="purpose"
                          style={{
                            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.12)",
                          }}
                          className="w-full rounded-lg border-white my-2"
                          type="text"
                        />
                        {touched.purpose && errors.purpose && (
                          <div className="text-red-500 text-sm -mt-2 mb-3">
                            {errors.purpose}
                          </div>
                        )}
                      </div>

                      <div>
                        <p className="mt-1 font-semibold">Pincode</p>
                        <Field
                          name="pincode"
                          style={{
                            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.12)",
                          }}
                          className="w-full rounded-lg border-white my-2"
                          type="text"
                        />
                        {touched.pincode && errors.pincode && (
                          <div className="text-red-500 text-sm -mt-2 mb-3">
                            {errors.pincode}
                          </div>
                        )}
                      </div>

                      <div>
                        <p className="mt-1 font-semibold">
                          Contact Number <span className="text-red-500">*</span>
                        </p>
                        <Field
                          name="contact_number"
                          style={{
                            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.12)",
                          }}
                          className="w-full rounded-lg border-white my-2"
                          type="text"
                        />
                        {touched.contact_number && errors.contact_number && (
                          <div className="text-red-500 text-sm -mt-2 mb-3">
                            {errors.contact_number}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="w-full">
                      <p className="mt-1 font-semibold">
                        Organisation Type{" "}
                        <span className="text-red-500">*</span>
                      </p>
                      <Field
                        name="organisation_type"
                        as="select"
                        style={{
                          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.12)",
                        }}
                        className="w-full rounded-lg border-white my-2"
                      >
                        <option value="" label="Select Organistion Type">
                          Select Organisation Type
                        </option>
                        {ORGANISATION_TYPE.map((item) => {
                          return (
                            <option
                              key={item.value}
                              value={item.value}
                              label={item.label}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </Field>

                      {touched.orgisation_type && errors.organisation_type && (
                        <div className="text-red-500 text-sm -mt-4 mb-3">
                          {errors.organisation_type}
                        </div>
                      )}
                    </div>

                    <div className="flex flex-row items-center justify-center w-full my-4">
                      <button
                        onClick={() => props.onClose(false)}
                        className="w-full mr-4 bg-orange-light text-japanese_indigo font-semibold p-2 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        // onClick={() => setShowQr(true)}
                        className="w-full ml-4 bg-orange text-white font-semibold p-2 rounded-lg"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col item-center justify-center font-poppins">
              <div className="flex items-center justify-center m-3">
                <div className="hidden lg:block">
                  <QRCode value={qrCodeLink} />
                </div>
                <a
                  className="block lg:hidden"
                  href={qrCodeLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <button className="bg-green-500 text-white font-semibold px-2 py-2 rounded-lg flex flex-row items-center justify-center">
                    <FaWhatsapp size={30} />
                    Connect on WhatsApp
                  </button>
                </a>
              </div>
              <p className="mx-auto text-lg font-semibold text-center my-2">
                Thank you for joining us!
              </p>
              <p className="mx-auto text-lg font-semibold hidden lg:block text-center my-2">
                Scan this QR code to start a chat with us on WhatsApp!
              </p>
              <p className="mx-auto text-lg font-semibold block lg:hidden text-center my-2">
                Tap the button to get a conversation going!
              </p>
              <button
                onClick={() => props.onClose(false)}
                className="w-full mx-auto lg:w-1/5 my-10 bg-orange-light text-japanese_indigo font-semibold p-2 rounded-lg"
              >
                Close
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}
