import { qrCodeGenerationApiUrl } from "api/Consts";

// Conditional Object: returns the object if condition is true, else an empty object (or falseObject)
export const condObj = (condition, trueObject, falseObject = {}) =>
  condition ? trueObject : falseObject;

// Returns an empty string for falsy values
export const _es = (s) => s || "";

// Returns that value if not a function, else returns the output of the function
export const resolveValue = (val) => {
  if (typeof val === "function") return val();
  else return val;
};

// extracts value from input event
export const wireEventValue = (func) => (e) => func(e.target.value);

// updates the values of the first object with the values of the second object
export const updateObject = (target, ref) => {
  Object.keys(ref).map((refKey) => {
    target[refKey] = ref[refKey];
  });
};

// returns an object as a path => value map
export const convertToPathValuePairs = (inp) => {
  JSON.stringify(inp);

  const rec = (obj) => {
    const map = {};

    if (Array.isArray(obj))
      obj.forEach((item, index) => {
        const subMap = rec(item);

        if (subMap && typeof subMap === "object")
          Object.keys(subMap).forEach(
            (subKey) => (map[`[${index}]${subKey}`] = subMap[subKey])
          );
        else map[`[${index}]`] = subMap;
      });
    else if (obj && typeof obj === "object") {
      Object.keys(obj).forEach((key) => {
        const value = rec(obj[key]);

        if (value && typeof value === "object") {
          Object.keys(value).forEach(
            (subKey) => (map[`.${key}${subKey}`] = value[subKey])
          );
        } else map[`.${key}`] = value;
      });
    } else return obj;
    return map;
  };

  const result = rec(inp);

  if (!Array.isArray(inp) && typeof inp === "object") {
    Object.keys(result).map((key) => {
      result[key.substring(1)] = result[key];
      delete result[key];
    });
  }

  return result;
};

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const enterpriseQrCode = async (contactData, purpose, address) => {
  let data;
  let waLink = "https://api.whatsapp.com/send?phone=919810109292&text=";
  const encodedWaLink = encodeURI(waLink);

  if (purpose) {
    data = `Hello, I am ${contactData.contact_name} from ${
      contactData.organisation_name
    }, location ${address.city_town ? address.city_town : ""}, ${
      address.district ? address.district : ""
    }. Can SkillsTrainer help me with "${purpose}"`;
  } else {
    data = `Hello, I am ${contactData.contact_name} from ${
      contactData.organisation_name
    }, location ${address.city_town ? address.city_town : ""}, ${
      address.district ? address.district : ""
    }. I am interested to know how I can be associated with SkillsTrainer.`;
  }

  let encodedData = encodeURI(data);
  const finalEncodedLink = encodedWaLink + encodedData;
  console.log(finalEncodedLink);
  return finalEncodedLink;
};
