import React, { Component } from "react";
import { ActionTypes } from "redux/actions/Quiz/ActionTypes";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { s3QuizImgBaseUrl } from "api/Consts";

const mapStateToProps = (state) => ({
  ...state.quiz,
  ...state.mode,
  ...state.pager,
  ...state.questionAnswered,
});

const mapDispatchToProps = (dispatch) => ({
  onAnswer: (payload) => dispatch({ type: ActionTypes.QuizAnswer, payload }),
});
class Questions extends Component {
  onAnswer(question, option) {
    let quiz = JSON.parse(JSON.stringify(this.props.quiz));

    let q = quiz.courses_questions.find((x) => x.id === question.id);

    q.quest_answer.forEach((x) => {
      x.selected = false;
    });

    q.quest_answer.find((x) => x.id === option.id).selected = true;

    const questionAnswerPayload = {};
    questionAnswerPayload[this.props.pager.index] = true;
    this.props.onAnswer({
      quiz: quiz,
      questionAnswered: questionAnswerPayload,
    });
  }
  componentDidMount() {
    const history = createBrowserHistory();
    history.listen((newLocation, action) => {
      if (action === "POP") {
        history.go(1);
      }
    });
  }

  // componentWillReceiveProps() {
  //   this.loadPageQuestions();
  // }

  // async loadPageQuestions() {
  //   let questions = this.props.quiz.courses_questions
  //     ? this.props.quiz.courses_questions.slice(
  //         this.props.pager.index,
  //         this.props.pager.index + this.props.pager.size
  //       )
  //     : [];

  //   // if (this.props.language !== "English") {
  //   //   questions.forEach(async (question, index) => {
  //   //     const questionKey =
  //   //       "question." + question.id + "." + this.props.language.toLowerCase();
  //   //     const answerKeys = (question.quest_answer || []).map((ans) => {
  //   //       return "answer." + ans.id + "." + this.props.language.toLowerCase();
  //   //     });
  //   //     const multiLangKeys = [questionKey, ...answerKeys];

  //   //     const translations = await apolloClient
  //   //       .query({
  //   //         query: GET_QUESTION_ANSWER_TRANSLATIONS,
  //   //         variables: {
  //   //           key_array: multiLangKeys,
  //   //         },
  //   //       })
  //   //       .then(({ data }) => {
  //   //         return data.courses_multi_lang_contents;
  //   //       });

  //   //     for (const trans in translations) {
  //   //       const keySplitArray = translations[trans].key.split(".");
  //   //       if (keySplitArray[0] === "question") {
  //   //         const questionTranslation = translations[trans].content.question;
  //   //         questions[index].question_text = questionTranslation;
  //   //       } else {
  //   //         const answerTranslation = translations[trans].content.answer;
  //   //         const answerId = keySplitArray[1];

  //   //         questions[index].quest_answer.forEach((ans) => {
  //   //           if (String(ans.id) === answerId) {
  //   //             ans.answer_text = answerTranslation;
  //   //           }
  //   //         });
  //   //       }
  //   //     }
  //   //   });
  //   // }

  //   this.setState({ pageQuestions: questions });
  // }

  render() {
    let questions = this.props.quiz.courses_questions
      ? this.props.quiz.courses_questions.slice(
          this.props.pager.index,
          this.props.pager.index + this.props.pager.size
        )
      : [];
    return (
      <div id="quiz ">
        <h2 className="text-center font-weight-normal">
          {this.props.quiz.name}
        </h2>
        <hr />
        <div key={this.props.pager.index}>
          {questions &&
            questions.map((q) => (
              <div key={q.id}>
                <div className="badge badge-info p-2">
                  Question {this.props.pager.index + 1} of{" "}
                  {this.props.pager.count}.
                </div>
                <h3 className="font-bold text-xl ">
                  {this.props.pager.index + 1}. <span>{q.question_text}</span>
                </h3>
                <div
                  className={`${
                    q.question_type === 2
                      ? "grid grid-cols-2 space-x-4"
                      : "row text-left options"
                  } `}
                >
                  {q.quest_answer.map((option) => (
                    <div key={option.id} className="col-6 mt-2">
                      <div className="option text ">
                        {q.question_type === 1 && (
                          <label
                            className="font-weight-normal cursor-pointer flex flex-row space-x-2 py-1 "
                            htmlFor={option.id}
                          >
                            <input
                              className=""
                              id={option.id}
                              checked={option.selected}
                              type="radio"
                              onChange={() => this.onAnswer(q, option)}
                            />
                            {q.question_type === 1 && (
                              <span className="ml-2  ">
                                {option.answer_text}
                              </span>
                            )}
                          </label>
                        )}
                        {q.question_type === 2 && (
                          <label
                            className="font-weight-normal cursor-pointer py-1 "
                            htmlFor={option.id}
                          >
                            <div className="flex flex-row space-x-2 py-1">
                              <input
                                className=""
                                id={option.id}
                                checked={option.selected}
                                type="radio"
                                onChange={() => this.onAnswer(q, option)}
                              />
                              <div className="flex flex-col space-y-2">
                                {q.question_type === 2 &&
                                  q.mode === "picture" && (
                                    <div className="overflow-hidden max-h-32 w-56">
                                      <img
                                        src={`${s3QuizImgBaseUrl}/${option?.answer_img}`}
                                        alt="Example dd"
                                        className="pb-8"
                                      />
                                    </div>
                                  )}
                                {q.question_type === 2 && (
                                  <span className=" py-4 mt-8 w-64 ">
                                    {option.answer_text}
                                  </span>
                                )}
                              </div>
                            </div>
                          </label>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
        <hr />

        <div class="inline-flex mt-4 ml-4">
          {/* {this.props.quiz.config.allowBack && (
						<button
							id="first"
							className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
							onClick={this.props.move}
						>
							First
						</button>
					)} */}
          {this.props.quiz.config.allowBack && this.props.pager.index > 0 && (
            <button
              id="prev"
              className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-gray-300 rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              onClick={this.props.move}
            >
              Prev
            </button>
          )}
          {this.props.pager.index + 1 !== this.props.pager.count &&
            (!this.props.quiz.blockNext ||
              this.props.questionAnswered[this.props.pager.index]) && (
              <button
                id="next"
                className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                onClick={this.props.move}
              >
                Next
              </button>
            )}
          {/* <button
						id="last"
						className="text-gray-900 bg-orange border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
						onClick={this.props.move}
					>
						Last
					</button> */}
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Questions);
