import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import courseSvc from "services/Course";
import Loader from "assets/animated-images/Loader";

export default function OrderSummaryCheckout(props) {
  const {
    courseData,
    paymentFunction,
    paymentEvent,
    setCourseData,
    closeFunction,
    setPaymentStage,
  } = props;
  const [coupon, setCoupon] = useState({ value: "" });
  const [loaderState, setLoaderState] = useState({
    couponApplication: false,
    checkout: false,
  });
  const history = useHistory();

  const subscriptionRelationArray =
    courseData?.course_subscription_relation_array[0] || undefined;

  console.log(courseData);

  const redirectToCourse = () => {
    if (history.location?.pathname.split("/")[2] === String(courseData.id))
      closeFunction();
    else history.go("/courses/" + courseData.id);
  };

  const validateCoupon = async () => {
    await courseSvc
      .validateDiscountCoupon(coupon.value, courseData.id)
      .then((res) => {
        setLoaderState({ ...loaderState, couponApplication: false });
        setCoupon({ ...coupon, validationResponse: res });

        console.log(res);
        if (res.coupon_validity) {
          const discounted_cost = applyDiscount(
            courseData.cost - courseData.discount,
            res.discount,
            res.discount_type
          );

          const discounted_subscription_cost = courseData.is_subscription
            ? applyDiscount(
                subscriptionRelationArray.subscription_cost,
                res.discount_type === "percentage"
                  ? res.discount
                  : Math.floor(
                      res.discount / subscriptionRelationArray.interval
                    ),
                res.discount_type
              )
            : undefined;

          setCourseData({
            ...courseData,
            discounted_cost: discounted_cost,
            coupon_id: res.coupon_id,
            discounted_subscription_cost: discounted_subscription_cost,
          });
        } else {
          setCourseData({
            ...courseData,
            discounted_cost: undefined,
            coupon_id: undefined,
            discounted_subscription_cost: undefined,
          });
        }
      });
  };

  const applyDiscount = (cost, discount, discount_type) => {
    if (discount_type === "percentage") {
      return cost - (cost * discount) / 100;
    } else {
      return cost - discount < 0 ? 0 : cost - discount;
    }
  };

  useEffect(() => {
    if (paymentEvent?.intialized) {
      setLoaderState({ ...loaderState, checkout: false });
    }
  }, [paymentEvent?.intialized]);

  console.log("Discounted cost:", courseData.discounted_cost);

  return (
    <div>
      <h2 class="text-black opacity-75 font-extrabold mx-8 pd-2 pl-2">
        Order Summary
      </h2>
      <hr class=" w-11/12 mx-auto h-px mt-2 bg-gray-200 border-0 " />
      <div class="text-gray-500 mt-2 pl-1 mx-8 font-semibold flex flex-col justify-between">
        <div className="flex flex-row py-1">
          <div className="w-4/6 mr-4 flex flex-col">
            <span>{courseData.full_name}</span>
            <button
              onClick={redirectToCourse}
              className="btn-primary text-sm w-2/4 py-1 my-2 hover:opacity-70"
            >
              View Course
            </button>
          </div>
          <div>
            <div className={`mr-6 `}>
              {/* Course cost */}
              {paymentEvent?.type === "subscription"
                ? `₹ ${subscriptionRelationArray.subscription_cost} x ${subscriptionRelationArray.interval}`
                : `₹ ${
                    courseData.cost - courseData.discount > 0
                      ? courseData.cost - courseData.discount
                      : "0"
                  }`}
            </div>
          </div>
        </div>
        {coupon.validationResponse?.coupon_validity &&
          paymentEvent.type !== "subscription" && (
            <div className="flex flex-row py-1">
              <div className=" w-4/6 mr-4">Discount Applied</div>
              <div className="text-red-500 font-semibold -ml-2">
                {courseData.discounted_cost && courseData.discounted_cost > 0
                  ? `- ₹ ${
                      courseData.cost -
                      courseData.discount -
                      courseData.discounted_cost
                    }`
                  : "Free"}
              </div>
            </div>
          )}
        {/* GST Section - Should not render tax calculation if course is free */}
        {courseData.cost - courseData.discount > 0 &&
        coupon.validationResponse?.coupon_validity ? (
          courseData.discounted_cost &&
          courseData.discounted_cost !== 0 && (
            <div className="flex flex-row py-1">
              <div className=" w-4/6 mr-4">GST(18%)</div>
              <div>{`₹ ${parseFloat(courseData.discounted_cost * 0.18).toFixed(
                2
              )}`}</div>
            </div>
          )
        ) : (
          <div className="flex flex-row py-1">
            <div className=" w-4/6 mr-4">GST(18%)</div>
            <div>
              {paymentEvent.type === "subscription"
                ? `₹ ${parseFloat(
                    subscriptionRelationArray.subscription_cost * 0.18
                  ).toFixed(2)} x ${subscriptionRelationArray.interval}`
                : `₹ ${parseFloat(
                    (courseData.cost - courseData.discount) * 0.18
                  ).toFixed(2)}`}
            </div>
          </div>
        )}

        <hr class=" w-full mx-auto h-px mt-2 bg-gray-200 border-0 " />
        {/* Total Cost */}
        <div className="flex flex-row py-1 mt-1">
          <div
            className={`w-4/6 mr-4 ${
              paymentEvent.type !== "subscription" && "text-black opacity-75"
            }`}
          >
            Total
          </div>
          <div
            className={`${
              paymentEvent.type !== "subscription" && "text-black opacity-75"
            }`}
          >
            {paymentEvent.type === "subscription"
              ? `₹ ${parseFloat(
                  subscriptionRelationArray.subscription_cost * 1.18
                ).toFixed(2)} x ${subscriptionRelationArray.interval}`
              : coupon.validationResponse?.coupon_validity
              ? `₹ ${parseFloat(courseData.discounted_cost * 1.18).toFixed(2)}`
              : `₹ ${parseFloat(
                  (courseData.cost - courseData.discount) * 1.18
                ).toFixed(2)}`}
          </div>
        </div>
        {paymentEvent.type === "subscription" && (
          <hr class=" w-full mx-auto h-px mt-2 bg-gray-200 border-0 " />
        )}
        {paymentEvent.type === "subscription" && (
          <div className="flex flex-row py-1 mt-1">
            <div className="w-4/6 mr-4 text-black opacity-75 ">
              {" "}
              Grand Total
            </div>
            <div className="text-black opacity-75">
              {`₹ ${parseFloat(
                subscriptionRelationArray.subscription_cost *
                  subscriptionRelationArray.interval *
                  1.18
              ).toFixed(2)}`}
            </div>
          </div>
        )}
      </div>
      <hr class=" w-11/12 mx-auto h-px mt-2 bg-gray-200 border-0 " />
      <div className="text-black mt-2 mx-8 font-semibold flex flex-row justify-between py-1">
        <div>
          <input
            type="text"
            onBlur={(e) =>
              setCoupon({ ...coupon, value: e.target.value.toUpperCase() })
            }
            placeholder="Enter Coupon"
            className="w-4/6 bg-gray-50 border  border-gray-300 text-gray-500 text-sm rounded-md focus:ring-orange focus:border-orange block opacity-60 focus:opacity-100"
          />
        </div>

        <button
          className={`btn-secondary text-sm  ${
            courseData.is_subscription && paymentEvent.type === "subscription"
              ? "opacity-50"
              : "opacity-85"
          }`}
          disabled={
            courseData.is_subscription && paymentEvent.type === "subscription"
          }
          onClick={() => {
            setLoaderState({ ...loaderState, couponApplication: true });
            validateCoupon();
          }}
        >
          {loaderState.couponApplication ? (
            <Loader color={"white"} className={"h-5 mx-auto pl-3"} />
          ) : (
            "Apply"
          )}
        </button>
      </div>
      {/* Coupon Validation response */}
      {coupon.validationResponse && paymentEvent.type === "full_payment" && (
        <span
          className={`${
            coupon.validationResponse?.coupon_validity
              ? "text-green-500"
              : "text-red-500"
          } ml-8 mt-2 font-semibold text-sm`}
        >
          {coupon.validationResponse?.coupon_validity
            ? "COUPON APPLIED SUCCESSFULLY"
            : coupon.validationResponse?.error}
        </span>
      )}
      <hr class=" w-11/12 mx-auto h-px mt-2 bg-gray-200 border-0 " />
      <button
        className="btn-primary float-right my-3 mr-7 opacity-85"
        onClick={() => {
          setLoaderState({ ...loaderState, checkout: true });
          setPaymentStage({
            orderSummary: false,
            billingAddress: true,
          });
          // paymentFunction();
        }}
      >
        {loaderState.checkout ? (
          <Loader color={"white"} className={"h-5 mx-auto pl-3"} />
        ) : (
          "Proceed"
        )}
      </button>
    </div>
  );
}
