import { useEffect, useState } from "react";
import { Modal } from "skillstrainer-resource-library";
import axios from "../api/Api";
import { useHistory } from "react-router-dom";
import Toast from "../utils/Toast";

export default function CreateReferralCode(props) {
  const { user, complete, params } = props;
  const [createdReferralData, setcreatedReferralData] = useState();

  const history = useHistory();

  useEffect(async () => {
    const data = {
      id: params.scheme_id,
      user_id: params.user_id,
    };
    try {
      const response = await axios.post("/create_referral_code", data);
      setcreatedReferralData(response.data);
  
      console.log('response===', response);
      if(response){
        history.push("/referral");
      }
    }catch (error){
      console.log('error', error);
      Toast.error("Your Code is Not generated, there is some tecnical issue");
    }
    
  }, []);

  console.log(createdReferralData);
  return (
    <>
    {
      createdReferralData && (
        <Modal isOpen={true} onClose={complete}>
        <div style={{ maxHeight: "40rem" }} className="flex font-poppins">
          <div className="flex-1 flex flex-col">
            <div className="flex h-full w-full">
              <main className="flex flex-col w-full bg-white overflow-x-hidden overflow-y-scroll mb-8">
                <div className="flex w-full mx-auto px-6">
                  <div className="flex flex-col w-full h-full text-center">
                    <h1 className="text-2xl font-semibold text-japanese_indigo mx-2 text-center">
                    Your referral code has been created
                    </h1>
                    <div className="flex flex-col justify-evenly my-4">
                      
                        <div className="my-auto  text-xl font-semibold  rounded-md mr-4">
                        Your code is: <span className="text-orange">{createdReferralData?.referral_code}</span>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </Modal>
      )
    }
     
    </>
  );
}
