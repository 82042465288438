import { apolloClient } from "../App";
import { GET_USER_BATCH_COURSE_BY_UID } from "graphql/queries/UserQueries";
import { GET_BATCH_SLOTS_BY_COURSE_ID } from "graphql/queries/Courses";
import _ from "lodash";
import { GET_COURSE_SECTION } from "graphql/queries/CourseBuilder/CourseSection";
import { GET_COURSE_SECTION_MODULE } from "graphql/queries/CourseBuilder/CourseSection";
import { GET_COURSE_MAPPING_DATA } from "graphql/queries/CourseBuilder/CourseSection";
import { CHECK_USER_SCORM_STATUS } from "graphql/queries/CourseBuilder/CourseSection";
import { CHECK_MOD_TYPE } from "graphql/queries/CourseBuilder/CourseSection";
import { CHECK_USER_COMPLETION_BY_SECTION_TOPIC } from "graphql/queries/CourseBuilder/CourseSection";
import { GET_COURSE_CERTIFICATE_TEMP } from "graphql/queries/CourseBuilder/CourseSection";
import { GET_COURSE_QUIZ } from "graphql/queries/CourseBuilder/Quiz";
import { INSERT_USER_COURSE_QUIZ_ATTEMPT } from "graphql/mutations/CourseBuilder/Quiz";
import { INSERT_USER_COURSE_QUESTION_ATTEMPT } from "graphql/mutations/CourseBuilder/Quiz";
import { COURSE_MODULE_COMPLETION } from "graphql/mutations/CourseBuilder/CourseMod";
import { GET_USER_COURSES } from "graphql/queries/Courses";
import { GET_COURSE_TAX } from "graphql/queries/CourseBuilder/CourseTax";
import { GET_MODULE_RESTRICTION } from "graphql/queries/CourseBuilder/CourseSection";
import { GET_RESTRICTION_COMPLETION_STATUS } from "graphql/queries/CourseBuilder/CourseSection";
import { INSERT_SCORM_DATA } from "graphql/mutations/CourseBuilder/CourseMod";
import { UPDATE_COURSE_SCORM_STATUS_1_2 } from "graphql/mutations/CourseBuilder/CourseMod";
import { UPDATE_COURSE_SCORM_STATUS_2004 } from "graphql/mutations/CourseBuilder/CourseMod";
import { INSERT_USER_ATTEMPT } from "graphql/queries/CourseBuilder/CourseSection";
import { UPDATE_USER_ATTEMPT } from "graphql/queries/CourseBuilder/CourseSection";
import { GET_USER_MODULE_ATTEMPT } from "graphql/queries/CourseBuilder/CourseSection";
import { GET_SCORM_TRACK_DATA_BY_ATTEMPT } from "graphql/queries/CourseBuilder/CourseSection";
import { GET_USER_QUIZ_ATTEMPT } from "graphql/queries/CourseBuilder/Quiz";
import { UPDATE_USER_QUIZ_ATTEMPT } from "graphql/mutations/CourseBuilder/Quiz";
import { GET_COURSE_CERTIFICATE_DATA } from "graphql/queries/CourseBuilder/Certificate";
import { USER_COURSE_CERTIFICATE_ISSUE } from "graphql/queries/CourseBuilder/Certificate";
import { GET_COURSE_ENROLLMENTID } from "graphql/queries/CourseBuilder/CourseSection";
import { INSERT_USER_COURSE_CERTIFICATE_ISSUE } from "graphql/mutations/CourseBuilder/CourseMod";
import { CERTIFICATE_VERIFY } from "graphql/queries/CourseBuilder/Certificate";
import { GET_COURSE_COMPLETION_TOPICS } from "graphql/queries/CourseBuilder/CourseSection";
import { GET_USER_COURSE_COMPLETED_TOPICS } from "graphql/queries/CourseBuilder/CourseSection";
import { MANUAL_USER_COURSE_ENROLL } from "graphql/mutations/razorpay";
import { USER_COURSE_COMPLETE } from "graphql/mutations/CourseBuilder/CourseMod";
// import { USER_COURSE_COMPLETE } from "graphql/mutations/CourseBuilder/CougetUserDetailsrseMod";
import { GET_USER_CERTIFICATE_DATA } from "graphql/queries/CourseBuilder/Certificate";
import { checkCourseComplete } from "api/Course";
import { error } from "utils/error";

import {
  getCourseSection
} from '../hooks/liveClass';
import { fetchCourseCompletionTopics, fetchUserCourseCompletionTopics } from "hooks/course";
import { fetchCourseModuleMapping } from "hooks/courseModule";

class liveClass {
  getDifference(array1, array2) {
    return array1.filter((object1) => {
      return array2.some((object2) => {
        return object1.mapping_id === object2.mapping_id;
      });
    });
  }

  getUserDetails(userId) {
    return apolloClient
      .query({
        query: GET_USER_BATCH_COURSE_BY_UID,
        variables: { user_id: userId },
      })
      .then(({ data }) => {
        return data;
      });
  }

  getBatchSlotsByCourseId(course_id) {
    return apolloClient
      .query({
        query: GET_BATCH_SLOTS_BY_COURSE_ID,
        variables: { course_id: course_id },
      })
      .then(({ data }) => {
        return data;
      });
  }

  async getCourseSection(courseid) {
    return await getCourseSection(courseid);
  }

  async getCourseSectionModule(courseid, sectionid) {
    const result = await apolloClient
      .query({
        query: GET_COURSE_SECTION_MODULE,
        variables: {
          course_id: courseid,
          section_id: sectionid,
        },
      })
      .then((res) => res.data.courses_course_module_mapping);

    return result;
  }

  async getCourseMapping(id) {
    const result = await fetchCourseModuleMapping(id);

    return result;
  }

  async checkUserScormStatus(user_id, props) {
    const result = await apolloClient
      .query({
        query: CHECK_USER_SCORM_STATUS,
        variables: {
          mapping_id: props.modData.mapping_id,
          attempt_id: props.modData.attempt_id,
          user_id: user_id,
        },
      })
      .then((res) =>
        res.data.courses_course_scorm_track_data.length > 0 ? true : false
      );

    return result;
  }

  async checkModType(mod_id) {
    const result = await apolloClient
      .query({
        query: CHECK_MOD_TYPE,
        variables: {
          mod_id: mod_id,
        },
      })
      .then((res) => res.data.courses_course_module_mapping[0].Module.type);

    return result;
  }

  async checkUserCompletionBySection(section_id, user_id, mapping_id) {
    const result = await apolloClient
      .query({
        query: CHECK_USER_COMPLETION_BY_SECTION_TOPIC,
        variables: {
          section_id: section_id,
          user_id: user_id,
          mapping_id: mapping_id,
        },
      })
      .then((res) =>
        res.data.courses_course_module_completion.length > 0 ? true : false
      );

    return result;
  }

  async getCourseCompletionCert(mapping_id, course_id) {
    const result = await apolloClient
      .query({
        query: GET_COURSE_CERTIFICATE_TEMP,
        variables: {
          mapping_id: mapping_id,
          course_id: course_id,
        },
      })
      .then((res) =>
        res.data.courses_course_certificate
          ? res.data.courses_course_certificate
          : false
      );

    return result;
  }

  async getCourseQuiz(module_mapping_id) {
    const result = await apolloClient
      .query({
        query: GET_COURSE_QUIZ,
        variables: {
          module_mapping_id: module_mapping_id,
        },
      })
      .then((res) => JSON.parse(JSON.stringify(res.data)));

    return result;
  }

  // async insertUserQuizAttempt(
  //   mapping_id,
  //   max_marks,
  //   result,
  //   earnedMarks,
  //   userId,
  //   completion_percentage
  // ) {
  //   return await apolloClient
  //     .mutate({
  //       mutation: INSERT_USER_COURSE_QUIZ_ATTEMPT,
  //       variables: {
  //         mapping_id: mapping_id,
  //         max_marks: max_marks,
  //         result: result < completion_percentage ? "failed" : "passed",
  //         total_score: earnedMarks,
  //         attempt_percentage: Math.round(result),
  //         user_id: userId,
  //       },
  //     })
  //     .then(
  //       ({ data }) => data.insert_courses_user_course_quiz_attempt.returning[0]
  //     );
  // }

  async UpdateUserQuizAttempt(
    mapping_id,
    max_marks,
    result,
    earnedMarks,
    userId,
    completion_percentage,
    attempt_id,
    course_id
  ) {
    return await apolloClient
      .mutate({
        mutation: UPDATE_USER_QUIZ_ATTEMPT,
        variables: {
          mapping_id: mapping_id,
          max_marks: max_marks,
          result: result < completion_percentage ? "failed" : "passed",
          total_score: earnedMarks ? earnedMarks : 0,
          attempt_percentage: Math.round(result),
          user_id: userId,
          course_id: course_id,
          id: attempt_id,
          attempt_completed: true,
          attempt_end: new Date(),
        },
      })
      .then(
        ({ data }) => data.update_courses_user_course_quiz_attempt.returning[0]
      );
  }

  async insertUserQuizAttempt(data, userId, filterAttemptCount) {
    return await apolloClient
      .mutate({
        mutation: INSERT_USER_COURSE_QUIZ_ATTEMPT,
        variables: {
          attempt_completed: false,
          attempt_number: filterAttemptCount + 1,
          attempt_start: new Date(),
          course_id: data.modData.course_id,
          mapping_id: data.modData.mapping_id,
          user_id: userId,
        },
      })
      .then(
        ({ data }) => data.insert_courses_user_course_quiz_attempt.returning[0]
      );
  }

  async insertUserQuestionAttempt(
    mapping_id,
    questions,
    attempt_id,
    userId,
    course_id
  ) {
    questions.map(async (e) => {
      const correctAnswerTextResponse = e.quest_answer.filter((a) => {
        return a.isAnswer;
      });

      let UserTextResp;

      UserTextResp = e.quest_answer.filter((a) => {
        return a.selected;
      });

      return await apolloClient
        .mutate({
          mutation: INSERT_USER_COURSE_QUESTION_ATTEMPT,
          variables: {
            mapping_id: parseInt(mapping_id),
            question_id: e.id,
            question_correct_answer:
              e.question_type === 1
                ? correctAnswerTextResponse[0].answer_text
                : "no_answer",
            response: e.isCorrect,
            user_id: userId,
            marks: e.isCorrect ? e.marks : 0,
            attempt_quiz_id: attempt_id,
            course_id: course_id,
            user_answer_text: UserTextResp[0].answer_text || null,
            answer_id: UserTextResp[0].id,
          },
        })
        .then(
          ({ data }) =>
            data.insert_courses_user_course_question_attemept.returning[0]
        );
    });
  }

  // async insertUserQuestionAttempt(data,userId) {

  //     return await apolloClient
  //       .mutate({
  //         mutation: INSERT_USER_COURSE_QUESTION_ATTEMPT,
  //         variables: {
  //           mapping_id: data.modData.mapping_id,
  //             user_id: userId,
  //             mod_id: data.modData.mod_id,
  //             course_id: data.modData.course_id,
  //             attempt_quiz_id:data.attempt_id

  //         },
  //       })
  //       .then(
  //         ({ data }) =>
  //           data.insert_courses_user_course_question_attemept &&
  //           data.insert_courses_user_course_question_attemept.returning[0]
  //       );

  // }

  async insertCourseModuleComplete(mode, mapping_id, userId) {
    console.log("invoked insertCourseModuleComplete");
    const result = await apolloClient
      .mutate({
        mutation: COURSE_MODULE_COMPLETION,
        variables: {
          user_id: userId,
          mod_id: mode.mod_id,
          course_id: mode.course_id,
          section_id: mode.section_id,
          completion_status: 1,
          completion_date: new Date(),
          mapping_id: mapping_id,
        },
      })
      .then(({ data }) => data);

    const resdata = await this.getCourseCompletionTopics(mode.course_id);

    const finallength = resdata.length;

    const userRep = await this.GetUserCourseCompletedTopics(
      mode.course_id,
      userId
    );
    console.log("userRep", userRep);
    if (result) {
      const params = {
        user_id: userId,
        course_id: mode.course_id,
      };
      const checkCourse = await checkCourseComplete(params);
      console.log("checkCourse", checkCourse);
    }

    const getDiff = this.getDifference(userRep, resdata);

    let perc = ((finallength - getDiff.length) / finallength) * 100;

    let currentProgress = 100 - perc;

    return result;
  }

  async ManuallyMarkComplete(data, userId) {
    console.log("Invoked ManuallyMarkComplete");
    const result = await apolloClient
      .mutate({
        mutation: COURSE_MODULE_COMPLETION,
        variables: {
          user_id: userId,
          mod_id: data.mod_id,
          course_id: data.course_id,
          section_id: data.section_id,
          completion_status: 1,
          completion_date: new Date(),
          mapping_id: data.mapping_id,
        },
      })
      .then(
        ({ data }) => data.insert_courses_course_module_completion.returning[0]
      );

    const resdata = await this.getCourseCompletionTopics(data.course_id);

    const finallength = resdata.length;

    const userRep = await this.GetUserCourseCompletedTopics(
      data.course_id,
      userId
    );
    console.log("userRep", userRep);

    const getDiff = this.getDifference(userRep, resdata);

    let perc = ((finallength - getDiff.length) / finallength) * 100;

    let currentProgress = 100 - perc;

    if (result) {
      const params = {
        user_id: userId,
        course_id: data.course_id,
      };
      await checkCourseComplete(params);
    }

    return result;
  }

  async getUserCourse(user_id) {
    const result = await apolloClient
      .query({
        query: GET_USER_COURSES,
        variables: {
          user_id: user_id,
        },
      })
      .then(({ data }) => data.courses_user_course_enrolment);

    return result;
  }

  async GetCourseTax(course_id) {
    const result = await apolloClient
      .query({
        query: GET_COURSE_TAX,
        variables: {
          course_id: course_id,
        },
      })
      .then(({ data }) =>
        data.courses_course_tax_relation
          ? data.courses_course_tax_relation[0]
          : []
      );

    return result;
  }

  async getModuleRestriction(mapping_id) {
    const result = await apolloClient
      .query({
        query: GET_MODULE_RESTRICTION,
        variables: { mapping_id: mapping_id },
      })
      .then(({ data }) =>
        data.courses_module_completion_resrtriction
          ? data.courses_module_completion_resrtriction[0]
          : []
      );

    return result;
  }

  async getModuleRestrictionStatus(user_id, restriction_ids) {
    const restIds = restriction_ids.split(",").map(function (strVale) {
      return Number(strVale);
    });

    const result = await apolloClient
      .query({
        query: GET_RESTRICTION_COMPLETION_STATUS,
        variables: {
          user_id: user_id,
          mapping_id: restIds,
        },
      })
      .then(({ data }) =>
        data.courses_course_module_completion
          ? data.courses_course_module_completion.map((e) => e.mapping_id)
          : []
      );

    return result;
  }

  async CourseModuleCompletion(props, userId) {
    const result = await apolloClient
      .mutate({
        mutation: COURSE_MODULE_COMPLETION,
        variables: {
          user_id: userId,
          mod_id: props.modData.mod_id,
          course_id: props.modData.course_id,
          section_id: props.modData.section_id,
          completion_status: 1,
          completion_date: new Date(),
          mapping_id: props.modData.mapping_id,
        },
      })
      .then(({ data }) => data.insert_courses_course_module_completion)
      .catch(console.error);

    const resdata = await this.getCourseCompletionTopics(
      props.modData.course_id
    );

    const finallength = resdata.length;

    const userRep = await this.GetUserCourseCompletedTopics(
      props.modData.course_id,
      userId
    );
    console.log("userRep", userRep);

    const getDiff = this.getDifference(userRep, resdata);

    let perc = ((finallength - getDiff.length) / finallength) * 100;

    let currentProgress = 100 - perc;

    if (result) {
      const params = {
        user_id: userId,
        course_id: props.modData.course_id,
      };
      await checkCourseComplete(params);
    }

    return result;
  }

  async insertScormData(data, userId) {
    return await apolloClient
      .mutate({
        mutation: INSERT_SCORM_DATA,
        variables: {
          mapping_id: data.modData.mapping_id,
          user_id: userId,
          mod_id: data.modData.mod_id,
          course_id: data.modData.course_id,
          attempt_id: data.attempt_id,
        },
      })
      .then(({ data }) => data.insert_courses_course_scorm_track_data);
  }

  async UpadateScormStatus1_2(props, scormData) {
    const { scorm_completion_criteria } = props.modData;

    const result = await apolloClient
      .mutate({
        mutation: UPDATE_COURSE_SCORM_STATUS_1_2,
        variables: {
          course_id: props.modData.course_id,
          attempt_id: props.attempt_id,

          lesson_status: scormData.core.lesson_status,
          mod_id: props.modData.mod_id,
          suspend_data: scormData.suspend_data,
          user_id: scormData.core.student_id,
          score_max: scormData.core.score.max
            ? scormData.core.score.max.toString()
            : "0",
          score_min: scormData.core.score.min
            ? scormData.core.score.min.toString()
            : "0",
          score_raw: scormData.core.score.raw
            ? scormData.core.score.raw.toString()
            : "0",
          mapping_id: props.modData.mapping_id,
          session_time: scormData.core.session_time,
        },
      })
      .then(
        ({ data }) => data.update_courses_course_scorm_track_data.returning[0]
      )
      .catch(console.error);

    var flag = false;

    if (
      scormData.core.lesson_status == "passed" ||
      scormData.core.lesson_status == "failed"
    ) {
      await this.UpdateUSerAttempt(result.attempt_id);
    }

    // if(scorm_completion_criteria && scorm_completion_criteria.completed ){

    //   if(scormData.completion_status=='completed'){
    //     flag = true;
    //   }
    // }

    if (scorm_completion_criteria && scorm_completion_criteria.passed) {
      if (scormData.core.lesson_status == "passed") {
        flag = true;
      } else {
        flag = false;
      }
    }

    if (flag) {
      const complition = await this.CourseModuleCompletion(
        props,
        scormData.core.student_id
      );

      return complition;
    } else {
      return result;
    }
  }

  async UpadateScormStatus2004(props, scormData) {
    const { scorm_completion_criteria } = props.modData;

    const result = await apolloClient
      .mutate({
        mutation: UPDATE_COURSE_SCORM_STATUS_2004,
        variables: {
          course_id: props.modData.course_id,
          attempt_id: props.attempt_id,
          // last_intersection: scormData.lastinteractions,
          lesson_status: scormData.completion_status,
          success_status: scormData.success_status,
          mod_id: props.modData.mod_id,
          suspend_data: scormData.suspend_data,
          user_id: scormData.learner_id,
          score_max: scormData.score.max ? scormData.score.max.toString() : "0",
          score_min: scormData.score.min ? scormData.score.min.toString() : "0",
          score_raw: scormData.score.raw ? scormData.score.raw.toString() : "0",
          mapping_id: props.modData.mapping_id,
          session_time: scormData.session_time,
        },
      })
      .then(
        ({ data }) => data.update_courses_course_scorm_track_data.returning[0]
      )
      .catch(console.error);

    if (
      scormData.completion_status == "completed" ||
      scormData.success_status == "passed" ||
      scormData.success_status == "failed"
    ) {
      await this.UpdateUSerAttempt(result.attempt_id);
    }

    var flag = false;

    if (scorm_completion_criteria && scorm_completion_criteria.completed) {
      if (scormData.completion_status == "completed") {
        flag = true;
      }
    }

    if (scorm_completion_criteria && scorm_completion_criteria.passed) {
      if (scormData.success_status == "passed") {
        flag = true;
      } else {
        flag = false;
      }
    }

    if (flag) {
      const complition = await this.CourseModuleCompletion(
        props,
        scormData.learner_id
      );

      return complition;
    } else {
      return result;
    }
  }

  async InsertUSerAttempt(data, userId, filterAttemptCount) {
    return await apolloClient
      .mutate({
        mutation: INSERT_USER_ATTEMPT,
        variables: {
          attempt_completed: false,
          attempt_number: filterAttemptCount + 1,
          attempt_start: new Date(),
          course_id: data.modData.course_id,
          mapping_id: data.modData.mapping_id,
          user_id: userId,
        },
      })
      .then(
        ({ data }) =>
          data.insert_courses_course_module_user_attempt.returning[0]
      );
  }

  async UpdateUSerAttempt(id) {
    return await apolloClient
      .mutate({
        mutation: UPDATE_USER_ATTEMPT,
        variables: {
          id: id,
          attempt_completed: true,
          attempt_end: new Date(),
        },
      })
      .then(({ data }) => data);
  }

  async getUserModuleAttempt(props, user_id) {
    const result = await apolloClient
      .query({
        query: GET_USER_MODULE_ATTEMPT,
        variables: {
          course_id: props.modData.course_id,
          mapping_id: props.modData.mapping_id,
          user_id: user_id,
        },
      })
      .then(({ data }) => data.courses_course_module_user_attempt);

    return result;
  }

  async GetScormDataByAttempt(attempt_id) {
    const result = await apolloClient
      .query({
        query: GET_SCORM_TRACK_DATA_BY_ATTEMPT,
        variables: {
          attempt_id: attempt_id,
        },
      })
      .then(({ data }) => data.courses_course_scorm_track_data[0]);

    return result;
  }

  async getUserQuizAttempt(props, user_id) {
    const result = await apolloClient
      .query({
        query: GET_USER_QUIZ_ATTEMPT,
        variables: {
          course_id: props.modData.course_id,
          mapping_id: props.modData.mapping_id,
          user_id: user_id,
        },
      })
      .then(({ data }) => data.courses_user_course_quiz_attempt);

    return result;
  }

  async getCourseCertificateData(mapping_id) {
    const result = await apolloClient
      .query({
        query: GET_COURSE_CERTIFICATE_DATA,
        variables: {
          mapping_id: mapping_id,
        },
      })
      .then(({ data }) => data.courses_course_template_certificates_data[0]);

    return result;
  }

  async checkUserCertificateIssue(propsData) {
    const result = await apolloClient
      .query({
        query: USER_COURSE_CERTIFICATE_ISSUE,
        variables: {
          course_id: propsData.modData.course_id,
          user_id: propsData.modData.user_id,
        },
      })
      .then(({ data }) => data.courses_course_user_certificate_issue[0]);

    if (result) {
      return result;
    } else {
      const insertCert = await this.InsertUserCertificateIssue(propsData);

      if (insertCert) {
        await this.CourseModuleCompletion(propsData, propsData.modData.user_id);
      }
      return insertCert;
    }
  }

  async GetUserEnrollmentId(user_id, course_id) {
    const result = await apolloClient
      .query({
        query: GET_COURSE_ENROLLMENTID,
        variables: {
          course_id: course_id,
          user_id: user_id,
        },
      })
      .then(({ data }) => data.courses_user_course_enrolment[0]);

    return result;
  }

  async InsertUserCertificateIssue(propsData) {
    return await apolloClient
      .mutate({
        mutation: INSERT_USER_COURSE_CERTIFICATE_ISSUE,
        variables: {
          enrollment_id: propsData.modData.enrollment_id,
          issue_date: new Date(),
          mapping_id: propsData.modData.mapping_id,
          user_id: propsData.modData.user_id,
          course_id: propsData.modData.course_id,
        },
      })
      .then(
        ({ data }) =>
          data.insert_courses_course_user_certificate_issue.returning[0]
      );
  }

  async CheckCertificateVerify(paramsData) {
    const result = await apolloClient
      .query({
        query: CERTIFICATE_VERIFY,
        variables: {
          enrollment_id: paramsData.enrollment_id,
          mapping_id: paramsData.mapping_id,
        },
      })
      .then(({ data }) => data.courses_course_user_certificate_issue[0]);

    return result;
  }

  async getCourseCompletionTopics(courseid) {
    const result = await fetchCourseCompletionTopics(courseid);

    return result;
  }

  async GetUserCourseCompletedTopics(course_id, user_id) {
    const result = await fetchUserCourseCompletionTopics(course_id, user_id);

    return result;
  }

  async ManualUserCourseEnroll({ courseId, userId, partnerId }) {
    return apolloClient
      .mutate({
        mutation: MANUAL_USER_COURSE_ENROLL,
        variables: {
          course_id: courseId,
          enroll_status: true,
          user_id: userId,
          partner_id: partnerId,
        },
      })
      .then(
        ({ data }) => data?.insert_courses_user_course_enrolment?.returning[0]
      )
      .catch((e) => {
        console.error(e);
        error("Couldn't enroll into course");
      });
  }

  async insertUserCourseComplete(course_id, user_id, percentage) {
    return await apolloClient
      .mutate({
        mutation: USER_COURSE_COMPLETE,
        variables: {
          completed: true,
          completed_on: new Date(),
          course_id: course_id,
          percentage: percentage,
          user_id: user_id,
        },
      })
      .then(({ data }) => data.insert_courses_user_course_complete);
  }

  async getUserCertificateData(course_id, user_id) {
    const result = await apolloClient
      .query({
        query: GET_USER_CERTIFICATE_DATA,
        variables: {
          user_id: user_id,
          course_id: course_id,
        },
      })
      .then((res) => res.data.courses_certificates[0]);

    return result;
  }
}

const liveClassService = new liveClass();

export default liveClassService;
