import { gql } from "@apollo/client";

export const GET_REFERRAL_SCHEMES = gql`
  query getReferralSchemes {
    courses_referral_scheme(order_by: { created_at: desc }) {
      id
      admin_user_id
      referrer_reward_type
      referrer_reward
      referee_reward_type
      referee_reward
      action
      constraints
      created_at
      updated_at
      is_active
      name
    }
  }
`;

export const FETCH_REFEREE_NAME = gql`
  query fetchUsers($id: bigint) {
    courses_users(where: { id: { _eq: $id } }) {
      id
      name
    }
  }
`;
