import { useQuery } from "@tanstack/react-query";
import queryClient from "./queryClient";
import {
  FETCH_COURSE_CATEGORIES,
  FETCH_DEFAULT_CATEGORIES,
  GET_USER_COURSES,
} from "graphql/queries/Courses";
import { apolloClient } from "../App";
// Async function to fetch default course categories
export const fetchDefaultCourseCategories = async () => {
  const data = await queryClient.fetchQuery({
    queryKey: ["fetchDefaultCourseCategories"],
    queryFn: async () => {
      const response = await apolloClient.query({
        query: FETCH_DEFAULT_CATEGORIES,
        variables: {},
      });
      // Deep cloning the categories to ensure immutability
      const categories = _.cloneDeep(response.data.courses_course_categories);
      return categories;
    },
    // Optionally, you can specify options like staleTime, cacheTime, etc.
  });

  console.log("🚀 ~ fetchCourseCategories ~ categories:", data);

  return data;
};

export const useFetchCourseCategories = () => {
  return useQuery(
    ["fetchDefaultCourseCategories"],
    fetchCourseCategories, // Directly reference the async function without arguments
    {
      // You can specify additional options like staleTime, cacheTime, etc., if needed.
    }
  );
};

export const fetchCourseCategories = async () => {
  const data = await queryClient.fetchQuery({
    queryKey: ["fetchCourseCategories"],
    queryFn: async () => {
      const response = await apolloClient.query({
        query: FETCH_COURSE_CATEGORIES,
        variables: {},
      });
      // Deep cloning the categories to ensure immutability
      const categories = _.cloneDeep(response.data.courses_course_categories);
      return categories;
    },
    // Optionally, you can specify options like staleTime, cacheTime, etc.
  });

  console.log("🚀 ~ fetchCourseCategories ~ categories:", data);

  return data;
};

export const useCourseCategories = () => {
  return useQuery(
    ["fetchCourseCategories"],
    fetchCourseCategories, // Directly reference the async function without arguments
    {
      // You can specify additional options like staleTime, cacheTime, etc., if needed.
    }
  );
};

// Async function to fetch courses for a specific user
export const fetchUserCourses = async (userId) => {
  const data = await queryClient.fetchQuery({
    queryKey: ["fetchUserCourses", userId],
    queryFn: async () => {
      const response = await apolloClient.query({
        query: GET_USER_COURSES,
        variables: { user_id: userId },
      });
      // Check for errors in the response
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      // Process and return the course data
      return response.data.courses_user_course_enrolment.map(
        (enrollment) => enrollment.course_relation_obj
      );
    },
    enabled: !!userId, // Only fetch if userId is provided
  });

  console.log("🚀 ~ getUserCourses ~ courses:", data);

  return data;
};

export const useGetUserCourses = (userId) => {
  return useQuery(["getUserCourses", userId], () => fetchUserCourses(userId), {
    enabled: !!userId, // Only attempt to fetch if a userId is provided
    // Additional options like staleTime, cacheTime, etc., can be specified here
  });
};
