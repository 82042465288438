import axios from "axios";
import { getLoginCookie, getJwtToken } from "../utils/Auth";
import { domain } from "./Consts";
import { translateApiError } from "utils/error";

export function getHeaders() {
  var headers = {};

  if (getLoginCookie()) {
    headers = {
      ...headers,
      "access-token": getLoginCookie(),
      "jwt-token": getJwtToken(),
    };
  }

  return headers;
}

let Axios = axios.create({
  baseURL: domain + "/api",
  headers: getHeaders(),
});

export const refreshAxios = () => {
  Axios.interceptors.request.use(
    (config) => {
      config.baseURL = domain + "/api";
      config.headers = getHeaders();
      return config;
    },
    (error) => Promise.reject(error)
  );
};
refreshAxios();

// adding response intereptor to log user out on unauthorised response
Axios.interceptors.response.use(
  (data) => {
    // console.log("Intercepted response", data);
    // Check for hasura errors
    return data;
  },
  (error) => {
    const wasForbidden =
      error.response && [403, 401].includes(error.response.status);
    if (wasForbidden) window.location.reload();

    throw translateApiError(error);
  }
);

export default Axios;

export async function uploadFile(file) {
  const { name: image_name } = file;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  const result = await new Promise((res) => {
    reader.onloadend = () => {
      res(reader.result);
    };
  });
  return Axios.post("/upload_file", {
    image_data: result,
    image_name,
  }).then(({ data }) => data);
}

export async function getFileUrl(...args) {
  const [url] = args;
  console.log(args);
  return Axios.post("/get_file", { data_url: url }).then(({ data }) => data);
}
