import { gql } from "@apollo/client";

export const GET_COURSE = gql`
  query MyQuery {
    courses_course {
      created_at
      duration
      updated_at
      description
      end_date
      full_name
      id
      identifier
      image_url
      is_live_course
      is_moodle_course
      discount
      course_category_id
      cost
      start_date
      nsqf_level
      publish
      short_name
      multilang
      moodle_course_url
      moodle_course_id
      moodle_config_id
    }
  }
`;
export const GET_COURSE_BY_SELECTED_ID = gql`
  query MyQuery {
    courses_course(where: { id: { _eq: "" } }) {
      id
      full_name
      is_live_course
      nsqf_level
      start_date
      duration
      description
      created_at
    }
  }
`;

export const GET_COUPONS_CODE = gql`
  query ($user_id: bigint) {
    courses_st_user_coupons(where: { user_id: { _eq: $user_id } }) {
      coupon_id
      user_id
    }
  }
`;
