import React, { useEffect, useState } from "react";
import CourseCard from "components/CourseCard";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import liveClassService from "services/liveClass";
import { FetchCourseProgress } from "redux/actions/Course";

export default function Mycourse() {
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const [course, setCourse] = useState([]);
  const courseProgress = useSelector(({ course }) => course.courseProgress);

  const dispatch = useDispatch();

  useEffect(async () => {
    const usercourse = await liveClassService.getUserCourse(userId);
    setCourse(usercourse);

    if (usercourse) dispatch(FetchCourseProgress(userId));
  }, []);

  return (
    <div>
      <div className="p-4">
        <h1
          className="font-bold text-center my-4 text-2xl
           lg:text-4xl text-japanese_indigo"
        >
          My Courses
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {course &&
          course.map((course, idx) => {
            return (
              <>
                <Link to={`/course/view/${course.course_relation_obj.id}`}>
                  <CourseCard
                    data={course.course_relation_obj}
                    courseProgress={courseProgress && courseProgress}
                  />
                </Link>
              </>
            );
          })}
      </div>
    </div>
  );
}
