import { gql } from "@apollo/client";

export const GET_ORDER_DETAILS = gql`
  query GetOrderDetails($id: Int) {
    courses_user_course_order(where: { id: { _eq: $id } }) {
      id
      amount
      course_id
      currency
      rajorpay_order_id
      rajorpay_payment_id
      rajorpay_signature
      order_status
      invoice
      invoice_id
      cost
      discount
      tax
      ordercourse {
        full_name
        cost
        tax_relation {
          course_tax_relation_object {
            tax_percentage
            tax_name
          }
          tax_id
        }
      }
      subscription_order
      subscription_id
      coupon_id
      order_email
      order_create_at
      order_contact
      ordercoupon {
        abs_discount
        code
        discount
      }
    }
  }
`;

export const GET_SUBSCRIPTION_ORDER = gql`
  query GetSubscriptionOrder($id: Int, $user_id: Int) {
    courses_user_course_subscription(
      where: { id: { _eq: $id }, user_id: { _eq: $user_id } }
    ) {
      payment_method
      paid_count
      current_start
      end_at
      current_end
      charge_at
      course_id
      user_id
      subscription_id
      subscription_created_at
      status
      start_at
      source
      remaining_count
      razorpay_signature
      razorpay_payment_id
      rajorpay_customer_id
      plan_id
      total_count
      id
      course_relation {
        cost
        full_name
      }
    }
  }
`;

export const GET_USER_SUBSCRIPTION_ORDER = gql`
  query GetSubscriptionOrder($user_id: Int) {
    courses_user_course_subscription(where: { user_id: { _eq: $user_id } }) {
      id
      payment_method
      paid_count
      current_start
      current_end
      charge_at
      end_at
      course_id
      user_id
      subscription_id
      subscription_created_at
      status
      start_at
      source
      remaining_count
      razorpay_signature
      razorpay_payment_id
      rajorpay_customer_id
      plan_id
      total_count
      course_relation {
        cost
        full_name
      }
    }
  }
`;

export const GET_USER_ALL_ORDERS = gql`
  query GetAllUserOrders($user_id: Int) {
    courses_user_course_order(
      where: {
        user_id: { _eq: $user_id }
        rajorpay_order_id: { _is_null: false }
      }
      order_by: { id: desc }
    ) {
      id
      subscription_order
      order_status
      order_create_at
      ordercourse {
        full_name
        id
      }
      amount
      coupon_id
      course_id
      currency
      customer_id
      enrollment_id
      order_contact
      order_email
      subscription_id
      rajorpay_signature
      rajorpay_payment_id
      rajorpay_order_id
    }
  }
`;
