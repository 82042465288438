import React, { useEffect } from "react";
import { useHistory } from "react-router";

export default function JoinBatch(props) {
  const { params, complete } = props;

  const history = useHistory();

  useEffect(() => {
    history.push("/join-batch/" + params.batchId);
    complete();
  }, []);

  return null;
}
